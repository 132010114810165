import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DndDropEvent, DropEffect } from "ngx-drag-drop";

export interface DragAndDropListInputOption {
  content: string;
  data: any;
}

@Component({
  selector: "app-drag-and-drop-list-input",
  templateUrl: "./drag-and-drop-list-input.component.html",
  styleUrls: ["./drag-and-drop-list-input.component.css"],
})
export class DragAndDropListInputComponent implements OnInit {
  @Input() label: string = "";
  @Input() options: DragAndDropListInputOption[] = [];
  @Input() disabled = false;

  @Output() optionsChange: EventEmitter<DragAndDropListInputOption[]> = new EventEmitter<DragAndDropListInputOption[]>();

  constructor() {}

  ngOnInit(): void {}

  onDragged(item: any, list: DragAndDropListInputOption[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
      this.optionsChange.emit(list);
    }
  }

  onDrop(event: DndDropEvent, list?: DragAndDropListInputOption[]) {
    if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {
      let index = event.index;

      if (typeof index === "undefined") {
        index = list.length;
      }

      list.splice(index, 0, event.data);
      this.optionsChange.emit(list);
    }
  }
}
