<div class="form-group">
  <div class="d-flex flex-column">
    <label><ng-container i18n>Options</ng-container></label>
    <div *ngFor="let opt of options.values; let i = index; let last = last; trackBy: trackByFn" class="d-flex mb-1 align-items-center">
      <input class="form-control" type="text" [ngModel]="options.values[i]" (ngModelChange)="options.values[i] = $event" />
      <ng-container>
        <div
          class="c-pointer d-flex align-items-center justify-content-center"
          [ngStyle]="{ background: options.base64Images[i] ? '' : '#eee' }"
          style="height: 40px; position: relative; background: #eee; margin: 0 5px; border-radius: 5px; white-space: nowrap; width: 130px"
        >
          <span i18n *ngIf="!options.base64Images[i]">Add image</span>
          <img style="object-fit: cover; height: 100%" *ngIf="options.base64Images[i]" [src]="domSanitizer.bypassSecurityTrustUrl(options.base64Images[i])" />
          <input
            type="file"
            accept="image/*"
            (change)="onImageUploaded(i, $event.target.files[0])"
            style="opacity: 0; position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; cursor: pointer"
          />
        </div>
      </ng-container>
      <button class="btn" (click)="removeOption(i)" tabindex="-1">
        <fa-icon style="color: red" [icon]="faMinus"></fa-icon>
      </button>
    </div>
    <div>
      <button class="btn" (click)="addNewOption()">
        <fa-icon style="color: black" [icon]="faPlus"></fa-icon>
      </button>
    </div>
  </div>
</div>
