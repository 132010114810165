import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { Door } from "../models/Door";
import { Permission, PermissionStatus, PermissionType } from "../models/Permission";
import { Warehouse } from "../models/Warehouse";
import { RouteHelperService } from "../router.service";
import { ModalService } from "../services/modal.service";
import { RestService } from "../services/rest.service";
import { TimespanConverterService } from "../timespan-converter.service";
import { File as FileModel } from "../models/File";
import { WarehouseService } from "../services/warehouse.service";
import { Role } from "../models/Role";

enum ViewType {
  GRAPHICAL,
  TABLE,
}

const ShowDoorCalendarsSideBySideLocalStorageSettings = {
  key: "showDoorCalendarsSideBySide",
  values: {
    TRUE: "true",
    FALSE: "false",
  },
};

const SynchronizeCalendarsTimeLocalStorageSettings = {
  key: "synchronizeCalendarsTime",
  values: {
    TRUE: "true",
    FALSE: "false",
  },
};

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-warehouse-page",
  templateUrl: "./warehouse-page.component.html",
  styleUrls: ["./warehouse-page.component.css"],
})
export class WarehousePageComponent implements OnInit, OnDestroy {
  warehouse: Warehouse = null;
  editable: boolean = false;
  disabled: boolean = true;
  permission: Permission = null;
  permissionStatus = PermissionStatus;

  ViewType = ViewType;
  hasFetchedReserved: boolean = false;

  filteredDoors: Door[] = null;
  doorFilterText = "";

  viewType: ViewType = ViewType.TABLE;

  sharedDoorDate: Date = new Date();
  appliedSharedDoorDate: Date | null = null;

  showDoorCalendarsSideBySide: boolean = true;
  synchronizeCalendarsTime: boolean = false;

  calendarElementsRefs: any[] = [];

  warehouseImageFile: File | null = null;

  hasPermissionToBook = false;
  hasPermissionToBookTwoPhase = false;

  Role = Role;

  constructor(
    private http: RestService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    public auth: AuthService,
    public warehouseService: WarehouseService,
    private msg: MessageTranslationService,
    private routeHelper: RouteHelperService,
    private timespanConverter: TimespanConverterService,
    private modalService: NgbModal,
    public appModalService: ModalService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.initializeShowDoorCalendarsSideBySide();
    this.initializeSynchronizeCalendarsTime();

    let id = this.route.snapshot.paramMap.get("id");
    this.http.post<Warehouse>(`api/warehouse/get/${id}`, {}).subscribe(
      (w) => {
        this.warehouse = w;

        if (this.warehouse?.availability?.minimumNotice) {
          this.warehouse.availability.minimumNotice = this.timespanConverter.convertFromTimespan(this.warehouse.availability.minimumNotice);
        }

        this.UpdateEditable();
        this.UpdatePermission();

        for (let i = 0; i < this.warehouse.doors.length; i++) {
          const door = this.warehouse.doors[i];
          door.reservedDatePick = new Date();
          this.warehouse.doors[i].filterString = (door.name + " " + door.description).toLowerCase();
        }

        this.filteredDoors = this.warehouse.doors;
        this.applyDoorFilters();
      },
      (error) => {
        this.toast.error(this.msg.fetchWarehouseError());
      }
    );
  }

  FileUrl(file: any) {
    return FileModel.Url(file);
  }

  async Upload() {
    await this.warehouseService.uploadWarehouseImage(this.warehouseImageFile, this.warehouse);
  }

  initializeShowDoorCalendarsSideBySide() {
    // read the setting from localstorage
    const showDoorCalendarsSideBySideFromLocalStorage = localStorage.getItem(ShowDoorCalendarsSideBySideLocalStorageSettings.key);
    this.onShowDoorCalendarsSideBySideChange(showDoorCalendarsSideBySideFromLocalStorage === ShowDoorCalendarsSideBySideLocalStorageSettings.values.TRUE);
  }

  onShowDoorCalendarsSideBySideChange(value: boolean) {
    this.showDoorCalendarsSideBySide = value;
    localStorage.setItem(
      ShowDoorCalendarsSideBySideLocalStorageSettings.key,
      value ? ShowDoorCalendarsSideBySideLocalStorageSettings.values.TRUE : ShowDoorCalendarsSideBySideLocalStorageSettings.values.FALSE
    );

    this.onApplyDateToAllCalendars();
    this.setupSynchronizedCalendarScrolls();
  }

  initializeSynchronizeCalendarsTime() {
    // read the setting from localstorage
    const synchronizeCalendarsTimeFromLocalStorage = localStorage.getItem(SynchronizeCalendarsTimeLocalStorageSettings.key);
    this.onSynchronizeCalendarsTimeChange(synchronizeCalendarsTimeFromLocalStorage === SynchronizeCalendarsTimeLocalStorageSettings.values.TRUE);
  }

  onSynchronizeCalendarsTimeChange(value: boolean) {
    this.synchronizeCalendarsTime = value;
    localStorage.setItem(
      SynchronizeCalendarsTimeLocalStorageSettings.key,
      value ? SynchronizeCalendarsTimeLocalStorageSettings.values.TRUE : SynchronizeCalendarsTimeLocalStorageSettings.values.FALSE
    );

    this.setupSynchronizedCalendarScrolls();
  }

  public doorFilterTextChange(txt) {
    const txtProcessed = txt.trim().toLowerCase();
    this.doorFilterText = txtProcessed;

    this.applyDoorFilters();
  }

  public applyDoorFilters() {
    this.doorFilterFullText();
  }

  public doorFilterFullText() {
    if (this.doorFilterText.length === 0) {
      this.filteredDoors = this.warehouse.doors;
      return;
    }

    this.filteredDoors = this.warehouse.doors.filter((a) => a.filterString.includes(this.doorFilterText));
  }

  UpdateEditable() {
    if (!this.auth.loggedInUser) {
      return;
    }

    this.editable = this.auth.IsWarehouse() && this.auth.loggedInUser.company.id == this.warehouse.companyId;
  }

  UpdatePermission() {
    this.permission = null;
    if (this.warehouse.permissions && this.warehouse.permissions.length > 0) {
      this.permission = this.warehouse.permissions.find((p) => p.carrierId === this.auth.loggedInUser.id);
    }

    this.hasPermissionToBook = this.warehouseService.hasPermissionToBook(this.warehouse, this.permission);
    this.hasPermissionToBookTwoPhase = this.warehouseService.hasPermissionToBookTwoPhase(this.warehouse, this.permission, this.warehouse.company);
  }

  Edit() {
    this.disabled = false;
  }

  openDeleteModal(content, permToDelete: Permission) {
    this.modalService.open(content).result.then(
      (result: boolean | null) => {},
      (_) => {}
    );
  }

  Delete() {
    this.http.post<Door>(`api/warehouse/deleteWarehouse/${this.warehouse.id}`, {}).subscribe(
      (d) => {
        this.routeHelper.GoTo(`my-warehouses`);
        this.modalService.dismissAll();
        this.toast.success(this.msg.deleteWarehouseSuccess());
      },
      (error) => {
        this.toast.error(this.msg.unknownError());
      }
    );
  }

  AddDoor() {
    this.http.post<Door>(`api/warehouse/addDoor/${this.warehouse.id}`, {}).subscribe(
      (d) => {
        this.routeHelper.GoTo(`door/${d.id}`);
      },
      (error) => {
        this.toast.error(this.msg.addDoorError());
      }
    );
  }

  async RequestPermission() {
    const success = await this.warehouseService.requestPermission(this.warehouse.id);
    if (success) {
      this.routeHelper.Refresh();
    }
  }

  async Update() {
    if (this.warehouse?.availability) {
      const availability = this.warehouse?.availability;
      const keys = ["minimumNotice", "maxArrivalInacurracy", "workTimeFrom", "workTimeTo"];
      for (const key of keys) {
        if (availability[key]) {
          availability[key] = this.timespanConverter.convertToTimespan(availability[key]);
        }
      }
    }

    await this.Upload();

    this.http.post(`api/warehouse/update`, this.warehouse).subscribe(
      (d) => {
        this.toast.success(this.msg.updateWarehouseSuccess());
        this.routeHelper.Refresh();
      },
      (error) => {
        this.toast.error(this.msg.updateWarehouseError());
      }
    );
  }

  CancelUpdate() {
    this.disabled = true;
    this.ngOnInit();
  }

  toggleView() {
    this.setupSynchronizedCalendarScrolls();
  }

  setupSynchronizedCalendarScrolls() {
    if (this.viewType === ViewType.GRAPHICAL && this.synchronizeCalendarsTime) {
      this.enableSynchronizedCalendarScrolls();
    } else {
      this.clearSynchronizedCalendarScrolls();
    }
  }

  enableSynchronizedCalendarScrolls() {
    setTimeout(() => {
      this.calendarElementsRefs = this.elementRef.nativeElement.querySelectorAll("#warehouse-graphical-view-wrapper .fc-scrollgrid-section-body .fc-scroller");
      if (this.calendarElementsRefs.length <= 1) {
        return;
      }

      const scrollAllElementsExceptOne = (scrollTop: number, excludingIndex: number) => {
        this.calendarElementsRefs.forEach((element, index) => {
          if (index === excludingIndex) {
            return;
          }

          element.scrollTop = scrollTop;
        });
      };

      this.calendarElementsRefs.forEach((element, index) => {
        element.onscroll = function () {
          const scrollTop = this.scrollTop;
          scrollAllElementsExceptOne(scrollTop, index);
        };
      });

      scrollAllElementsExceptOne(this.calendarElementsRefs[0].scrollTop, 0);
    }, 100);
  }

  clearSynchronizedCalendarScrolls() {
    this.calendarElementsRefs = [];
  }

  onApplyDateToAllCalendars() {
    if (!this.sharedDoorDate) {
      return;
    }

    this.appliedSharedDoorDate = new Date(this.sharedDoorDate);
  }

  ngOnDestroy(): void {
    this.clearSynchronizedCalendarScrolls();
  }
}
