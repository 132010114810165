import { Component, ContentChild, ElementRef, EventEmitter, Output } from "@angular/core";
import { ViewModeDirective } from "./view-mode.directive";
import { EditModeDirective } from "./edit-mode.directive";
import { fromEvent, Subject } from "rxjs";
import { filter, take, switchMapTo } from "rxjs/operators";
import { untilDestroyed, UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy({ checkProperties: true })
@Component({
  selector: "editable",
  template: ` <ng-container *ngTemplateOutlet="currentView"></ng-container> `,
  styleUrls: ["./editable.component.css"],
})
export class EditableComponent {
  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective;
  @Output() update = new EventEmitter();
  @Output() modeChange = new EventEmitter<boolean>();

  editMode = new Subject();
  editMode$ = this.editMode.asObservable();

  mode: "view" | "edit" = "view";

  constructor(private host: ElementRef) {}

  ngOnInit() {
    this.viewModeHandler();
    this.editModeHandler();
  }

  toViewMode() {
    this.update.next();
    this.modeChange.next(false);
    this.mode = "view";
  }

  private get element() {
    return this.host.nativeElement;
  }

  private viewModeHandler() {
    fromEvent(this.element, "dblclick")
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.mode = "edit";
        this.modeChange.next(true);
        this.editMode.next(true);
      });
  }

  private editModeHandler() {
    const clickOutside$ = fromEvent(document, "click").pipe(
      filter(({ target }) => this.element.contains(target) === false),
      take(1)
    );

    this.editMode$.pipe(switchMapTo(clickOutside$), untilDestroyed(this)).subscribe((event) => this.toViewMode());
  }

  get currentView() {
    return this.mode === "view" ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  ngOnDestroy() {}
}
