import { Injectable } from "@angular/core";
import * as moment from "moment";
import { SelectedCalendarDate } from "./creating-reservation/reservation-calendar/reservation-calendar.component";
import { DateService } from "./date.service";
import { Holiday } from "./models/Holiday";

export interface EventTimeWindow {
  start?: string;
  end?: string;
  startDate?: Date;
  endDate?: Date;
  bookableWeekdays?: number[];
}

interface MomentTimeWindow {
  start: moment.Duration;
  end: moment.Duration;
}

interface TimeWindowOverlap {
  overlap: MomentTimeWindow;
  side: "left" | "right" | "middle";
}

@Injectable({
  providedIn: "root",
})
export class TimespanConverterService {
  constructor(private dateService: DateService) {}

  convertToTimespan(timespan: string) {
    const split = timespan.split(":");
    if (split.length !== 3) {
      return timespan;
    }

    const hours = Number(split[0]);
    if (hours == null || isNaN(hours)) {
      return timespan;
    }

    let days = 0,
      actualHours = hours;
    if (hours > 23) {
      days = Math.floor(hours / 24);
      actualHours = hours % 24;
    } else {
      return timespan;
    }

    return `${days}.${actualHours}:${split[1]}:${split[2]}`;
  }

  convertFromTimespan(timespan: string) {
    const splitOnDay = timespan.split(".");
    if (splitOnDay.length !== 2) {
      return timespan;
    }

    const days = Number(splitOnDay[0]);
    if (!days || isNaN(days) || days === 0) {
      return splitOnDay[1];
    }

    const split = splitOnDay[1].split(":");
    if (split.length !== 3) {
      return timespan;
    }

    const hours = Number(split[0]);
    if (hours == null || isNaN(hours)) {
      return timespan;
    }

    let actualHours = 24 * days + hours;
    return `${actualHours}:${split[1]}:${split[2]}`;
  }

  normalizeDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  durationToString(duration: moment.Duration) {
    return ("" + duration.hours()).padStart(2, "0") + ":" + ("" + duration.minutes()).padStart(2, "0") + ":" + ("" + duration.seconds()).padStart(2, "0");
  }

  dateToDuration(date: Date) {
    const parsedDate = moment(date);
    return ("" + parsedDate.hours()).padStart(2, "0") + ":" + ("" + parsedDate.minutes()).padStart(2, "0") + ":" + ("" + parsedDate.seconds()).padStart(2, "0");
  }

  calculateRestrictedPeriodsFromTimeWindows(timeWindows: EventTimeWindow[], range: SelectedCalendarDate, holidays: Holiday[]): EventTimeWindow[] {
    const preprocessedTimeWindows = this.preprocessTimeWindows(timeWindows);

    const getRestrictedPeriods = (dayIndex: number) => {
      let restrictedPeriods: MomentTimeWindow[] = [{ start: this.durationToMoment("00:00:00"), end: this.durationToMoment("23:59:59") }];
      for (const timeWindow of preprocessedTimeWindows) {
        if (!timeWindow.tw.bookableWeekdays) {
          continue;
        }

        if (!timeWindow.tw.bookableWeekdays.includes(dayIndex)) {
          continue;
        }

        const currentRestrictedPeriods = [];
        for (const restrictedPeriod of restrictedPeriods) {
          const trimmedTimeWindow = this.trimTimeWindowBasedOnOverlap(restrictedPeriod, timeWindow);
          currentRestrictedPeriods.push(...trimmedTimeWindow);
        }

        restrictedPeriods = currentRestrictedPeriods;
      }

      return restrictedPeriods;
    };

    const restrictedCalendarEvents: EventTimeWindow[] = [];

    for (var d = new Date(range.start); d < range.end; d.setDate(d.getDate() + 1)) {
      const isHoliday = holidays.some((h) => moment(h.date).isSame(d, "date"));
      if (isHoliday) {
        restrictedCalendarEvents.push({
          startDate: moment(d).set("hour", 0).set("minute", 0).set("second", 0).toDate(),
          endDate: moment(d).set("hour", 23).set("minute", 59).set("second", 59).toDate(),
        });
      } else {
        const dayIndex = d.getDay();
        const restrictedPeriods = getRestrictedPeriods(dayIndex);
        restrictedPeriods.forEach((period) => {
          restrictedCalendarEvents.push({
            startDate: moment(d).set("hour", period.start.hours()).set("minute", period.start.minutes()).toDate(),
            endDate: moment(d).set("hour", period.end.hours()).set("minute", period.end.minutes()).toDate(),
          });
        });
      }
    }

    return restrictedCalendarEvents;
  }

  private preprocessTimeWindows(timeWindows: EventTimeWindow[]) {
    // convert to moment and make sure start is not more than end
    return timeWindows.map((tw) => {
      let start = this.durationToMoment(tw.start);
      let end = this.durationToMoment(tw.end);

      const twStartCompareTwEnd = this.compareDurations(start, end);
      // start > end
      if (twStartCompareTwEnd > 0) {
        const tmp = start;
        start = end;
        end = tmp;
      }

      return {
        start,
        end,
        tw,
      };
    });
  }

  private durationToMoment(durationString: string) {
    return moment.duration(durationString);
  }

  private trimTimeWindowBasedOnOverlap(timeWindowToTrim: MomentTimeWindow, tw: MomentTimeWindow): MomentTimeWindow[] {
    const overlap = this.getOverlapOfTimeWindows(tw, timeWindowToTrim);
    if (overlap == null) {
      return [timeWindowToTrim];
    }

    if (overlap.side === "left") {
      return [
        {
          start: moment.duration(overlap.overlap.end).add(2, "minute"),
          end: moment.duration(timeWindowToTrim.end).subtract(1, "minute"),
        },
      ];
    } else if (overlap.side === "right") {
      return [
        {
          start: moment.duration(timeWindowToTrim.start).add(2, "minute"),
          end: moment.duration(overlap.overlap.start).subtract(1, "minute"),
        },
      ];
    } else {
      return [
        {
          start: moment.duration(timeWindowToTrim.start).add(4, "minute"),
          end: moment.duration(overlap.overlap.start).subtract(1, "minute"),
        },
        {
          start: moment.duration(overlap.overlap.end).add(4, "minute"),
          end: moment.duration(timeWindowToTrim.end).subtract(1, "minute"),
        },
      ];
    }
  }

  private getOverlapOfTimeWindows(tw1: MomentTimeWindow, tw2: MomentTimeWindow): TimeWindowOverlap | null {
    const tw1EndCompareTw2Start = this.compareDurations(tw1.end, tw2.start);

    // tw1.end < tw2.start? TW1S TW1E < TW2S TW2E
    if (tw1EndCompareTw2Start < 0) {
      return null;
    }

    const tw1EndCompareTw2End = this.compareDurations(tw1.end, tw2.end);
    // tw1.end <= tw2.end TW2S <= TW1E <= TW2E
    if (tw1EndCompareTw2End <= 0) {
      const tw1StartCompareTw2Start = this.compareDurations(tw1.start, tw2.start);
      // tw1.start <= tw2.start TW1S <= TW2S <= TW1E <= TW2E
      if (tw1StartCompareTw2Start <= 0) {
        return {
          overlap: {
            start: tw2.start,
            end: tw1.end,
          },
          side: "left",
        };
      }
      // tw1.start > tw2.start TW2S > TW1S <= TW1E <= TW2E
      else {
        return {
          overlap: {
            start: tw1.start,
            end: tw1.end,
          },
          side: "middle",
        };
      }
    }
    // tw1.end > tw2.end TW2S < TW2E > TW1E
    else {
      const tw1StartCompareTw2End = this.compareDurations(tw1.start, tw2.end);
      // tw1.start <= tw2.end TW2S < TW1S <= TW2E > TW1E
      if (tw1StartCompareTw2End <= 0) {
        return {
          overlap: {
            start: tw1.start,
            end: tw2.end,
          },
          side: "right",
        };
      }
      // tw1.start > tw2.end TW2S < TW2E < TW1S < TW1E
      else {
        return null;
      }
    }
  }

  // returns amount of seconds between left and right
  // left == right : 0
  // left < right : -secondsDiff
  // left > right : secondsDiff
  private compareDurations(left: moment.Duration, right: moment.Duration) {
    const leftSeconds = left.asSeconds();
    const rightSeconds = right.asSeconds();
    return leftSeconds - rightSeconds;
  }

  calculateExpiredTimeWindows(timeWindows: EventTimeWindow[], minNoticePeriod: string): EventTimeWindow[] {
    const preprocessedTimeWindows = this.preprocessTimeWindows(timeWindows);

    const expiredTimeWindows: MomentTimeWindow[] = [];

    let minNoticePeriodSeconds = 0;
    if (minNoticePeriod) {
      minNoticePeriodSeconds = this.durationToMoment(minNoticePeriod).asSeconds();
    }

    const dateWithMinNoticePeriod = moment().add(minNoticePeriodSeconds, "seconds");
    const isToday = dateWithMinNoticePeriod.isSame(new Date(), "day");
    let currentTimeTimeWindowEnd = moment.duration("23:59:59");
    if (isToday) {
      currentTimeTimeWindowEnd = moment.duration(this.dateToDuration(dateWithMinNoticePeriod.toDate()));
    }

    const currentTimeTimeWindow: MomentTimeWindow = {
      start: moment.duration("00:00:00"),
      end: currentTimeTimeWindowEnd,
    };

    for (const timeWindow of preprocessedTimeWindows) {
      if (!timeWindow.tw.bookableWeekdays.includes(moment().day())) {
        continue;
      }

      const overlap = this.getOverlapOfTimeWindows(timeWindow, currentTimeTimeWindow);
      if (overlap == null) {
        continue;
      }

      if (overlap.side === "left" || overlap.side === "middle") {
        expiredTimeWindows.push({
          start: timeWindow.start,
          end: timeWindow.end,
        });
      }

      if (overlap.side === "right") {
        expiredTimeWindows.push({
          start: timeWindow.start,
          end: currentTimeTimeWindow.end,
        });
      }
    }

    return expiredTimeWindows.map((period) => {
      const durationStart = this.durationToString(period.start);
      const durationEnd = this.durationToString(period.end);

      const startDate = this.dateService.mergeDateAndTime(new Date(), durationStart);
      const endDate = this.dateService.mergeDateAndTime(new Date(), durationEnd);

      return { startDate, endDate };
    });
  }

  convertMinutesToDuration(minutes: number): string {
    return this.durationToString(moment.duration(minutes, "minutes"));
  }
}
