import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/core/auth.service";
import { DateService } from "src/app/date.service";
import { FileExcerptDto } from "src/app/models/File";
import { ReservationDto, ReservationDtoType } from "src/app/models/MyReservations";
import { ReservationFieldType } from "src/app/models/ReservationField";
import { CountriesService } from "src/app/services/countries.service";
import { ReservationService } from "src/app/services/reservation.service";
import { SupportService } from "src/app/services/support.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reservation-view",
  templateUrl: "./reservation-view.component.html",
  styleUrls: ["./reservation-view.component.css"],
})
export class ReservationViewComponent implements OnInit, OnChanges {
  @Input() isRecurring: boolean | null = null;

  ReservationDtoType = ReservationDtoType;
  ReservationFieldType = ReservationFieldType;

  reservationId: number | null = null;
  reservationCode: string | null = null;

  loading = true;
  loadingDelete = false;
  reservation: ReservationDto | null = null;

  constructor(
    public support: SupportService,
    public contriesService: CountriesService,
    private auth: AuthService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private reservationService: ReservationService,
    public dateService: DateService
  ) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.route.paramMap.subscribe(async (params) => {
        this.reservationId = Number(params.get("id"));
        this.fetchReservation();
      });
      this.route.queryParams.subscribe((params) => {
        this.reservationCode = params.get("code");
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isRecurring) {
      this.fetchReservation();
    }
  }

  downloadReservationFile(file: FileExcerptDto) {
    this.support.downloadFile(file.url + "?reservationCode=" + this.reservation.code);
  }

  async fetchReservation() {
    if (this.isRecurring == null || this.reservationId == null) {
      return;
    }

    if (this.auth.loggedInUser == null) {
      return;
    }

    this.loading = true;
    this.reservation = await this.reservationService.getReservation(this.reservationId, this.isRecurring, this.auth.IsCarrier(), this.reservationCode);
    this.loading = false;
  }

  onDeleteClick(content: any) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      (result: boolean | null) => {},
      (_) => {}
    );
  }

  onEditClick() {
    this.reservationService.navigateToEditReservation(this.reservation.id, this.reservation.code);
  }

  onApproveClick() {
    this.reservationService.navigateToApproveReservation(this.reservation.id, this.reservation.code);
  }

  closeDeleteModal() {
    this.modalService.dismissAll();
  }

  getLinkToYamasArrival() {
    if (this.reservation.yamasArrivalId == null) {
      return null;
    }

    return `${environment.yamasUrl}/warehouse-arrival/${this.reservation.yamasArrivalId}`;
  }

  getLinkToYamasArrivalCreation() {
    return `${environment.yamasUrl}/gate-management?rC=${this.reservation.code}`;
  }

  async onDelete() {
    this.loadingDelete = true;
    const success = await this.reservationService.removeReservation(this.reservation.id, this.reservation.code, this.isRecurring);
    this.loadingDelete = false;

    if (success) {
      this.router.navigate(["/"]);
    }
  }
}
