import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-confirm-delete-modal",
  templateUrl: "./confirm-delete-modal.component.html",
  styleUrls: ["./confirm-delete-modal.component.css"],
})
export class ConfirmDeleteModalComponent implements OnInit {
  @Input() entity: "reservation" | null = null;
  @Input() isLoading: boolean = false;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onConfirm: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
