<div class="container app-container">
  <app-spinner *ngIf="loadingDoor"></app-spinner>
  <ng-container *ngIf="!loadingDoor && door">
    <h3 class="app-heading">
      <ng-container i18n>Door: {{ door.name }}</ng-container>
    </h3>

    <app-warehouse-breadcrumbs [door]="door"></app-warehouse-breadcrumbs>

    <app-accordion-with-content i18n-label label="Basic door data">
      <div class="form-group">
        <label><ng-container i18n="Name">Name</ng-container></label>
        <input class="form-control input-h3" placeholder="Name" [(ngModel)]="door.name" />
      </div>

      <div class="form-group">
        <label for="reason"><ng-container i18n="Description">Description</ng-container></label>
        <textarea
          id="reason"
          placeholder="Description"
          i18n-placeholder
          class="form-control"
          rows="3"
          [(ngModel)]="door.description"
          [ngModelOptions]="{ standalone: true }"
        ></textarea>
      </div>

      <div class="form-group mr-1 flex-grow-1">
        <label><ng-container i18n>Maximum pallets per day (0 = unlimited)</ng-container></label>
        <input class="form-control" type="number" i18n-placeholder [(ngModel)]="door.dailyPalletsLimit" />
      </div>

      <div class="my-2">
        <div class="form-group">
          <label><ng-container i18n="Reservation duration">Reservation duration</ng-container></label>
          <select [(ngModel)]="door.properties.type" class="form-control">
            <option [value]="reservationType.Fixed">
              <ng-container i18n="Fixed">Fixed</ng-container>
            </option>
            <option [value]="reservationType.Calculated">
              <ng-container i18n="Calculated from pallets">Calculated from pallets</ng-container>
            </option>
            <option [value]="reservationType.Free">
              <ng-container i18n="Free"> Free</ng-container>
            </option>
          </select>
        </div>
      </div>

      <div *ngIf="door.properties.type == reservationType.Calculated">
        <div class="d-flex">
          <div class="form-group mr-1 flex-grow-1">
            <label><ng-container i18n="Base time">Base time</ng-container></label>
            <input class="form-control" i18n-placeholder placeholder="Base time" [(ngModel)]="door.properties.baseTime" />
          </div>

          <div class="form-group ml-1 flex-grow-1">
            <label><ng-container i18n="Time per pallet">Time per pallet</ng-container></label>
            <input class="form-control" i18n-placeholder placeholder="Time per pallet" [(ngModel)]="door.properties.timePerPallet" />
          </div>
        </div>
      </div>

      <div>
        <app-availability-display [(availability)]="door.availability" [type]="door.properties.type" [doorId]="door.id"> </app-availability-display>
      </div>

      <div class="d-flex">
        <button class="btn btn-primary mt-4 mr-2" (click)="editDoor()">
          <ng-container i18n>Save Changes</ng-container>
        </button>
      </div>
      <br />
    </app-accordion-with-content>

    <app-accordion-with-content i18n-label label="Reservation filter fields">
      <small class="d-block" i18n
        >These fields are the important warehouse fields values that must be selected for this door to appear in the reservation. Navigate to
        <a [routerLink]="['/warehouse', door.warehouse.id]">warehouse settings</a> to set them.</small
      >
      <app-important-fields-filter [forDoor]="true" [warehouseId]="door.warehouse.id" [doorId]="door.id"></app-important-fields-filter>
      <br />
    </app-accordion-with-content>

    <app-accordion-with-content i18n-label label="Reservation fields settings">
      <app-reservation-fields [doorId]="door.id"></app-reservation-fields>
      <br />
    </app-accordion-with-content>

    <app-accordion-with-content i18n-label label="Email attachments">
      <small i18n class="mb-2 d-block">Configure email attachments that will be sent with every mail for this door.</small>
      <app-mail-attachments-settings [doorId]="door.id"></app-mail-attachments-settings>
    </app-accordion-with-content>
  </ng-container>
</div>
