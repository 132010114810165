<div class="container app-container arrival-table-container">
  <h1 class="app-heading">
    <ng-container i18n *ngIf="mode == ReservationFlowMode.EDITING">Edit reservation </ng-container>
    <ng-container i18n *ngIf="mode == ReservationFlowMode.APPROVING_TWO_PHASE">Approve reservation </ng-container>
    <ng-container i18n *ngIf="mode == ReservationFlowMode.CREATING_TWO_PHASE">Reserve two-phase </ng-container>
    <ng-container i18n *ngIf="mode == ReservationFlowMode.CREATING">Reserve </ng-container>
    <ng-container *ngIf="company" i18n> for company {{ company.name }}</ng-container>
  </h1>

  <!-- 
  <p style="font-size: 18px"><span style="font-weight: bold" i18n>Selected warehouse:</span> {{ warehouse.name }}</p>
  <p style="font-size: 18px" *ngIf="door"><span style="font-weight: bold" i18n>Selected door:</span> {{ door.name }}</p>
-->

  <div id="stepper1" class="bs-stepper">
    <div class="bs-stepper-header" role="tablist">
      <!-- your steps here -->
      <div class="step" data-target="#warehouse-part">
        <button type="button" class="step-trigger" role="tab" aria-controls="warehouse-part" id="warehouse-part-trigger" [disabled]="isReservationLoading">
          <span class="bs-stepper-circle">1</span>
          <span class="bs-stepper-label" i18n>Select warehouse</span>
        </button>
      </div>
      <div class="line"></div>
      <ng-container>
        <div class="step" data-target="#door-part" [ngClass]="{ 'd-none': mode === ReservationFlowMode.CREATING_TWO_PHASE }">
          <button
            type="button"
            class="step-trigger"
            role="tab"
            aria-controls="door-part"
            id="door-part-trigger"
            [disabled]="!selectedWarehouse || isReservationLoading"
          >
            <span class="bs-stepper-circle">2</span>
            <span class="bs-stepper-label" i18n>Select door</span>
          </button>
        </div>
        <div class="line" [ngClass]="{ 'd-none': mode === ReservationFlowMode.CREATING_TWO_PHASE }"></div>
      </ng-container>
      <div class="step" data-target="#reservation-part">
        <button
          type="button"
          class="step-trigger"
          role="tab"
          aria-controls="reservation-part"
          id="reservation-part-trigger"
          [disabled]="isReservationLoading || (mode != ReservationFlowMode.CREATING_TWO_PHASE ? !selectedDoor : !selectedWarehouse)"
        >
          <span class="bs-stepper-circle">{{ mode === ReservationFlowMode.CREATING_TWO_PHASE ? "2" : "3" }}</span>
          <span class="bs-stepper-label" i18n>Enter reservation data</span>
        </button>
      </div>
      <div class="line"></div>
      <ng-container>
        <div class="step" data-target="#reservation-time" [ngClass]="{ 'd-none': mode === ReservationFlowMode.CREATING_TWO_PHASE }">
          <button
            type="button"
            class="step-trigger"
            role="tab"
            aria-controls="reservation-time"
            id="reservation-time-trigger"
            [disabled]="!reservationData.isValid || isReservationLoading"
            (click)="rerenderForCalendar()"
          >
            <span class="bs-stepper-circle">4</span>
            <span class="bs-stepper-label" i18n>Select time</span>
          </button>
        </div>
        <div class="line" [ngClass]="{ 'd-none': mode === ReservationFlowMode.CREATING_TWO_PHASE }"></div>
      </ng-container>
      <div class="step" data-target="#reservation-review">
        <button
          type="button"
          class="step-trigger"
          role="tab"
          aria-controls="reservation-review"
          id="reservation-review-trigger"
          [disabled]="isReservationLoading || !reservationData.isValid || (mode != ReservationFlowMode.CREATING_TWO_PHASE && !reservationData.dateRange)"
          (click)="rerenderForCalendar()"
        >
          <span class="bs-stepper-circle">{{ mode === ReservationFlowMode.CREATING_TWO_PHASE ? "3" : "5" }}</span>
          <span class="bs-stepper-label" i18n>Review</span>
        </button>
      </div>
    </div>

    <div class="bs-stepper-content">
      <div id="warehouse-part" class="content" role="tabpanel" aria-labelledby="warehouse-part-trigger">
        <app-company-reservation-choose-warehouse
          [selectedWarehouse]="selectedWarehouse"
          (selectedWarehouseChange)="onWarehouseSelected($event)"
          [warehouses]="company?.warehouses"
          [canReadWarehouseIdFromQuery]="mode == ReservationFlowMode.CREATING"
        ></app-company-reservation-choose-warehouse>
      </div>
      <div id="door-part" class="content" role="tabpanel" aria-labelledby="door-part-trigger">
        <app-company-reservation-choose-door
          [warehouseId]="selectedWarehouse?.id"
          [doors]="selectedWarehouseDoors"
          (onDoorSelect)="onDoorSelected($event)"
          [selectedDoor]="selectedDoor"
          [selectedImportantFields]="reservationData?.importantFields"
          (onSelectAnotherWarehouse)="goToWarehouseSelect()"
          [isEditMode]="mode == ReservationFlowMode.EDITING"
        ></app-company-reservation-choose-door>
      </div>
      <div id="reservation-part" class="content" role="tabpanel" aria-labelledby="reservation-part-trigger">
        <app-company-reservation-reserve-door
          [mode]="mode"
          [reservationData]="reservationData"
          (onNextClick)="onReservationDataConfirmed()"
          (onReservationFieldsChange)="onReservationFieldsChange()"
        ></app-company-reservation-reserve-door>
      </div>
      <div id="reservation-time" class="content" role="tabpanel" aria-labelledby="reservation-time-trigger">
        <app-company-reservation-select-time
          [door]="selectedDoor"
          [reservation]="reservationData.reservation"
          [savedDateRange]="reservationData.dateRange"
          [reservationData]="reservationData.reservation.data"
          (onSelectDateRange)="onSelectDateRange($event)"
          (onNextClick)="onDateRangeConfirmed()"
          #selectTimeComponent
        ></app-company-reservation-select-time>
      </div>
      <div id="reservation-review" class="content" role="tabpanel" aria-labelledby="reservation-review-trigger">
        <app-company-reservation-review
          [reservationData]="reservationData"
          [loading]="isReservationLoading"
          [bookButtonText]="bookButtonText"
          [mode]="mode"
          [selectedWarehouse]="selectedWarehouse"
          [selectedDoor]="selectedDoor"
          (onReservationBooked)="onReservationConfirmed($event)"
        ></app-company-reservation-review>
      </div>
    </div>
  </div>
</div>
