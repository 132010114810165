<app-spinner *ngIf="isLoading"></app-spinner>
<div *ngIf="!isLoading && template">
  <div class="d-flex" style="gap: 10px">
    <app-app-text-input name="subjectTemplate" [(value)]="template.subjectTemplate" style="flex: 1" [disabled]="!canEdit">
      <ng-container i18n>Subject template</ng-container>
    </app-app-text-input>
    <app-email-template-placeholder-dropdown
      [location]="'subject'"
      (onPlaceholderSelect)="onSubjectPlaceholder($event)"
      [disabled]="!canEdit"
    ></app-email-template-placeholder-dropdown>
  </div>

  <div class="form-group mt-2">
    <div class="d-flex align-items-end justify-content-between mb-1">
      <label for="contentTemplate" i18n>Content template</label>
      <app-email-template-placeholder-dropdown
        [location]="'content'"
        (onPlaceholderSelect)="onContentPlaceholder($event)"
        [disabled]="!canEdit"
      ></app-email-template-placeholder-dropdown>
    </div>

    <quill-editor
      style="display: block"
      format="html"
      placeholder=""
      sanitize
      [modules]="modules"
      [(ngModel)]="template.contentTemplate"
      [disabled]="!canEdit"
    ></quill-editor>
  </div>

  <button class="btn btn-primary mt-2 d-block mb-2" *ngIf="canEdit" [disabled]="isSaveLoading" i18n (click)="saveTemplate()">Save</button>
</div>
