<app-reservation-calendar
  *ngIf="door"
  [existingEvents]="reservationEvents"
  [timeWindows]="doorTimeWindowEvents"
  [enableEventSelect]="dragToSelect"
  [selection]="selection"
  (selectionChange)="selectionChange.emit($event)"
  (selectedDateChange)="calendarSelectedDate = $event; computeReservationsForSelectedDate()"
  [minNoticePeriod]="door.availability.minimumNotice"
  (eventClick)="onReservationClick($event, detailsContent)"
  [granularityInMinutes]="door.availability.granularityMinutes"
  [forReading]="forReading"
  [selectedDate]="selectedDate"
  [mode]="mode"
  [holidays]="holidays"
></app-reservation-calendar>

<ng-template #detailsContent let-modal>
  <app-reservation-details-modal
    [reservation]="reservationForDetails"
    [allowEdit]="reservationForDetails.allowEdit"
    [allowApprove]="reservationForDetails.allowApprove"
    (onClose)="closeDetailsModal()"
    (onEdit)="reservationService.navigateToEditReservation(reservationForDetails.id, reservationForDetails.code)"
    (onApprove)="reservationService.navigateToApproveReservation(reservationForDetails.id)"
  ></app-reservation-details-modal>
</ng-template>
