import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-weekday-display",
  templateUrl: "./weekday-display.component.html",
  styleUrls: ["./weekday-display.component.css"],
})
export class WeekdayDisplayComponent implements OnInit, OnChanges {
  @Input() weekdays: number[] | null = null;

  sortedWeekdays: number[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.weekdays) {
      this.sortWeekDays();
    }
  }

  sortWeekDays() {
    const sortedWeekDays = [...(this.weekdays || [])];
    sortedWeekDays.sort();
    this.sortedWeekdays = sortedWeekDays;
  }
}
