import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { File } from "src/app/models/File";
import { WarehouseListItem } from "src/app/models/Warehouse";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-company-reservation-choose-warehouse",
  templateUrl: "./company-reservation-choose-warehouse.component.html",
  styleUrls: ["./company-reservation-choose-warehouse.component.css"],
})
export class CompanyReservationChooseWarehouseComponent implements OnInit, OnChanges {
  @Input() warehouses: WarehouseListItem[] | null = null;

  @Input() selectedWarehouse: WarehouseListItem | null = null;
  @Output() selectedWarehouseChange: EventEmitter<WarehouseListItem> = new EventEmitter<WarehouseListItem>();

  @Input() canReadWarehouseIdFromQuery: boolean | null = null;

  // selected, but not yet oficially confirmed with button click
  pendingSelectedWarehouse: WarehouseListItem | null = null;

  warehouseIdFromQuery: number | null = null;

  loading = false;

  File = File;

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      if (params.warehouseId) {
        this.warehouseIdFromQuery = Number(params.warehouseId);
        this.selectWarehouseFromQueryParams();
      }
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.warehouses || changes.canReadWarehouseIdFromQuery) {
      this.selectWarehouseFromQueryParams();
    }
  }

  selectWarehouseFromQueryParams() {
    if (this.warehouseIdFromQuery == null) {
      return;
    }

    if (!this.canReadWarehouseIdFromQuery) {
      return;
    }

    if (this.selectedWarehouse != null) {
      return;
    }

    if (this.warehouses == null) {
      return;
    }

    const warehouse = this.warehouses.find((w) => w.id === this.warehouseIdFromQuery);
    if (warehouse) {
      this.pendingSelectedWarehouse = warehouse;
    }
  }
}
