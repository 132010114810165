import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { Feature, Map, View } from "ol";
import { Geometry } from "ol/geom";
import Point from "ol/geom/Point";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat, transform } from "ol/proj";
import OSM from "ol/source/OSM";
import Vector from "ol/source/Vector";
import { Circle, Fill, Stroke, Style } from "ol/style";

const pointStyle = new Style({
  image: new Circle({
    radius: 6,
    fill: new Fill({
      color: "red",
    }),
    stroke: new Stroke({
      color: "red",
      width: 0.5,
    }),
  }),
});

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-map-location-picker",
  templateUrl: "./map-location-picker.component.html",
  styleUrls: ["./map-location-picker.component.css"],
})
export class MapLocationPickerComponent implements OnInit {
  map: Map;

  @Output() onLonLatSelected: EventEmitter<number[]> = new EventEmitter<number[]>();

  markerLayer: VectorLayer<Vector<Geometry>> | null = null;
  @Input() selectedLon: number | null = null;
  @Input() selectedLat: number | null = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedLon"] || changes["selectedLat"]) {
      this.addSelectedMarker();
    }
  }

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setupMap();
  }

  setupMap() {
    let center = fromLonLat([14.49731, 46.017755]);

    this.map = new Map({
      view: new View({
        center,
        zoom: 9,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      overlays: [],
      target: "mapLocationPicker",
    });

    this.map.on("click", (e) => {
      if (e.dragging) return;

      const lonlat = transform(e.coordinate, "EPSG:3857", "EPSG:4326");

      if (lonlat.length < 2) {
        return;
      }

      this.onLonLatSelected.emit(lonlat);
    });

    this.addSelectedMarker(true);
  }

  addSelectedMarker(centerOnMarker = false) {
    if (!this.map) {
      return;
    }

    if (this.selectedLat && this.selectedLon && !isNaN(this.selectedLat) && !isNaN(this.selectedLon)) {
      this.addMarker(this.selectedLon, this.selectedLat);

      if (centerOnMarker) {
        const center = fromLonLat([this.selectedLon, this.selectedLat]);
        this.map.setView(
          new View({
            center,
            zoom: 9,
          })
        );
      }
    } else {
      if (this.markerLayer) {
        this.map.removeLayer(this.markerLayer);
      }
    }
  }

  addMarker(lon: number, lat: number) {
    const vectorSource = new Vector();
    const point = new Point([lon, lat]).transform("EPSG:4326", "EPSG:3857");
    const feature = new Feature({ geometry: point });
    vectorSource.addFeature(feature);

    const layer = new VectorLayer({
      source: vectorSource,
      style: () => {
        return pointStyle;
      },
    });

    if (this.markerLayer) {
      this.map.removeLayer(this.markerLayer);
    }

    this.map.addLayer(layer);
    this.markerLayer = layer;
  }

  onLatChange(value: number) {
    this.onLonLatSelected.emit([this.selectedLon, value]);
  }

  onLonChange(value: number) {
    this.onLonLatSelected.emit([value, this.selectedLat]);
  }
}
