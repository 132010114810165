<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n>Details</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose.emit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" *ngIf="reservation">
  <div class="text-center table-container">
    <table class="table table-bordered">
      <tr>
        <th width="35%">Carrier name:</th>
        <td>
          {{ reservation.carrier.name || "/" }}
        </td>
      </tr>
      <tr *ngFor="let field of reservation.data || []">
        <th width="35%">
          <ng-container> {{ field.name }}</ng-container
          >:
        </th>
        <td>
          <span style="white-space: pre-line" *ngIf="field.type != ReservationFieldType.Checkbox">{{ field.value || "/" }}</span>
          <input *ngIf="field.type == ReservationFieldType.Checkbox" type="checkbox" disabled [(ngModel)]="field.value" />
        </td>
      </tr>
    </table>
  </div>

  <div class="col">
    <div class="row">
      <div *ngFor="let file of reservation.files" class="col-auto px-1 py-1">
        <a class="btn btn-secondary" [href]="file.url">
          {{ file.name }}
        </a>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer d-flex justify-content-between">
  <button type="submit" class="btn" (click)="onClose.emit()">
    <ng-container i18n>Close</ng-container>
  </button>
  <button *ngIf="allowEdit" class="btn btn-info" i18n (click)="onEdit.emit()">Edit</button>
  <button *ngIf="allowApprove" (click)="onApprove.emit()" class="ml-1 btn btn-secondary">
    <ng-container i18n>Approve</ng-container>
  </button>
</div>
