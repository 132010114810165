import { ReturnStatement } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { defaultUserMailSendingData, UserMailSendingData } from "../models/User";
import { RestService } from "../services/rest.service";

@Component({
  selector: "app-profile-mail-sending-settings",
  templateUrl: "./profile-mail-sending-settings.component.html",
  styleUrls: ["./profile-mail-sending-settings.component.css"],
})
export class ProfileMailSendingSettingsComponent implements OnInit {
  data: UserMailSendingData | null = null;

  canEdit = false;

  fields = [
    {
      fieldName: "sendMailsForReservationCreated",
      name: "Send mails for created reservation",
    },
    {
      fieldName: "sendMailsForReservationEdited",
      name: "Send mails for updated reservation",
    },
    {
      fieldName: "sendMailsForReservationDeleted",
      name: "Send mails for deleted reservation",
    },
    {
      fieldName: "sendMailsForRecurringReservationCreated",
      name: "Send mails for created recurring reservation",
    },
    {
      fieldName: "sendMailsForRecurringReservationEdited",
      name: "Send mails for updated recurring reservation",
    },
    {
      fieldName: "sendMailsForRecurringReservationDeleted",
      name: "Send mails for deleted recurring reservation",
    },
    {
      fieldName: "sendMailsForTwoPhaseReservationCreated",
      name: "Send mails for created two-phase reservation",
    },
    {
      fieldName: "sendMailsForTwoPhaseReservationConfirmed",
      name: "Send mails for approved two-phase reservation",
    },
    {
      fieldName: "sendMailsForTwoPhaseReservationEdited",
      name: "Send mails for updated two-phase reservation",
    },
    {
      fieldName: "sendMailsForTwoPhaseReservationDeleted",
      name: "Send mails for deleted two-phase reservation",
    },
  ];

  constructor(private httpClient: RestService, private auth: AuthService, private toast: ToastrService, private msgT: MessageTranslationService) {}

  ngOnInit(): void {
    this.setDefaults();
    this.fetchMailSendingData();
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.canEdit = this.auth.IsCarrier() || this.auth.IsWarehouseAdmin();
    });
  }

  setDefaults() {}

  async fetchMailSendingData() {
    try {
      const data = await this.httpClient.get<UserMailSendingData | null>(`api/user/getMailSendingData`).toPromise();

      this.data = {
        ...defaultUserMailSendingData,
        ...(data && data),
      };
    } catch (e) {
      console.log(e);
    }
  }

  async updateMailSendingData() {
    try {
      await this.httpClient.post<UserMailSendingData | null>(`api/user/updateMailSendingData`, this.data).toPromise();
      this.toast.success(this.msgT.updateSuccess());
    } catch (e) {
      console.log(e);
      this.toast.error(this.msgT.unknownError());
    }
  }
}
