<div class="form-group">
  <label style="display: flex" class="form-check-label" for="country">
    <ng-container
      >{{ fieldName }}<span *ngIf="required" style="color: red">*</span>
      <div *ngIf="helpText" [ngbTooltip]="helpTextTooltip" triggers="click:blur">
        <fa-icon style="color: black" class="ml-2 c-pointer" [icon]="faQuestionCircle"></fa-icon>
      </div>
    </ng-container>
    <ng-template #helpTextTooltip><div [innerHtml]="helpText" class="ql-editor"></div></ng-template>
  </label>

  <select
    [ngClass]="{ 'is-invalid': errorMessage != null }"
    [attr.required]="required"
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="selectedCountryId"
    (ngModelChange)="selectedCountryIdChange.emit($event)"
    id="country"
    name="country"
    class="form-control"
  >
    <option [value]="null" selected disabled>
      <ng-container i18n>Select a country...</ng-container>
    </option>
    <option [value]="country.id" *ngFor="let country of countriesService.countries">
      <ng-container>{{ country.name }}</ng-container>
    </option>
  </select>

  <div *ngIf="errorMessage" class="invalid-feedback">
    {{ errorMessage }}
  </div>
</div>
