import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-app-text-input",
  templateUrl: "./app-text-input.component.html",
  styleUrls: ["./app-text-input.component.css"],
})
export class AppTextInputComponent implements OnInit {
  @Input() type: string = "text";
  @Input() placeholder: string;
  @Input() noMargin: boolean = false;
  @Input() name: string;
  @Input() value: boolean;
  @Input() disabled: boolean = false;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}
