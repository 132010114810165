<div class="container app-container" *ngIf="auth.loggedInUser">
  <h1 class="app-heading">
    <ng-container i18n>Mail settings</ng-container>
  </h1>

  <app-accordion-with-content i18n-label label="General settings">
    <div class="form-group">
      <label for="language"><ng-container i18n>Default language for receiving mails</ng-container></label>
      <select
        [(ngModel)]="defaultMailLanguageId"
        id="defaultMailLanguageId"
        name="defaultMailLanguageId"
        class="form-control"
        *ngxPermissionsOnly="[Role.COMPANY_ADMIN]; authorisedStrategy: 'enable'; unauthorisedStrategy: 'disable'"
      >
        <option [value]="null" disabled selected>
          <ng-container i18n>Select language...</ng-container>
        </option>
        <option [value]="language.id" *ngFor="let language of languages">
          <ng-container i18n>{{ language.name }}</ng-container>
        </option>
      </select>
    </div>

    <app-app-checkbox [(value)]="sendContractInMail" name="sendContractInMail" [disabled]="!isAdmin">
      <ng-container i18n>Send YAMAS contract in emails</ng-container>
    </app-app-checkbox>

    <button
      *ngxPermissionsOnly="[Role.COMPANY_ADMIN]"
      class="btn btn-primary"
      i18n
      (click)="updateGeneralSettings()"
      [disabled]="defaultMailLanguageId == null"
    >
      Update
    </button>
  </app-accordion-with-content>

  <br />

  <app-accordion-with-content i18n-label label="Email templates">
    <small i18n class="mb-2 d-block">Configure how mails will look like. Select language and type to continue.</small>
    <div class="d-flex mb-2" style="gap: 10px">
      <div class="form-group">
        <label for="language"><ng-container i18n>Language</ng-container></label>
        <select [(ngModel)]="selectedLanguageId" id="language" name="language" class="form-control">
          <option [value]="null" disabled selected>
            <ng-container i18n>Select language...</ng-container>
          </option>
          <option [value]="language.id" *ngFor="let language of languages">
            <ng-container i18n>{{ language.name }}</ng-container>
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="type"><ng-container i18n>Type</ng-container></label>
        <select [(ngModel)]="selectedType" id="type" name="type" class="form-control">
          <option [value]="null" disabled selected>
            <ng-container i18n>Select type...</ng-container>
          </option>
          <option [value]="type" *ngFor="let type of templateTypes">
            <ng-container i18n>{{ msgT.translateEmailTemplateType(type) }}</ng-container>
          </option>
        </select>
      </div>
    </div>

    <app-profile-edit-mail-template
      [templateType]="selectedType"
      [canEdit]="isAdmin"
      [templateLanguageId]="selectedLanguageId"
    ></app-profile-edit-mail-template>
  </app-accordion-with-content>

  <app-accordion-with-content i18n-label label="Email attachments">
    <small i18n class="mb-2 d-block">Configure email attachments that will be sent with every mail.</small>
    <app-mail-attachments-settings [companyId]="auth.loggedInUser.company.id"></app-mail-attachments-settings>
  </app-accordion-with-content>

  <br />

  <app-profile-mail-sending-settings></app-profile-mail-sending-settings>

  <br />

  <app-profile-contact-mails-settings></app-profile-contact-mails-settings>
</div>
