<div class="app-container arrival-table-container">
  <h1 class="app-heading" i18n>Reservation archive</h1>

  <app-reservation-list-search-bar
    [warehouses]="warehousesOptions"
    [doors]="doorsOptions"
    [filter]="filter"
    (refresh)="doFetch()"
    (filterChange)="filter = $event; filterReservations()"
    [isArchive]="true"
  ></app-reservation-list-search-bar>

  <div>
    <div *ngIf="loadingCsv" class="spinner-border ml-1" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <app-reservation-table
    [reservations]="filteredReservations"
    [loading]="loading"
    [type]="ReservationDtoType.STANDARD"
    [isCarrier]="isCarrier"
    [allReservationColumns]="reservationColumns"
    [tableId]="'archive'"
  ></app-reservation-table>
</div>
