<app-spinner *ngIf="isLoading"></app-spinner>
<div *ngIf="!isLoading">
  <div *ngIf="attachments.length > 0">
    <ul>
      <li *ngFor="let attachment of attachments" class="mb-2">
        <div class="d-flex align-items-center" style="gap: 10px">
          <a style="color: black; text-decoration: underline; cursor: pointer" (click)="downloadAttachmentFile(attachment)">
            {{ attachment.name }}
          </a>
          <app-language-select [selectedLanguage]="attachment.language" (onSelect)="onAttachmentLanguageChange(attachment, $event)"></app-language-select>
          <fa-icon
            (click)="openRemoveAttachmentModal(deleteContent, attachment)"
            style="color: red"
            class="ml-2 c-pointer"
            [icon]="faTimes"
            *ngxPermissionsOnly="[Role.COMPANY_ADMIN]"
          ></fa-icon>
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="attachments.length === 0">
    <i i18n>No attachments yet.</i>
  </div>

  <div class="form-group mt-1" *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
    <label><ng-container i18n>Upload new attachment</ng-container></label>
    <input #attachmentFileInput type="file" (change)="attachmentToUpload = $event.target.files[0]" class="form-control-file" />
  </div>
  <div>
    <button class="btn btn-primary" [disabled]="isUploadingLoading" (click)="onUpload()" *ngIf="attachmentToUpload" i18n>Upload attachment</button>
  </div>
</div>

<ng-template #deleteContent let-modal>
  <app-confirmation-modal
    [isLoading]="loadingDelete"
    header="Delete attachment?"
    i18n-header
    subHeader="Confirm deleting attachment"
    i18n-subHeader
    cancelButtonText="Cancel"
    i18n-cancelButtonText
    confirmButtonText="Delete"
    i18n-confirmButtonText
    confirmButtonClass="btn-danger"
    (onCancel)="modalService.dismissAll()"
    (onConfirm)="removeAttachment()"
  >
  </app-confirmation-modal>
</ng-template>
