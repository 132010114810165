import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";
import { Role } from "src/app/models/Role";
import { ModalService } from "src/app/services/modal.service";
import { WarehouseService } from "src/app/services/warehouse.service";
import { WarehouseCompanyListItem } from "../../models/Warehouse";

@Component({
  selector: "app-my-warehouses",
  templateUrl: "./my-warehouses.component.html",
  styleUrls: ["./my-warehouses.component.css"],
})
export class MyWarehousesComponent implements OnInit {
  Role = Role;

  loading: boolean = true;
  isCarrier: boolean;

  myCompaniesWithWarehouses: WarehouseCompanyListItem[];

  filterText: string;

  constructor(private auth: AuthService, private warehouseService: WarehouseService, public modalService: ModalService) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.isCarrier = this.auth.IsCarrier();
      this.doFetch();
    });
  }

  async doFetch() {
    this.loading = true;

    try {
      this.myCompaniesWithWarehouses = await this.warehouseService.fetchMyWarehouses();
    } catch (e) {
      console.log(e);
    }

    this.loading = false;
  }

  onRefresh() {
    this.doFetch();
  }
}
