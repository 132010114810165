import { Component, Input, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { File } from "../models/File";
import { Role } from "../models/Role";
import { RestService } from "../services/rest.service";

@Component({
  selector: "app-upload-user-image",
  templateUrl: "./upload-user-image.component.html",
  styleUrls: ["./upload-user-image.component.css"],
})
export class UploadUserImageComponent implements OnInit {
  file: any = null;
  loading = false;

  Role = Role;

  constructor(public auth: AuthService, private toast: ToastrService, private msg: MessageTranslationService, private http: RestService) {}

  ngOnInit() {}

  FileUrl(file: any) {
    return File.Url(file);
  }

  get image() {
    if (this.auth.loggedInUser.company) {
      return this.auth.loggedInUser.company.image;
    } else {
      return this.auth.loggedInUser.image;
    }
  }

  Upload() {
    if (this.file == null) return;

    this.loading = true;

    const formData: FormData = new FormData();
    formData.append("file", this.file, this.file.name);
    return this.http.postFormData(`api/file/uploadImage`, formData).subscribe(
      (d) => {
        this.toast.success(this.msg.imageUploadedSuccess());
        this.auth.Refresh();
        this.loading = false;
      },
      (error) => {
        this.toast.error(this.msg.imageUploadedError());
        console.log(error);
        this.loading = false;
      }
    );
  }
}
