import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { Door } from "../models/Door";
import { Permission, PermissionStatus, PermissionType } from "../models/Permission";
import { Role } from "../models/Role";
import { RestService } from "../services/rest.service";
import { SupportService } from "../services/support.service";

@Component({
  selector: "app-warehouse-permissions",
  templateUrl: "./warehouse-permissions.component.html",
  styleUrls: ["./warehouse-permissions.component.css"],
})
export class WarehousePermissionsComponent implements OnInit {
  list: Array<Permission>;
  filteredPermissions: Array<Permission>;

  PermissionStatus = PermissionStatus;
  PermissionType = PermissionType;
  permToDelete: Permission | null = null;

  permissionToManage: Permission | null = null;
  permissionToManageSelectedDoors: Door[] = [];
  acceptLoading = false;

  deleteLoading = false;

  filterText: string = "";

  Role = Role;

  permissionCheckboxes = {
    [PermissionStatus.Accepted]: true,
    [PermissionStatus.Declined]: true,
    [PermissionStatus.Pending]: true,
  };

  doorsSettings: IDropdownSettings = {};

  constructor(
    private http: RestService,
    private support: SupportService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.Load();

    this.doorsSettings = {
      ...this.support.getDropdownDefaults(),
      noDataAvailablePlaceholderText: this.msgT.noDoorsAvailable(),
      idField: "id",
      textField: "name",
    };
  }

  Load() {
    this.onManagePermissionsClose();
    this.http.post<Array<Permission>>(`api/manage/permissions/`, {}).subscribe(
      (d) => {
        this.list = d;

        for (let i = 0; i < this.list.length; i++) {
          this.list[i].filterString = (this.list[i].carrier.name + " " + this.list[i].carrier.email + " " + this.list[i].warehouse.name).toLowerCase();
        }

        this.filteredPermissions = this.list;
        this.applyFilters();
      },
      (error) => {
        console.log(error);
        this.toast.error(this.msgT.unknownError());
      }
    );
  }

  onManagePermission(permission: Permission, content) {
    this.permissionToManage = permission;
    this.permissionToManageSelectedDoors = this.permissionToManage.permissionsForDoor.map((p) => p.door);
    this.modalService.open(content).result.then(
      () => {
        this.onManagePermissionsClose();
      },
      () => {
        this.onManagePermissionsClose();
      }
    );
  }

  onManagePermissionsClose() {
    this.modalService.dismissAll();
    this.permissionToManage = null;
    this.permissionToManageSelectedDoors = [];
  }

  async Accept() {
    if (!this.permissionToManage) {
      return;
    }

    this.acceptLoading = true;

    try {
      await this.http
        .post<Array<Permission>>(`api/manage/accept/${this.permissionToManage.id}`, {
          type: this.permissionToManage.type,
          allowedDoors: this.permissionToManageSelectedDoors.map((d) => d.id),
        })
        .toPromise();

      this.onManagePermissionsClose();
      this.Load();
    } catch (error) {
      console.log(error);
      this.toast.error(this.msgT.unknownError());
    }

    this.acceptLoading = false;
  }

  Decline(permission: Permission) {
    this.http.post<Array<Permission>>(`api/manage/decline/${permission.id}`, {}).subscribe(
      (d) => {
        this.Load();
      },
      (error) => {
        console.log(error);
        this.toast.error(this.msgT.unknownError());
      }
    );
  }

  Cancel(permission: Permission) {
    this.http.post<Array<Permission>>(`api/manage/cancel/${permission.id}`, {}).subscribe(
      (d) => {
        this.Load();
      },
      (error) => {
        console.log(error);
        this.toast.error(this.msgT.unknownError());
      }
    );
  }

  openDeleteModal(content, permToDelete: Permission) {
    this.permToDelete = permToDelete;
    this.modalService.open(content).result.then(
      (result: boolean | null) => {},
      (_) => {}
    );
  }

  Delete(permission: Permission) {
    this.deleteLoading = true;
    this.http.post<Array<Permission>>(`api/manage/deletePermission/${permission.id}`, {}).subscribe(
      (d) => {
        this.modalService.dismissAll();
        this.Load();
        this.deleteLoading = false;
      },
      (error) => {
        console.log(error);
        this.toast.error(this.msgT.unknownError());
        this.deleteLoading = false;
      }
    );
  }

  public filterTextChange(txt) {
    const txtProcessed = txt.trim().toLowerCase();
    this.filterText = txtProcessed;

    this.applyFilters();
  }

  public applyFilters() {
    this.filterFullText();
    this.filterStatusCheckboxes();
  }

  public filterFullText() {
    if (this.filterText.length === 0) {
      this.filteredPermissions = this.list;
      return;
    }

    this.filteredPermissions = this.list.filter((a) => a.filterString.includes(this.filterText));
  }

  public filterStatusCheckboxes() {
    this.filteredPermissions = this.filteredPermissions.filter((a) => this.permissionCheckboxes[a.status]);
  }

  public onRefreshClick() {
    this.ngOnInit();
  }

  public permissionCheckboxChange(event: any, status: PermissionStatus) {
    this.permissionCheckboxes[status] = event.target.checked;
    this.applyFilters();
  }
}
