<ng-container *ngIf="warehouse">
  <div class="modal-header">
    <h4 class="modal-title" i18n>Add door to warehouse {{ warehouse.name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel.emit()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group mb-2">
      <label><ng-container i18n>Name</ng-container></label>
      <input class="form-control" i18n-placeholder placeholder="Name" [(ngModel)]="addDoorInput.name" [disabled]="isLoading" (keydown.enter)="onAdd()" />
    </div>

    <div class="form-group mb-2">
      <label for="reason"><ng-container i18n>Description</ng-container></label>
      <textarea class="form-control" i18n-placeholder placeholder="Description" [(ngModel)]="addDoorInput.description" [disabled]="isLoading"></textarea>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="onCancel.emit()" [disabled]="isLoading">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button class="btn btn-primary" i18n [disabled]="!canAdd() || isLoading" (click)="onAdd()">Add</button>
  </div>
</ng-container>
