import { Component, OnInit } from "@angular/core";
import { LanguagePickerLanguage } from "../language-picker/language-picker.component";
import { SupportService } from "../services/support.service";

@Component({
  selector: "app-navbar-language-picker",
  templateUrl: "./navbar-language-picker.component.html",
  styleUrls: ["./navbar-language-picker.component.css"],
})
export class NavbarLanguagePickerComponent implements OnInit {
  window = window;

  constructor(public supportService: SupportService) {}

  ngOnInit(): void {}

  refreshPageWithSelectedLanguage(lang: LanguagePickerLanguage) {
    window.open(lang.link + location.pathname, "_self");
  }
}
