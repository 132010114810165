<div class="form-check form-check-inline">
  <input class="form-check-input" type="checkbox" id="isRecurring" [ngModel]="isRecurring" (ngModelChange)="isRecurringChange.emit($event)" />
  <label class="form-check-label" for="isRecurring">
    <ng-container i18n>Is recurring</ng-container>
  </label>
</div>

<ng-container *ngIf="isRecurring">
  <div class="form-group">
    <label for="rule"><ng-container i18n>Frequency</ng-container></label>
    <select [ngModel]="recurrenceRule" (ngModelChange)="recurrenceRuleChange.emit($event)" id="rule" name="rule" class="form-control">
      <option [value]="undefined" selected>
        <ng-container i18n>Every day</ng-container>
      </option>
      <option [value]="'1'">
        <ng-container i18n>Every monday</ng-container>
      </option>
      <option [value]="'2'">
        <ng-container i18n>Every tuesday</ng-container>
      </option>
      <option [value]="'3'">
        <ng-container i18n>Every wednesday</ng-container>
      </option>
      <option [value]="'4'">
        <ng-container i18n>Every thursday</ng-container>
      </option>
      <option [value]="'5'">
        <ng-container i18n>Every friday</ng-container>
      </option>
      <option [value]="'6'">
        <ng-container i18n>Every saturday</ng-container>
      </option>
      <option [value]="'0'">
        <ng-container i18n>Every sunday</ng-container>
      </option>
    </select>
  </div>

  <div class="d-flex">
    <div class="form-group mr-1 flex-grow-1">
      <label><ng-container i18n>From date</ng-container></label>
      <app-pick-date [date]="fromDate" (dateChange)="fromDateChange.emit($event)"></app-pick-date>
    </div>

    <div class="form-group ml-1 flex-grow-1">
      <label><ng-container i18n>To date</ng-container></label>
      <app-pick-date [date]="toDate" (dateChange)="toDateChange.emit($event)"></app-pick-date>
    </div>
  </div>
</ng-container>
