import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ReservationDto } from "src/app/models/MyReservations";
import { Reservation } from "src/app/models/Reservation";
import { ReservationFieldType } from "src/app/models/ReservationField";

@Component({
  selector: "app-reservation-details-modal",
  templateUrl: "./reservation-details-modal.component.html",
  styleUrls: ["./reservation-details-modal.component.css"],
})
export class ReservationDetailsModalComponent implements OnInit {
  ReservationFieldType = ReservationFieldType;

  @Input() reservation: ReservationDto | null = null;
  @Input() allowEdit: boolean = false;
  @Input() allowApprove: boolean = false;

  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output() onApprove: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
