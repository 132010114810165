import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AddDoorRequest } from "src/app/models/Door";
import { WarehouseService } from "src/app/services/warehouse.service";

@Component({
  selector: "app-add-door-modal",
  templateUrl: "./add-door-modal.component.html",
  styleUrls: ["./add-door-modal.component.css"],
})
export class AddDoorModalComponent implements OnInit {
  addDoorInput: AddDoorRequest = {
    name: "",
    description: "",
  };

  isLoading = false;

  @Input() warehouse: { id: number; name: number } = null;

  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onAddSuccess: EventEmitter<void> = new EventEmitter<void>();

  constructor(private warehouseService: WarehouseService, private router: Router) {}

  ngOnInit(): void {}

  canAdd() {
    return this.addDoorInput.name.trim().length > 0;
  }

  async onAdd() {
    if (!this.canAdd()) {
      return;
    }

    this.isLoading = true;

    const door = await this.warehouseService.addDoor(this.warehouse.id, this.addDoorInput);

    this.isLoading = false;

    if (!door) {
      return;
    }

    this.router.navigate(["door", door.id]);
    this.onAddSuccess.emit();
  }
}
