<app-accordion-with-content i18n-label label="Company image">
  <div *ngIf="image != null" class="p-3 d-flex justify-content-center" style="object-fit: cover">
    <img [src]="FileUrl(image)" style="max-height: 20vh" />
  </div>
  <ng-container *ngxPermissionsOnly="[Role.COMPANY_ADMIN, Role.OWL_CARRIER]">
    <div>
      <input type="file" (change)="file = $event.target.files[0]" class="form-control-file" />
    </div>
    <div class="my-2" *ngIf="file">
      <button (click)="Upload()" *ngIf="!loading" class="btn btn-primary">
        <ng-container i18n="Upload">Upload</ng-container>
      </button>
      <div class="spinner-border" *ngIf="loading" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-container>
</app-accordion-with-content>
