import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DoorService } from "src/app/door.service";
import { LocaleService } from "src/app/locale.service";
import { Door } from "src/app/models/Door";
import { ReservationField } from "src/app/models/ReservationField";
import { OnReservationFieldsChangeData } from "src/app/reservation-fields-form/reservation-fields-form.component";
import { RestService } from "src/app/services/rest.service";

export interface ChooseDoorData {
  door: Door;
  importantFields: ReservationField[];
}

@Component({
  selector: "app-company-reservation-choose-door",
  templateUrl: "./company-reservation-choose-door.component.html",
  styleUrls: ["./company-reservation-choose-door.component.css"],
})
export class CompanyReservationChooseDoorComponent implements OnInit, OnChanges {
  @Input() preselectedDoor: Door | null = null;
  @Input() isEditMode: boolean;

  @Input() selectedDoor: Door | null = null;
  @Input() selectedImportantFields: ReservationField[] | null = null;

  @Input() warehouseId: number | null = null;
  @Input() doors: Door[] | null = null;

  @Output() onDoorSelect: EventEmitter<ChooseDoorData> = new EventEmitter<ChooseDoorData>();
  @Output() onSelectAnotherWarehouse: EventEmitter<void> = new EventEmitter<void>();

  isLoading = true;

  importantFields: ReservationField[] = [];
  originalImportantFields: { id: number; value: any }[] = [];

  bookableDoors: Door[] = [];

  pendingSelectedDoor: Door | null = null;
  haveReservationsFieldsChanged: boolean = false;

  isSelectedDoorBookable: boolean | null = null;

  canSelectDoor: boolean = false;

  constructor(private http: RestService, private doorService: DoorService, private locale: LocaleService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.doors || changes.warehouseId || changes.isEditMode || changes.selectedDoor) {
      this.load();
    }

    if (changes.selectedImportantFields && this.selectedImportantFields) {
      this.originalImportantFields = this.selectedImportantFields.map((f) => ({
        id: f.id,
        value: f.value,
      }));
    }
  }

  async load(): Promise<void> {
    if (this.doors == null || this.warehouseId == null) {
      return;
    }

    if (this.isEditMode && !this.selectedDoor) {
      return;
    }

    this.isLoading = true;

    this.importantFields = [];
    this.canSelectDoor = false;

    try {
      this.importantFields = await this.http.get<ReservationField[]>(`api/settings/getImportantWarehouseReservationFields/${this.warehouseId}`).toPromise();
      this.applySelectedDoorData();
      if (this.importantFields.length === 0) {
        this.onImportantFieldsChange({ isValid: true, triggeredByChange: false });
      }
    } catch (e) {
      console.error("load error", e);
    }

    this.isLoading = false;
  }

  onImportantFieldsChange({ isValid, triggeredByChange }: OnReservationFieldsChangeData): void {
    this.canSelectDoor = isValid;
    this.haveReservationsFieldsChanged = triggeredByChange;
    if (this.canSelectDoor) {
      this.bookableDoors = this.doors.filter((door) => this.doorService.doReservationFieldsMatchDoor(door.doorFieldsFilters, this.importantFields));
    } else {
      this.bookableDoors = [];
    }

    this.resetPendingSelectedDoorIfNotInBookableDoors();
    this.warnUserIfImportantDataRemovesSelectedDoor();
  }

  resetPendingSelectedDoorIfNotInBookableDoors() {
    if (!this.findInBookableDoors(this.pendingSelectedDoor)) {
      this.pendingSelectedDoor = null;
    }
  }

  warnUserIfImportantDataRemovesSelectedDoor() {
    if (!this.selectedDoor) {
      this.isSelectedDoorBookable = null;
      return;
    }

    if (!this.findInBookableDoors(this.selectedDoor)) {
      // only warn once
      if (this.isSelectedDoorBookable === false) {
        return;
      }

      this.isSelectedDoorBookable = false;
      alert(`Warning: entered data cannot be applied to currently selected door ${this.selectedDoor.name}. Select a different door or undo changes.`);
    } else {
      this.isSelectedDoorBookable = true;
    }
  }

  findInBookableDoors(door: Door) {
    if (door == null) {
      return;
    }

    return this.bookableDoors.some((d) => d.id == door.id);
  }

  applySelectedDoorData() {
    if (this.selectedDoor == null || this.selectedImportantFields == null) {
      return;
    }

    for (let i = 0; i < this.selectedImportantFields.length; i++) {
      const field = this.selectedImportantFields[i];
      const matchingField = this.importantFields.find((f) => f.id === field.id);

      if (matchingField) {
        matchingField.value = field.value;
      } else {
        this.importantFields.push(field);
      }
    }

    this.onDoorSelected(this.selectedDoor);
  }

  onDoorSelected(door: Door) {
    this.onDoorSelect.emit({
      door,
      importantFields: [...this.importantFields],
    });
    this.haveReservationsFieldsChanged = false;
    this.pendingSelectedDoor = null;
  }

  resetImportantFieldValues() {
    this.importantFields.forEach((f) => {
      const originalValue = this.originalImportantFields.find((ff) => ff.id === f.id);
      if (originalValue) {
        f.value = originalValue.value;
      }
    });
    this.onImportantFieldsChange({ isValid: true, triggeredByChange: true });
  }
}
