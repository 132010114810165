import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { RestService } from "../services/rest.service";
import { SupportService } from "../services/support.service";

@Component({
  selector: "app-carrier-profile",
  templateUrl: "./carrier-profile.component.html",
  styleUrls: ["./carrier-profile.component.css"],
})
export class CarrierProfileComponent implements OnInit {
  email: string;
  name: string;
  phoneNumber: string;
  address: string;
  title: string;

  newPassword: string = "";
  confirmNewPassword: string = "";

  loading = false;

  constructor(
    public auth: AuthService,
    private support: SupportService,
    private http: RestService,
    private toast: ToastrService,
    private msgT: MessageTranslationService
  ) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (isDone) {
        this.name = this.auth.loggedInUser.name;
        this.address = this.auth.loggedInUser.address;
        this.phoneNumber = this.auth.loggedInUser.phoneNumber;
        this.email = this.auth.loggedInUser.email;
        this.title = this.auth.loggedInUser.title;
      }
    });
  }

  async updateProfile() {
    this.loading = true;

    try {
      await this.http
        .post(`api/authentication/updateProfile`, {
          name: this.name,
          phoneNumber: this.phoneNumber,
          address: this.address,
          title: this.title,
        })
        .toPromise();
      this.toast.success(this.msgT.updateSuccess());
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
      console.error(e);
    }

    this.loading = false;
  }

  async changePassword() {
    this.loading = true;

    if (!this.support.checkPasswordStrength(this.newPassword)) {
      this.loading = false;
      return;
    }

    if (this.newPassword !== this.confirmNewPassword) {
      this.toast.error(this.msgT.passwordDontMatch());
      this.loading = false;
      return;
    }

    try {
      await this.http
        .post(`api/authentication/changePassword`, {
          password: this.newPassword,
        })
        .toPromise();
      this.newPassword = "";
      this.confirmNewPassword = "";
      this.toast.success(this.msgT.updateSuccess());
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
      console.error(e);
    }

    this.loading = false;
  }
}
