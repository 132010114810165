import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DoorService } from "src/app/door.service";
import { Door } from "src/app/models/Door";

@Component({
  selector: "app-warehouse-door-select",
  templateUrl: "./warehouse-door-select.component.html",
  styleUrls: ["./warehouse-door-select.component.css"],
})
export class WarehouseDoorSelectComponent implements OnInit, OnChanges {
  doorList: Door[] = [];

  @Input() warehouseId: number | null = null;

  @Input() selectedDoorId: number | null = null;
  @Output() selectedDoorIdChange: EventEmitter<number | null> = new EventEmitter<number | null>();

  constructor(private doorService: DoorService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.warehouseId) {
      this.fetchDoorList();
    }
  }

  async fetchDoorList() {
    if (this.warehouseId == null) {
      return;
    }

    this.doorList = await this.doorService.getDoorsOfWarehouse(this.warehouseId);
  }

  onDoorSelect(e: any) {
    let doorId = e.target.value;
    if (e.target.value === "") {
      doorId = null;
    }

    this.selectedDoorIdChange.emit(doorId);
  }
}
