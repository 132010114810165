import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.css"],
})
export class ConfirmationComponent implements OnInit {
  @Input() active: boolean;
  @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() response: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  Yes() {
    this.response.emit(true);
    this.active = false;
    this.activeChange.emit(false);
  }

  No() {
    this.response.emit(false);
    this.active = false;
    this.activeChange.emit(false);
  }
}
