import { Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "src/app/core/auth.service";
import { CreateOrEditReservationOnDoorComponent } from "src/app/creating-reservation/create-or-edit-reservation-on-door/create-or-edit-reservation-on-door.component";
import { DoorService } from "src/app/door.service";
import { MessageTranslationService } from "src/app/message-translation.service";
import { Door, ReservationType } from "src/app/models/Door";
import { Role } from "src/app/models/Role";
import { RouteHelperService } from "src/app/router.service";
import { SupportService } from "src/app/services/support.service";
import { WarehouseService } from "src/app/services/warehouse.service";

export interface CopyToDoorSettings {
  name: boolean;
  description: boolean;
  palletsPerDay: boolean;
  workDay: boolean;
  minimumArrivalInaccuracy: boolean;
  timeWindows: boolean;
  reservationDurationSettings: boolean;
  reservationFields: boolean;
  emailAttachments: boolean;
}

@Component({
  selector: "app-door-details-page",
  templateUrl: "./door-details-page.component.html",
  styleUrls: ["./door-details-page.component.css"],
})
export class DoorDetailsPageComponent implements OnInit, OnDestroy {
  loadingDoor = true;

  loadingDelete = false;
  loadingCopy = false;

  door: Door | null = null;

  canCreateReservation: boolean = false;

  Role = Role;

  reservationType = ReservationType;

  allDoors: Door[] = [];
  copyToDoors: Door[] = [];

  authSubscription: Subscription | null = null;

  copytoDoorSettings: CopyToDoorSettings;

  doorsSettings: IDropdownSettings = {};

  isCopyingToAnotherDoor = false;

  @ViewChild("createOrEditReservationComponent") createOrEditReservationComponent: CreateOrEditReservationOnDoorComponent;

  constructor(
    private doorService: DoorService,
    private warehouseService: WarehouseService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private msgT: MessageTranslationService,
    private support: SupportService,
    public modalService: NgbModal
  ) {
    this.initCopyToDoorSettings();
  }

  initCopyToDoorSettings() {
    this.copytoDoorSettings = {
      description: true,
      name: true,
      emailAttachments: true,
      minimumArrivalInaccuracy: true,
      palletsPerDay: true,
      reservationDurationSettings: true,
      reservationFields: true,
      timeWindows: true,
      workDay: true,
    };
  }

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.route.paramMap.subscribe((params) => {
        const doorId = params.get("id");
        if (doorId != null) {
          this.fetchDoor(Number(doorId));
        }
      });
    });

    this.doorsSettings = {
      ...this.support.getDropdownDefaults(),
      noDataAvailablePlaceholderText: this.msgT.noDoorsAvailable(),
      idField: "id",
      textField: "name",
    };
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  async fetchDoor(doorId: number) {
    this.loadingDoor = true;
    this.door = await this.doorService.getDoor(doorId);
    this.onDoorChange();
    this.loadingDoor = false;
  }

  onDoorChange() {
    this.updateCanCreateReservation();
  }

  private updateCanCreateReservation() {
    this.canCreateReservation = this.doorService.canCurrentUserCreateReservationOnDoor(this.door);
  }

  openModal(content) {
    this.modalService.open(content);
  }

  navigateToWarehouseReservation() {
    this.warehouseService.navigateToWarehouseReservation(this.door.warehouse.companyId, this.door.warehouseId);
  }

  navigateToDoorEdit() {
    this.doorService.navigateToDoorEdit(this.door.id);
  }

  async copyDoor() {
    const copiedDoor = await this.doorService.copyDoor(this.door.id);

    if (copiedDoor) {
      this.router.navigate(["door", copiedDoor.id]);
      this.modalService.dismissAll();
    }
  }

  async deleteDoor() {
    const success = await this.doorService.deleteDoor(this.door.id);

    if (success) {
      this.router.navigate(["warehouse", this.door.warehouseId]);
      this.modalService.dismissAll();
    }
  }

  async fetchAllCompanyDoors() {
    const allDoors = await this.doorService.getAllCompanyDoors();
    this.allDoors = allDoors.filter((d) => d.id !== this.door.id);
  }

  async confirmCopyToAnotherDoor() {
    this.isCopyingToAnotherDoor = true;

    const success = await this.doorService.copyDoorSettingsToAnotherDoor(this.copytoDoorSettings, this.door, this.copyToDoors);
    if (success) {
      this.modalService.dismissAll();
    }

    this.isCopyingToAnotherDoor = false;
  }

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.createOrEditReservationComponent) {
      return true;
    }

    return this.createOrEditReservationComponent.canDeactivate();
  }
}
