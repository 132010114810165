import { Component, Input, OnInit } from "@angular/core";
import { PermissionStatus } from "../models/Permission";

@Component({
  selector: "app-render-permission-status",
  templateUrl: "./render-permission-status.component.html",
  styleUrls: ["./render-permission-status.component.css"],
})
export class RenderPermissionStatusComponent implements OnInit {
  PermissionStatus = PermissionStatus;
  @Input() status: PermissionStatus;

  constructor() {}

  ngOnInit(): void {}
}
