import { AppLanguage } from "./ReservationField";

export enum EmailTemplateType {
  RESERVATION_CREATED,
  RECCURING_RESERVATION_CREATED,
  TWO_PHASE_RESERVATION_CREATED,
  RESERVATION_UPDATED,
  RECCURING_RESERVATION_UPDATED,
  TWO_PHASE_RESERVATION_UPDATED,
  RESERVATION_DELETED,
  RECCURING_RESERVATION_DELETED,
  TWO_PHASE_RESERVATION_DELETED,
  TWO_PHASE_RESERVATION_CONFIRMED,
}

export interface EmailTemplate {
  id: number;
  type: EmailTemplateType;
  language: AppLanguage;

  subjectTemplate: string;
  contentTemplate: string;
}

export type EmailTemplatePlaceholder =
  | "recipient_name"
  | "warehouse_name"
  | "warehouse_company_name"
  | "warehouse_address"
  | "warehouse_email"
  | "warehouse_phone"
  | "warehouse_location"
  | "warehouse_worktime_from"
  | "warehouse_worktime_to"
  | "warehouse_max_arrival_inaccuracy"
  | "carrier_company_name"
  | "carrier_address"
  | "carrier_contact_person_name"
  | "carrier_phone"
  | "reservation_date"
  | "reservation_time"
  | "reservation_data"
  | "reservation_code"
  | "reservation_link"
  | "is_combination";

export const isEmailTemplatePlaceholderConditional = (p: EmailTemplatePlaceholder) => {
  return p === "is_combination";
};

export type EmailTemplatePlaceholderLocation = "subject" | "content";

export const EmailTemplatePlaceholderLocationMapping: Record<EmailTemplatePlaceholder, EmailTemplatePlaceholderLocation[]> = {
  recipient_name: ["content"],
  warehouse_name: ["content", "subject"],
  warehouse_company_name: ["content"],
  warehouse_address: ["content"],
  warehouse_email: ["content"],
  warehouse_phone: ["content"],
  warehouse_location: ["content"],
  warehouse_worktime_from: ["content"],
  warehouse_worktime_to: ["content"],
  warehouse_max_arrival_inaccuracy: ["content"],
  carrier_company_name: ["content", "subject"],
  carrier_address: ["content"],
  carrier_contact_person_name: ["content", "subject"],
  carrier_phone: ["content"],
  reservation_date: ["content", "subject"],
  reservation_time: ["content", "subject"],
  reservation_data: ["content"],
  reservation_code: ["content"],
  reservation_link: ["content"],
  is_combination: ["content"],
};
