<div class="modal-header">
  <h4 [ngSwitch]="entity" class="modal-title">
    <span *ngSwitchCase="'reservation'" i18n="Delete reservation?"> Delete reservation? </span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose.emit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p *ngIf="!isLoading" i18n>Confirm deleting of the reservation.</p>
  <div *ngIf="isLoading" class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="modal-footer d-flex justify-content-between">
  <button type="submit" class="btn" (click)="onClose.emit()" [disabled]="isLoading">
    <ng-container i18n="Cancel">Cancel</ng-container>
  </button>
  <button class="btn btn-danger" i18n="Change" [disabled]="isLoading" (click)="onConfirm.emit()">Delete</button>
</div>
