import { Component, Input, OnInit } from "@angular/core";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-help-text",
  templateUrl: "./help-text.component.html",
  styleUrls: ["./help-text.component.css"],
})
export class HelpTextComponent implements OnInit {
  faQuestionCircle = faQuestionCircle;
  @Input() text: string = "";

  constructor() {}

  ngOnInit(): void {}
}
