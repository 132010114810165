import { Country } from "./Country";

export interface Driver {
  code: string;
  transportCompanyCountry: Country;
  name: string;
  surname: string;
  transportCompany: string;
  email: string;
}

export const parseFullName = (driver: Driver): [string, string] => {
  const split = driver.name.split(/\s/);
  if (split.length === 0) {
    return ["", ""];
  }

  const firstName = split[0].trim();
  if (split.length === 1) {
    return [firstName, ""];
  }

  const lastName = split
    .slice(1)
    .map((n) => n.trim())
    .join(" ");
  return [firstName, lastName];
};
