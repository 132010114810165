import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { File } from "src/app/models/File";
import { PermissionStatus } from "src/app/models/Permission";
import { Role } from "src/app/models/Role";
import { WarehouseExcerptDto, WarehouseCompanyListItem, Warehouse, WarehouseListItem } from "src/app/models/Warehouse";
import { ModalService } from "src/app/services/modal.service";
import { WarehouseService } from "src/app/services/warehouse.service";

@Component({
  selector: "app-warehouse-list-item",
  templateUrl: "./warehouse-list-item.component.html",
  styleUrls: ["./warehouse-list-item.component.css"],
})
export class WarehouseListItemComponent implements OnInit {
  PermissionStatus = PermissionStatus;
  Role = Role;

  @Input() warehouse: WarehouseCompanyListItem;
  @Input() isCarrier: boolean;

  warehouseForAddingDoor: WarehouseExcerptDto | null = null;

  @Output() onRequestPermission: EventEmitter<number> = new EventEmitter<number>();

  constructor(public warehouseService: WarehouseService, public modalService: ModalService) {}

  ngOnInit(): void {}

  FileUrl(file: any) {
    return File.Url(file);
  }

  navigateToWarehouseReservation(warehouse: WarehouseListItem) {
    this.warehouseService.navigateToWarehouseReservation(this.warehouse.company.id, warehouse.id);
  }
}
