import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { DateService } from "src/app/date.service";
import { MessageTranslationService } from "src/app/message-translation.service";
import { SupportService } from "src/app/services/support.service";

export interface MultiSelectOption {
  item_id: number;
  item_text: string;
}

export interface ReservationListSearchBarFilter {
  filterText: string;
  dateFrom: Date | null;
  dateTo: Date | null;
  warehouses: MultiSelectOption[];
  doors: MultiSelectOption[];
}

export const getDefaultReservationListSearchBarFilter = (): ReservationListSearchBarFilter => ({
  filterText: "",
  dateFrom: null,
  dateTo: null,
  warehouses: [],
  doors: [],
});

export const getDefaultReservationListArchiveSearchBarFilter = (): ReservationListSearchBarFilter => ({
  ...getDefaultReservationListSearchBarFilter(),
  dateFrom: moment().subtract(1, "week").toDate(),
  dateTo: new Date(),
});

@Component({
  selector: "app-reservation-list-search-bar",
  templateUrl: "./reservation-list-search-bar.component.html",
  styleUrls: ["./reservation-list-search-bar.component.css"],
})
export class ReservationListSearchBarComponent implements OnInit {
  @Input() warehouses: MultiSelectOption[] = [];
  @Input() doors: MultiSelectOption[] = [];

  @Input() isArchive: boolean = false;

  @Input() filter: ReservationListSearchBarFilter = getDefaultReservationListSearchBarFilter();
  @Output() filterChange: EventEmitter<ReservationListSearchBarFilter> = new EventEmitter<ReservationListSearchBarFilter>();

  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();

  warehousesSettings: IDropdownSettings = {};
  doorsSettings: IDropdownSettings = {};

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  possiblePageSizes = [10, 20, 50, 100];

  constructor(
    private msgT: MessageTranslationService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public support: SupportService,
    private dateService: DateService
  ) {}

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  ngOnInit() {
    const defaultSettings = {
      ...this.support.getDropdownDefaults(),
      idField: "item_id",
      textField: "item_text",
    };

    this.warehousesSettings = {
      ...defaultSettings,
      noDataAvailablePlaceholderText: this.msgT.noWarehousesAvailable(),
    };

    this.doorsSettings = {
      ...defaultSettings,
      noDataAvailablePlaceholderText: this.msgT.noDoorsAvailable(),
    };
  }

  onDateChange(dateFrom: Date | null | undefined, dateTo: Date | null | undefined) {
    dateFrom = dateFrom === undefined ? this.filter.dateFrom : dateFrom;
    dateTo = dateTo === undefined ? this.filter.dateTo : dateTo;

    ({ dateFrom, dateTo } = this.dateService.swapDatesIfFromLaterThanTo(dateFrom, dateTo));

    this.filter.dateFrom = dateFrom;
    this.filter.dateTo = dateTo;
  }

  onFilterChange() {
    this.filterChange.emit(this.filter);
  }

  todayClick() {
    const todaysDate = new Date();
    this.setDateFromDateTo(todaysDate, todaysDate);
  }

  tomorrowClick() {
    const tomorrowsDate = new Date();
    tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);
    this.setDateFromDateTo(tomorrowsDate, tomorrowsDate);
  }

  yesterdayClick() {
    const yesterdaysDate = new Date();
    yesterdaysDate.setDate(yesterdaysDate.getDate() - 1);
    this.setDateFromDateTo(yesterdaysDate, yesterdaysDate);
  }

  thisWeekClick() {
    const thisWeekStart = moment().startOf("isoWeek");
    const thisWeekEnd = moment().endOf("isoWeek");
    this.setDateFromDateTo(thisWeekStart.toDate(), thisWeekEnd.toDate());
  }

  nextWeekClick() {
    const nextWeekStart = moment().add(1, "week").startOf("isoWeek");
    const nextWeekEnd = moment().add(1, "week").endOf("isoWeek");
    this.setDateFromDateTo(nextWeekStart.toDate(), nextWeekEnd.toDate());
  }

  lastWeekClick() {
    const lastWeekStart = moment().subtract(1, "week").startOf("isoWeek");
    const lastWeekEnd = moment().subtract(1, "week").endOf("isoWeek");
    this.setDateFromDateTo(lastWeekStart.toDate(), lastWeekEnd.toDate());
  }

  thisMonthClick() {
    const thisMonthStart = moment().startOf("month");
    const thisMonthEnd = moment().endOf("month");
    this.setDateFromDateTo(thisMonthStart.toDate(), thisMonthEnd.toDate());
  }

  lastMonthClick() {
    const lastMonthStart = moment().subtract(1, "month").startOf("month");
    const lastMonthEnd = moment().subtract(1, "month").endOf("month");
    this.setDateFromDateTo(lastMonthStart.toDate(), lastMonthEnd.toDate());
  }

  setDateFromDateTo(dateFrom: Date, dateTo: Date) {
    this.filter.dateFrom = dateFrom;
    this.filter.dateTo = dateTo;
    this.onFilterChange();
  }

  clearDate() {
    this.filter.dateFrom = null;
    this.filter.dateTo = null;
    this.onFilterChange();
  }

  clearFilters() {
    this.filter = getDefaultReservationListSearchBarFilter();
    this.onFilterChange();
  }
}
