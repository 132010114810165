import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-multi-file-picker",
  templateUrl: "./multi-file-picker.component.html",
  styleUrls: ["./multi-file-picker.component.css"],
})
export class MultiFilePickerComponent implements OnInit {
  @Input() files: File[] = [];
  @Output() filesChange: EventEmitter<File[]> = new EventEmitter<File[]>();

  constructor() {}

  ngOnInit(): void {}

  onSelectFiles(event) {
    this.files.push(...event.addedFiles);
    this.filesChange.emit(this.files);
  }

  onRemoveFile(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.filesChange.emit(this.files);
  }
}
