import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../core/auth.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
})
export class HomeComponent {
  constructor(private auth: AuthService, private router: Router) {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (isDone) {
        this.Redirect();
      }
    });
  }

  Redirect() {
    if (this.auth.IsLoggedIn()) {
      if (this.auth.IsWarehouseAdmin()) this.router.navigate(["manage/permissions"]);
      if (this.auth.IsWarehouse()) this.router.navigate([`my-warehouses`]);
      if (this.auth.IsCarrier()) this.router.navigate(["my-warehouses"]);
    } else {
      this.router.navigate(["/"]);
    }
  }
}
