import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";
import { Permission, PermissionStatus } from "src/app/models/Permission";
import { WarehouseCompanyListItem } from "src/app/models/Warehouse";
import { WarehouseService } from "src/app/services/warehouse.service";

@Component({
  selector: "app-all-warehouses",
  templateUrl: "./all-warehouses.component.html",
  styleUrls: ["./all-warehouses.component.css"],
})
export class AllWarehousesComponent implements OnInit {
  loading: boolean = true;
  isCarrier: boolean;

  allCompaniesWithWarehouses: WarehouseCompanyListItem[];

  filterText: string;

  constructor(private auth: AuthService, private warehouseService: WarehouseService) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.isCarrier = this.auth.IsCarrier();
      this.doFetch();
    });
  }

  async doFetch() {
    this.loading = true;

    try {
      this.allCompaniesWithWarehouses = await this.warehouseService.fetchAllWarehouses();
    } catch (e) {
      console.log(e);
    }

    this.loading = false;
  }

  async onRequestPermission(warehouseId: number) {
    const permission = await this.warehouseService.requestPermission(warehouseId);
    if (!permission) {
      return;
    }

    this.allCompaniesWithWarehouses = this.allCompaniesWithWarehouses.map((w) => ({
      ...w,
      warehouses: w.warehouses.map((ww) => {
        if (ww.id === warehouseId) {
          return {
            ...ww,
            permission: permission,
          };
        }

        return ww;
      }),
    }));
  }

  onRefresh() {
    this.doFetch();
  }
}
