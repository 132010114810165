import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { DateRange } from "../availability-display/availability-display.component";
import { ReservationFlowMode } from "../company/company-reservation-flow-v2/company-reservation-flow-v2.component";
import { ReservationData } from "../company/company-reservation-reserve-door/company-reservation-reserve-door.component";
import { AuthService } from "../core/auth.service";
import { DataTableColumn } from "../data-table/data-table.component";
import { DateService } from "../date.service";
import { LocaleService } from "../locale.service";
import { MessageTranslationService } from "../message-translation.service";
import { ReservationDto, ReservationDtosByType, ReservationDtoType } from "../models/MyReservations";
import { PaginatedResponse } from "../models/PaginatedResponse";
import {
  getReservationActions,
  getStaticReservationColumns,
  Reservation,
  ReservationEmailType,
  ReservationOperation,
  reservationToDataTableEntries,
} from "../models/Reservation";
import { ReservationListSearchBarFilter } from "../reservations/reservation-list-search-bar/reservation-list-search-bar.component";
import { RestService } from "./rest.service";
import { SupportService } from "./support.service";

@Injectable({
  providedIn: "root",
})
export class ReservationService {
  constructor(
    private http: RestService,
    private msgT: MessageTranslationService,
    private dateService: DateService,
    private supportService: SupportService,
    private toast: ToastrService,
    private auth: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private locale: LocaleService
  ) {}

  async fetchMyReservations(isCarrier: boolean): Promise<ReservationDtosByType> {
    const reservations = await this.http.get<ReservationDto[]>(`api/carrier/myReservations`).toPromise();

    reservations.forEach((r) => {
      r.filterString = this.filterStringForReservation(r);
      this.assignPermissionsToReservation(r, isCarrier, false);
      this.assignActionsToReservation(r);
    });

    const standardReservations = reservations.filter((r) => r.type === ReservationDtoType.STANDARD || r.type === ReservationDtoType.RECURRING);
    const twoPhaseReservations = reservations.filter((r) => r.type === ReservationDtoType.TWO_PHASE);

    return {
      standardReservations: this.getDataTableInfoFromReservations(standardReservations),
      twoPhaseReservations: this.getDataTableInfoFromReservations(twoPhaseReservations),
    };
  }

  getDataTableInfoFromReservations(reservations: ReservationDto[]) {
    const columns = this.getAllReservationColumns(reservations);
    reservations.forEach((r) => {
      this.assignDataTableEntriesToReservation(r, columns);
    });

    return { data: reservations, columns };
  }

  async fetchReservationsFromArchive(filter: ReservationListSearchBarFilter, selectedPageNumber: number): Promise<PaginatedResponse<ReservationDto>> {
    const url = `api/carrier/reservationsArchive${this.archiveQueryStringFromFilters(filter, selectedPageNumber)}`;

    return await this.http.get<PaginatedResponse<ReservationDto>>(url).toPromise();
  }

  async fetchAllReservationsFromArchive() {
    const url = `api/carrier/reservationsArchiveAll`;

    const reservations = await this.http.get<ReservationDto[]>(url).toPromise();
    reservations.forEach((r) => {
      r.filterString = this.filterStringForReservation(r);
    });

    return this.getDataTableInfoFromReservations(reservations);
  }

  private archiveQueryStringFromFilters(filter: ReservationListSearchBarFilter, selectedPageNumber: number) {
    let qs = `?pageNumber=${selectedPageNumber}&searchString=${filter.filterText}`;

    if (filter.dateFrom) {
      qs = `${qs}&from=${this.dateService.formatDate(filter.dateFrom, { forBackend: true })}`;
    }

    if (filter.dateTo) {
      qs = `${qs}&to=${this.dateService.formatDate(filter.dateTo, { forBackend: true })}`;
    }

    if (filter.warehouses.length > 0) {
      qs = `${qs}&warehouseId=${filter.warehouses.map((w) => w.item_id).join(",")}`;
    }

    if (filter.doors.length > 0) {
      qs = `${qs}&doorId=${filter.doors.map((w) => w.item_id).join(",")}`;
    }

    return qs;
  }

  downloadArchiveCsv(filter: ReservationListSearchBarFilter, selectedPageNumber: number, selectedTableLabels: number[]) {
    const selectedColumns = [...selectedTableLabels];
    const selectedColumnsText = encodeURIComponent(selectedColumns.join(","));
    const url = `api/carrier/reservationsArchiveCSV${this.archiveQueryStringFromFilters(filter, selectedPageNumber)}&cols=${selectedColumnsText}`;
    window.open(url);
  }

  assignPermissionsToReservation(r: ReservationDto, isCarrier: boolean, hasAccessPermissions: boolean) {
    if (this.auth.loggedInUser) {
      r.allowView = isCarrier ? this.auth.loggedInUser.id === r.carrier?.id : true;
    } else {
      r.allowView = hasAccessPermissions;
    }

    r.allowApprove = r.type === ReservationDtoType.TWO_PHASE && !isCarrier;
    r.allowEdit = r.type === ReservationDtoType.STANDARD;
    r.allowDelete = true;

    if (this.auth.IsWarehouse()) {
      return;
    }

    if ((isCarrier || hasAccessPermissions) && !r.warehouse.canCarrierDeleteReservation) {
      r.allowDelete = false;
    }

    if ((isCarrier || hasAccessPermissions) && !r.warehouse.canCarrierEditReservation) {
      r.allowEdit = false;
    }
  }

  assignDataTableEntriesToReservation(reservation: ReservationDto, columns: DataTableColumn[]) {
    reservation.dataTableEntries = reservationToDataTableEntries(
      reservation,
      this.msgT.getStaticDataFieldNames(),
      (rule: string) => {
        return this.msgT.translateRecurrenceRule(rule);
      },
      columns,
      this.dateService,
      this.supportService
    );
  }

  assignActionsToReservation(reservation: ReservationDto) {
    reservation.actions = getReservationActions(reservation, this.msgT.getReservationActionNames());
  }

  private filterStringForReservation(reservation: ReservationDto) {
    const standardFilterString = `${reservation.warehouse.company.name} ${reservation.warehouse.company.address} ${reservation.warehouse.company.phone} ${
      reservation.warehouse.company.contactPerson
    } ${reservation.door?.name || ""} ${reservation.warehouse.name} ${reservation.carrier?.name || ""} ${reservation.carrier?.title || ""}`;
    const reservationDataFilterString = reservation.data.map((f) => f.value).join(" ");
    return `${standardFilterString} ${reservationDataFilterString}`.toLowerCase();
  }

  getAllReservationColumns(reservations: ReservationDto[]): DataTableColumn[] {
    const staticColumns = getStaticReservationColumns(this.msgT.getStaticDataFieldNames(), this.dateService, this.supportService);

    const allDataColumns: DataTableColumn[] = [];

    reservations.forEach((reservation) => {
      reservation.data.forEach((reservationField) => {
        const existingColumn = allDataColumns.find((column) => column.text === reservationField.name);

        if (existingColumn) {
          if (!existingColumn.ids.includes(reservationField.id)) {
            existingColumn.ids.push(reservationField.id);
          }
        } else {
          allDataColumns.push({
            ids: [reservationField.id],
            text: reservationField.name,
          });
        }
      });
    });

    return [...staticColumns, ...allDataColumns];
  }

  filterReservationsByFilter(reservations: ReservationDto[], filter: ReservationListSearchBarFilter): ReservationDto[] {
    let filteredReservations = reservations;
    if (filter.filterText != null && filter.filterText.trim().length > 0) {
      filteredReservations = filteredReservations.filter((r) => r.filterString.includes(filter.filterText.toLowerCase()));
    }

    filteredReservations = filteredReservations.filter((r) => this.filterReservationByDate(r, filter.dateFrom, filter.dateTo));

    if (filter.warehouses.length > 0) {
      const warehouseIds = filter.warehouses.map((w) => w.item_id);
      filteredReservations = filteredReservations.filter((r) => warehouseIds.includes(r.warehouse.id));
    }

    if (filter.doors.length > 0) {
      const doorIds = filter.doors.map((w) => w.item_id);
      filteredReservations = filteredReservations.filter((r) => r.door && doorIds.includes(r.door.id));
    }

    return filteredReservations;
  }

  private filterReservationByDate(reservation: ReservationDto, dateFrom: Date | null, dateTo: Date | null): boolean {
    if (reservation.type === ReservationDtoType.TWO_PHASE) {
      return true;
    }

    if (reservation.standardReservationData) {
      if (dateFrom != null) {
        if (this.filterStandardReservationByDate(DateService.dateIsAfter, reservation, dateFrom) === false) {
          return false;
        }
      }

      if (dateTo != null) {
        if (this.filterStandardReservationByDate(DateService.dateIsBefore, reservation, dateTo) === false) {
          return false;
        }
      }

      return true;
    } else if (reservation.recurringReservationData) {
      return this.filterRecurringReservationByDate(reservation, dateFrom, dateTo);
    }

    return false;
  }

  private filterStandardReservationByDate(beforeOrAfter: (d1: Date, d2: Date) => boolean, reservation: ReservationDto, date: Date) {
    return beforeOrAfter(reservation.standardReservationData.date, date);
  }

  private filterRecurringReservationByDate(reservation: ReservationDto, from: Date | null, to: Date | null): boolean {
    if (!reservation.recurringReservationData) {
      return false;
    }

    console.log(reservation.recurringReservationData);
    const startR = DateService.removeTime(new Date(reservation.recurringReservationData.fromDate));
    const endR = DateService.removeTime(new Date(reservation.recurringReservationData.toDate));
    const startQ = DateService.removeTime(from);
    const endQ = DateService.removeTime(to);

    return (startR == null || endQ == null || startR <= endQ) && (endR == null || startQ == null || endR >= startQ);
  }

  async getReservationCompanyId(reservationId: number, reservationCode: string) {
    try {
      const { companyId } = await this.http
        .post<{ companyId: number }>(`api/manage/getReservationCompanyId/${reservationId}`, { code: reservationCode })
        .toPromise();
      return companyId;
    } catch (e) {
      console.log("Error getReservationCompanyId", e);
      this.toast.error(this.msgT.reservationNotFoundError());
    }

    return null;
  }

  async getReservationToEdit(reservationId: number, reservationCode: string, isApproving: boolean) {
    try {
      const endpoint = isApproving ? "getMyPendingTwoPhaseReservation" : "getMyArrival";
      return await this.http.post<Reservation>(`api/manage/${endpoint}/${reservationId}`, { code: reservationCode }).toPromise();
    } catch (e) {
      console.log("Error getReservationToEdit", e);
      this.toast.error(this.msgT.reservationNotFoundError());
    }

    return null;
  }

  async fetchReservation(reservationId: number, reservationCode: string, isApproving: boolean) {
    try {
      const endpoint = isApproving ? "getMyPendingTwoPhaseReservation" : "getMyArrival";
      return await this.http.post<ReservationDto>(`api/manage/${endpoint}/${reservationId}`, { code: reservationCode }).toPromise();
    } catch (e) {
      console.log("Error fetchReservation", e);
      this.toast.error(this.msgT.reservationNotFoundError());
    }

    return null;
  }

  public getReservationFields(reservationData: ReservationData) {
    if (reservationData == null) {
      return [];
    }

    const allFields = reservationData?.reservation?.data || [];
    const basicFields = allFields.filter((d) => d.importantFieldWarehouseId == null);
    const importantFields = reservationData?.importantFields || [];

    return [...basicFields, ...importantFields];
  }

  getReservationDataForBackend(doorId: number, reservationData: ReservationData) {
    return {
      ...reservationData.reservation,
      carrierId: this.auth.loggedInUser?.id,
      doorId,
      date: reservationData.dateRange.date,
      start: reservationData.dateRange.start,
      end: reservationData.dateRange.end,
      data: this.getReservationFields(reservationData),
      additionalContactEmail: reservationData.guestEmail,
    };
  }

  async createReservation(doorId: number, reservationData: ReservationData) {
    const reservation = this.getReservationDataForBackend(doorId, reservationData);

    try {
      let endpoint = "reserve";
      if (reservationData.isRecurring) {
        endpoint = "reserveRecurring";
      }

      const createdReservation = await this.http.post<Reservation>(`api/carrier/${endpoint}`, reservation, true, reservationData.languageForEmail).toPromise();

      await this.uploadReservationFiles(createdReservation.id, createdReservation.code, reservationData.files, reservationData.isRecurring);
      await this.sendReservationMail(createdReservation.id, false, reservationData.isRecurring, createdReservation.code, reservationData.languageForEmail);

      this.toast.success(this.msgT.createReservationSuccess());

      return createdReservation;
    } catch (error) {
      console.log(error);
      this.displayCreateReservationError(error, reservation.end);
    }

    return null;
  }

  async createTwoPhaseReservation(warehouseId: number, data: ReservationData) {
    const reservation = {
      warehouseId,
      ...data.reservation,
      carriedId: this.auth.loggedInUser?.id,
      guestEmail: data.guestEmail,
    };

    try {
      const createdReservation = await this.http.post<Reservation>(`api/carrier/reserveTwoPhase`, reservation, true, data.languageForEmail).toPromise();
      await this.uploadReservationFiles(createdReservation.id, createdReservation.code, data.files, false);
      await this.sendTwoPhaseReservationCreatedMail(createdReservation.id, createdReservation.code);
      this.toast.success(this.msgT.createReservationSuccess());
      return createdReservation;
    } catch (error) {
      console.log(error);
      this.displayCreateReservationError(error, reservation.end);
    }

    return null;
  }

  async sendTwoPhaseReservationCreatedMail(id: number, code: string | null) {
    try {
      await this.http
        .post(`api/carrier/sendReservationEmail/${id}`, {
          operation: ReservationOperation.CREATE,
          type: ReservationEmailType.TWO_PHASE,
          reservationCode: code,
        })
        .toPromise();
    } catch (error) {
      this.toast.error(this.msgT.couldNotSendMailError());
      console.log(error);
    }
  }

  async editReservation(id: number, doorId: number, reservationData: ReservationData, mode: ReservationFlowMode) {
    const reservation = this.getReservationDataForBackend(doorId, reservationData);
    const isApproving = mode === ReservationFlowMode.APPROVING_TWO_PHASE;

    try {
      const editedReservation = await this.http.post<Reservation>(`api/manage/editReservation/${id}`, reservation).toPromise();

      await this.uploadReservationFiles(editedReservation.id, editedReservation.code, reservationData.files, false);

      if (isApproving) {
        await this.sendTwoPhaseReservationConfirmedMail(editedReservation.id, editedReservation.code, reservationData.languageForEmail);
      } else {
        await this.sendReservationMail(editedReservation.id, true, false, editedReservation.code, reservationData.languageForEmail);
      }

      if (isApproving) {
        this.toast.success(this.msgT.reservationApprovedSuccess());
      } else {
        this.toast.success(this.msgT.reservationUpdatedSuccess());
      }

      return editedReservation;
    } catch (error) {
      console.log(error);
      this.displayCreateReservationError(error, reservation.end);
    }

    return null;
  }

  private async uploadReservationFiles(reservationId: number, reservationCode: string, files: File[], isRecurring: boolean) {
    if (files.length === 0) {
      return true;
    }

    this.toast.info(this.msgT.uploadingFilesDontCloseThisPage());

    const formData: FormData = new FormData();
    for (let file of files) {
      formData.append("files", file, file.name);
    }

    formData.append("reservationCode", reservationCode);

    if (isRecurring) {
      formData.append("isRecurring", "true");
    }

    try {
      await this.http.postFormData(`api/file/upload/${reservationId}`, formData).toPromise();
      this.toast.success(this.msgT.uploadingFilesFinished());
      return true;
    } catch (error) {
      this.toast.error(this.msgT.uploadFilesError());
      console.log(error);
    }

    return false;
  }

  private async sendReservationMail(
    reservationId: number,
    isEdit: boolean,
    isRecurring: boolean,
    reservationCode: string | null,
    language: string | undefined
  ) {
    await this.sendMailUponReservation(
      reservationId,
      isEdit ? ReservationOperation.UPDATE : ReservationOperation.CREATE,
      isRecurring ? ReservationEmailType.RECURRING : ReservationEmailType.STANDARD,
      reservationCode,
      language
    );
  }

  private async sendTwoPhaseReservationConfirmedMail(reservationId: number, reservationCode: string | null, language: string | undefined) {
    await this.sendMailUponReservation(reservationId, ReservationOperation.CREATE, ReservationEmailType.CONFIRM_TWO_PHASE, reservationCode, language);
  }

  private async sendMailUponReservation(
    reservationId: number,
    operation: ReservationOperation,
    type: ReservationEmailType,
    code: string | null,
    language: string | undefined
  ) {
    try {
      await this.http
        .post(
          `api/carrier/sendReservationEmail/${reservationId}`,
          {
            operation,
            type,
            reservationCode: code,
          },
          true,
          language
        )
        .toPromise();
    } catch (error) {
      this.toast.error(this.msgT.couldNotSendMailError());
      console.log(error);
    }
  }

  public displayCreateReservationError(error: any, reservationEnd: string) {
    if (error?.error != null) {
      console.log(error.error);
      if (error.error.includes("Start after end")) {
        this.toast.error(this.msgT.createReservationErrorStartAfterEnd());
        return;
      } else if (error.error.includes("No pallets field!")) {
        this.toast.error(this.msgT.noPalletsField());
        return;
      } else if (error.error.includes("Minimum notice too early")) {
        this.toast.error(this.msgT.createReservationErrorMinimumNotice());
        return;
      } else if (error.error.includes("Date is in past")) {
        this.toast.error(this.msgT.createReservationErrorDateIsInPast());
        return;
      } else if (error.error.includes("Invalid recurrence rule")) {
        this.toast.error(this.msgT.createReservationErrorInvalidRecurrenceRule());
        return;
      } else if (error.error.includes("Door has no time windows")) {
        this.toast.error(this.msgT.createReservationErrorDoorNoTimeWindows());
        return;
      } else if (error.error.includes("Door has existing reservations")) {
        this.toast.error(this.msgT.createReservationErrorDoorExistingReservations());
        return;
      } else if (error.error.includes("Door is missing")) {
        this.toast.error(this.msgT.createReservationErrorDoorNotFound());
        return;
      } else if (error.error.includes("Fixed reservation time window not found")) {
        this.toast.error(this.msgT.createReservationErrorFixedReservationTimeWindowNotFound());
        return;
      } else if (error.error.includes("Calculated reservation time window not matching ")) {
        const regex = /Calculated reservation time window not matching (\d+\:\d+:\d+)\n/;
        const matches = error.error.match(regex);
        let expectedReservationEnd = "?";
        if (matches.length > 1) {
          expectedReservationEnd = matches[1];
        }

        this.toast.error(this.msgT.createReservationErrorCalculatedReservationTimeWindowNotMatching(reservationEnd, expectedReservationEnd));
        return;
      } else if (error.error.includes("No permission")) {
        this.toast.error(this.msgT.createReservationErrorNoPermission());
        return;
      } else if (error.error.includes("Email exists for this day")) {
        this.toast.error(this.msgT.createReservationErrorDoubleEmailBooking());
        return;
      } else if (error.error.includes("Driver code exists for this day")) {
        this.toast.error(this.msgT.createReservationErrorDoubleBooking());
        return;
      }
    }

    this.toast.error(this.msgT.createReservationError());
  }

  navigateToReservation(id: number, code: string, type: ReservationDtoType) {
    this.modalService.dismissAll();
    if (type === ReservationDtoType.RECURRING) {
      this.router.navigate(["reservation-recurring", id], { queryParams: { code } });
    } else {
      this.router.navigate(["reservation", id], { queryParams: { code } });
    }
  }

  navigateToEditReservation(reservationId: number, code: string) {
    this.modalService.dismissAll();
    this.router.navigate(["edit-reserve-company", reservationId, code]);
  }

  navigateToApproveReservation(reservationId: number, code: string) {
    this.router.navigate(["approve-reserve-company", reservationId, code]);
  }

  async removeReservation(reservationId: number, reservationCode: string, isRecurring: boolean) {
    try {
      await this.http
        .post(`api/carrier/${isRecurring ? "cancelRecurringReservation" : "cancelReservation"}/${reservationId}`, {
          completeCancel: true,
          code: reservationCode,
        })
        .toPromise();
      this.toast.success(this.msgT.reservationDeletedSuccess());
      this.modalService.dismissAll();
      return true;
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
      console.log(e);
    }
    return false;
  }

  async getReservation(id: number, isRecurring: boolean, isCarrier: boolean, code: string | null): Promise<ReservationDto | null> {
    try {
      const reservation = await this.http.post<ReservationDto>(`api/carrier/getReservation/${id}`, { isRecurring, code }).toPromise();
      this.assignPermissionsToReservation(reservation, isCarrier, true);
      return reservation;
    } catch (e) {
      this.toast.error(this.msgT.reservationNotFoundError());
      console.log(e);
    }

    return null;
  }
}
