import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { DateService } from "../date.service";
import { LanguagePickerLanguage } from "../language-picker/language-picker.component";
import { MessageTranslationService } from "../message-translation.service";
import { RecurringReservationData } from "../models/MyReservations";
import { Permission, PermissionStatus, PermissionType } from "../models/Permission";
import { AppLanguage } from "../models/ReservationField";
import { RestService } from "./rest.service";

export const hasPermissionsForDoor = (permission: Permission, doorId: number): boolean => {
  if (permission.status != PermissionStatus.Accepted) {
    return false;
  }

  if (permission.type == PermissionType.ONLY_TWO_PHASE) {
    return false;
  }

  if (permission.type == PermissionType.ALL_DOORS) {
    return true;
  }

  return permission.permissionsForDoor.some((d) => d.doorId === doorId);
};

@Injectable({
  providedIn: "root",
})
export class SupportService {
  public languages: AppLanguage[] = [];

  constructor(
    private toast: ToastrService,
    private dateService: DateService,
    private restService: RestService,
    private http: RestService,
    private msgT: MessageTranslationService,
    private httpClient: HttpClient,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.fetchLanguages();
  }

  public checkPasswordStrength(password: string) {
    let count = 0;

    if (password.length >= 10) {
      const regexpNumber: RegExp = /.*\d.*/;
      if (regexpNumber.test(password)) {
        count++;
      }

      const regexpSmall: RegExp = /.*[a-z].*/;
      if (regexpSmall.test(password)) {
        count++;
      }

      const regexpCapital: RegExp = /.*[A-Z].*/;
      if (regexpCapital.test(password)) {
        count++;
      }

      const regexpSpecial: RegExp = /.*[*.!@#$%^&(){}[]:";'<>,.?\/~`_+-=|\\].*/;
      if (regexpSpecial.test(password)) {
        count++;
      }
    }

    const isStrongEnough = count >= 3;
    if (!isStrongEnough) {
      this.toast.error(this.msgT.passwordTooWeak());
    }

    return isStrongEnough;
  }

  public recurringReservationToString(recurringReservationData: RecurringReservationData) {
    if (recurringReservationData == null) {
      return "-";
    }

    let recurringReservationRule = this.msgT.translateRecurrenceRule(recurringReservationData.recurrenceRule);
    const dateRange = [];
    if (recurringReservationData.fromDate) {
      dateRange.push(this.dateService.toTime(recurringReservationData.fromDate, { onlyDate: true }));
    } else {
      dateRange.push("*");
    }

    if (recurringReservationData.toDate) {
      dateRange.push(this.dateService.toTime(recurringReservationData.toDate, { onlyDate: true }));
    } else {
      dateRange.push("*");
    }

    return `${recurringReservationRule}; ${dateRange.join(" - ")}`;
  }

  downloadStringAsFile(filename: string, text: string) {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public async downloadFile(url: string): Promise<void> {
    const blob = await this.httpClient.get(`${environment.apiUrl}/${url}`, { responseType: "blob", headers: this.http.getHeaders() }).toPromise();
    const objectUrl = window.URL.createObjectURL(blob);
    window.open(objectUrl, "_blank");
    window.URL.revokeObjectURL(objectUrl);
  }

  public getDropdownDefaults() {
    return {
      singleSelection: false,
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: this.msgT.search(),
      selectAllText: this.msgT.selectAll(),
      unSelectAllText: this.msgT.unselectAll(),
    };
  }

  public isEmailValid(email: string): boolean {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  public toBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  public getCurrentLanguage(): LanguagePickerLanguage {
    return environment.languages.find((l) => l.code === this.locale);
  }

  async fetchLanguages() {
    try {
      this.languages = await this.restService.get<AppLanguage[]>(`api/company/getAllLanguages`).toPromise();
    } catch (e) {
      console.error(e);
    }
  }
}
