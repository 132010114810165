import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable, of } from "rxjs";
import { LocaleService } from "../locale.service";
import { environment } from "src/environments/environment";

enum Method {
  GET,
  POST,
  PATCH,
  PUT,
  DELETE,
}

@Injectable({
  providedIn: "root",
})
export class RestService {
  constructor(private http: HttpClient, private locale: LocaleService, private oAuth: OAuthService) {}

  private defaultHeaders: HttpHeaders = new HttpHeaders({
    "content-type": "application/json; charset=utf-8",
  });

  private defaultFromDataHeaders: HttpHeaders = new HttpHeaders({});

  public getHeaders(defaultHeaders: HttpHeaders | null = null, includeAuthorization = true): HttpHeaders {
    const headers = defaultHeaders || this.defaultHeaders;
    const accessToken = this.oAuth.getAccessToken();
    if (includeAuthorization && accessToken) {
      return headers.append("Authorization", "Bearer " + accessToken);
    }

    return headers;
  }

  public getFormDataHeaders(): HttpHeaders {
    return this.getHeaders(this.defaultFromDataHeaders);
  }

  public get<T>(path: string, body?: any): Observable<T> {
    return this.makeHttpRequestObservable<T>(Method.GET, path, this.getHeaders(), body);
  }

  public post<T>(path: string, body: any, includeAuthorization = true, languageOverride?: string): Observable<T> {
    return this.makeHttpRequestObservable<T>(Method.POST, path, this.getHeaders(null, includeAuthorization), body, languageOverride);
  }

  public postFormData<T>(path: string, body: any): Observable<T> {
    return this.makeHttpRequestObservable<T>(Method.POST, path, this.getFormDataHeaders(), body);
  }

  public patch<T>(path: string, body: any): Observable<T> {
    return this.makeHttpRequestObservable<T>(Method.PATCH, path, this.getHeaders(), body);
  }

  public delete<T>(path: string, body?: any): Observable<T> {
    return this.makeHttpRequestObservable<T>(Method.DELETE, path, this.getHeaders(), body);
  }

  private makeHttpRequestObservable<T>(method: Method, path: string, headers: HttpHeaders, body: any = null, languageOverride?: string): Observable<T> {
    const pathPrefix = environment.apiUrl
    path = `${pathPrefix}/${path}`
    path = this.appendLanguageToPath(path, languageOverride);
    if (method === Method.GET) {
      return this.http.get<T>(path, { headers });
    } else if (method === Method.POST) {
      return this.http.post<T>(path, body, { headers });
    } else if (method === Method.PATCH) {
      return this.http.patch<T>(path, body, { headers });
    } else if (method === Method.DELETE) {
      return this.http.delete<T>(path, { headers });
    } else if (method === Method.PUT) {
      return this.http.put<T>(path, body, { headers });
    }

    return of({} as T);
  }

  private appendLanguageToPath(path: string, localeOverride?: string): string {
    const url = new URL(path);
    url.searchParams.set("lang", localeOverride || this.locale.locale);
    return url.toString()
  }
}
