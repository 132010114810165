import { Component, RendererFactory2 } from "@angular/core";
import { NgxPermissionsConfigurationService } from "ngx-permissions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "app";
  constructor(rendererFactory: RendererFactory2, private ngxPermissionsConfigurationService: NgxPermissionsConfigurationService) {
    const renderer = rendererFactory.createRenderer(null, null);
    this.ngxPermissionsConfigurationService.addPermissionStrategy("disable", (templateRef) => {
      renderer.setAttribute(templateRef.elementRef.nativeElement.previousElementSibling, "disabled", "true");
    });

    this.ngxPermissionsConfigurationService.addPermissionStrategy("enable", (templateRef) => {
      renderer.removeAttribute(templateRef.elementRef.nativeElement.previousElementSibling, "disabled");
    });
  }
}
