import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { LanguagePickerLanguage } from "../language-picker/language-picker.component";
import { MessageTranslationService } from "../message-translation.service";
import { File, File as FileModel } from "../models/File";
import { AppLanguage } from "../models/ReservationField";
import { Role } from "../models/Role";
import { RestService } from "../services/rest.service";
import { SupportService } from "../services/support.service";

export enum AttachmentType {
  COMPANY,
  WAREHOUSE,
  DOOR,
}

@Component({
  selector: "app-mail-attachments-settings",
  templateUrl: "./mail-attachments-settings.component.html",
  styleUrls: ["./mail-attachments-settings.component.css"],
})
export class MailAttachmentsSettingsComponent implements OnInit, OnChanges {
  @Input() companyId: number | null = null;
  @Input() warehouseId: number | null = null;
  @Input() doorId: number | null = null;

  Role = Role;

  faTimes = faTimes;

  attachmentToUpload: any | null = null;
  isLoading = false;
  isUploadingLoading = false;
  loadingDelete = false;

  attachments: FileModel[] = [];

  attachmentToDelete: FileModel | null = null;

  @ViewChild("attachmentFileInput") attachmentFileInput: ElementRef;

  constructor(
    private http: RestService,
    public modalService: NgbModal,
    private toast: ToastrService,
    private msg: MessageTranslationService,
    private support: SupportService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId || changes.warehouseId || changes.doorId) {
      this.fetchAttachments();
    }
  }

  async fetchAttachments() {
    try {
      this.isLoading = true;

      let endpoint: string | null = null;
      if (this.companyId != null) {
        endpoint = `getCompanyAttachments`;
      } else if (this.warehouseId != null) {
        endpoint = `getWarehouseAttachments/${this.warehouseId}`;
      } else if (this.doorId != null) {
        endpoint = `getDoorAttachments/${this.doorId}`;
      }

      if (endpoint == null) {
        return;
      }

      this.attachments = await this.http.get<FileModel[]>(`api/emailTemplates/${endpoint}`).toPromise();
    } catch (e) {
      console.error("fetchAttachments error", e);
    }

    this.isLoading = false;
  }

  getRelevantId(): { id: number; type: AttachmentType } | null {
    if (this.companyId != null) {
      return { id: this.companyId, type: AttachmentType.COMPANY };
    }

    if (this.warehouseId != null) {
      return { id: this.warehouseId, type: AttachmentType.WAREHOUSE };
    }

    if (this.doorId != null) {
      return { id: this.doorId, type: AttachmentType.DOOR };
    }

    return null;
  }

  async onUpload() {
    if (!this.attachmentToUpload) {
      return;
    }

    try {
      const { id, type } = this.getRelevantId();

      this.isUploadingLoading = true;

      const formData: FormData = new FormData();
      formData.append("attachmentFile", this.attachmentToUpload, this.attachmentToUpload.name);
      formData.append("type", type.toString());
      const file = await this.http.postFormData<FileModel>(`api/emailTemplates/uploadAttachment/${id}`, formData).toPromise();
      this.attachments.push(file);

      this.attachmentToUpload = null;
      this.attachmentFileInput.nativeElement.value = "";
    } catch (e) {
      this.toast.error(this.msg.unknownError());
      console.error("onUpload error", e);
    }

    this.isUploadingLoading = false;
  }

  async onAttachmentLanguageChange(attachment: FileModel, language: AppLanguage | null) {
    try {
      const file = await this.http
        .postFormData<FileModel>(`api/emailTemplates/updateAttachment/${attachment.id}`, {
          languageId: language?.id,
        })
        .toPromise();

      const index = this.attachments.findIndex((a) => a.id === attachment.id);
      if (index >= 0) {
        this.attachments[index] = file;
      }

      this.toast.success(this.msg.updateSuccess());
    } catch (e) {
      this.toast.error(this.msg.unknownError());
      console.error("onlanuagechange error", e);
    }
  }

  downloadAttachmentFile(attachment: FileModel) {
    this.support.downloadFile(FileModel.Url(attachment));
  }

  openRemoveAttachmentModal(deleteContent: any, attachment: FileModel) {
    this.attachmentToDelete = attachment;
    this.modalService.open(deleteContent, { ariaLabelledBy: "modal-basic-title" }).result.then(
      () => {
        this.attachmentToDelete = null;
      },
      (_) => {
        this.attachmentToDelete = null;
      }
    );
  }

  async removeAttachment() {
    if (!this.attachmentToDelete) {
      return;
    }

    try {
      this.loadingDelete = true;
      await this.http.post(`api/emailTemplates/deleteAttachment/${this.attachmentToDelete.id}`, {}).toPromise();
      this.attachments = this.attachments.filter((a) => a !== this.attachmentToDelete);
      this.modalService.dismissAll();
    } catch (e) {
      this.toast.error(this.msg.unknownError());
      console.error("removeAttachment error", e);
    }

    this.loadingDelete = false;
  }
}
