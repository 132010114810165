import { AuthService } from "../core/auth.service";
import { DataTableEntryType } from "../data-table/data-table.component";

export enum ReservationFieldType {
  Number = 0,
  String = 1,
  Select = 2,
  Checkbox = 3,
  Country = 4,
  Date = 5,
}

const reservationFieldTypeToDataTableEntryTypeMap: Record<ReservationFieldType, DataTableEntryType> = {
  [ReservationFieldType.Number]: "number",
  [ReservationFieldType.String]: "text",
  [ReservationFieldType.Select]: "select",
  [ReservationFieldType.Checkbox]: "checkbox",
  [ReservationFieldType.Country]: "select",
  [ReservationFieldType.Date]: "text",
};

export const reservationFieldTypeToDataTableEntryType = (type: ReservationFieldType): DataTableEntryType => {
  return reservationFieldTypeToDataTableEntryTypeMap[type];
};

export const renderReservationFieldType = (f: ReservationField) => {
  switch (f.type) {
    case ReservationFieldType.Number:
      return `${f.isMultiLine ? "Multiline " : ""}Number`;
    case ReservationFieldType.String:
      return `${f.isMultiLine ? "Multiline " : ""}String`;
    case ReservationFieldType.Select:
      return "Select";
    case ReservationFieldType.Checkbox:
      return "Checkbox";
    case ReservationFieldType.Country:
      return "Country";
    case ReservationFieldType.Date:
      return "Date";
  }

  return f;
};

export class AppLanguage {
  id: number;
  name: string;
  subdomain: string;
}

export class ReservationFieldName {
  name: string;
  language: AppLanguage;
}

export interface SelectValues {
  values: string[];
  base64Images?: string[];
}

export class ReservationField {
  id: number;
  sequenceNumber: number;
  name: string;
  reservationFieldNames: ReservationFieldName[];
  required: boolean;
  isMultiLine: boolean;
  showInMail: boolean;
  hideField: boolean;
  hideForCarriers: boolean;
  default?: string;
  min?: number;
  max?: number;
  selectValues: SelectValues;
  type: ReservationFieldType;
  userId?: number;
  warehouseId?: number;
  importantFieldWarehouseId?: number;
  doorId?: number;
  value: any;
  values: string[];
  errorMessage: string | null;
  helpText: string;
  specialMeaning: ReservationFieldSpecialMeaningField | null;
  derivedFromFieldId: number;

  constructor(languages: AppLanguage[]) {
    this.reservationFieldNames = languages.map((lang) => ({
      language: lang,
      name: "",
    }));
    this.required = false;
    this.errorMessage = null;
    this.type = ReservationFieldType.String;
    this.showInMail = true;
    this.hideField = false;
    this.hideForCarriers = false;
    this.isMultiLine = false;
    this.helpText = "";
    this.specialMeaning = null;
    this.selectValues = { values: [], base64Images: [] };
  }
}

export const isStringNullOrEmpty = (s: any) => {
  return s == null || s.toString().trim().length === 0;
};

export const isNumberNullOrInvalid = (n: any) => {
  return n == null || isNumberInvalid(n);
};

export const isNumberInvalid = (n: any) => {
  return isNaN(n);
};

export const isReservationFieldValid = (f: ReservationField): boolean => {
  const nullName = f.reservationFieldNames.find((name) => !name.name?.length);
  const requiredNotNull = !nullName && f.type != null;
  if (!requiredNotNull) {
    return false;
  }

  if (f.type == ReservationFieldType.Select && (f.selectValues == null || f.selectValues.values.length === 0)) {
    return false;
  }

  return true;
};

export const getReservationFieldErrorMessage = (f: ReservationField, shouldHideField: boolean, msgT: any): string | null => {
  if (shouldHideField) {
    return;
  }

  if (f.type === ReservationFieldType.String || f.type === ReservationFieldType.Date) {
    if (f.required && isStringNullOrEmpty(f.value)) {
      return msgT.FIELD_REQUIRED;
    }
  } else if (f.type === ReservationFieldType.Number) {
    const isEmpty = isStringNullOrEmpty(f.value);
    if (f.required && isEmpty) {
      return msgT.FIELD_REQUIRED;
    }

    let values = [];
    if (!isEmpty) {
      if (f.isMultiLine) {
        values = f.value.split("\n");
      } else {
        values = [f.value];
      }
    }

    for (const value of values) {
      let error = validateNumber(value, f, isEmpty, msgT);
      if (error != null) {
        return error;
      }
    }
  } else if (f.type === ReservationFieldType.Select) {
    if (f.required && isStringNullOrEmpty(f.value)) {
      return msgT.FIELD_REQUIRED;
    }

    if (!f.selectValues.values.includes(f.value)) {
      return msgT.FIELD_ONE_OF_VALUES + `${f.selectValues.values.join(", ")}.`;
    }
  } else if (f.type === ReservationFieldType.Country) {
    if (f.required && isStringNullOrEmpty(f.value)) {
      return msgT.FIELD_REQUIRED;
    }
  }

  return null;
};

const validateNumber = (value: any, field: ReservationField, isEmpty: boolean, msgT: any) => {
  if (!isEmpty && isNumberInvalid(value)) {
    if (field.isMultiLine) {
      return msgT.FIELD_NUMBERS_MULTIPLE;
    } else {
      return msgT.FIELD_NUMBERS_SINGLE;
    }
  }

  if (field.min != null && value < field.min) {
    if (field.isMultiLine) {
      return msgT.FIELD_LESS_MULTIPLE + `${field.min}.`;
    } else {
      return msgT.FIELD_LESS_SINGLE + `${field.min}.`;
    }
  }

  if (field.max != null && value > field.max) {
    if (field.isMultiLine) {
      return msgT.FIELD_MORE_MULTIPLE + `${field.max}.`;
    } else {
      return msgT.FIELD_MORE_SINGLE + `${field.max}.`;
    }
  }

  return null;
};

export enum ReservationFieldSpecialMeaningField {
  YAMAS_DRIVER_CODE,
  DRIVER_NAME,
  TRANSPORT_COMPANY,
  TRUCK_REGISTRATION_NUMBER,
  TRANSPORT_COMPANY_COUNTRY,
  EMAIL,
  NUMBER_OF_PALLETS,
  LOADING_COUNTRY,
  DRIVER_SURNAME,
  NUMBER_OF_HALF_PALLETS,
}

export interface ReservationFieldSpecialMeaning {
  field: ReservationFieldSpecialMeaningField;
  type: ReservationFieldType;
}

export const isPalletsField = (field: ReservationField): boolean => {
  return field.specialMeaning === ReservationFieldSpecialMeaningField.NUMBER_OF_PALLETS;
};

export const isHalfPalletsField = (field: ReservationField): boolean => {
  return field.specialMeaning === ReservationFieldSpecialMeaningField.NUMBER_OF_HALF_PALLETS;
};

export const getFieldByMeaning = (fields: ReservationField[], meaning: ReservationFieldSpecialMeaningField): ReservationField | null => {
  return fields.find((field) => field.specialMeaning === meaning) || null;
};

export const getImportantFields = (fields: ReservationField[]): ReservationField[] => {
  return fields.filter((field) => field.importantFieldWarehouseId != null);
};

export const getRegularFields = (fields: ReservationField[]): ReservationField[] => {
  return fields.filter((field) => field.importantFieldWarehouseId == null);
};

export const combineWithDerivedFields = (emptyFields: ReservationField[], existingFields: ReservationField[]): ReservationField[] => {
  emptyFields.forEach((f) => {
    const matchingExistingField = existingFields.find((eF) => eF.id === f.derivedFromFieldId);
    if (matchingExistingField) {
      f.value = matchingExistingField.value;
    }
  });
  return emptyFields;
};

export const combineWithChildDerivedFields = (emptyFields: ReservationField[], existingFields: ReservationField[]): ReservationField[] => {
  console.log(emptyFields, existingFields);
  emptyFields.forEach((f) => {
    const matchingExistingField = existingFields.find((eF) => eF.derivedFromFieldId != null && eF.derivedFromFieldId === f.derivedFromFieldId);
    if (matchingExistingField) {
      f.value = matchingExistingField.value;
    }
  });
  return emptyFields;
};
