<div class="container app-container">
  <h1 class="app-heading">
    <ng-container i18n>Register as a carrier</ng-container>
  </h1>
  <p i18n>Note: to register as a warehouse company, please contact <a href="mailto:info@omniopti.eu">info@omniopti.eu</a>.</p>
  <form (ngSubmit)="SendRegistration()">
    <div class="form-group">
      <label for="email"><ng-container i18n>Username</ng-container></label>
      <input type="email" class="form-control" id="email" [(ngModel)]="user.email" [ngModelOptions]="{ standalone: true }" />
    </div>

    <div class="form-group">
      <label for="password"><ng-container i18n>Password</ng-container></label>
      <input type="password" class="form-control" id="password" [(ngModel)]="password" [ngModelOptions]="{ standalone: true }" />
    </div>

    <div class="form-group">
      <label for="passwordConf"><ng-container i18n>Password confirmation</ng-container></label>
      <input type="password" class="form-control" id="passwordConf" [(ngModel)]="passwordConfirmation" [ngModelOptions]="{ standalone: true }" />
    </div>

    <div class="form-group">
      <label for="title"><ng-container i18n>Company name</ng-container></label>
      <input type="text" class="form-control" id="title" [(ngModel)]="user.title" [ngModelOptions]="{ standalone: true }" />
    </div>

    <div class="form-group">
      <label for="name"><ng-container i18n>Contact person</ng-container></label>
      <input type="text" class="form-control" id="name" [(ngModel)]="user.name" [ngModelOptions]="{ standalone: true }" />
    </div>

    <div class="form-group">
      <label for="name"><ng-container i18n>Phone number</ng-container></label>
      <input type="text" class="form-control" id="phoneNumber" [(ngModel)]="user.phoneNumber" [ngModelOptions]="{ standalone: true }" />
    </div>

    <button type="submit" class="btn btn-primary">
      <ng-container i18n>Register</ng-container>
    </button>
  </form>
</div>
