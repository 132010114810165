<div class="modal-header">
  <h4 class="modal-title" i18n>Add warehouse</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel.emit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="form-group mb-2">
    <label><ng-container i18n>Name</ng-container></label>
    <input class="form-control" i18n-placeholder placeholder="Name" [(ngModel)]="addWarehouseInput.name" [disabled]="isLoading" (keydown.enter)="onAdd()" />
  </div>

  <div class="form-group mb-2">
    <label><ng-container i18n>Description</ng-container></label>
    <input
      class="form-control"
      i18n-placeholder
      placeholder="Description"
      [(ngModel)]="addWarehouseInput.description"
      [disabled]="isLoading"
      (keydown.enter)="onAdd()"
    />
  </div>

  <div class="form-group mb-2">
    <label><ng-container i18n>Address</ng-container></label>
    <input
      class="form-control"
      i18n-placeholder
      placeholder="Address"
      [(ngModel)]="addWarehouseInput.address"
      [disabled]="isLoading"
      (keydown.enter)="onAdd()"
    />
  </div>

  <div class="form-group mb-2">
    <label><ng-container i18n>Contact email</ng-container></label>
    <input
      class="form-control"
      i18n-placeholder
      placeholder="Contact email"
      [(ngModel)]="addWarehouseInput.contactEmail"
      [disabled]="isLoading"
      (keydown.enter)="onAdd()"
    />
  </div>

  <div class="form-group mb-3">
    <label><ng-container i18n>Contact phone</ng-container></label>
    <input
      class="form-control"
      i18n-placeholder
      placeholder="Contact phone"
      [(ngModel)]="addWarehouseInput.contactPhone"
      [disabled]="isLoading"
      (keydown.enter)="onAdd()"
    />
  </div>

  <div class="form-group">
    <label><ng-container i18n>Image</ng-container></label>
    <input type="file" (change)="warehouseImageFile = $event.target.files[0]" class="form-control-file" />
  </div>

  <div class="form-check">
    <input
      id="canCarrierEditReservation"
      type="checkbox"
      class="form-check-input"
      [(ngModel)]="addWarehouseInput.canCarrierEditReservation"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="isLoading"
    />
    <label class="form-check-label" for="canCarrierEditReservation" i18n> Carrier can edit reservation </label>
  </div>

  <div class="form-check">
    <input
      id="canCarrierDeleteReservation"
      type="checkbox"
      class="form-check-input"
      [(ngModel)]="addWarehouseInput.canCarrierDeleteReservation"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="isLoading"
    />
    <label class="form-check-label" for="canCarrierDeleteReservation" i18n> Carrier can delete reservation </label>
  </div>

  <div class="form-check">
    <input
      id="canCarrierCreateAnonymousReservation"
      type="checkbox"
      class="form-check-input"
      [(ngModel)]="addWarehouseInput.canCarrierCreateAnonymousReservation"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="isLoading"
    />
    <label class="form-check-label" for="canCarrierCreateAnonymousReservation" i18n> Carrier can create public reservation </label>
  </div>
</div>

<div class="modal-footer d-flex justify-content-between">
  <button type="submit" class="btn" (click)="onCancel.emit()" [disabled]="isLoading">
    <ng-container i18n="Cancel">Cancel</ng-container>
  </button>
  <button class="btn btn-primary" i18n [disabled]="!canAdd() || isLoading" (click)="onAdd()">Add</button>
</div>
