<div>
  <div class="row">
    <div class="form-group col-6">
      <label for="dateFrom">
        <ng-container i18n>Filter</ng-container>
      </label>
      <input
        class="form-control table-filter-input"
        i18n-placeholder
        placeholder="Filter by anything..."
        type="text"
        [ngModel]="filter.filterText"
        (ngModelChange)="filter.filterText = $event; onFilterChange()"
      />
    </div>

    <div class="form-group col-3">
      <label for="dateFrom"><ng-container i18n>Date from</ng-container></label>
      <app-pick-date [date]="filter.dateFrom" (dateChange)="onDateChange($event, undefined); onFilterChange()"></app-pick-date>
    </div>

    <div class="form-group col-3">
      <label for="dateTo"><ng-container i18n>Date to</ng-container></label>
      <app-pick-date [date]="filter.dateTo" (dateChange)="onDateChange(undefined, $event); onFilterChange()"></app-pick-date>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-6">
      <label>
        <ng-container i18n>Warehouse</ng-container>
      </label>
      <ng-multiselect-dropdown
        class="ng-multiselect-dropdown"
        placeholder="All warehouses"
        i18n-placeholder
        [settings]="warehousesSettings"
        [data]="warehouses"
        [ngModel]="filter.warehouses"
        (ngModelChange)="filter.warehouses = $event; onFilterChange()"
      >
      </ng-multiselect-dropdown>
    </div>

    <div class="form-group col-6">
      <label for="dateFrom">
        <ng-container i18n>Door</ng-container>
      </label>
      <ng-multiselect-dropdown
        class="ng-multiselect-dropdown"
        placeholder="All doors"
        i18n-placeholder
        [settings]="doorsSettings"
        [data]="doors"
        [ngModel]="filter.doors"
        (ngModelChange)="filter.doors = $event; onFilterChange()"
      >
      </ng-multiselect-dropdown>
    </div>
  </div>

  <div class="mb-3 d-flex justify-content-between align-items-center">
    <div>
      <span class="btn table-refresh-btn mr-2" (click)="todayClick()" i18n>
        <u>Today</u>
      </span>

      <span *ngIf="!isArchive" class="btn table-refresh-btn mr-2" (click)="tomorrowClick()" i18n>
        <u>Tomorrow</u>
      </span>

      <span *ngIf="isArchive" class="btn table-refresh-btn mr-2" (click)="yesterdayClick()" i18n>
        <u>Yesterday</u>
      </span>

      <span class="btn table-refresh-btn mr-2" (click)="thisWeekClick()" i18n>
        <u>This week</u>
      </span>

      <span *ngIf="!isArchive" class="btn table-refresh-btn mr-2" (click)="nextWeekClick()" i18n>
        <u>Next week</u>
      </span>

      <span *ngIf="isArchive" class="btn table-refresh-btn mr-2" (click)="lastWeekClick()" i18n>
        <u>Last week</u>
      </span>

      <span class="btn table-refresh-btn mr-2" (click)="thisMonthClick()" i18n>
        <u>This month</u>
      </span>

      <span *ngIf="isArchive" class="btn table-refresh-btn mr-2" (click)="lastMonthClick()" i18n>
        <u>Last month</u>
      </span>

      <span class="btn table-refresh-btn mr-2 text-danger" (click)="clearDate()" i18n>
        <u>Clear date</u>
      </span>
    </div>

    <div>
      <button class="btn table-refresh-btn mr-2" (click)="clearFilters()" i18n>Clear all filters</button>
      <button class="btn btn-primary table-refresh-btn" (click)="refresh.emit()">
        <ng-container i18n>Refresh</ng-container>
      </button>
    </div>
  </div>
</div>
