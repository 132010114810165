import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-weekday-picker",
  templateUrl: "./weekday-picker.component.html",
  styleUrls: ["./weekday-picker.component.css"],
})
export class WeekdayPickerComponent implements OnInit {
  @Input() value: number[] | null = null;
  @Output() valueChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor() {}

  ngOnInit(): void {}

  onValueChange(dayIndex: number) {
    const value = [...(this.value || [])];

    const index = value.findIndex((v) => v === dayIndex);
    if (index >= 0) {
      value.splice(index, 1);
    } else {
      value.push(dayIndex);
    }

    this.valueChange.emit(value);
  }

  isSelected(dayIndex: number) {
    if (!this.value) {
      return false;
    }

    return this.value.includes(dayIndex);
  }
}
