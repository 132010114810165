import { Pipe, PipeTransform } from "@angular/core";
import { DataTableEntry, getDataColumnStringId } from "./data-table.component";

@Pipe({
  name: "dataTableEntryAccess",
})
export class DataTableEntryAccessPipe implements PipeTransform {
  transform(columnId: string, entries: DataTableEntry[]): DataTableEntry | null {
    return entries.find((e) => getDataColumnStringId(e.columnIds) === columnId);
  }
}
