import { Door } from "./Door";
import { User } from "./User";
import { Warehouse } from "./Warehouse";

export enum PermissionStatus {
  Pending,
  Accepted,
  Declined,
}

export enum PermissionType {
  ALL_DOORS,
  ONLY_SPECIFIC_DOORS,
  ONLY_TWO_PHASE,
}

export interface PermissionForDoor {
  door: Door;
  doorId: number;
}

export class Permission {
  id: number;
  carrierId: number;
  warehouseId: number;
  status: PermissionStatus;
  updatedAt: Date;
  createdAt: Date;

  type: PermissionType;
  permissionsForDoor: PermissionForDoor[];

  carrier: User;
  warehouse: Warehouse;

  filterString: string;
}
