import { Component, Input, OnInit } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { Role } from "../models/Role";
import { RestService } from "../services/rest.service";
import { ContactMail } from "../warehouse-profile/warehouse-profile.component";

@Component({
  selector: "app-profile-contact-mails-settings",
  templateUrl: "./profile-contact-mails-settings.component.html",
  styleUrls: ["./profile-contact-mails-settings.component.css"],
})
export class ProfileContactMailsSettingsComponent implements OnInit {
  contactMails: ContactMail[] = [];
  newContactMail: string = "";

  faTimes = faTimes;

  Role = Role;

  constructor(private http: RestService, private toast: ToastrService, private msgT: MessageTranslationService) {}

  ngOnInit(): void {
    this.fetchContactMails();
  }

  fetchContactMails() {
    this.http.get<ContactMail[]>("api/user/getContactMails", {}).subscribe(
      (u) => {
        this.contactMails = u;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  AddContactMail() {
    if (this.newContactMail.trim().length === 0) {
      return;
    }

    this.http.post(`api/user/addContactMail`, { mail: this.newContactMail }).subscribe(
      (d: ContactMail) => {
        this.toast.success(this.msgT.addContactMailSuccess());
        this.contactMails.push(d);
        this.newContactMail = "";
      },
      (error) => {
        console.log("Add mail error");
        console.log(error.error);
        this.toast.error(this.msgT.unknownError());
      }
    );
  }

  RemoveContactMail(id: number) {
    this.http.delete(`api/user/deleteContactMail/${id}`, {}).subscribe(
      () => {
        this.toast.success(this.msgT.removeContactMailSuccess());
        this.contactMails = this.contactMails.filter((d) => d.id !== id);
      },
      (error) => {
        console.log("Remove mail error");
        console.log(error.error);
        this.toast.error(this.msgT.unknownError());
      }
    );
  }
}
