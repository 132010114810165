<ng-container [ngSwitch]="recurrenceRule" class="text-secondary small">
  <span *ngSwitchCase="null">
    <ng-container i18n="Every day">Every day</ng-container>
  </span>
  <span *ngSwitchCase="'0'">
    <ng-container i18n="Every Sunday">Every Sunday</ng-container>
  </span>
  <span *ngSwitchCase="'1'">
    <ng-container i18n="Every Monday">Every Monday</ng-container>
  </span>
  <span *ngSwitchCase="'2'">
    <ng-container i18n="Every Tuesday">Every Tuesday</ng-container>
  </span>
  <span *ngSwitchCase="'3'">
    <ng-container i18n="Every Wednesday">Every Wednesday</ng-container>
  </span>
  <span *ngSwitchCase="'4'">
    <ng-container i18n="Every Thursday">Every Thursday</ng-container>
  </span>
  <span *ngSwitchCase="'5'">
    <ng-container i18n="Every Friday">Every Friday</ng-container>
  </span>
  <span *ngSwitchCase="'6'">
    <ng-container i18n="Every Saturday">Every Saturday</ng-container>
  </span>
</ng-container>
