<div class="container app-container arrival-table-container">
  <h1 class="app-heading" i18n>All warehouses</h1>
  <app-warehouse-list-search-bar [disabled]="loading" [(filterText)]="filterText" (onRefresh)="onRefresh()"></app-warehouse-list-search-bar>

  <ng-container *ngIf="loading">
    <app-spinner></app-spinner>
  </ng-container>

  <ng-container *ngIf="!loading">
    <app-warehouse-list
      [warehouses]="allCompaniesWithWarehouses"
      [filterText]="filterText"
      [isCarrier]="isCarrier"
      (onRequestPermission)="onRequestPermission($event)"
    ></app-warehouse-list>
  </ng-container>
</div>
