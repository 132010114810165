import { Component, OnInit } from "@angular/core";
import { Holiday } from "../models/Holiday";
import { RestService } from "../services/rest.service";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { Role } from "../models/Role";

@Component({
  selector: "app-company-holiday-list",
  templateUrl: "./company-holiday-list.component.html",
  styleUrls: ["./company-holiday-list.component.css"],
})
export class CompanyHolidayListComponent implements OnInit {
  isLoading = true;
  isAddingLoading = false;

  holidays: Holiday[] = [];

  faTimes = faTimes;

  newHolidayDate: Date;

  Role = Role;

  constructor(public http: RestService, private toast: ToastrService, private msgT: MessageTranslationService) {}

  ngOnInit(): void {
    this.fetchHolidays();
  }

  async fetchHolidays() {
    try {
      this.holidays = await this.http.get<Holiday[]>("/api/holiday/list").toPromise();
    } catch (e) {
      console.error("Error fetching holidays", e);
    } finally {
      this.isLoading = false;
    }
  }

  async addHoliday() {
    if (this.newHolidayDate == null) {
      return;
    }

    this.isAddingLoading = true;

    try {
      const holiday = await this.http.post<Holiday>(`api/holiday/create`, { date: this.newHolidayDate }).toPromise();
      this.toast.success(this.msgT.addHolidaySuccess());
      this.holidays.push(holiday);
      this.newHolidayDate = null;
    } catch (error) {
      console.log("Add mail error");
      console.log(error.error);
      this.toast.error(this.msgT.unknownError());
    } finally {
      this.isAddingLoading = false;
    }
  }

  async removeHoliday(id: number) {
    try {
      this.http.delete(`api/holiday/delete/${id}`, {}).toPromise();
      this.toast.success(this.msgT.removeHolidaySuccess());
      this.holidays = this.holidays.filter((d) => d.id !== id);
    } catch (error) {
      console.log("Remove holiday error");
      console.log(error.error);
      this.toast.error(this.msgT.unknownError());
    }
  }
}
