import { Component, OnDestroy, OnInit } from "@angular/core";
import { ReservationDto, ReservationDtosByType, ReservationDtoType } from "src/app/models/MyReservations";
import { ReservationService } from "src/app/services/reservation.service";
import { WarehouseService } from "src/app/services/warehouse.service";
import {
  getDefaultReservationListSearchBarFilter,
  MultiSelectOption,
  ReservationListSearchBarFilter,
} from "../reservation-list-search-bar/reservation-list-search-bar.component";
import { Subject, interval } from "rxjs";
import { startWith, switchMap, share, takeUntil } from "rxjs/operators";
import { DataTableColumn } from "src/app/data-table/data-table.component";
import { AuthService } from "src/app/core/auth.service";

const POLLING_INTERVAL = 30 * 1000;

@Component({
  selector: "app-warehouse-reservation-list",
  templateUrl: "./warehouse-reservation-list.component.html",
  styleUrls: ["./warehouse-reservation-list.component.css"],
})
export class WarehouseReservationListComponent implements OnInit, OnDestroy {
  isCarrier: boolean;

  selectedTab: ReservationDtoType = ReservationDtoType.STANDARD;

  warehousesOptions: MultiSelectOption[] = [];
  doorsOptions: MultiSelectOption[] = [];

  loading = true;

  reservationDtosByType: ReservationDtosByType = null;

  paginatedReservations: ReservationDto[] = [];

  selectedReservationsAllColumns: DataTableColumn[] = [];

  filter: ReservationListSearchBarFilter = getDefaultReservationListSearchBarFilter();

  stopPolling = new Subject();

  constructor(private warehouseService: WarehouseService, private reservationService: ReservationService, private auth: AuthService) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.isCarrier = this.auth.IsCarrier();
      this.setupPolling();
    });
  }

  setupPolling() {
    interval(POLLING_INTERVAL)
      .pipe(
        startWith(0),
        switchMap(async () => {
          this.doFetch();
        }),
        share(),
        takeUntil(this.stopPolling)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.stopPolling.next();
  }

  async doFetch(): Promise<void> {
    await this.fetchWarehouseAndDoorOptions();
    await this.fetchReservations();
    this.loading = false;
  }

  doRefresh(): void {
    this.loading = true;
    this.doFetch();
  }

  onSelectedTabChange(tab: ReservationDtoType) {
    this.selectedTab = tab;
    this.filterReservations();
  }

  filterReservations() {
    let reservations: ReservationDto[] = [];
    let columns: DataTableColumn[] = [];
    switch (this.selectedTab) {
      case ReservationDtoType.STANDARD:
        reservations = this.reservationDtosByType?.standardReservations.data || [];
        columns = this.reservationDtosByType?.standardReservations.columns || [];
        break;
      case ReservationDtoType.TWO_PHASE:
        reservations = this.reservationDtosByType?.twoPhaseReservations.data || [];
        columns = this.reservationDtosByType?.twoPhaseReservations.columns || [];
        break;
    }

    const filteredDestinations = this.reservationService.filterReservationsByFilter(reservations, this.filter);

    this.paginatedReservations = filteredDestinations;
    this.selectedReservationsAllColumns = columns;
  }

  private async fetchReservations(): Promise<void> {
    try {
      this.reservationDtosByType = await this.reservationService.fetchMyReservations(this.isCarrier);
      this.filterReservations();
    } catch (e) {
      console.log(e);
    }
  }

  onReservationDelete(reservation: ReservationDto) {
    switch (this.selectedTab) {
      case ReservationDtoType.STANDARD:
        this.reservationDtosByType.standardReservations.data = this.reservationDtosByType.standardReservations.data.filter((r) => r.id !== reservation.id);
        this.reservationDtosByType.standardReservations.columns = this.reservationService.getAllReservationColumns(
          this.reservationDtosByType.standardReservations.data
        );
        break;
      case ReservationDtoType.TWO_PHASE:
        this.reservationDtosByType.twoPhaseReservations.data = this.reservationDtosByType.twoPhaseReservations.data.filter((r) => r.id !== reservation.id);
        this.reservationDtosByType.twoPhaseReservations.columns = this.reservationService.getAllReservationColumns(
          this.reservationDtosByType.twoPhaseReservations.data
        );
        break;
    }

    this.filterReservations();
  }

  private async fetchWarehouseAndDoorOptions(): Promise<void> {
    const multiSelectOptions = await this.warehouseService.fetchWarehouseAndDoorMultiselectOptions(this.isCarrier);
    this.warehousesOptions = multiSelectOptions.warehousesOptions;
    this.doorsOptions = multiSelectOptions.doorsOptions;
  }
}
