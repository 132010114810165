<app-warehouse-door-select
  *ngIf="isApproving"
  [warehouseId]="reservation?.warehouseId"
  [selectedDoorId]="door?.id"
  (selectedDoorIdChange)="onDoorSelected($event)"
></app-warehouse-door-select>

<div *ngIf="door && reservation">
  <!-- DOOR INFO -->
  <div class="my-3">
    <app-accordion-with-content i18n-label label="DOOR INFORMATION">
      <div>
        <p class="m-0"><b i18n>Warehouse:</b> {{ door.warehouse.name }}</p>
        <p class="m-0"><b i18n>Warehouse address:</b> {{ door.warehouse.address || "/" }}</p>
        <p class="m-0"><b i18n>Door name:</b> {{ door.name }}</p>
        <p class="m-0"><b i18n>Notes:</b> {{ door.description || "/" }}</p>
      </div>

      <div *ngIf="isEditingReservation" class="mt-2 mb-3">
        <button (click)="openDeleteReservationModal(deleteContent)" class="btn btn-danger">
          <ng-container i18n>Delete reservation</ng-container>
        </button>
      </div>
    </app-accordion-with-content>
  </div>

  <!-- DATE & TIME OF RESERVATION INFO -->
  <div class="my-3">
    <app-accordion-with-content i18n-label label="DATE AND TIME OF RESERVATION">
      <div class="form-group">
        <label><ng-container i18n>Date</ng-container></label>
        <app-pick-date [date]="reservationDateRange.date" (dateChange)="onReservationDateRangeDateChange($event)"></app-pick-date>
      </div>

      <ng-container *ngIf="door.properties.type == ReservationType.Free">
        <div class="d-flex">
          <app-app-time-input
            class="flex-1 w-100 mr-1"
            [time]="reservationDateRange.start"
            (timeChange)="onReservationDateRangeTimeStartChange($event)"
            label="From"
            i18n-label
          ></app-app-time-input>
          <app-app-time-input
            class="flex-1 w-100 ml-1"
            [time]="reservationDateRange.end"
            (timeChange)="onReservationDateRangeTimeEndChange($event)"
            label="To"
            i18n-label
          ></app-app-time-input>
        </div>
      </ng-container>

      <ng-container *ngIf="door.properties.type == ReservationType.Calculated">
        <div class="d-flex">
          <app-app-time-input
            class="flex-1 w-100 mr-1"
            [time]="reservationDateRange.start"
            (timeChange)="onReservationDateRangeTimeStartChange($event)"
            label="From"
            i18n-label
          ></app-app-time-input>
          <app-app-time-input
            class="flex-1 w-100 ml-1"
            [time]="reservationDateRange.end"
            label="To (calculated)"
            i18n-label
            [disabled]="true"
          ></app-app-time-input>
        </div>
      </ng-container>

      <ng-container *ngIf="door.properties.type == ReservationType.Fixed">
        <td><ng-container i18n>Choose time window:</ng-container></td>
        <td class="row mb-1">
          <div *ngFor="let tw of door.availability.timeWindows" class="col-auto">
            <button
              (click)="selectFixedTimeWindow(tw)"
              class="btn"
              [ngClass]="{
                'btn-primary': selected == tw.id,
                'btn-secondary': selected != tw.id
              }"
            >
              {{ tw.start }}-{{ tw.end }}
            </button>
          </div>
        </td>
      </ng-container>

      <app-door-availability-calendar
        [door]="door"
        [selectedReservationId]="reservation.id"
        [dragToSelect]="door.properties.type === ReservationType.Free || door.properties.type === ReservationType.Calculated"
        [selection]="reservationDateRange"
        (selectionChange)="reservationDateRange = $event; onReservationDateRangeChange({}, true)"
      ></app-door-availability-calendar>
    </app-accordion-with-content>
  </div>

  <!-- RESERVATION DATA INFO -->
  <div class="my-3">
    <app-accordion-with-content i18n-label label="RESERVATION DATA">
      <app-reservation-fields-form
        [isCreatingNew]="!isEditingReservation && !approvalMode"
        *ngIf="reservation.data"
        [confirmButtonText]="confirmButtonText"
        [reservationFields]="reservation.data"
        [loading]="loading"
        (onConfirmClick)="handleReservationConfirmClick()"
        (onPalletsFieldNgModelChange)="calculateEndTimeFromPallets()"
      >
        <div class="mb-2">
          <app-multi-file-picker [(files)]="files"></app-multi-file-picker>
        </div>

        <div class="mt-3 mb-3" *ngIf="!isEditingReservation && !isApproving">
          <app-recurring-reservation-form
            [(isRecurring)]="isRecurring"
            [(recurrenceRule)]="reservation.recurrenceRule"
            [(fromDate)]="reservation.fromDate"
            [(toDate)]="reservation.toDate"
          ></app-recurring-reservation-form>
        </div>
      </app-reservation-fields-form>
    </app-accordion-with-content>
  </div>
</div>

<ng-template #deleteContent let-modal>
  <app-confirmation-modal
    [isLoading]="loadingDelete"
    header="Delete reservation?"
    i18n-header
    subHeader="Confirm deleting reservation"
    i18n-subHeader
    cancelButtonText="Cancel"
    i18n-cancelButtonText
    confirmButtonText="Delete"
    i18n-confirmButtonText
    confirmButtonClass="btn-danger"
    (onCancel)="modalService.dismissAll()"
    (onConfirm)="deleteReservation()"
  >
  </app-confirmation-modal>
</ng-template>
