import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MessageTranslationService } from "../message-translation.service";

export interface CheckboxDropdownOption {
  id: number;
  text: string;
}

@Component({
  selector: "app-checkbox-dropdown-input",
  templateUrl: "./checkbox-dropdown-input.component.html",
  styleUrls: ["./checkbox-dropdown-input.component.css"],
})
export class CheckboxDropdownInputComponent implements OnInit {
  dropdownSettings = {};

  @Input()
  options: CheckboxDropdownOption[] = [];

  @Input()
  selectedOptions: CheckboxDropdownOption[] = [];
  @Output()
  selectedOptionsChange: EventEmitter<CheckboxDropdownOption[]> = new EventEmitter<CheckboxDropdownOption[]>();

  placeholder = "";

  constructor(private msgT: MessageTranslationService) {}

  ngOnInit() {
    const texts = this.msgT.getCheckboxDropdownText();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "text",
      selectAllText: texts.SELECT_ALL,
      unSelectAllText: texts.SELECT_NONE,
      itemsShowLimit: 0,
      allowSearchFilter: false,
    };

    this.placeholder = texts.FILTERS;
  }
}
