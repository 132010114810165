import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { RestService } from "../services/rest.service";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.css"],
})
export class NavMenuComponent {
  public toggleNavbar = false;

  constructor(private http: RestService, public auth: AuthService, private toast: ToastrService, private msgT: MessageTranslationService) {}

  Logout() {
    this.http.post("api/authentication/logout", {}).subscribe(
      (d) => {
        this.onLogout();
      },
      (error) => {
        console.log("Log out error");
        console.log(error);
        this.onLogout();
      }
    );
  }

  onLogout() {
    this.auth.logout();
    this.toast.success(this.msgT.loggedOutSuccess());
  }
}
