<div id="mapLocationPicker" [attr.id]="'mapLocationPicker'" class="map"></div>

<div class="form-group flex-grow-1 mt-2">
  <label><ng-container i18n>Latitude</ng-container></label>
  <input class="form-control" placeholder="Location latitude" (ngModelChange)="onLatChange($event)" [ngModel]="selectedLat" />
</div>

<div class="form-group flex-grow-1">
  <label><ng-container i18n>Longitude</ng-container></label>
  <input class="form-control" placeholder="Location longitude" (ngModelChange)="onLonChange($event)" [ngModel]="selectedLon" />
</div>
