<ng-container *ngIf="data != null">
  <app-accordion-with-content i18n-label label="Mail attachments settings">
    <app-app-checkbox [(value)]="data.sendAttachmentForReservationCreated" name="sendAttachmentForReservationCreated" [disabled]="!canEdit">
      <ng-container i18n>Send attachments for reservation created</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendAttachmentForReservationEdited" name="sendAttachmentForReservationEdited" [disabled]="!canEdit">
      <ng-container i18n>Send attachments for reservation edited</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendAttachmentForReservationDeleted" name="sendAttachmentForReservationDeleted" [disabled]="!canEdit">
      <ng-container i18n>Send attachments for reservation deleted</ng-container>
    </app-app-checkbox>

    <button class="btn btn-primary" (click)="updateMailSendingData()" *ngIf="canEdit">
      <ng-container i18n>Update data</ng-container>
    </button>
  </app-accordion-with-content>

  <br />

  <app-accordion-with-content i18n-label label="Mail sending information">
    <app-app-checkbox [(value)]="data.sendMailsForReservationCreated" name="sendMailsForReservationCreated" [disabled]="!canEdit">
      <ng-container i18n>Send mails for created reservation</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendMailsForReservationEdited" name="sendMailsForReservationEdited" [disabled]="!canEdit">
      <ng-container i18n>Send mails for updated reservation</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendMailsForReservationDeleted" name="sendMailsForReservationDeleted" [disabled]="!canEdit">
      <ng-container i18n>Send mails for deleted reservation</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendMailsForRecurringReservationCreated" name="sendMailsForRecurringReservationCreated" [disabled]="!canEdit">
      <ng-container i18n>Send mails for created recurring reservation</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendMailsForRecurringReservationEdited" name="sendMailsForRecurringReservationEdited" [disabled]="!canEdit">
      <ng-container i18n>Send mails for updated recurring reservation</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendMailsForRecurringReservationDeleted" name="sendMailsForRecurringReservationDeleted" [disabled]="!canEdit">
      <ng-container i18n>Send mails for deleted recurring reservation</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendMailsForTwoPhaseReservationCreated" name="sendMailsForTwoPhaseReservationCreated" [disabled]="!canEdit">
      <ng-container i18n>Send mails for created two-phase reservation</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendMailsForTwoPhaseReservationConfirmed" name="sendMailsForTwoPhaseReservationConfirmed" [disabled]="!canEdit">
      <ng-container i18n>Send mails for approved two-phase reservation</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendMailsForTwoPhaseReservationEdited" name="sendMailsForTwoPhaseReservationEdited" [disabled]="!canEdit">
      <ng-container i18n>Send mails for updated two-phase reservation</ng-container>
    </app-app-checkbox>

    <app-app-checkbox [(value)]="data.sendMailsForTwoPhaseReservationDeleted" name="sendMailsForTwoPhaseReservationDeleted" [disabled]="!canEdit">
      <ng-container i18n>Send mails for deleted two-phase reservation</ng-container>
    </app-app-checkbox>

    <button class="btn btn-primary" (click)="updateMailSendingData()" *ngIf="canEdit">
      <ng-container i18n>Update data</ng-container>
    </button>
  </app-accordion-with-content>
</ng-container>
