import { Reservation } from "./Reservation";
import { AppLanguage } from "./ReservationField";
import { User } from "./User";
import { Warehouse } from "./Warehouse";

export class File {
  id: number;
  name: string;
  reservationId: number;
  recurringReservationId: number;

  language?: AppLanguage;

  reservation: Reservation;
  user: User;
  warehouse: Warehouse;

  static Url(file: any): string {
    if (file == null) {
      return null;
    }

    return `api/file/download/${file.id}/${file.name}`;
  }
}

export interface FileExcerptDto {
  id: string;
  name: string;
  url: string;
}
