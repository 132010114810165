import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AddWarehouseRequest } from "src/app/models/Warehouse";
import { WarehouseService } from "src/app/services/warehouse.service";

@Component({
  selector: "app-add-warehouse-modal",
  templateUrl: "./add-warehouse-modal.component.html",
  styleUrls: ["./add-warehouse-modal.component.css"],
})
export class AddWarehouseModalComponent implements OnInit {
  addWarehouseInput: AddWarehouseRequest = {
    name: "",
    description: "",
    address: "",
    contactEmail: "",
    contactPhone: "",
    canCarrierEditReservation: false,
    canCarrierDeleteReservation: false,
    canCarrierCreateAnonymousReservation: false,
  };

  isLoading = false;
  warehouseImageFile: File = null;

  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onAddSuccess: EventEmitter<void> = new EventEmitter<void>();

  constructor(private warehouseService: WarehouseService, private router: Router) {}

  ngOnInit(): void {}

  canAdd() {
    return this.addWarehouseInput.name.trim().length > 0;
  }

  async onAdd() {
    if (!this.canAdd()) {
      return;
    }

    this.isLoading = true;

    const warehouse = await this.warehouseService.addWarehouse(this.addWarehouseInput);
    await this.warehouseService.uploadWarehouseImage(this.warehouseImageFile, warehouse);

    this.isLoading = false;

    if (!warehouse) {
      return;
    }

    this.router.navigate(["warehouse", warehouse.id]);
    this.onAddSuccess.emit();
  }
}
