<ul class="nav nav-pills nav-justified">
  <li class="nav-item mr-1">
    <div
      class="nav-link reservation-nav-link"
      [ngClass]="{ active: selectedTab === ReservationDtoType.STANDARD }"
      aria-current="page"
      href="#"
      (click)="selectedTabChange.emit(ReservationDtoType.STANDARD)"
    >
      <ng-container i18n>Arrivals</ng-container>
    </div>
  </li>
  <li class="nav-item ml-1">
    <div
      class="nav-link reservation-nav-link"
      [ngClass]="{
        active: selectedTab === ReservationDtoType.TWO_PHASE
      }"
      aria-current="page"
      href="#"
      (click)="selectedTabChange.emit(ReservationDtoType.TWO_PHASE)"
    >
      <ng-container i18n>Two-phase arrivals</ng-container>
    </div>
  </li>
</ul>
