import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { EmailTemplate, EmailTemplatePlaceholder, EmailTemplateType, isEmailTemplatePlaceholderConditional } from "../models/EmailTemplate";
import { Role } from "../models/Role";
import { RestService } from "../services/rest.service";

@Component({
  selector: "app-profile-edit-mail-template",
  templateUrl: "./profile-edit-mail-template.component.html",
  styleUrls: ["./profile-edit-mail-template.component.css"],
})
export class ProfileEditMailTemplateComponent implements OnInit, OnChanges {
  Role = Role;

  @Input() templateType: EmailTemplateType | null = null;
  @Input() templateLanguageId: number | null = null;
  @Input() canEdit: boolean = false;

  isLoading: boolean = false;
  isSaveLoading: boolean = false;
  template: EmailTemplate | null = null;

  modules = {
    toolbar: [["bold", "italic", "underline", "strike"], [{ list: "ordered" }, { list: "bullet" }], [{ script: "sub" }, { script: "super" }], ["image"]],
  };

  constructor(private http: RestService, private toast: ToastrService, private msgT: MessageTranslationService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templateType || changes.templateLanguageId) {
      this.fetchTemplate();
    }
  }

  async fetchTemplate() {
    if (this.templateType == null || this.templateLanguageId == null) {
      return;
    }

    this.template = null;
    this.isLoading = true;

    try {
      this.template = await this.http
        .post<EmailTemplate>(`/api/emailTemplates/getEmailTemplate`, {
          type: this.templateType,
          languageId: this.templateLanguageId,
        })
        .toPromise();
    } catch (e) {
      console.error("fetchTemplate error", e);
    }

    this.isLoading = false;
  }

  async saveTemplate() {
    this.isSaveLoading = true;

    try {
      this.template = await this.http
        .post<EmailTemplate>(`/api/emailTemplates/setEmailTemplate`, {
          type: this.template.type,
          languageId: this.template.language.id,
          subjectTemplate: this.template.subjectTemplate,
          contentTemplate: this.template.contentTemplate,
        })
        .toPromise();
      this.toast.success(this.msgT.updateSuccess());
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
      console.error("fetchTemplate error", e);
    }

    this.isSaveLoading = false;
  }

  onSubjectPlaceholder(placeholder: EmailTemplatePlaceholder) {
    this.template.subjectTemplate += `{{${placeholder}}}`;
  }

  onContentPlaceholder(placeholder: EmailTemplatePlaceholder) {
    if (isEmailTemplatePlaceholderConditional(placeholder)) {
      this.template.contentTemplate += `{{if ${placeholder}}}ADD CONTENT HERE{{end}}`;
    } else {
      this.template.contentTemplate += `{{${placeholder}}}`;
    }
  }
}
