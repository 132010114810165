import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { DataTableColumn, DataTableRow, EmittedActionOnRow } from "src/app/data-table/data-table.component";
import { MessageTranslationService } from "src/app/message-translation.service";
import { ReservationDto, ReservationDtoType } from "src/app/models/MyReservations";
import { ReservationActions } from "src/app/models/Reservation";
import { ReservationFieldType } from "src/app/models/ReservationField";
import { ReservationService } from "src/app/services/reservation.service";
import { RestService } from "src/app/services/rest.service";

@Component({
  selector: "app-reservation-table",
  templateUrl: "./reservation-table.component.html",
  styleUrls: ["./reservation-table.component.css"],
})
export class ReservationTableComponent implements OnInit, OnChanges {
  ReservationDtoType = ReservationDtoType;
  ReservationFieldType = ReservationFieldType;

  @Input() type: ReservationDtoType;
  @Input() isCarrier = false;
  @Input() loading = false;
  @Input() reservations: ReservationDto[] = [];

  @Input() enableEditDeleteAndApprove: boolean = true;

  @Output() delete: EventEmitter<ReservationDto> = new EventEmitter<ReservationDto>();

  @Input() allReservationColumns: DataTableColumn[] = [];

  @Input() tableId: string;

  reservationToDelete: ReservationDto | null = null;
  loadingDelete = false;

  reservationsForDataTable: DataTableRow[] = [];

  /*
  @Input() 
  selectedTableLabels: DataTableLabelGroup[] = [];

  @Output()
  selectedTableLabelsChange: EventEmitter<DataTableLabelGroup> = new EventEmitter<DataTableLabelGroup>();
  */

  constructor(
    private modalService: NgbModal,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    private http: RestService,
    private reservationService: ReservationService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reservations) {
      this.computeReservationEntriesFromReservations();
    }
  }

  computeReservationEntriesFromReservations() {
    this.reservationsForDataTable = this.reservations.map((reservation) => {
      return {
        id: reservation.id,
        entries: reservation.dataTableEntries,
        actions: reservation.actions,
      };
    });
  }

  private getReservationById(reservationId: number) {
    return this.reservations.find((r) => r.id === reservationId);
  }

  onActionOccurred(deleteContent: any, action: EmittedActionOnRow) {
    const reservation = this.getReservationById(action.id);
    if (action.action === ReservationActions.DETAILS) {
      this.reservationService.navigateToReservation(reservation.id, reservation.code, reservation.type);
    } else if (action.action === ReservationActions.APPROVE) {
      this.onApproveReservation(action.id, reservation.code);
    } else if (action.action === ReservationActions.EDIT) {
      this.onEditReservation(action.id, reservation.code);
    } else if (action.action === ReservationActions.DELETE) {
      this.showDeleteReservationModal(deleteContent, action.id);
    }
  }

  onApproveReservation(reservationId: number, code: string) {
    this.reservationService.navigateToApproveReservation(reservationId, code);
  }

  onEditReservation(reservationId: number, code: string) {
    this.reservationService.navigateToEditReservation(reservationId, code);
  }

  showDeleteReservationModal(deleteContent: any, reservationId: number) {
    this.reservationToDelete = this.getReservationById(reservationId);
    if (!this.reservationToDelete) {
      return;
    }

    this.modalService.open(deleteContent, { ariaLabelledBy: "modal-basic-title" }).result.then(
      () => {
        this.reservationToDelete = null;
      },
      (_) => {
        this.reservationToDelete = null;
      }
    );
  }

  closeDeleteModal() {
    this.reservationToDelete = null;
    this.modalService.dismissAll();
  }

  async onDelete() {
    if (!this.reservationToDelete) {
      return;
    }

    const isRecurring = this.type === ReservationDtoType.RECURRING;
    this.loadingDelete = true;

    try {
      await this.http
        .post(`api/carrier/${isRecurring ? "cancelRecurringReservation" : "cancelReservation"}/${this.reservationToDelete.id}`, {
          code: this.reservationToDelete.code,
        })
        .toPromise();
      this.toast.success(this.msgT.reservationDeletedSuccess());
      this.delete.emit(this.reservationToDelete);
      this.closeDeleteModal();
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
      console.log(e);
    }

    this.loadingDelete = false;
  }
}
