import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-warehouse-list-search-bar",
  templateUrl: "./warehouse-list-search-bar.component.html",
  styleUrls: ["./warehouse-list-search-bar.component.css"],
})
export class WarehouseListSearchBarComponent implements OnInit {
  @Input() disabled: boolean;

  @Input() filterText: string;
  @Output() filterTextChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() onRefresh: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
