import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { SelectValues } from "../models/ReservationField";
import { SupportService } from "../services/support.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-reservation-field-select-item",
  templateUrl: "./reservation-field-select-item.component.html",
  styleUrls: ["./reservation-field-select-item.component.css"],
})
export class ReservationFieldSelectItemComponent implements OnInit, OnChanges {
  @Input() options: SelectValues = { values: [], base64Images: [] };
  @Output() optionsChange: EventEmitter<SelectValues> = new EventEmitter<SelectValues>();

  faMinus = faMinus;
  faPlus = faPlus;

  constructor(public domSanitizer: DomSanitizer, private support: SupportService, private toast: ToastrService, private msgT: MessageTranslationService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      if (this.options.base64Images == null) {
        this.options.base64Images = this.options.values.map(() => undefined);
      }
    }
  }

  addNewOption() {
    this.options.values.push("");
    this.options.base64Images.push();
    this.emitChange();
  }

  removeOption(index: number) {
    this.options.values.splice(index, 1);
    this.options.base64Images.splice(index, 1);
    this.emitChange();
  }

  emitChange() {
    this.optionsChange.emit(this.options);
  }

  trackByFn(index: number) {
    return index;
  }

  async onImageUploaded(index: number, file: File) {
    try {
      const base64Image = await this.support.toBase64(file);
      this.options.base64Images[index] = base64Image;
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    }
  }
}
