import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { CompanyReservationFlowV2Component } from "../company-reservation-flow-v2/company-reservation-flow-v2.component";

@Component({
  selector: "app-company-two-phase-reservation",
  templateUrl: "./company-two-phase-reservation.component.html",
  styleUrls: ["./company-two-phase-reservation.component.css"],
})
export class CompanyTwoPhaseReservationComponent implements OnInit {
  @ViewChild("reservationFlow") reservationFlow: CompanyReservationFlowV2Component;

  constructor() {}

  ngOnInit(): void {}

  @HostListener("window:beforeunload")
  public canDeactivate(): Observable<boolean> | boolean {
    return this.reservationFlow.canDeactivate();
  }
}
