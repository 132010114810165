import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { DataTableFieldNamesDisplayMode } from "../models/User";
import { RestService } from "../services/rest.service";

@Component({
  selector: "app-profile-owl-settings",
  templateUrl: "./profile-owl-settings.component.html",
  styleUrls: ["./profile-owl-settings.component.css"],
})
export class ProfileOwlSettingsComponent implements OnInit {
  DataTableFieldNamesDisplayMode = DataTableFieldNamesDisplayMode;

  dataTableFieldNamesDisplayMode: DataTableFieldNamesDisplayMode = DataTableFieldNamesDisplayMode.RESERVATION_TIME;
  displayDateFormat: string;

  constructor(private http: RestService, private auth: AuthService, private toast: ToastrService, private msgT: MessageTranslationService) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (isDone) {
        this.dataTableFieldNamesDisplayMode = this.auth.loggedInUser.dataTableFieldNamesDisplayMode;
        this.displayDateFormat = this.auth.loggedInUser.displayDateFormat;
      }
    });
  }

  changeOWLData() {
    this.http
      .post(`api/user/changeOWLData`, {
        dataTableFieldNamesDisplayMode: this.dataTableFieldNamesDisplayMode,
        displayDateFormat: this.displayDateFormat.trim(),
      })
      .subscribe(
        (d) => {
          this.toast.success(this.msgT.changeBasicDataSuccess());
        },
        (error) => {
          console.log("Change OWL data error");
          console.log(error.error);
          this.toast.error(this.msgT.unknownError());
        }
      );
  }
}
