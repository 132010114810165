<ng-container *ngIf="status != null">
  <div *ngIf="status === PermissionStatus.Pending">
    <span class="warning-text"><ng-container i18n="Permission pending"> Permission pending</ng-container> </span>
  </div>
  <div *ngIf="status == PermissionStatus.Accepted" class="success-text">
    <ng-container *ngIf="canCarrierCreateAnonymousReservation" i18n> Public reservations allowed </ng-container>
    <ng-container *ngIf="!canCarrierCreateAnonymousReservation" i18n> Permission granted </ng-container>
  </div>
  <div *ngIf="status === PermissionStatus.Declined">
    <span class="error-text"><ng-container i18n="Permission denied">Permission denied</ng-container> </span>
  </div>
</ng-container>
