import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-recurring-reservation-form",
  templateUrl: "./recurring-reservation-form.component.html",
  styleUrls: ["./recurring-reservation-form.component.css"],
})
export class RecurringReservationFormComponent implements OnInit {
  @Input() isRecurring: boolean = false;
  @Output() isRecurringChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() recurrenceRule: string | null = null;
  @Output() recurrenceRuleChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  @Input() fromDate: Date | null = null;
  @Output() fromDateChange: EventEmitter<Date | null> = new EventEmitter<Date | null>();

  @Input() toDate: Date | null = null;
  @Output() toDateChange: EventEmitter<Date | null> = new EventEmitter<Date | null>();

  constructor() {}

  ngOnInit(): void {}
}
