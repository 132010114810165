import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DateRange } from "@fullcalendar/angular";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectedCalendarDate, CalendarEvent, ReservationCalendarMode } from "src/app/creating-reservation/reservation-calendar/reservation-calendar.component";
import { DateService } from "src/app/date.service";
import { DoorService } from "src/app/door.service";
import { Door } from "src/app/models/Door";
import { Holiday } from "src/app/models/Holiday";
import { ReservationDto } from "src/app/models/MyReservations";
import { ReservationService } from "src/app/services/reservation.service";
import { RestService } from "src/app/services/rest.service";
import { EventTimeWindow } from "src/app/timespan-converter.service";

@Component({
  selector: "app-door-availability-calendar",
  templateUrl: "./door-availability-calendar.component.html",
  styleUrls: ["./door-availability-calendar.component.css"],
})
export class DoorAvailabilityCalendarComponent implements OnInit, OnChanges {
  @Input() door: Door;
  @Input() selectedReservationId: number;
  @Input() dragToSelect: boolean;

  @Input() selection: DateRange | null;
  @Output() selectionChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  @Input() forReading: boolean = false;

  @Input() selectedDate: Date | null = null;

  @Input() mode: ReservationCalendarMode = "week";

  reservations: ReservationDto[] = [];
  reservationForDetails: ReservationDto | null = null;

  reservationEvents: CalendarEvent<Date>[] = [];
  doorTimeWindowEvents: EventTimeWindow[] = [];

  calendarSelectedDate: SelectedCalendarDate | null = null;

  holidays: Holiday[] = [];

  constructor(
    private doorService: DoorService,
    private dateService: DateService,
    private modalService: NgbModal,
    public reservationService: ReservationService,
    private http: RestService
  ) {}

  ngOnInit(): void {
    this.getHolidays();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.door) {
      this.computeDoorTimeWindowEvents();
      this.computeReservationsForSelectedDate();
    }
  }

  private computeDoorTimeWindowEvents() {
    if (!this.door) {
      this.doorTimeWindowEvents = [];
      return;
    }

    this.doorTimeWindowEvents = this.door.availability.timeWindows.map((tw) => ({
      text: "",
      start: tw.start,
      end: tw.end,
      bookableWeekdays: tw.bookableWeekdays,
    }));
  }

  private async computeReservationsForSelectedDate() {
    let reservationsInSelectedDate: ReservationDto[] = [];
    if (this.calendarSelectedDate && this.door) {
      reservationsInSelectedDate = await this.doorService.getReservationsForDoor(this.door.id, this.calendarSelectedDate.start, this.calendarSelectedDate.end);
    }

    this.reservations = reservationsInSelectedDate.filter((r) => r.id !== this.selectedReservationId);
    this.computeReservationEvents();
  }

  private async getHolidays() {
    try {
      this.holidays = await this.http.get<Holiday[]>("api/holiday/list").toPromise();
    } catch (e) {
      console.error("Error fetching holidays", e);
      this.holidays = [];
    }
  }

  private computeReservationEvents() {
    this.reservationEvents = this.reservations
      .map((reservation) => {
        if (!reservation.standardReservationData) {
          return null;
        }

        let carrierName = "Public";
        if (reservation.carrier != null) {
          carrierName = reservation.carrier.name;
        }
        return {
          start: this.dateService.mergeDateAndTime(reservation.standardReservationData.date, reservation.standardReservationData.start),
          end: this.dateService.mergeDateAndTime(reservation.standardReservationData.date, reservation.standardReservationData.end),
          text: "",
          id: reservation.id,
          allowView: reservation.allowView,
          carrierName: carrierName,
          isFixed: reservation.standardReservationData?.isFixed,
        };
      })
      .filter((r) => r != null);
  }

  onReservationClick(reservationId: number, modalContent: any) {
    this.reservationForDetails = this.reservations.find((r) => r.id === reservationId);
    if (this.reservationForDetails) {
      this.modalService.open(modalContent);
    }
  }

  closeDetailsModal() {
    this.reservationForDetails = null;
    this.modalService.dismissAll();
  }
}
