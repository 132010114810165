import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../core/auth.service";
import { RestService } from "../services/rest.service";

@Component({
  selector: "app-company-first-time-setup-notice",
  templateUrl: "./company-first-time-setup-notice.component.html",
  styleUrls: ["./company-first-time-setup-notice.component.css"],
})
export class CompanyFirstTimeSetupNoticeComponent implements OnInit {
  showNotice = false;

  @Input() isInProfile: boolean = false;

  constructor(private auth: AuthService, private http: RestService) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.showNotice = this.auth.IsWarehouseAdmin() && this.auth.loggedInUser?.company?.showFirstTimeProfileSetupNotice;
    });
  }

  async closeNotice() {
    try {
      await this.http.post(`api/settings/dismissFirstTimeProfileSetupNotice`, {}).toPromise();
    } catch (e) {
      console.error("Close notice error", e);
    }

    this.showNotice = false;
    if (this.auth.loggedInUser?.company) {
      this.auth.loggedInUser.company.showFirstTimeProfileSetupNotice = false;
    }
  }
}
