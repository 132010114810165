import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faArrowDown, faArrowUp, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { LocaleService } from "../locale.service";
import { MessageTranslationService } from "../message-translation.service";
import {
  AppLanguage,
  isReservationFieldValid,
  renderReservationFieldType,
  ReservationField,
  ReservationFieldName,
  ReservationFieldSpecialMeaning,
  ReservationFieldSpecialMeaningField,
  ReservationFieldType,
} from "../models/ReservationField";
import { Role } from "../models/Role";
import { RestService } from "../services/rest.service";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-reservation-fields",
  templateUrl: "./reservation-fields.component.html",
  styleUrls: ["./reservation-fields.component.css"],
})
export class ReservationFieldsComponent implements OnInit, OnChanges {
  faTimes = faTimes;
  faEdit = faEdit;
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;

  ReservationFieldType = ReservationFieldType;
  renderReservationFieldType = renderReservationFieldType;

  fieldToDelete: ReservationField | null = null;
  newField: ReservationField;

  addLoading: boolean = false;
  deleteLoading: boolean = false;
  fieldSequenceEditLoading: boolean = false;

  isSequenceModified: boolean = false;

  initialFieldSequence: { id: number; sequenceNumber: number }[];

  isReservationFieldValid = isReservationFieldValid;

  @Input() warehouseId: number | null = null;
  @Input() importantFieldWarehouseId: number | null = null;
  @Input() doorId: number | null = null;

  fields: ReservationField[] = [];
  allFieldMeanings: ReservationFieldSpecialMeaning[] = [];
  availableFieldMeanings: ReservationFieldSpecialMeaningField[] = [];
  languages: AppLanguage[] = [];

  showSetDefaultFields = false;

  specialMeaningsTranslations: Record<ReservationFieldSpecialMeaningField, string>;

  modules = {
    toolbar: [["bold", "italic", "underline", "strike"], [{ list: "ordered" }, { list: "bullet" }], [{ script: "sub" }, { script: "super" }], ["image"]],
  };

  Role = Role;

  constructor(
    private http: RestService,
    private toast: ToastrService,
    public msgT: MessageTranslationService,
    private modalService: NgbModal,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {
    this.specialMeaningsTranslations = this.msgT.getSpecialMeaningsNames();
    this.route.queryParams.subscribe((params) => {
      if (params.auto) {
        this.showSetDefaultFields = true;
      }
    });
  }

  ngOnInit(): void {
    this.fetchData();
  }

  async fetchData() {
    await this.fetchFieldSpecialMeanings();
    await this.fetchLanguages();
    await this.fetchFields();
    this.resetNewFieldInput();
    this.computeAvailableFieldSpecialMeanings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.warehouseId || changes.importantFieldWarehouseId || changes.doorId) {
      this.fetchFields();
    }
  }

  async fetchFields() {
    let endpointName: string | null = "";
    if (this.warehouseId) {
      endpointName = `getWarehouseReservationFields/${this.warehouseId}`;
    } else if (this.doorId) {
      endpointName = `getDoorReservationFields/${this.doorId}`;
    } else if (this.importantFieldWarehouseId) {
      endpointName = `getImportantWarehouseReservationFields/${this.importantFieldWarehouseId}`;
    } else {
      endpointName = "getCompanyReservationFields";
    }

    try {
      this.fields = await this.http.get<ReservationField[]>(`api/settings/${endpointName}`).toPromise();
      this.addLanguagesToMissingFields();
      this.sortFieldsBySequence();
      this.calculateInitialFieldSequence();
    } catch (e) {
      console.log("Fetch fields error");
      console.log(e.error);
    }
  }

  async fetchFieldSpecialMeanings() {
    try {
      this.allFieldMeanings = await this.http.get<ReservationFieldSpecialMeaning[]>(`api/settings/getReservationFieldsSpecialMeanings`).toPromise();
    } catch (e) {
      console.error("Special meanings", e);
    }
  }

  async computeAvailableFieldSpecialMeanings() {
    this.availableFieldMeanings = this.allFieldMeanings
      .filter((fieldMeaning) => {
        return !this.fields.some((field) => field.specialMeaning === fieldMeaning.field);
      })
      .map((fieldMeaning) => fieldMeaning.field);
  }

  addLanguagesToMissingFields() {
    for (const field of this.fields) {
      field.reservationFieldNames = this.languages.map((lang) => {
        let matchingFieldLanguageName: ReservationFieldName | null = null;
        if (field.reservationFieldNames) {
          matchingFieldLanguageName = field.reservationFieldNames.find((name) => name.language.id === lang.id);
        }

        return {
          language: lang,
          name: matchingFieldLanguageName ? matchingFieldLanguageName.name : "",
        };
      });
    }
  }

  async fetchLanguages() {
    try {
      this.languages = await this.http.get<AppLanguage[]>(`api/settings/getLanguages`).toPromise();
    } catch (e) {
      console.log("Fetch languages error");
      console.log(e.error);
    }
  }

  async setDefaults() {
    let endpointName: string | null = "";
    if (this.warehouseId) {
      endpointName = `setWarehouseDefaultReservationFields/${this.warehouseId}`;
    } else if (this.doorId) {
      endpointName = `setDoorDefaultReservationFields/${this.doorId}`;
    } else if (this.importantFieldWarehouseId) {
      // no defaults
    } else {
      endpointName = "setCompanyDefaultReservationFields";
    }

    try {
      await this.http.post<void>(`api/settings/${endpointName}`, {}).toPromise();
    } catch (e) {
      console.log("Set default fields error");
      console.log(e.error);
    }
  }

  resetNewFieldInput() {
    this.newField = new ReservationField(this.languages);
  }

  calculateInitialFieldSequence() {
    this.initialFieldSequence = this.fields.map((f) => ({
      id: f.id,
      sequenceNumber: f.sequenceNumber,
    }));
  }

  async removeField(id: number) {
    this.deleteLoading = true;

    try {
      await this.http.delete(`api/settings/removeReservationField/${id}`).toPromise();
      this.toast.success(this.msgT.removeReservationFieldSuccess());
      this.fields = this.fields.filter((d) => d.id !== id);
      this.modalService.dismissAll();
      this.sortFieldsBySequence();
      this.calculateInitialFieldSequence();
    } catch (error) {
      console.log("Remove field error");
      console.log(error.error);
      this.toast.error(this.msgT.unknownError());
    }

    this.deleteLoading = false;
  }

  async addField() {
    if (!isReservationFieldValid(this.newField)) {
      return;
    }

    const isEdit = this.newField.id != null;

    this.addLoading = true;

    try {
      let userId = null;
      if (this.warehouseId == null && this.importantFieldWarehouseId == null && this.doorId == null) {
        userId = this.auth.loggedInUser.id;
      }

      const newField = await this.http
        .post<ReservationField>(`api/settings/${isEdit ? `editReservationField/${this.newField.id}` : "addReservationField"}`, {
          reservationFieldNamesData: this.newField.reservationFieldNames.map((name) => ({
            name: name.name,
            languageId: name.language.id,
          })),
          required: this.newField.required,
          type: this.newField.type,
          warehouseId: this.warehouseId,
          importantFieldWarehouseId: this.importantFieldWarehouseId,
          doorId: this.doorId,
          userId,
          min: this.newField.min,
          max: this.newField.max,
          default: this.newField.default,
          isMultiLine: this.newField.isMultiLine || false,
          showInMail: this.newField.showInMail,
          hideField: this.newField.hideField,
          hideForCarriers: this.newField.hideForCarriers,
          specialMeaning: this.newField.specialMeaning,
          helpText: this.newField.helpText,
          selectValues: this.newField.selectValues,
        })
        .toPromise();

      if (isEdit) {
        this.toast.success(this.msgT.editReservationFieldSuccess());
        const fieldIndex = this.fields.findIndex((f) => f.id == newField.id);
        if (fieldIndex >= 0) {
          this.fields[fieldIndex] = newField;
        }
      } else {
        this.toast.success(this.msgT.addReservationFieldSuccess());
        this.fields.push(newField);
      }

      this.sortFieldsBySequence();
      this.calculateInitialFieldSequence();
      this.resetNewFieldInput();
      this.computeAvailableFieldSpecialMeanings();
      this.modalService.dismissAll();
    } catch (error) {
      const duplicateLanguage = this.checkIfReservationFieldErrorIsDuplicate(error);
      if (duplicateLanguage) {
        this.toast.error(this.msgT.duplicateReservationFieldNameError(duplicateLanguage.name));
      } else {
        console.log("Add field error");
        console.log(error.error);
        this.toast.error(this.msgT.unknownError());
      }
    }

    this.addLoading = false;
  }

  checkIfReservationFieldErrorIsDuplicate(error: any): AppLanguage | null {
    const errorMsg: string = error?.error || "";
    const regex = new RegExp("Duplicate name for language (\\d+)");
    const errorMatches = errorMsg.match(regex);
    if (!errorMatches || errorMatches.length !== 2) {
      return null;
    }

    const duplicateLanguageId = Number(errorMatches[1]);
    const duplicateLanguage = this.languages.find((l) => l.id === duplicateLanguageId);
    return duplicateLanguage;
  }

  openModal(content) {
    this.modalService.open(content).result.then(
      (result: boolean | null) => {},
      (_) => {}
    );
  }

  openEditFieldModal(content, fieldToEdit: ReservationField) {
    this.newField = { ...fieldToEdit };
    this.modalService.open(content).result.then(
      (result: boolean | null) => {
        this.resetNewFieldInput();
      },
      (_) => {
        this.resetNewFieldInput();
      }
    );
  }

  openDeleteFieldModal(content, fieldToDelete: ReservationField) {
    this.fieldToDelete = fieldToDelete;
    this.modalService.open(content).result.then(
      (result: boolean | null) => {},
      (_) => {}
    );
  }

  moveField(field: ReservationField, direction: number) {
    if (direction !== -1 && direction !== 1) {
      return;
    }

    const newSequenceNumber = field.sequenceNumber + direction;

    const replacedField = this.fields.find((f) => f.sequenceNumber === newSequenceNumber);

    if (replacedField) {
      replacedField.sequenceNumber += -direction;
    }

    field.sequenceNumber = newSequenceNumber;

    this.isSequenceModified = true;
    this.sortFieldsBySequence();
  }

  sortFieldsBySequence() {
    this.fields.sort((f1, f2) => f1.sequenceNumber - f2.sequenceNumber);
  }

  cancelSequenceChanges() {
    this.restoreInitialSequence();
    this.sortFieldsBySequence();
    this.isSequenceModified = false;
  }

  restoreInitialSequence() {
    for (let f of this.fields) {
      f.sequenceNumber = this.initialFieldSequence.find((f1) => f1.id === f.id)?.sequenceNumber || 0;
    }
  }

  async editReservationFieldSequences() {
    this.fieldSequenceEditLoading = true;

    try {
      await this.http
        .patch(`api/settings/editReservationFieldSequences`, {
          fields: this.fields.map((f) => ({
            id: f.id,
            sequenceNumber: f.sequenceNumber,
          })),
        })
        .toPromise();
      this.toast.success(this.msgT.updateSuccess());
      this.isSequenceModified = false;
      this.calculateInitialFieldSequence();
    } catch (error) {
      console.log("Remove field error");
      console.log(error.error);
      this.toast.error(this.msgT.unknownError());
    }

    this.fieldSequenceEditLoading = false;
  }

  onSpecialMeaningChange() {
    this.newField.specialMeaning = Number(this.newField.specialMeaning);
    if (this.newField.specialMeaning == null || isNaN(this.newField.specialMeaning)) {
      this.newField.specialMeaning = null;
      return;
    }

    const type = this.allFieldMeanings.find((meaning) => meaning.field === Number(this.newField.specialMeaning));
    if (!type) {
      return;
    }

    this.newField.type = type.type;
  }
}
