<div>
  <fa-icon style="color: black" class="mr-2" [icon]="faQuestionCircle"></fa-icon>
  <span i18n>Find out how many and which time periods were booked per day / week / month / year, and on which warehouse and which door.</span>
  <small i18n class="text-warning d-block">Calculating statistics may take some time, so it is recommended to do it in the less busy parts of the day.</small>
</div>

<div class="row mt-3">
  <div class="form-group col-3">
    <label for="dateFrom"><ng-container i18n>Date from</ng-container></label>
    <app-pick-date [date]="filter.dateFrom" (dateChange)="onDateChange($event, undefined)"></app-pick-date>
  </div>

  <div class="form-group col-3">
    <label for="dateTo"><ng-container i18n>Date to</ng-container></label>
    <app-pick-date [date]="filter.dateTo" (dateChange)="onDateChange(undefined, $event)"></app-pick-date>
  </div>

  <div class="form-group col-3">
    <label><ng-container i18n>Time period</ng-container></label>
    <select [(ngModel)]="filter.precision" class="form-control">
      <option [value]="BookedWindowsStatsDatePrecision.DAILY">
        <ng-container i18n>Daily</ng-container>
      </option>
      <option [value]="BookedWindowsStatsDatePrecision.WEEKLY">
        <ng-container i18n>Weekly</ng-container>
      </option>
      <option [value]="BookedWindowsStatsDatePrecision.MONTHLY">
        <ng-container i18n>Monthly</ng-container>
      </option>
      <option [value]="BookedWindowsStatsDatePrecision.YEARLY">
        <ng-container i18n>Yearly</ng-container>
      </option>
    </select>
  </div>
  <div class="form-group col-3">
    <div class="w-100 align-items-end d-flex justify-content-end h-100">
      <button i18n class="btn btn-primary" (click)="calculate()" [disabled]="isLoading">Calculate</button>
    </div>
  </div>
</div>

<div class="mt-2">
  <div *ngIf="isLoading" class="d-flex justify-content-center">
    <app-spinner></app-spinner>
  </div>

  <div *ngIf="!isLoading && !stats"><i i18n>Stats will appear here.</i></div>

  <div *ngIf="!isLoading && stats">
    <div class="d-flex">
      <b i18n>Total bookings in selected range: </b>
      &nbsp;
      <span>{{ stats.totalNumberOfBookings }}</span>
    </div>

    <div class="mt-2" *ngFor="let dayData of stats.data">
      <app-accordion-with-content i18n-label label="{{ formatDate(dayData.forDate, stats.precision) }}">
        <div class="d-flex mb-3">
          <b i18n>Total bookings for {{ formatDate(dayData.forDate, stats.precision) }}: </b>
          &nbsp;
          <span>{{ dayData.totalNumberOfBookings }}</span>
        </div>

        <div *ngFor="let warehouse of dayData.bookingsPerWarehouse">
          <div class="d-flex mb-3">
            <b i18n>Warehouse: </b>
            &nbsp;
            <span class="c-pointer" [routerLink]="['/warehouse', warehouse.warehouseId]">
              <u>{{ warehouse.warehouseName }}</u>
            </span>
          </div>

          <div class="ml-2">
            <div class="d-flex mb-3">
              <b i18n>Total bookings for warehouse {{ warehouse.warehouseName }}: </b>
              &nbsp;
              <span>{{ warehouse.totalNumberOfBookings }}</span>
            </div>

            <div *ngFor="let door of warehouse.bookingsPerDoor">
              <div class="d-flex mb-3">
                <b i18n>Door: </b>
                &nbsp;
                <span class="c-pointer" [routerLink]="['/door', door.doorId]">
                  <u>{{ door.doorName }}</u>
                </span>
              </div>

              <div class="ml-2">
                <div class="d-flex mb-3">
                  <b i18n>Total bookings for door {{ door.doorName }}: </b>
                  &nbsp;
                  <span>{{ door.totalNumberOfBookings }}</span>
                </div>

                <div *ngFor="let timeWindow of door.bookingsPerTimeWindow">
                  <div class="d-flex mb-3">
                    <b i18n>Time window: </b>
                    &nbsp;
                    <span>
                      {{ timeWindow.timeWindow }}
                    </span>
                  </div>

                  <div class="d-flex mb-3 ml-2">
                    <b i18n>Total bookings for time window {{ timeWindow.timeWindow }}: </b>
                    &nbsp;
                    <span>{{ timeWindow.totalNumberOfBookings }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-accordion-with-content>
    </div>
  </div>
</div>
