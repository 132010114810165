<ng-container *ngIf="fields != null && newField != null">
  <div class="mb-2">
    <ng-container *ngIf="fields.length === 0"
      ><small><i>No fields yet.</i></small></ng-container
    >

    <ng-container *ngIf="fields.length > 0">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col" i18n>Field</th>
            <th scope="col" i18n>Type</th>
            <th scope="col" i18n>Required?</th>
            <th scope="col" i18n>Show in email?</th>
            <th scope="col" i18n>Hide?</th>
            <th scope="col" i18n>Possible values</th>
            <th scope="col" i18n>Default</th>
            <th scope="col" i18n>Meaning</th>
            <th scope="col" i18n *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let field of fields; let last = last; let first = first">
            <td>
              {{ field.name }}
            </td>
            <td>{{ renderReservationFieldType(field) }}</td>
            <td>{{ field.required ? "Yes" : "No" }}</td>
            <td>{{ field.showInMail ? "Yes" : "No" }}</td>
            <td>
              <p><b i18n>Hide in emails: </b>{{ field.hideField ? "Yes" : "No" }}</p>
              <p><b i18n>Hide for carriers: </b>{{ field.hideForCarriers ? "Yes" : "No" }}</p>
            </td>
            <td>
              {{
                field.type == ReservationFieldType.Select && field.selectValues?.values
                  ? field.selectValues.values.join(", ")
                  : (field.min != null ? field.min : "") + " - " + (field.max != null ? field.max : "")
              }}
            </td>
            <td>{{ field.default }}</td>
            <td>
              {{ field.specialMeaning != null ? specialMeaningsTranslations[field.specialMeaning] : "-" }}
            </td>
            <td *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                  <button class="btn btn-info mb-1" i18n (click)="openEditFieldModal(addContent, field)">Edit</button>
                  <button class="btn btn-danger" i18n (click)="openDeleteFieldModal(deleteContent, field)">Delete</button>
                </div>
                <div>
                  <fa-icon *ngIf="!first" (click)="moveField(field, -1)" class="ml-2 px-2 c-pointer" [icon]="faArrowUp"></fa-icon>
                  <fa-icon *ngIf="!last" (click)="moveField(field, 1)" class="ml-2 px-2 c-pointer" [icon]="faArrowDown"></fa-icon>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>

  <div class="d-flex justify-content-between" *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
    <button class="btn btn-primary" style="width: 200px" (click)="openModal(addContent)">
      <ng-container i18n>Add new field</ng-container>
    </button>

    <div class="d-flex">
      <button (click)="cancelSequenceChanges()" class="btn btn-warn mr-1" style="width: 230px" *ngIf="isSequenceModified">
        <ng-container i18n>Cancel sequence changes</ng-container>
      </button>

      <button (click)="editReservationFieldSequences()" class="btn btn-info ml-1" style="width: 200px" *ngIf="isSequenceModified && !fieldSequenceEditLoading">
        <ng-container i18n>Save sequence</ng-container>
      </button>
      <div *ngIf="fieldSequenceEditLoading" class="spinner-border ml-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

  <button *ngIf="showSetDefaultFields" class="btn btn-info" (click)="setDefaults()">Set defaults</button>
</ng-container>

<ng-template #addContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ newField.id != null ? "Edit reservation field" : "Add new reservation field" }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group flex-grow-2" *ngFor="let fieldName of newField.reservationFieldNames">
      <label for="name"><ng-container i18n="Name">Name</ng-container> ({{ fieldName.language.name }})</label>
      <input
        [disabled]="addLoading"
        placeholder="Name of the field"
        i18n-placeholder
        id="name"
        class="form-control mr-1 flex-grow-1"
        (keydown.enter)="addField()"
        [(ngModel)]="fieldName.name"
      />
    </div>

    <div class="d-flex align-items-end">
      <div class="form-group flex-grow-1">
        <label for="type"><ng-container i18n="Type">Type</ng-container></label>
        <select [disabled]="addLoading || newField.specialMeaning != null" [(ngModel)]="newField.type" id="type" name="type" class="form-control">
          <option [value]="ReservationFieldType.String">
            <ng-container i18n="String">String</ng-container>
          </option>
          <option [value]="ReservationFieldType.Number">
            <ng-container i18n="Number">Number</ng-container>
          </option>
          <option [value]="ReservationFieldType.Select">
            <ng-container i18n="Select">Select</ng-container>
          </option>
          <option [value]="ReservationFieldType.Country">
            <ng-container i18n="Country">Country</ng-container>
          </option>
          <option [value]="ReservationFieldType.Checkbox">
            <ng-container i18n="Checkbox">Checkbox</ng-container>
          </option>
          <option [value]="ReservationFieldType.Date">
            <ng-container i18n="Date">Date</ng-container>
          </option>
        </select>
      </div>
    </div>

    <div class="d-flex align-items-end">
      <div class="form-group flex-grow-1">
        <label for="specialMeaning"><ng-container i18n>Special meaning</ng-container></label>
        <select
          [disabled]="addLoading"
          [(ngModel)]="newField.specialMeaning"
          (ngModelChange)="onSpecialMeaningChange()"
          id="specialMeaning"
          name="specialMeaning"
          class="form-control"
        >
          <option [value]="null" selected i18n>No meaning</option>
          <option [value]="meaning.field" *ngFor="let meaning of allFieldMeanings" [disabled]="!availableFieldMeanings.includes(meaning.field)">
            {{ specialMeaningsTranslations[meaning.field] }}
          </option>
        </select>
      </div>
    </div>

    <ng-container *ngIf="newField.type == ReservationFieldType.Number">
      <div class="form-group flex-grow-2">
        <label for="min"><ng-container i18n="Min">Min</ng-container></label>
        <input
          [disabled]="addLoading"
          placeholder="Minimum"
          i18n-placeholder
          type="number"
          id="min"
          class="form-control mr-1 flex-grow-1"
          (keydown.enter)="addField()"
          [(ngModel)]="newField.min"
        />
      </div>
      <div class="form-group flex-grow-2">
        <label for="max"><ng-container i18n="Max">Max</ng-container></label>
        <input
          [disabled]="addLoading"
          placeholder="Maximum"
          i18n-placeholder
          type="number"
          id="max"
          class="form-control mr-1 flex-grow-1"
          (keydown.enter)="addField()"
          [(ngModel)]="newField.max"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="newField.type == ReservationFieldType.Number || newField.type == ReservationFieldType.String">
      <div class="form-group flex-grow-2">
        <label for="max"><ng-container i18n="Default">Default</ng-container></label>
        <input
          [disabled]="addLoading"
          placeholder="Default"
          i18n-placeholder
          type="{{ newField.type == ReservationFieldType.Number ? 'number' : 'text' }}"
          id="max"
          class="form-control mr-1 flex-grow-1"
          (keydown.enter)="addField()"
          [(ngModel)]="newField.default"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="newField.type == ReservationFieldType.String || newField.type == ReservationFieldType.Number">
      <div class="form-check" style="height: 30px">
        <input id="multiline" type="checkbox" class="form-check-input" [(ngModel)]="newField.isMultiLine" [ngModelOptions]="{ standalone: true }" />
        <label class="form-check-label" for="multiline" i18n="Multiline?"> Multiline? </label>
      </div>
    </ng-container>

    <ng-container *ngIf="newField.type == ReservationFieldType.Select">
      <app-reservation-field-select-item [(options)]="newField.selectValues"></app-reservation-field-select-item>
    </ng-container>

    <div class="form-check" style="height: 30px">
      <input
        [disabled]="addLoading"
        id="requried"
        type="checkbox"
        class="form-check-input"
        [(ngModel)]="newField.required"
        [ngModelOptions]="{ standalone: true }"
      />
      <label class="form-check-label" for="requried" i18n="Required"> Required </label>
    </div>

    <div class="form-check" style="height: 30px">
      <input id="showInMail" type="checkbox" class="form-check-input" [(ngModel)]="newField.showInMail" [ngModelOptions]="{ standalone: true }" />
      <label class="form-check-label" for="showInMail" i18n="Show in email?"> Show in email? </label>
    </div>

    <div class="form-check" style="height: 30px">
      <input id="hideField" type="checkbox" class="form-check-input" [(ngModel)]="newField.hideField" [ngModelOptions]="{ standalone: true }" />
      <label class="form-check-label" for="hideField" i18n> Hide field? </label>
    </div>

    <div class="form-check" style="height: 30px">
      <input id="hideForCarriers" type="checkbox" class="form-check-input" [(ngModel)]="newField.hideForCarriers" [ngModelOptions]="{ standalone: true }" />
      <label class="form-check-label" for="hideForCarriers" i18n> Hide field for carriers? </label>
    </div>

    <div class="form-group">
      <label for="helpText" i18n> Help text </label>
      <quill-editor style="display: block" format="html" placeholder="" sanitize [modules]="modules" [(ngModel)]="newField.helpText"></quill-editor>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close()" [disabled]="addLoading">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button *ngIf="!addLoading" class="btn btn-primary" i18n (click)="addField()" [disabled]="!isReservationFieldValid(newField)">
      {{ newField.id != null ? "Edit field" : "Add field" }}
    </button>
    <div *ngIf="addLoading" class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #deleteContent let-modal>
  <ng-container *ngIf="fieldToDelete">
    <div class="modal-header">
      <h4 class="modal-title" i18n>Delete field?</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <p *ngIf="!deleteLoading" i18n>Confirm deleting of the field {{ fieldToDelete.name }}.</p>
      <div *ngIf="deleteLoading" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <button type="submit" class="btn" (click)="modal.close()" [disabled]="deleteLoading">
        <ng-container i18n="Cancel">Cancel</ng-container>
      </button>
      <button *ngIf="!deleteLoading" class="btn btn-danger" i18n="Change" (click)="removeField(fieldToDelete.id)">Delete</button>
      <div *ngIf="deleteLoading" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div></ng-container
  >
</ng-template>
