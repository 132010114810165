<app-accordion-with-content i18n-label label="Holidays">
  <small i18n class="mb-2 d-block">Set holidays - days when all warehouses are closed and bookings aren't possible.</small>

  <div class="mb-2">
    <ng-container *ngIf="holidays.length === 0"
      ><small i18n><i>No holidays yet.</i></small></ng-container
    >

    <ng-container *ngIf="holidays.length > 0">
      <div class="d-flex justify-content-start align-items-center" *ngFor="let holiday of holidays">
        <p class="m-0">{{ holiday.date | date: "dd. MM. yyyy" }}</p>

        <fa-icon
          (click)="removeHoliday(holiday.id)"
          style="color: red"
          class="ml-2 c-pointer"
          [icon]="faTimes"
          *ngxPermissionsOnly="[Role.COMPANY_ADMIN]"
        ></fa-icon>
      </div>
    </ng-container>
  </div>

  <div class="form-group" *ngxPermissionsOnly="[Role.COMPANY_ADMIN, Role.OWL_CARRIER]">
    <label for="date"><ng-container i18n>Add new holiday</ng-container></label>

    <div class="d-flex align-items-center">
      <app-pick-date [(date)]="newHolidayDate" style="flex: 1"></app-pick-date>

      <button class="ml-1 btn btn-primary" style="width: 250px" (click)="addHoliday()" [disabled]="isAddingLoading || newHolidayDate == null">
        <ng-container i18n>Add holiday</ng-container>
      </button>
    </div>
  </div>
</app-accordion-with-content>
