<div class="container app-container" *ngIf="auth.loggedInUser">
  <h1 class="app-heading">
    <ng-container i18n>Carrier profile settings</ng-container>
  </h1>

  <app-accordion-with-content i18n-label label="Basic profile information">
    <div class="form-group">
      <label for="email"><ng-container i18n>Username</ng-container></label>
      <input type="text" class="form-control" id="email" disabled [(ngModel)]="email" (keydown.enter)="updateProfile()" />
    </div>
    <div class="form-group">
      <label for="name"><ng-container i18n>Contact person name</ng-container></label>
      <input type="text" class="form-control" id="name" [(ngModel)]="name" (keydown.enter)="updateProfile()" />
    </div>
    <div class="form-group">
      <label for="name"><ng-container i18n>Company title</ng-container></label>
      <input type="text" class="form-control" id="title" [(ngModel)]="title" (keydown.enter)="updateProfile()" />
    </div>
    <div class="form-group">
      <label for="name"><ng-container i18n>Phone number</ng-container></label>
      <input type="text" class="form-control" id="phoneNumber" [(ngModel)]="phoneNumber" (keydown.enter)="updateProfile()" />
    </div>
    <div class="form-group">
      <label for="name"><ng-container i18n>Address</ng-container></label>
      <input type="text" class="form-control" id="address" [(ngModel)]="address" (keydown.enter)="updateProfile()" />
    </div>

    <button type="button" class="btn btn-primary" i18n [disabled]="loading" (click)="updateProfile()">Update</button>
  </app-accordion-with-content>

  <br />

  <app-accordion-with-content i18n-label label="Change password">
    <div class="form-group">
      <label for="newPassword"><ng-container i18n>New password</ng-container></label>
      <input type="password" class="form-control" id="newPassword" [(ngModel)]="newPassword" (keydown.enter)="changePassword()" />
    </div>
    <div class="form-group">
      <label for="confirmNewPassword"><ng-container i18n>Confirm new password</ng-container></label>
      <input type="password" class="form-control" id="confirmNewPassword" [(ngModel)]="confirmNewPassword" (keydown.enter)="changePassword()" />
    </div>

    <button type="button" class="btn btn-primary" i18n [disabled]="loading" (click)="changePassword()">Change password</button>
  </app-accordion-with-content>

  <br />

  <app-profile-owl-settings></app-profile-owl-settings>

  <br />

  <app-upload-user-image [isPersonal]="true"></app-upload-user-image>

  <br />

  <app-profile-mail-sending-settings></app-profile-mail-sending-settings>

  <br />

  <app-profile-contact-mails-settings></app-profile-contact-mails-settings>
</div>
