import { Component, Input, OnInit } from "@angular/core";
import { DateService } from "src/app/date.service";
import { ReservationField, ReservationFieldType } from "src/app/models/ReservationField";
import { CountriesService } from "src/app/services/countries.service";

@Component({
  selector: "app-reservation-data-table",
  templateUrl: "./reservation-data-table.component.html",
  styleUrls: ["./reservation-data-table.component.css"],
})
export class ReservationDataTableComponent implements OnInit {
  @Input() reservationData: ReservationField[] = [];

  ReservationFieldType = ReservationFieldType;

  constructor(public contriesService: CountriesService, public dateService: DateService) {}

  ngOnInit(): void {}
}
