import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { CalendarOptions, DateSelectArg, DatesSetArg, EventInput } from "@fullcalendar/angular";
import { DateRange } from "src/app/availability-display/availability-display.component";
import { DateService } from "src/app/date.service";
import { MessageTranslationService } from "src/app/message-translation.service";
import { EventTimeWindow, TimespanConverterService } from "src/app/timespan-converter.service";
import { AuthService } from "src/app/core/auth.service";
import { Holiday } from "src/app/models/Holiday";
import { ReservationType } from "src/app/models/Door";

export interface CalendarEvent<T> {
  start: T;
  end: T;
  text: string;
  id?: number;
  allowView?: boolean;
  carrierName?: string;
  isFixed: boolean;
}

export interface SelectedCalendarDate {
  start: Date;
  end: Date;
}

const BUSY_EVENT_CLASS = "reservation-calendar-busy-event";
const EXPIRED_EVENT_CLASS = "reservation-calendar-expired-event";
const MY_RESERVATION_EVENT_CLASS = "reservation-calendar-my-reservation-event";
const NOT_MY_RESERVATION_EVENT_CLASS = "reservation-calendar-not-my-reservation-event";

export type ReservationCalendarMode = "week" | "day";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-reservation-calendar",
  templateUrl: "./reservation-calendar.component.html",
  styleUrls: ["./reservation-calendar.component.css"],
})
export class ReservationCalendarComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() existingEvents: CalendarEvent<string | Date>[] = [];
  @Input() minNoticePeriod: string | null = null;
  @Input() timeWindows: EventTimeWindow[] = [];
  @Input() selectedEvent: CalendarEvent<string | Date> | null = null;

  @Input() enableEventSelect: boolean = false;

  @Input() granularityInMinutes: number = 15;

  @Input() selection: DateRange | null = null;
  @Output() selectionChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  selectedCalendarRange: SelectedCalendarDate | null = null;

  @Output() selectedDateChange: EventEmitter<SelectedCalendarDate> = new EventEmitter<SelectedCalendarDate>();

  @Output() eventClick: EventEmitter<number> = new EventEmitter<number>();

  @Input() forReading: boolean = false;

  @Input() selectedDate: Date = null;

  @Input() mode: ReservationCalendarMode = "week";

  @Input() holidays: Holiday[] = [];

  @ViewChild("calendar") calendarRef: any;

  calendarOptions: CalendarOptions = {
    initialView: this.getCalendarInitialView(),
    selectable: this.enableEventSelect || true,
    selectOverlap: false,
    allDaySlot: false,
    buttonText: {
      today: this.msgT.today(),
    },
    unselectAuto: false,
    selectMirror: true,
    events: [],
    select: this.eventSelected.bind(this),
    height: 600,
    datesSet: this.dateChanged.bind(this),
    snapDuration: "00:15",
    selectConstraint: [
      {
        startTime: "00:01",
        endTime: "23:59",
      },
    ],
    validRange: {
      start: new Date(),
    },
    editable: false,
    eventClick: (el: any) => {
      if (el.event.extendedProps == null) {
        return;
      }

      if (!el.event.extendedProps.allowView) {
        return;
      }

      if (el.event.extendedProps.id == null) {
        return;
      }
      this.eventClick.emit(Number(el.event.extendedProps.id));
    },
  };

  constructor(
    private timeSpanConverter: TimespanConverterService,
    private msgT: MessageTranslationService,
    private dateService: DateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.calendarOptions.titleFormat = `ddd[\n]${this.dateService.dateFormat}`;
      this.calendarOptions.dayHeaderFormat = `ddd[\n]${this.dateService.dateFormat}`;
      this.calendarOptions.locale = this.msgT.locale;
    });
  }

  ngAfterViewInit() {
    this.selectDateOnCalendarBasedOnInputs();
    this.selectDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enableEventSelect) {
      this.calendarOptions.selectable = this.enableEventSelect;
    }

    if (changes.timeWindows || changes.holidays) {
      this.computeBusyEventsFromTimeWindows();
    }

    if (changes.timeWindows || changes.minNoticePeriod || changes.forReading) {
      this.computeExpiredTimeWindows();
    }

    if (changes.existingEvents) {
      this.computeExistingEvents();
    }

    if (changes.selection) {
      this.selectDateOnCalendarBasedOnInputs();
    }

    if (changes.granularityInMinutes) {
      this.changeGranularityInMinutes();
    }

    if (changes.forReading) {
      this.setValidRange();
    }

    if (changes.selectedDate) {
      this.selectDate();
    }

    if (changes.mode) {
      this.calendarOptions.initialView = this.getCalendarInitialView();
    }
  }

  private getCalendarInitialView() {
    if (this.mode === "week") {
      return "timeGridWeek";
    }

    return "timeGridDay";
  }

  private selectDate() {
    if (!this.selectedDate || !this.calendarRef) {
      return;
    }

    this.calendarRef.calendar.gotoDate(this.selectedDate);
  }

  private setValidRange() {
    this.calendarOptions.validRange = this.forReading ? null : { start: new Date() };
  }

  private changeGranularityInMinutes() {
    this.calendarOptions.snapDuration = this.timeSpanConverter.convertMinutesToDuration(this.granularityInMinutes || 15);
  }

  private selectDateOnCalendarBasedOnInputs() {
    if (!this.calendarRef) {
      return;
    }

    if (this.selection) {
      const dateFrom = this.dateService.mergeDateAndTime(this.selection.date, this.selection.start);
      const dateTo = this.dateService.mergeDateAndTime(this.selection.date, this.selection.end);
      this.calendarRef.calendar.select({ start: dateFrom, end: dateTo });
    } else {
      this.calendarRef.calendar.unselect();
    }
  }

  private computeBusyEventsFromTimeWindows() {
    if (!this.selectedCalendarRange) {
      return;
    }

    let restrictedPeriods: EventTimeWindow[] = [];
    if (this.timeWindows.length > 0 || this.holidays.length > 0) {
      restrictedPeriods = this.timeSpanConverter.calculateRestrictedPeriodsFromTimeWindows(this.timeWindows, this.selectedCalendarRange, this.holidays);
    }

    this.replaceEvents(restrictedPeriods, this.msgT.outOfWorkingHours(), BUSY_EVENT_CLASS);
  }

  private computeExpiredTimeWindows() {
    let expiredTimeWindows: EventTimeWindow[] = [];
    if (!this.forReading) {
      expiredTimeWindows = this.timeSpanConverter.calculateExpiredTimeWindows(this.timeWindows, this.minNoticePeriod);
    }

    this.replaceEvents(expiredTimeWindows, this.msgT.timeWindowExpired(), EXPIRED_EVENT_CLASS);
  }

  private replaceEvents(events: EventTimeWindow[], title: string, className: string) {
    const newEvents: EventInput[] = events.map((p) => ({
      startTime: p.start,
      endTime: p.end,
      start: p.startDate,
      end: p.endDate,
      title,
      className,
    }));

    const currentEvents = this.calendarOptions.events as EventInput[];
    const eventsWithoutCurrentEvents = currentEvents.filter((e) => e.className !== className);
    this.calendarOptions.events = [...eventsWithoutCurrentEvents, ...newEvents];
  }

  private computeExistingEvents() {
    const newEvents: EventInput[] = [];

    const timesMap = new Map<string, number>();
    this.existingEvents.forEach((e) => {
      const key = `${e.start}-${e.end}`;
      let count = timesMap.get(key) ?? 0;
      timesMap.set(key, count + 1);
    });

    this.existingEvents.forEach((e) => {
      const key = `${e.start}-${e.end}`;
      let count = timesMap.get(key) ?? 0;
      newEvents.push({
        start: new Date(e.start),
        end: new Date(e.end),
        title: e.allowView ? e.carrierName : `${count.toString()} ${this.msgT.booked()}`,
        className: MY_RESERVATION_EVENT_CLASS,
        extendedProps: {
          allowView: e.allowView,
          id: e.id,
        },
      });
    });

    const events = this.calendarOptions.events as EventInput[];
    const eventsWithoutExistingEvents = events.filter((e) => e.className !== MY_RESERVATION_EVENT_CLASS && e.className !== NOT_MY_RESERVATION_EVENT_CLASS);
    this.calendarOptions.events = [...eventsWithoutExistingEvents, ...newEvents];
  }

  eventSelected(arg: DateSelectArg) {
    if (!arg.jsEvent) {
      return;
    }

    this.selectionChange.emit({
      date: arg.start,
      start: this.dateService.formatTime(arg.start),
      end: this.dateService.formatTime(arg.end),
    });
  }

  dateChanged(arg: DatesSetArg) {
    this.selectedCalendarRange = {
      start: arg.start,
      end: arg.end,
    };

    this.selectedDateChange.emit({
      start: arg.start,
      end: arg.end,
    });
    this.selectDateOnCalendarBasedOnInputs();
    this.computeBusyEventsFromTimeWindows();
  }
}
