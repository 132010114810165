import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { EmailTemplateType } from "../models/EmailTemplate";
import { AppLanguage } from "../models/ReservationField";
import { Role } from "../models/Role";
import { RestService } from "../services/rest.service";

enum EmailTemplateSpecialField {
  WAREHOUSE_LOCATION,
  WAREHOUSE_CONTACT_DATA,
  CARRIER_DATA,
  DATE_AND_TIME,
  RESERVATION_DATA,
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-profile-mail-templates",
  templateUrl: "./profile-mail-templates.component.html",
  styleUrls: ["./profile-mail-templates.component.css"],
})
export class ProfileMailTemplatesComponent implements OnInit {
  Role = Role;

  mailText: string = "";

  languages: AppLanguage[] = [];
  templateTypes = Object.keys(EmailTemplateType)
    .filter((item) => {
      return !isNaN(Number(item));
    })
    .map((item) => Number(item));

  selectedLanguageId: number | null = null;
  selectedType: EmailTemplateType | null = null;

  defaultMailLanguageId: number | null = null;
  sendContractInMail: boolean = false;

  isAdmin = false;

  modules = {
    toolbar: [["bold", "italic", "underline", "strike"], [{ list: "ordered" }, { list: "bullet" }], [{ script: "sub" }, { script: "super" }], ["image"]],
  };

  constructor(public auth: AuthService, private http: RestService, public msgT: MessageTranslationService, private toast: ToastrService) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.defaultMailLanguageId = this.auth.loggedInUser.company.defaultMailLanguageId;
      this.sendContractInMail = this.auth.loggedInUser.company.sendContractInMail;
      this.isAdmin = this.auth.IsWarehouseAdmin();
      this.fetchLanguages();
    });
  }

  async fetchLanguages() {
    try {
      this.languages = await this.http.get<AppLanguage[]>(`api/settings/getLanguages`).toPromise();
    } catch (e) {
      console.error("fetchLanguages error", e);
    }
  }

  async updateGeneralSettings() {
    if (this.defaultMailLanguageId == null) {
      return;
    }

    try {
      await this.http
        .post(`api/settings/SetEmailGeneralSettings`, { defaultMailLanguageId: this.defaultMailLanguageId, SendContractInMail: this.sendContractInMail })
        .toPromise();
      this.toast.success(this.msgT.updateSuccess());
    } catch (e) {
      console.error("fetchLanguages error", e);
      this.toast.error(this.msgT.unknownError());
    }
  }
}
