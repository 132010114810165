import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { environment } from "src/environments/environment";

export interface LanguagePickerLanguage {
  name: string;
  link: string;
  code: string;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-language-picker",
  templateUrl: "./language-picker.component.html",
  styleUrls: ["./language-picker.component.css"],
})
export class LanguagePickerComponent implements OnInit {
  languages: LanguagePickerLanguage[] = [];

  @Input() selectedLanguageCode: string;
  @Output() onSelect: EventEmitter<LanguagePickerLanguage> = new EventEmitter<LanguagePickerLanguage>();

  constructor() {}

  ngOnInit(): void {
    this.languages = environment.languages;
  }

  onSelectLanguageCode(code: string) {
    const lang = this.languages.find((l) => l.code === code);
    if (lang) {
      this.onSelect.emit(lang);
    }
  }
}
