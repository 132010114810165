import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { SortEvent } from "primeng/api";
import { DateService } from "../date.service";
import { DataTableDateColumn } from "../models/Reservation";
import { SupportService } from "../services/support.service";

export interface DataTableRow {
  id: number;
  entries: DataTableEntry[];
  actions: DataTableRowAction[]; // all actions that can be performed on this row
}

// key - value pair for data table
export interface DataTableEntry {
  columnIds: number[]; // refers to ids in DataTableColumn (equal by value for sorting and filtering)
  value: any;
  type: DataTableEntryType;
  selectOptions: string[]; // if type is select, then this holds options
  additionalData?: any;
}

export type DataTableEntryType = "number" | "text" | "select" | "checkbox";

export interface DataTableColumn {
  ids: number[];
  text: string;
}

export interface DataTableRowAction {
  translation?: string;
  icon?: any;
  actionName: string;
  actionTranslation: string;
  buttonClass: ActionButtonClass;
}

type ActionButtonClass = "btn-primary" | "btn-info" | "btn-danger";

export interface EmittedActionOnRow {
  action: string;
  id: number;
}

export type DataTableDataRow = Record<string, any>;
export type DataTableDataColumn = { id: string; text: string };

export const getDataColumnStringId = (ids: number[]) => {
  return ids.join("_");
};

const SelectedColumnsLocalStorageKey = "dataTable-selectedColumns";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.css"],
})
export class DataTableComponent implements OnInit, OnChanges {
  faFile = faFile;

  @Input() isLoading: boolean;
  @Input() totalRecords: number;

  @Input() rows: DataTableRow[] = [];
  @Input() allColumns: DataTableColumn[] = [];

  @Output() onRowAction: EventEmitter<EmittedActionOnRow> = new EventEmitter<EmittedActionOnRow>();

  @Input() noDataText: string;

  @Input() showDetailsButton = false;

  @Input() tableId: string;

  rowData: DataTableDataRow[] = [];
  columnData: DataTableDataColumn[] = [];
  _selectedColumns: DataTableDataColumn[] = [];

  isFirstTimeCompute: boolean = true;

  @Input() get selectedColumns(): DataTableDataColumn[] {
    return this._selectedColumns;
  }

  set selectedColumns(newSelectedColumns: DataTableDataColumn[]) {
    this._selectedColumns = this.columnData.filter((col) => newSelectedColumns.includes(col));
    this.storeSelectedColumnsInLocalStorage();
  }

  constructor(private supportService: SupportService, private dateService: DateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rows || changes.allColumns) {
      this.setRowsAndColumns();
    }
  }

  private storeSelectedColumnsInLocalStorage() {
    const areAllSelected = this.selectedColumns.length === this.columnData.length;
    if (areAllSelected) {
      localStorage.removeItem(this.tableId + SelectedColumnsLocalStorageKey);
    } else {
      localStorage.setItem(this.tableId + SelectedColumnsLocalStorageKey, this.selectedColumns.map((c) => c.id).join(","));
    }
  }

  private initializeSelectedColumnsFromLocalStorage() {
    const selectedColumnsFromLocalStorage = localStorage.getItem(this.tableId + SelectedColumnsLocalStorageKey);
    if (selectedColumnsFromLocalStorage) {
      const columnIds = selectedColumnsFromLocalStorage.split(",");
      this._selectedColumns = this.columnData.filter((c) => columnIds.includes(c.id));
    } else {
      this._selectedColumns = this.columnData;
    }
  }

  private setRowsAndColumns() {
    const rowData: Record<string, any>[] = [];

    let rowIndex = 0;
    for (const row of this.rows) {
      const entryData: Record<string, any> = {};
      for (const entry of row.entries) {
        entryData[getDataColumnStringId(entry.columnIds)] = entry.value;
      }

      entryData.index = rowIndex;
      rowData.push(entryData);
      rowIndex++;
    }

    const columnData: { id: string; text: string }[] = [];

    for (const column of this.allColumns) {
      columnData.push({ id: getDataColumnStringId(column.ids), text: column.text });
    }

    this.rowData = rowData;
    this.columnData = columnData;

    const previousSelectedColumns = this._selectedColumns;
    if (this.isFirstTimeCompute) {
      if (columnData.length > 0) {
        this.initializeSelectedColumnsFromLocalStorage();
      }
    } else {
      this._selectedColumns = columnData.filter((cd) => previousSelectedColumns.some((c) => c.id === cd.id));
    }
  }

  exportToCsv() {
    const csvStr = this.generateCsvFromData();
    this.supportService.downloadStringAsFile("export.csv", csvStr);
  }

  private generateCsvFromData() {
    const fieldIds = this.selectedColumns.map((c) => c.id);
    const fieldTexts = this.selectedColumns.map((c) => JSON.stringify(c.text));

    const separator = ";";

    const replacer = function (_, value) {
      return value === null ? "" : value;
    };

    let csv = this.rowData.map((row) => {
      return fieldIds
        .map((fieldId) => {
          return JSON.stringify(row[fieldId], replacer);
        })
        .join(separator);
    });

    csv.unshift(fieldTexts.join(separator));
    return csv.join("\r\n");
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let result = null;

      let value1 = data1[event.field];
      let value2 = data2[event.field];

      const isDate = event.field === DataTableDateColumn.toString();
      if (isDate) {
        const date1 = moment(value1.split(" -")[0], `${this.dateService.dateFormat}; ${this.dateService.timeFormat}`).toDate();
        const date2 = moment(value2.split(" -")[0], `${this.dateService.dateFormat}; ${this.dateService.timeFormat}`).toDate();

        result = date1 < date2 ? -1 : date1 > date2 ? 1 : 0;
      } else {
        if (value1 == null && value2 != null) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (typeof value1 === "string" && typeof value2 === "string") result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }

      return event.order * result;
    });
  }
}
