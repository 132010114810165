import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RouteHelperService {
  constructor(private router: Router) {}

  Refresh() {
    let url = this.router.url;
    this.router.navigate(["/"]).then(() => this.router.navigateByUrl(url));
  }

  InNewTab(target: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([target]));
    window.open(url, "_blank");
  }

  GoTo(target: string) {
    return this.router.navigate([target]);
  }
}
