import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import Stepper from "bs-stepper";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { DateRange, isDateRangeValid } from "src/app/availability-display/availability-display.component";
import { AuthService } from "src/app/core/auth.service";
import { DoorService } from "src/app/door.service";
import { MessageTranslationService } from "src/app/message-translation.service";
import { Door } from "src/app/models/Door";
import { ReservationDtoType } from "src/app/models/MyReservations";
import { Reservation } from "src/app/models/Reservation";
import { combineWithChildDerivedFields, combineWithDerivedFields, getImportantFields, getRegularFields } from "src/app/models/ReservationField";
import { WarehouseCompanyListItem, WarehouseListItem } from "src/app/models/Warehouse";
import { ReservationService } from "src/app/services/reservation.service";
import { SupportService } from "src/app/services/support.service";
import { WarehouseService } from "src/app/services/warehouse.service";
import { ChooseDoorData } from "../company-reservation-choose-door/company-reservation-choose-door.component";
import { ReservationData } from "../company-reservation-reserve-door/company-reservation-reserve-door.component";
import { CompanyReservationSelectTimeComponent } from "../company-reservation-select-time/company-reservation-select-time.component";

export enum ReservationFlowMode {
  CREATING,
  EDITING,
  APPROVING_TWO_PHASE,
  CREATING_TWO_PHASE,
}

enum FlowSteps {
  SELECT_WAREHOUSE,
  SELECT_DOOR,
  RESERVATION_DATA,
  TIME,
  REVIEW,
}

@Component({
  selector: "app-company-reservation-flow-v2",
  templateUrl: "./company-reservation-flow-v2.component.html",
  styleUrls: ["./company-reservation-flow-v2.component.css"],
})
export class CompanyReservationFlowV2Component implements OnInit, OnChanges {
  private stepper: Stepper;

  FlowSteps = FlowSteps;

  @Input() editingReservationId: number;
  @Input() editingReservationCode: string | null;
  @Input() isApprovingTwoPhase: boolean;
  @Input() isCreatingTwoPhase: boolean;

  mode: ReservationFlowMode | null = null;

  companyId: number | null = null;

  reservationToEdit: Reservation | null = null;

  ReservationFlowMode = ReservationFlowMode;

  public company: WarehouseCompanyListItem | null = null;
  public selectedWarehouse: WarehouseListItem | null = null;

  public selectedWarehouseDoors: Door[] | null = null;
  public selectedDoor: Door | null = null;

  reservationData: ReservationData;

  isDataLoading: boolean = true; // initial loading
  isReservationLoading: boolean = false; // creating, editing, approving loading

  @ViewChild("selectTimeComponent") selectTimeComponent: CompanyReservationSelectTimeComponent;

  constructor(
    private auth: AuthService,
    private reservationService: ReservationService,
    private doorService: DoorService,
    private warehouseService: WarehouseService,
    private route: ActivatedRoute,
    private support: SupportService,
    private toast: ToastrService,
    private msgT: MessageTranslationService
  ) {
    this.reset();
  }

  /* INIT: FETCH COMPANY */

  // wait for logged in user, init stepper, get company and reservation
  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.stepper = new Stepper(document.querySelector("#stepper1"), {
        linear: false,
        animation: true,
      });

      this.route.paramMap.subscribe(async (params) => {
        if (params.get("companyId") == null) {
          return;
        }

        const companyId = Number(params.get("companyId"));
        if (isNaN(companyId)) {
          return;
        }

        this.companyId = companyId;
        await this.initializeCompanyAndReservation();
      });
    });
  }

  isEditingDataAvailable() {
    return this.editingReservationId != null && this.editingReservationCode != null;
  }

  async initializeCompanyAndReservationFromExistingReservation() {
    if (!this.isEditingDataAvailable()) {
      return;
    }

    const companyId = await this.reservationService.getReservationCompanyId(this.editingReservationId, this.editingReservationCode);
    if (companyId == null) {
      return;
    }

    this.companyId = companyId;

    await this.initializeCompanyAndReservation();
  }

  async initializeCompanyAndReservation() {
    if (this.companyId == null) {
      return;
    }

    await this.fetchWarehouseCompanyAndWarehouses(this.companyId);
    await this.initializeReservationForEditOrCreate();
  }

  // initializes the entire reservation by loading the company and warehouses in it
  async fetchWarehouseCompanyAndWarehouses(companyId: number) {
    if (!companyId) {
      return;
    }

    this.isDataLoading = true;

    try {
      this.company = await this.warehouseService.fetchBookableCompanyWarehouses(companyId);
    } catch (e) {
      console.error(e);
      this.company = null;
    }

    this.isDataLoading = false;
  }

  /* INIT: SETUP CREATING OR EDITING THE RESERVATION */

  // reset state of reservation
  reset() {
    this.selectedWarehouse = null;
    this.selectedWarehouseDoors = null;
    this.selectedDoor = null;

    this.resetReservationData();

    this.isReservationLoading = false;
  }

  resetReservationData() {
    this.reservationData = {
      reservation: new Reservation(),
      isRecurring: false,
      files: [],
      isValid: false,
      dateRange: null,
      importantFields: [],
      guestEmail: null,
      languageForEmail: this.support.getCurrentLanguage().code,
    };
  }

  // initialize res data if inputs change
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editingReservationCode || changes.editingReservationId || changes.isApprovingTwoPhase) {
      this.initializeCompanyAndReservationFromExistingReservation();
    }
  }

  async initializeReservationForEditOrCreate(): Promise<void> {
    this.reset();

    if (!this.company) {
      return;
    }

    if (this.isEditingDataAvailable()) {
      await this.fetchReservationToEditOrApprove();
    } else {
      this.createEmptyPresentation();
    }
  }

  // setup creating brand new presentation
  async createEmptyPresentation(): Promise<void> {
    this.mode = this.isCreatingTwoPhase ? ReservationFlowMode.CREATING_TWO_PHASE : ReservationFlowMode.CREATING;
  }

  async fetchReservationToEdit() {
    if (!this.isEditingDataAvailable()) {
      return null;
    }

    if (this.reservationToEdit && this.reservationToEdit.id === this.editingReservationId) {
      return this.reservationToEdit;
    }

    this.reservationToEdit = await this.reservationService.getReservationToEdit(
      this.editingReservationId,
      this.editingReservationCode,
      this.mode === ReservationFlowMode.APPROVING_TWO_PHASE
    );

    return this.reservationToEdit;
  }

  // setup editing existing presentation
  async fetchReservationToEditOrApprove(): Promise<void> {
    if (!this.isEditingDataAvailable()) {
      return;
    }

    if (this.isApprovingTwoPhase) {
      this.mode = ReservationFlowMode.APPROVING_TWO_PHASE;
    } else {
      this.mode = ReservationFlowMode.EDITING;
    }

    const reservation = await this.fetchReservationToEdit();

    const warehouse = reservation.warehouse || reservation.door?.warehouse;
    if (!warehouse) {
      return;
    }

    const warehouseItem = this.company.warehouses.find((w) => w.id === warehouse.id);
    if (!warehouseItem) {
      return;
    }

    await this.onWarehouseSelected(warehouseItem);

    if (reservation.door) {
      await this.onDoorSelected({ importantFields: this.reservationData.importantFields, door: reservation.door });
    }

    this.switchToStep(this.mode === ReservationFlowMode.APPROVING_TWO_PHASE ? FlowSteps.SELECT_DOOR : FlowSteps.REVIEW);
  }

  async onWarehouseSelected(warehouse: WarehouseListItem) {
    const stepToSwitchTo = this.mode === ReservationFlowMode.CREATING_TWO_PHASE ? FlowSteps.RESERVATION_DATA : FlowSteps.SELECT_DOOR;
    if (this.selectedWarehouse && this.selectedWarehouse.id === warehouse.id) {
      this.switchToStep(stepToSwitchTo);
      return;
    }

    if (this.selectedWarehouse) {
      const changeConfirmed = confirm("Warning: changing the warehouse will remove all current reservation data!");
      if (!changeConfirmed) {
        return;
      }
    }

    // reset if choosing another warehouse
    this.reset();
    this.selectedWarehouse = warehouse;
    await this.onTwoPhaseWarehouseSelected();

    if (this.mode !== ReservationFlowMode.CREATING_TWO_PHASE) {
      this.selectedWarehouseDoors = await this.doorService.getDoorsOfWarehouse(warehouse.id);
    }

    this.switchToStep(stepToSwitchTo);
  }

  goToWarehouseSelect() {
    this.switchToStep(FlowSteps.SELECT_WAREHOUSE);
  }

  async onTwoPhaseWarehouseSelected() {
    if (this.mode !== ReservationFlowMode.CREATING_TWO_PHASE) {
      return;
    }

    this.reservationData.importantFields = [];
    this.reservationData.reservation.data = await this.warehouseService.getWarehouseReservationFields(this.selectedWarehouse.id);
  }

  async onDoorSelected(data: ChooseDoorData) {
    if (this.selectedDoor && this.selectedDoor.id !== data.door.id) {
      const changeConfirmed = confirm("Warning: changing the door may remove all current reservation data!");
      if (!changeConfirmed) {
        return;
      }
    }

    this.reservationData.importantFields = data.importantFields;

    if (this.selectedDoor && this.selectedDoor.id === data.door.id) {
      this.switchToStep(FlowSteps.RESERVATION_DATA);
      return;
    }

    const previousReservationFields = this.reservationData?.reservation?.data || [];
    this.resetReservationData();

    this.selectedDoor = await this.doorService.getDoor(data.door.id);

    const reservationToEdit = await this.fetchReservationToEdit();
    // copy data without new data
    if (reservationToEdit != null && this.mode === ReservationFlowMode.APPROVING_TWO_PHASE) {
      const doorFields = await this.doorService.getDoorReservationFields(this.selectedDoor.id);
      const twoPhaseFields = reservationToEdit.data;
      this.reservationData.importantFields = data.importantFields;
      this.reservationData.reservation.data = combineWithDerivedFields(doorFields, twoPhaseFields);
    }
    // edit: same door
    else if (reservationToEdit != null && reservationToEdit.door?.id === this.selectedDoor.id) {
      this.reservationData.reservation = reservationToEdit;
      this.reservationData.isRecurring = this.reservationData.reservation.recurrenceRule != null;
      this.reservationData.importantFields = getImportantFields(this.reservationData.reservation.data);
      this.reservationData.reservation.data = getRegularFields(this.reservationData.reservation.data);
    } else {
      this.reservationData.importantFields = data.importantFields;
      this.reservationData.reservation.data = await this.doorService.getDoorReservationFields(this.selectedDoor.id);

      // combine with data that was entered previously
      this.reservationData.reservation.data = combineWithChildDerivedFields(this.reservationData.reservation.data, previousReservationFields);
    }

    this.switchToStep(FlowSteps.RESERVATION_DATA);
  }

  onReservationDataConfirmed() {
    this.switchToStep(this.mode === ReservationFlowMode.CREATING_TWO_PHASE ? FlowSteps.REVIEW : FlowSteps.TIME);
    this.rerenderForCalendar();
  }

  onDateRangeConfirmed() {
    this.switchToStep(FlowSteps.REVIEW);
  }

  onReservationFieldsChange() {
    this.selectTimeComponent.onReservationDataChange();
  }

  // workaround: makes calendar appear in the stepper
  public rerenderForCalendar(): void {
    window.dispatchEvent(new Event("resize"));
  }

  onSelectDateRange(dateRange: DateRange) {
    if (isDateRangeValid(dateRange)) {
      this.reservationData.dateRange = dateRange;
    } else {
      this.reservationData.dateRange = null;
    }
  }

  get reservationFields() {
    return [...this.reservationData.reservation.data.filter((d) => d.importantFieldWarehouseId == null), ...this.reservationData.importantFields];
  }

  async onReservationConfirmed(guestEmail: string) {
    this.reservationData.guestEmail = guestEmail;
    if (this.mode === ReservationFlowMode.CREATING) {
      await this.createReservation();
    } else if (this.mode === ReservationFlowMode.CREATING_TWO_PHASE) {
      await this.createTwoPhaseReservation();
    } else {
      await this.editOrApproveReservation();
    }
  }

  async editOrApproveReservation() {
    try {
      this.isReservationLoading = true;

      this.reservationData.reservation.id = this.editingReservationId;
      const reservation = await this.reservationService.editReservation(this.editingReservationId, this.selectedDoor.id, this.reservationData, this.mode);

      if (reservation) {
        this.reservationService.navigateToReservation(reservation.id, reservation.code, ReservationDtoType.STANDARD);
      }
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    } finally {
      this.isReservationLoading = false;
    }
  }

  async createReservation() {
    try {
      this.isReservationLoading = true;

      const reservation = await this.reservationService.createReservation(this.selectedDoor.id, this.reservationData);

      if (reservation) {
        this.reservationService.navigateToReservation(
          reservation.id,
          reservation.code,
          this.reservationData.isRecurring ? ReservationDtoType.RECURRING : ReservationDtoType.STANDARD
        );
      }
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    } finally {
      this.isReservationLoading = false;
    }
  }

  async createTwoPhaseReservation() {
    try {
      this.isReservationLoading = true;

      const reservation = await this.reservationService.createTwoPhaseReservation(this.selectedWarehouse.id, this.reservationData);

      if (reservation) {
        this.reservationService.navigateToReservation(reservation.id, reservation.code, ReservationDtoType.STANDARD);
      }
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    } finally {
      this.isReservationLoading = false;
    }
  }

  get bookButtonText() {
    if (this.mode === ReservationFlowMode.APPROVING_TWO_PHASE) {
      return this.msgT.bookButtonText().APPROVE;
    }

    if (this.mode === ReservationFlowMode.CREATING_TWO_PHASE) {
      return this.msgT.bookButtonText().TWO_PHASE;
    }

    if (this.mode === ReservationFlowMode.EDITING) {
      return this.msgT.bookButtonText().EDIT;
    }
    return this.msgT.bookButtonText().BOOK;
  }

  canDeactivate(): Observable<boolean> | boolean {
    return !this.isReservationLoading;
  }

  switchToStep(step: FlowSteps) {
    const stepNumber = this.getStepNumber(step);
    this.stepper.to(stepNumber);
  }

  getStepNumber(step: FlowSteps) {
    const stepNumberMapper: Record<FlowSteps, number> = {
      [FlowSteps.SELECT_WAREHOUSE]: 1,
      [FlowSteps.SELECT_DOOR]: 2,
      [FlowSteps.RESERVATION_DATA]: 3,
      [FlowSteps.TIME]: 4,
      [FlowSteps.REVIEW]: 5,
    };

    return stepNumberMapper[step];
  }
}
