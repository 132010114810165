<label
  ><ng-container>{{ label }}</ng-container></label
>
<div class="d-flex align-items-end w-100">
  <div class="form-group mr-1 flex-1 w-100">
    <app-pick-date [date]="date" (dateChange)="onDateChange($event)"></app-pick-date>
  </div>

  <div class="form-group ml-1 flex-1 w-100">
    <input type="text" [value]="time" (input)="onTimeChange($event)" [className]="'html-duration-picker form-control'" />
  </div>
</div>
