import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { CommonModule } from "@angular/common";
import { ContextMenuModule } from "ngx-contextmenu";

import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { HomeComponent } from "./home/home.component";
import { RegisterComponent } from "./register/register.component";
import { WarehousePermissionsComponent } from "./warehouse-permissions/warehouse-permissions.component";
import { WarehouseListComponent } from "./warehouses/warehouse-list/warehouse-list.component";
import { WarehousePageComponent } from "./warehouse-page/warehouse-page.component";
import { AvailabilityDisplayComponent } from "./availability-display/availability-display.component";
import { DateInterceptor } from "./DateInterceptor";
import { MyWarehousesComponent } from "./warehouses/my-warehouses/my-warehouses.component";
import { PadZeroesPipe } from "./pad-zeroes.pipe";
import { UploadUserImageComponent } from "./upload-user-image/upload-user-image.component";
import { ProfileComponent } from "./profile/profile.component";
import { PickDateComponent } from "./pick-date/pick-date.component";
import { ConfirmationComponent } from "./confirmation/confirmation.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DayDisplayComponent } from "./day-display/day-display.component";

import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EditableComponent } from "./editable/editable.component";
import { ViewModeDirective } from "./editable/view-mode.directive";
import { EditModeDirective } from "./editable/edit-mode.directive";
import { EditableOnEnterDirective } from "./editable/editable-on-enter.directive";
import { FocusableDirective } from "./focusable.directive";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { WarehouseBreadcrumbsComponent } from "./warehouse-breadcrumbs/warehouse-breadcrumbs.component";
import { ReservationStatusComponent } from "./reservation-status/reservation-status.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { ConfirmDeleteModalComponent } from "./confirm-delete-modal/confirm-delete-modal.component";
import { ReservationFieldsComponent } from "./reservation-fields/reservation-fields.component";
import { ReservationFieldsFormComponent } from "./reservation-fields-form/reservation-fields-form.component";
import { ProfileMailSendingSettingsComponent } from "./profile-mail-sending-settings/profile-mail-sending-settings.component";
import { RenderPermissionStatusComponent } from "./render-permission-status/render-permission-status.component";
import { PendingChangesGuard } from "./guards/pending-changes.guard";
import { DragAndDropListInputComponent } from "./drag-and-drop-list-input/drag-and-drop-list-input.component";
import { DndModule } from "ngx-drag-drop";
import { DataTableComponent } from "./data-table/data-table.component";
import { DataTableEntryAccessPipe } from "./data-table/data-table-entry-access.pipe";
import { CheckboxDropdownInputComponent } from "./checkbox-dropdown-input/checkbox-dropdown-input.component";
import { AppCheckboxComponent } from "./shared-components/app-checkbox/app-checkbox.component";
import { AppTextInputComponent } from "./shared-components/app-text-input/app-text-input.component";
import { AllWarehousesComponent } from "./warehouses/all-warehouses/all-warehouses.component";
import { WarehouseListSearchBarComponent } from "./warehouses/warehouse-list-search-bar/warehouse-list-search-bar.component";
import { SpinnerComponent } from "./shared-components/spinner/spinner.component";
import { WarehouseListItemComponent } from "./warehouses/warehouse-list-item/warehouse-list-item.component";
import { RenderWarehousePermissionStatusComponent } from "./warehouses/render-warehouse-permission-status/render-warehouse-permission-status.component";
import { WarehouseReservationListComponent } from "./reservations/warehouse-reservation-list/warehouse-reservation-list.component";
import { ReservationListSearchBarComponent } from "./reservations/reservation-list-search-bar/reservation-list-search-bar.component";
import { ReservationTableComponent } from "./reservations/reservation-table/reservation-table.component";
import { ReservationListTabsComponent } from "./reservations/reservation-list-tabs/reservation-list-tabs.component";
import { AddWarehouseModalComponent } from "./warehouses/add-warehouse-modal/add-warehouse-modal.component";
import { AddDoorModalComponent } from "./warehouses/add-door-modal/add-door-modal.component";
import { ReservationArchiveComponent } from "./reservations/reservation-archive/reservation-archive.component";

import { FullCalendarModule } from "@fullcalendar/angular";
import { ReservationCalendarComponent } from "./creating-reservation/reservation-calendar/reservation-calendar.component"; // must go before plugins

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from "@fullcalendar/moment";

import { AppDatetimeInputComponent } from "./shared-components/app-datetime-input/app-datetime-input.component";
import { AppTimeInputComponent } from "./shared-components/app-time-input/app-time-input.component";
import { WarehouseDoorSelectComponent } from "./creating-reservation/warehouse-door-select/warehouse-door-select.component";
import { CreateOrEditReservationOnDoorComponent } from "./creating-reservation/create-or-edit-reservation-on-door/create-or-edit-reservation-on-door.component";
import { AccordionWithContentComponent } from "./shared-components/accordion-with-content/accordion-with-content.component";
import { DoorDetailsPageComponent } from "./door/door-details-page/door-details-page.component";
import { DoorAvailabilityCalendarComponent } from "./door/door-availability-calendar/door-availability-calendar.component";
import { MultiFilePickerComponent } from "./shared-components/multi-file-picker/multi-file-picker.component";
import { RecurringReservationFormComponent } from "./creating-reservation/recurring-reservation-form/recurring-reservation-form.component";
import { ConfirmationModalComponent } from "./shared-components/confirmation-modal/confirmation-modal.component";
import { ReservationDetailsModalComponent } from "./shared-components/reservation-details-modal/reservation-details-modal.component";
import { EditDoorPageComponent } from "./door/edit-door-page/edit-door-page.component";

import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { TooltipModule } from "primeng/tooltip";
import { CoreModule } from "./core/core.module";
import { CompanyLoginComponent } from "./auth/company-login/company-login.component";
import { LoginErrorComponent } from "./auth/login-error/login-error.component";
import { NgxPermissionsModule } from "ngx-permissions";
import { AuthGuard } from "./core/auth-guard.service";
import { NoAuthGuard } from "./core/no-auth-guard.service";
import { ProductNotPermittedComponent } from "./auth/product-not-permitted/product-not-permitted.component";
import { CompanyReservationLinkComponent } from "./company/company-reservation-link/company-reservation-link.component";
import { CompanyReservationComponent } from "./company/company-reservation/company-reservation.component";
import { CarrierProfileComponent } from "./carrier-profile/carrier-profile.component";
import { WarehouseProfileComponent } from "./warehouse-profile/warehouse-profile.component";
import { ProfileOwlSettingsComponent } from "./profile-owl-settings/profile-owl-settings.component";
import { ProfileContactMailsSettingsComponent } from "./profile-contact-mails-settings/profile-contact-mails-settings.component";
import { LanguagePickerComponent } from "./language-picker/language-picker.component";
import { CompanyReservationChooseWarehouseComponent } from "./company/company-reservation-choose-warehouse/company-reservation-choose-warehouse.component";
import { CompanyReservationChooseDoorComponent } from "./company/company-reservation-choose-door/company-reservation-choose-door.component";
import { CompanyReservationReserveDoorComponent } from "./company/company-reservation-reserve-door/company-reservation-reserve-door.component";

import { QuillModule } from "ngx-quill";
import { CompanyReservationSelectTimeComponent } from "./company/company-reservation-select-time/company-reservation-select-time.component";
import { CountryPickerComponent } from "./shared-components/country-picker/country-picker.component";
import { CompanyTwoPhaseReservationComponent } from "./company/company-two-phase-reservation/company-two-phase-reservation.component";
import { CompanyEditReservationComponent } from "./company/company-edit-reservation/company-edit-reservation.component";
import { ReservationViewComponent } from "./reservations/reservation-view/reservation-view.component";
import { BasicReservationViewComponent } from "./reservations/basic-reservation-view/basic-reservation-view.component";
import { RecurringReservationViewComponent } from "./reservations/recurring-reservation-view/recurring-reservation-view.component";
import { CompanyApproveTwoPhaseComponent } from "./company/company-approve-two-phase/company-approve-two-phase.component";
import { ImportantFieldsFilterComponent } from "./door/important-fields-filter/important-fields-filter.component";
import { MapLocationPickerComponent } from "./shared-components/map-location-picker/map-location-picker.component";
import { ProfileMailTemplatesComponent } from "./profile-mail-templates/profile-mail-templates.component";
import { MailAttachmentsSettingsComponent } from "./mail-attachments-settings/mail-attachments-settings.component";
import { ProfileEditMailTemplateComponent } from "./profile-edit-mail-template/profile-edit-mail-template.component";
import { ReservationFieldLabelComponent } from "./reservation-field-label/reservation-field-label.component";
import { CompanyFirstTimeSetupNoticeComponent } from "./company-first-time-setup-notice/company-first-time-setup-notice.component";
import { EmailTemplatePlaceholderDropdownComponent } from "./email-template-placeholder-dropdown/email-template-placeholder-dropdown.component";
import { HelpTextComponent } from "./help-text/help-text.component";
import { ErrorInterceptor } from "./error-interceptor";
import { CompanyReservationFlowV2Component } from "./company/company-reservation-flow-v2/company-reservation-flow-v2.component";
import { CompanyReservationReviewComponent } from "./company/company-reservation-review/company-reservation-review.component";
import { ReservationDataTableComponent } from "./reservations/reservation-data-table/reservation-data-table.component";
import { StatsDashboardComponent } from "./stats/stats-dashboard/stats-dashboard.component";
import { StatsBookedWindowsComponent } from "./stats/stats-booked-windows/stats-booked-windows.component";
import { ReservationFieldSelectItemComponent } from "./reservation-field-select-item/reservation-field-select-item.component";
import { NavbarLanguagePickerComponent } from './navbar-language-picker/navbar-language-picker.component';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';
import { WeekdayPickerComponent } from './weekday-picker/weekday-picker.component';
import { WeekdayDisplayComponent } from './weekday-display/weekday-display.component';
import { CompanyHolidayListComponent } from './company-holiday-list/company-holiday-list.component';
import { ProfileOwlCompanySettingsComponent } from './profile-owl-company-settings/profile-owl-company-settings.component';
import { LanguageSelectComponent } from './language-select/language-select.component';

FullCalendarModule.registerPlugins([dayGridPlugin, momentPlugin, timeGridPlugin, interactionPlugin]);

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    RegisterComponent,
    WarehousePermissionsComponent,
    WarehouseListComponent,
    WarehousePageComponent,
    AvailabilityDisplayComponent,
    MyWarehousesComponent,
    PadZeroesPipe,
    UploadUserImageComponent,
    ProfileComponent,
    PickDateComponent,
    ConfirmationComponent,
    DayDisplayComponent,
    EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    FocusableDirective,
    EditableOnEnterDirective,
    WarehouseBreadcrumbsComponent,
    ReservationStatusComponent,
    ConfirmDeleteModalComponent,
    ReservationFieldsComponent,
    ReservationFieldsFormComponent,
    ProfileMailSendingSettingsComponent,
    RenderPermissionStatusComponent,
    AppCheckboxComponent,
    AppTextInputComponent,
    DragAndDropListInputComponent,
    DataTableComponent,
    DataTableEntryAccessPipe,
    CheckboxDropdownInputComponent,
    AllWarehousesComponent,
    WarehouseListSearchBarComponent,
    SpinnerComponent,
    WarehouseListItemComponent,
    RenderWarehousePermissionStatusComponent,
    WarehouseReservationListComponent,
    ReservationListSearchBarComponent,
    ReservationTableComponent,
    ReservationListTabsComponent,
    AddWarehouseModalComponent,
    AddDoorModalComponent,
    ReservationArchiveComponent,
    ReservationCalendarComponent,
    AppDatetimeInputComponent,
    AppTimeInputComponent,
    WarehouseDoorSelectComponent,
    CreateOrEditReservationOnDoorComponent,
    AccordionWithContentComponent,
    DoorDetailsPageComponent,
    DoorAvailabilityCalendarComponent,
    MultiFilePickerComponent,
    RecurringReservationFormComponent,
    ConfirmationModalComponent,
    ReservationDetailsModalComponent,
    EditDoorPageComponent,
    CompanyLoginComponent,
    LoginErrorComponent,
    ProductNotPermittedComponent,
    CompanyReservationLinkComponent,
    CompanyReservationComponent,
    CarrierProfileComponent,
    WarehouseProfileComponent,
    ProfileOwlSettingsComponent,
    ProfileContactMailsSettingsComponent,
    LanguagePickerComponent,
    CompanyReservationChooseWarehouseComponent,
    CompanyReservationChooseDoorComponent,
    CompanyReservationReserveDoorComponent,
    CompanyReservationSelectTimeComponent,
    CountryPickerComponent,
    CompanyTwoPhaseReservationComponent,
    CompanyEditReservationComponent,
    ReservationViewComponent,
    BasicReservationViewComponent,
    RecurringReservationViewComponent,
    CompanyApproveTwoPhaseComponent,
    ImportantFieldsFilterComponent,
    MapLocationPickerComponent,
    ProfileMailTemplatesComponent,
    MailAttachmentsSettingsComponent,
    ProfileEditMailTemplateComponent,
    ReservationFieldLabelComponent,
    CompanyFirstTimeSetupNoticeComponent,
    EmailTemplatePlaceholderDropdownComponent,
    HelpTextComponent,
    CompanyReservationFlowV2Component,
    CompanyReservationReviewComponent,
    ReservationDataTableComponent,
    StatsDashboardComponent,
    StatsBookedWindowsComponent,
    ReservationFieldSelectItemComponent,
    NavbarLanguagePickerComponent,
    AddressAutocompleteComponent,
    WeekdayPickerComponent,
    WeekdayDisplayComponent,
    CompanyHolidayListComponent,
    ProfileOwlCompanySettingsComponent,
    LanguageSelectComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule.forRoot(),
    ReactiveFormsModule,
    NgxDropzoneModule,
    ContextMenuModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    FullCalendarModule,
    TableModule,
    MultiSelectModule,
    TooltipModule,
    QuillModule.forRoot(),
    RouterModule.forRoot(
      [
        { path: "", component: HomeComponent, pathMatch: "full", canActivate: [AuthGuard] },
        {
          path: "login-error",
          component: LoginErrorComponent,
          pathMatch: "full",
          canActivate: [NoAuthGuard],
        },
        {
          path: "login",
          component: CompanyLoginComponent,
          pathMatch: "full",
          canActivate: [NoAuthGuard],
        },
        {
          path: "not-permitted",
          component: ProductNotPermittedComponent,
          pathMatch: "full",
          canActivate: [],
        },
        { path: "register", component: RegisterComponent, pathMatch: "full", canActivate: [NoAuthGuard] },
        { path: "profile", component: ProfileComponent, pathMatch: "full", canActivate: [AuthGuard] },
        {
          path: "email-settings",
          component: ProfileMailTemplatesComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "reserve-company/:companyId",
          component: CompanyReservationComponent,
          pathMatch: "full",
          canDeactivate: [PendingChangesGuard],
        },
        {
          path: "edit-reserve-company/:reservationId/:code",
          component: CompanyEditReservationComponent,
          pathMatch: "full",
          canDeactivate: [PendingChangesGuard],
        },
        {
          path: "approve-reserve-company/:reservationId/:code",
          component: CompanyApproveTwoPhaseComponent,
          pathMatch: "full",
          canDeactivate: [PendingChangesGuard],
        },
        {
          path: "reserve-company-two-phase/:companyId",
          component: CompanyTwoPhaseReservationComponent,
          pathMatch: "full",
          canDeactivate: [PendingChangesGuard],
        },
        {
          path: "reservation/:id",
          component: BasicReservationViewComponent,
          pathMatch: "full",
        },
        {
          path: "reservation-recurring/:id",
          component: RecurringReservationViewComponent,
          pathMatch: "full",
        },
        {
          path: "warehouses",
          component: AllWarehousesComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "warehouse/:id",
          component: WarehousePageComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "door/:id",
          component: DoorDetailsPageComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
          canDeactivate: [PendingChangesGuard],
        },
        {
          path: "edit-door/:id",
          component: EditDoorPageComponent,
          pathMatch: "full",
          canDeactivate: [],
          canActivate: [AuthGuard],
        },
        {
          path: "my-warehouses",
          component: MyWarehousesComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "my-reservations",
          component: WarehouseReservationListComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "archive",
          component: ReservationArchiveComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "stats",
          component: StatsDashboardComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "manage/permissions",
          component: WarehousePermissionsComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
      ],
      { onSameUrlNavigation: "reload", relativeLinkResolution: "legacy" }
    ),
    DndModule,
    NgbModule,
    BrowserAnimationsModule, // required animations module
    FontAwesomeModule, // ToastrModule added
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    PendingChangesGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
