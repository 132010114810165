<div class="w-100 mb-1" *ngIf="options && options.length > 0">
  <label for="dndList-{{ label }}"
    ><ng-container i18n="dndList-{{ label }}">{{ label }}</ng-container></label
  >
  <div dndDropzone [dndHorizontal]="true" dndEffectAllowed="copyMove" (dndDrop)="onDrop($event, options)" class="dnd-list">
    <div dndPlaceholderRef class="dnd-list-item dnd-list-placeholder"></div>

    <div
      *ngFor="let item of options"
      [dndDraggable]="item"
      [dndDisableIf]="disabled"
      [dndEffectAllowed]="'move'"
      (dndCopied)="onDragged(item, options, 'copy')"
      (dndLinked)="onDragged(item, options, 'link')"
      (dndMoved)="onDragged(item, options, 'move')"
      (dndCanceled)="onDragged(item, options, 'none')"
      class="dnd-list-item"
    >
      <p class="dnd-list-item-text">{{ item.content }}</p>
    </div>
  </div>
</div>
