import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "padZeroes",
})
export class PadZeroesPipe implements PipeTransform {
  transform(value: any, zeroes: number): any {
    let s = "" + value;
    s = "0".repeat(zeroes - s.length) + s;
    return s;
  }
}
