<div class="text-center table-container">
  <table class="table table-bordered">
    <tr *ngFor="let field of reservationData || []">
      <th width="35%">
        <ng-container> {{ field.name }}</ng-container
        >:
      </th>
      <td>
        <input *ngIf="field.type == ReservationFieldType.Checkbox" type="checkbox" disabled [(ngModel)]="field.value" />

        <ng-container *ngIf="field.type == ReservationFieldType.Country">{{
          field.value ? contriesService.getCountryById(field.value).name : "/"
        }}</ng-container>

        <ng-container *ngIf="field.type == ReservationFieldType.Date">{{ field.value ? dateService.formatDate(field.value) : "/" }}</ng-container>

        <span
          style="white-space: pre-line"
          *ngIf="field.type != ReservationFieldType.Checkbox && field.type != ReservationFieldType.Date && field.type != ReservationFieldType.Country"
        >
          <ng-container>{{ field.value || "/" }}</ng-container>
        </span>
      </td>
    </tr>
  </table>
</div>
