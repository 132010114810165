import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DoorService } from "src/app/door.service";
import { MessageTranslationService } from "src/app/message-translation.service";
import { Door, DoorFieldsFilter, ReservationType } from "src/app/models/Door";
import { RouteHelperService } from "src/app/router.service";
import { RestService } from "src/app/services/rest.service";

@Component({
  selector: "app-edit-door-page",
  templateUrl: "./edit-door-page.component.html",
  styleUrls: ["./edit-door-page.component.css"],
})
export class EditDoorPageComponent implements OnInit {
  reservationType = ReservationType;

  door: Door | null = null;
  loadingDoor: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private http: RestService,
    private doorService: DoorService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    private routeHelper: RouteHelperService
  ) {}

  ngOnInit(): void {
    const doorId = Number(this.route.snapshot.paramMap.get("id"));
    this.fetchDoor(doorId);
  }

  async fetchDoor(doorId: number) {
    this.loadingDoor = true;
    this.door = await this.doorService.getDoor(doorId);
    this.loadingDoor = false;
  }

  async editDoor() {
    if (!this.door) {
      return;
    }

    const editSuccess = await this.doorService.editDoor(this.door);
    if (editSuccess) {
      this.routeHelper.Refresh();
    }
  }
}
