import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { DateRange } from "src/app/availability-display/availability-display.component";
import { Door, ReservationType } from "src/app/models/Door";
import { getFieldByMeaning, isHalfPalletsField, isPalletsField, ReservationField, ReservationFieldSpecialMeaningField } from "src/app/models/ReservationField";
import { TimeWindow } from "src/app/models/TimeWindow";
import * as moment from "moment";
import { TimespanConverterService } from "src/app/timespan-converter.service";
import { Reservation } from "src/app/models/Reservation";
import { RestService } from "src/app/services/rest.service";
import { DoorService } from "src/app/door.service";
import { interval, Subject } from "rxjs";
import { startWith, switchMap, share, takeUntil } from "rxjs/operators";
import { DateService } from "src/app/date.service";

export interface SelectedTimeWindow {
  selectedTimeWindowId: number | null;
  dateRange: DateRange;
}

const POLLING_INTERVAL = 30 * 1000;

@Component({
  selector: "app-company-reservation-select-time",
  templateUrl: "./company-reservation-select-time.component.html",
  styleUrls: ["./company-reservation-select-time.component.css"],
})
export class CompanyReservationSelectTimeComponent implements OnInit, OnChanges, OnDestroy {
  reservationDateRange: DateRange | null = null;
  ReservationType = ReservationType;

  @Input() door: Door | null = null;
  @Input() reservation: Reservation | null = null;
  @Input() reservationData: ReservationField[];
  @Input() savedDateRange: DateRange | null; // just for passing from parent

  @Output() onSelectDateRange: EventEmitter<DateRange> = new EventEmitter<DateRange>();
  @Output() onNextClick = new EventEmitter<void>();

  selectedTimeWindowId: number = null;

  fixedTimeWindows: TimeWindow[] = [];

  reservationFieldsCache: { lastFetchedAt: Date; forDate: Date; doorId: number; timeWindows: TimeWindow[] } | null;

  stopPolling = new Subject();

  constructor(
    private timespanConverter: TimespanConverterService,
    private doorService: DoorService,
    private http: RestService,
    private dateService: DateService
  ) {
    this.init();
    interval(POLLING_INTERVAL)
      .pipe(
        startWith(0),
        switchMap(async () => {
          this.fetchAvailableFixedTimeWindows();
        }),
        share(),
        takeUntil(this.stopPolling)
      )
      .subscribe();
  }

  private init() {
    this.reservationDateRange = null;
    this.emitDateRange();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.stopPolling.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.door) {
      this.onDoorChange();
    }

    if (changes.reservation || changes.reservationData) {
      this.fetchAvailableFixedTimeWindows();
    }

    if (changes.reservation && this.reservation) {
      this.selectedTimeWindowId = this.reservation.fixedTimeWindowId;
    }
  }

  public onReservationDataChange() {
    this.fetchAvailableFixedTimeWindows();
    this.applyChangesOnDoorOrReservation();
  }

  private async onDoorChange() {
    if (!this.door || !this.reservation) {
      return;
    }

    // if (!this.isEditingReservation) {
    // await this.setDoorReservationFields(this.door.id);
    //}

    this.selectedTimeWindowId = null;
    this.reservationDateRange = null;

    this.applyChangesOnDoorOrReservation();
  }

  private applyChangesOnDoorOrReservation() {
    this.calculateEndTimeFromPallets();
    this.updateReservationDateRange();
  }

  private updateReservationDateRange() {
    this.onReservationDateRangeChange(
      {
        date: this.reservation.date,
        start: this.reservation.start,
        end: this.reservation.end,
      },
      true
    );
  }

  selectFixedTimeWindow(tw: TimeWindow | null) {
    if (tw == null) {
      this.selectedTimeWindowId = null;
      this.reservationDateRange = {
        date: this.reservationDateRange?.date,
        end: null,
        start: null,
      };
      this.emitDateRange();
    } else {
      this.selectedTimeWindowId = tw.id;
      this.onReservationDateRangeChange(
        {
          start: tw.start,
          end: tw.end,
        },
        true
      );
    }
  }

  onReservationDateRangeDateChange(date: Date) {
    this.onReservationDateRangeChange({ date }, true);
  }

  onReservationDateRangeTimeStartChange(start: string) {
    this.onReservationDateRangeChange({ start }, true);
  }

  onReservationDateRangeTimeEndChange(end: string) {
    this.onReservationDateRangeChange({ end }, true);
  }

  onReservationDateRangeChange(newDateRange: Partial<DateRange>, triggerCalculations: boolean = false) {
    if (!this.door) {
      return;
    }

    this.reservationDateRange = {
      date: newDateRange.date || this.reservationDateRange?.date,
      start: newDateRange.start || this.reservationDateRange?.start,
      end: newDateRange.end || this.reservationDateRange?.end,
    };

    if (!triggerCalculations) {
      return;
    }

    if (this.door.properties.type === ReservationType.Calculated) {
      this.calculateEndTimeFromPallets();
    } else if (this.door.properties.type === ReservationType.Free) {
      const hasEndChanged = newDateRange.end != null;
      this.makeSureStartIsBeforeEnd(hasEndChanged);
    } else if (this.door.properties.type === ReservationType.Fixed) {
      this.fetchAvailableFixedTimeWindows();
    }

    this.emitDateRange();
  }

  emitDateRange() {
    this.onSelectDateRange.emit(this.reservationDateRange);
  }

  calculateEndTimeFromPallets() {
    if (!this.door) {
      return;
    }

    if (this.door.properties.type !== ReservationType.Calculated || this.reservationDateRange == null) {
      return;
    }

    const palletsField = this.reservation.data.find((f) => isPalletsField(f));
    const halfPalletsField = this.reservation.data.find((f) => isHalfPalletsField(f));

    const start = moment.duration(this.reservationDateRange.start);
    const perPallet = moment.duration(this.door.properties.timePerPallet);
    const baseTime = moment.duration(this.door.properties.baseTime);

    let pallets = 0;
    if (palletsField) {
      pallets += Number(palletsField.value);
    }

    if (halfPalletsField) {
      pallets += Number(halfPalletsField.value) * 0.5;
    }

    const end = start.add(baseTime.asHours() + perPallet.asHours() * pallets, "hours");

    this.reservationDateRange = {
      ...this.reservationDateRange,
      end: this.timespanConverter.durationToString(end),
    };
  }

  private makeSureStartIsBeforeEnd(hasEndChanged = false) {
    if (this.door.properties.type !== ReservationType.Free) {
      return;
    }

    const start = moment.duration(this.reservationDateRange.start);
    const end = moment.duration(this.reservationDateRange.end);

    if (start.asSeconds() >= end.asSeconds()) {
      if (hasEndChanged) {
        const adjustedEnd = start.add(15, "minutes");
        this.onReservationDateRangeChange({ end: this.timespanConverter.durationToString(adjustedEnd) }, false);
      } else {
        const adjustedStart = end.subtract(15, "minutes");
        this.onReservationDateRangeChange({ start: this.timespanConverter.durationToString(adjustedStart) }, false);
      }
    }
  }

  private async fetchAvailableFixedTimeWindows() {
    if (this.door == null || this.reservation?.data == null) {
      return;
    }

    if (this.door.properties.type !== ReservationType.Fixed) {
      return;
    }

    try {
      const date = this.reservationDateRange.date;
      const fixedTimeWindows = await this.fetchFixedTimeWindows(this.door.id, date);
      this.fixedTimeWindows = fixedTimeWindows.filter((tw) => this.doorService.doReservationFieldsMatchDoor(tw.timeWindowFieldsFilter, this.reservation.data));
      this.fixedTimeWindows = this.fixedTimeWindows.filter((tw) => {
        if (tw.bookablePallets === 0) {
          return true;
        }

        const palletsField = getFieldByMeaning(this.reservation.data, ReservationFieldSpecialMeaningField.NUMBER_OF_PALLETS);
        const halfPalletsField = getFieldByMeaning(this.reservation.data, ReservationFieldSpecialMeaningField.NUMBER_OF_HALF_PALLETS);

        if (palletsField == null && halfPalletsField == null) {
          return true;
        }

        let pallets = 0;
        if (palletsField) {
          pallets += Number(palletsField.value);
        }

        if (halfPalletsField) {
          pallets += Number(halfPalletsField.value) * 0.5;
        }

        return tw.bookablePallets >= pallets;
      });

      if (this.selectedTimeWindowId != null) {
        const timeWindow = this.fixedTimeWindows.find((tw) => tw.id === this.selectedTimeWindowId);
        if (!timeWindow) {
          this.selectFixedTimeWindow(null);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async fetchFixedTimeWindows(doorId: number, forDate: Date | null) {
    if (forDate == null) {
      return [];
    }

    try {
      let fetchTimeWindows = false;
      const doorNotInCache =
        this.reservationFieldsCache == null ||
        this.reservationFieldsCache.doorId != doorId ||
        !this.dateService.areSameDates(this.reservationFieldsCache.forDate, forDate);

      if (doorNotInCache) {
        fetchTimeWindows = true;
      } else {
        const secondsFromFetch = this.dateService.timeDiff(this.reservationFieldsCache.lastFetchedAt, new Date(), "milliseconds");
        fetchTimeWindows = secondsFromFetch >= POLLING_INTERVAL;
      }

      if (fetchTimeWindows) {
        const fetchedTimeWindows = await this.http
          .post<TimeWindow[]>(`api/door/getAvailableTimeWindows/${doorId}`, { date: forDate, reservationIdForEditing: this.reservation?.id })
          .toPromise();
        this.reservationFieldsCache = {
          doorId,
          forDate,
          lastFetchedAt: new Date(),
          timeWindows: fetchedTimeWindows,
        };
      }

      return this.reservationFieldsCache.timeWindows;
    } catch (e) {
      console.error("fetchFixedTimeWindows error", e);
    }

    return [];
  }
}
