import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { User } from "../models/User";
import { RestService } from "../services/rest.service";
import { SupportService } from "../services/support.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  user: User;
  password: string;
  passwordConfirmation: string;
  constructor(private http: RestService, private support: SupportService, private toast: ToastrService, private msgT: MessageTranslationService) {
    this.Reset();
  }

  Reset() {
    this.user = new User();
    this.password = "";
    this.passwordConfirmation = "";
  }

  ngOnInit() {}

  SendRegistration() {
    if (
      this.user.email == null ||
      this.user.email.length === 0 ||
      this.password == null ||
      this.password.length === 0 ||
      this.user.name == null ||
      this.user.name.length === 0
    ) {
      this.toast.error(this.msgT.errorValidatingForm());
      return;
    }

    if (!this.support.checkPasswordStrength(this.password)) {
      return;
    }

    if (this.password != this.passwordConfirmation) {
      this.toast.error(this.msgT.passwordDontMatch());
      return;
    }

    this.http
      .post("api/authentication/registerCarrier", {
        ...this.user,
        password: this.password,
      }, false)
      .subscribe(
        (d) => {
          this.Reset();
          this.toast.success(this.msgT.registerSuccess());
        },
        (error) => {
          console.log("Register error");
          console.log(error);
          if (error.status === 409) {
            this.toast.error(this.msgT.emailTakenError());
          } else {
            this.toast.error(this.msgT.registerError());
          }
        }
      );
  }
}
