<div class="container app-container arrival-table-container">
  <h1 class="app-heading" i18n="Manage permissions">Manage permissions</h1>

  <div class="d-flex justify-content-center mb-4 checkboxes-container">
    <div class="form-check form-check-inline" *ngFor="let permStatus of [PermissionStatus.Accepted, PermissionStatus.Pending, PermissionStatus.Declined]">
      <input class="form-check-input" type="checkbox" id="{{ permStatus }}FilterCheckbox" (change)="permissionCheckboxChange($event, permStatus)" checked />
      <label class="form-check-label" for="{{ permStatus }}FilterCheckbox">
        <app-render-permission-status [status]="permStatus"></app-render-permission-status>
      </label>
    </div>
  </div>

  <ng-container *ngIf="filteredPermissions">
    <div class="d-flex mb-4" class="table-filter-container">
      <input
        class="form-control table-filter-input"
        i18n-placeholder="Filter..."
        placeholder="Filter..."
        type="text"
        (input)="filterTextChange($event.target.value)"
      />
      <button class="btn btn-primary table-refresh-btn" i18n="Refresh" (click)="onRefreshClick()">Refresh</button>
    </div>

    <ng-container *ngIf="filteredPermissions.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th i18n="Warehouse">Warehouse</th>
              <th i18n="Carrier">Carrier</th>
              <th i18n="Status">Status</th>
              <th i18n="Actions" *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let perm of filteredPermissions">
              <td>
                <a class="btn btn-secondary" [routerLink]="'/warehouse/' + perm.warehouse.id">{{ perm.warehouse.name }}</a>
              </td>

              <td>
                {{ perm.carrier.name }}
                <span class="text-secondary" small>({{ perm.carrier.email }})</span>
              </td>

              <td>
                <app-render-permission-status [status]="perm.status"></app-render-permission-status>
                <div *ngIf="perm.status === PermissionStatus.Accepted">
                  <div *ngIf="perm.type === PermissionType.ALL_DOORS" i18n>Has access to all doors</div>
                  <div *ngIf="perm.type === PermissionType.ONLY_SPECIFIC_DOORS">
                    <ng-container i18n>Has access only to doors: </ng-container>
                    <ng-container *ngFor="let permissionForDoor of perm.permissionsForDoor; let last = last">
                      {{ permissionForDoor.door.name }}<ng-container *ngIf="!last">, </ng-container>
                    </ng-container>
                  </div>
                  <div *ngIf="perm.type === PermissionType.ONLY_TWO_PHASE" i18n>Has access only to two-phase reservations</div>
                </div>
              </td>

              <td width="20%" *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
                <div class="d-flex" *ngIf="perm.status === PermissionStatus.Pending">
                  <button (click)="onManagePermission(perm, manageContent)" class="btn btn-primary mr-2">
                    <ng-container i18n="Accept"> Accept</ng-container>
                  </button>
                  <button (click)="Decline(perm)" class="btn btn-danger mr-2 ml-2">
                    <ng-container i18n="Decline"> Decline</ng-container>
                  </button>
                  <button (click)="openDeleteModal(deleteContent, perm)" class="btn btn-danger ml-2">
                    <ng-container i18n="Delete"> Delete</ng-container>
                  </button>
                </div>
                <div class="d-flex" *ngIf="perm.status === PermissionStatus.Declined">
                  <button (click)="onManagePermission(perm, manageContent)" class="btn btn-primary mr-2">
                    <ng-container i18n="Accept"> Accept</ng-container>
                  </button>
                  <button (click)="openDeleteModal(deleteContent, perm)" class="btn btn-danger ml-2">
                    <ng-container i18n="Delete"> Delete</ng-container>
                  </button>
                </div>
                <div class="d-flex" *ngIf="perm.status === PermissionStatus.Accepted">
                  <button (click)="onManagePermission(perm, manageContent)" class="btn btn-primary mr-2">
                    <ng-container i18n="Manage"> Manage</ng-container>
                  </button>
                  <button (click)="Cancel(perm)" class="btn btn-danger mx-2">
                    <ng-container i18n="Cancel"> Cancel</ng-container>
                  </button>
                  <button (click)="openDeleteModal(deleteContent, perm)" class="btn btn-danger ml-2">
                    <ng-container i18n="Delete"> Delete</ng-container>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-container>

  <p *ngIf="filteredPermissions && filteredPermissions.length === 0" i18n="No new requests.">No new requests.</p>
</div>

<ng-template #deleteContent let-modal>
  <ng-container *ngIf="permToDelete">
    <div class="modal-header">
      <h4 class="modal-title" i18n>Delete permission?</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <p *ngIf="!deleteLoading" i18n>
        Confirm deleting of the permission for carrier
        {{ permToDelete.carrier.name }}.
      </p>
      <div *ngIf="deleteLoading" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <button type="submit" class="btn" (click)="modal.close()" [disabled]="deleteLoading">
        <ng-container i18n="Cancel">Cancel</ng-container>
      </button>
      <button *ngIf="!deleteLoading" class="btn btn-danger" i18n (click)="Delete(permToDelete)">Delete</button>
      <div *ngIf="deleteLoading" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div></ng-container
  >
</ng-template>

<ng-template #manageContent let-modal>
  <ng-container *ngIf="permissionToManage">
    <div class="modal-header">
      <h4 class="modal-title" i18n>Manage access to warehouse {{ permissionToManage.warehouse.name }} for carrier {{ permissionToManage.carrier.name }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="type"><ng-container i18n>Select permission type</ng-container></label>
        <select id="type" name="type" class="form-control" [(ngModel)]="permissionToManage.type">
          <option [value]="PermissionType.ALL_DOORS" i18n>Allow all doors</option>
          <option [value]="PermissionType.ONLY_SPECIFIC_DOORS" i18n>Allow specific doors</option>
          <option [value]="PermissionType.ONLY_TWO_PHASE" i18n>Allow only two-phase reservations</option>
        </select>
      </div>

      <div *ngIf="permissionToManage.type == PermissionType.ONLY_SPECIFIC_DOORS">
        <p i18n class="m-0">Please select individual doors that carrier has access to.</p>
        <small i18n class="d-block mb-2"
          >Note: if you select all doors below, the carrier will not have access to new doors. If you'd like carrier to have access to all doors, select the
          option 'Allow all doors' inn the dropdown above.</small
        >

        <div class="form-group">
          <label>
            <ng-container i18n>Allowed doors</ng-container>
          </label>
          <ng-multiselect-dropdown
            class="ng-multiselect-dropdown"
            placeholder="All current doors for the warehouse"
            i18n-placeholder
            [settings]="doorsSettings"
            [data]="permissionToManage.warehouse.doors"
            [(ngModel)]="permissionToManageSelectedDoors"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <button type="submit" class="btn" (click)="modal.close()" [disabled]="acceptLoading">
        <ng-container i18n="Cancel">Cancel</ng-container>
      </button>
      <button *ngIf="!acceptLoading" class="btn btn-primary" i18n (click)="Accept()">Save and grant permission</button>
      <div *ngIf="acceptLoading" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div></ng-container
  >
</ng-template>
