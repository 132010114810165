import { DataTableColumn, DataTableEntry, DataTableEntryType, DataTableRowAction } from "../data-table/data-table.component";
import { DoorExcerptDto } from "./Door";
import { FileExcerptDto } from "./File";
import { Reservation } from "./Reservation";
import { ReservationField } from "./ReservationField";
import { UserExcerptDto } from "./User";
import { WarehouseExcerptDto } from "./Warehouse";

export class MyReservations {
  reservations: Reservation[];
  recurringReservations: Reservation[];
  twoPhaseReservations: Reservation[];
}

export enum ReservationDtoType {
  STANDARD,
  TWO_PHASE,
  RECURRING,
}

export interface ReservationDto {
  id: number;
  type: ReservationDtoType;
  standardReservationData: StandardReservationData;
  recurringReservationData: RecurringReservationData;
  data: ReservationField[];
  carrier?: UserExcerptDto;
  code: string | null;
  door: DoorExcerptDto | null;
  warehouse: WarehouseExcerptDto;
  files: FileExcerptDto[];
  createdAt: Date;
  yamasArrivalId: number | null;

  filterString: string;

  allowView: boolean;
  allowEdit: boolean;
  allowDelete: boolean;
  allowApprove: boolean;

  dataTableEntries: DataTableEntry[];
  actions: DataTableRowAction[];
}

export interface ReservationDtosByType {
  standardReservations: { columns: DataTableColumn[]; data: ReservationDto[] };
  twoPhaseReservations: { columns: DataTableColumn[]; data: ReservationDto[] };
}

export interface RecurringReservationData {
  recurrenceRule: string;
  fromDate: Date;
  toDate: Date;
}

interface StandardReservationData {
  date: Date;
  start: string;
  end: string;
  isFixed: boolean;
}
