import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "./core/auth.service";
import { CopyToDoorSettings } from "./door/door-details-page/door-details-page.component";
import { LocaleService } from "./locale.service";
import { MessageTranslationService } from "./message-translation.service";
import { Door, DoorFieldsFilter } from "./models/Door";
import { ReservationDto } from "./models/MyReservations";
import { PermissionStatus, PermissionType } from "./models/Permission";
import { ReservationField } from "./models/ReservationField";
import { ReservationService } from "./services/reservation.service";
import { RestService } from "./services/rest.service";
import { hasPermissionsForDoor } from "./services/support.service";
import { TimespanConverterService } from "./timespan-converter.service";

@Injectable({
  providedIn: "root",
})
export class DoorService {
  constructor(
    private http: RestService,
    private timespanConverter: TimespanConverterService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    private locale: LocaleService,
    private auth: AuthService,
    private reservationService: ReservationService,
    private router: Router
  ) {}

  async getDoor(id: number) {
    try {
      const door = await this.http.post<Door>(`api/door/get/${id}`, {}).toPromise();

      if (door?.availability?.minimumNotice) {
        door.availability.minimumNotice = this.timespanConverter.convertFromTimespan(door.availability.minimumNotice);
      }

      return door;
    } catch (e) {
      this.toast.error(this.msgT.fetchDoorError());
      console.log(e);
    }

    return null;
  }

  async getDoorReservationFields(doorId: number) {
    try {
      return await this.http.get<ReservationField[]>(`api/settings/getDoorReservationFields/${doorId}`).toPromise();
    } catch (e) {
      this.toast.error(this.msgT.fetchDoorError());
      console.log(e);
    }

    return [];
  }

  async getDoorsOfWarehouse(warehouseId: number) {
    try {
      return await this.http.get<Door[]>(`api/door/getDoors/${warehouseId}`, {}).toPromise();
    } catch (e) {
      this.toast.error(this.msgT.fetchingDoorsError());
      console.log(e);
    }

    return [];
  }

  async getReservationsForDoor(doorId: number, from: Date, to: Date): Promise<ReservationDto[]> {
    try {
      const reservations = await this.http
        .post<ReservationDto[]>(`api/door/reservationsOnDoor/${doorId}`, {
          from: this.timespanConverter.normalizeDate(from),
          to: this.timespanConverter.normalizeDate(to),
        })
        .toPromise();

      reservations.forEach((r) => {
        this.reservationService.assignPermissionsToReservation(r, this.auth.IsCarrier(), false);
      });

      return reservations;
    } catch (e) {
      this.toast.error(this.msgT.fetchReservedError());
      console.log(e);
    }

    return [];
  }

  isCurrentUserDoorAdmin(door: Door) {
    if (door == null) {
      return false;
    }

    return this.auth.IsWarehouseAdmin() && this.auth.loggedInUser.company.id == door.warehouse.companyId;
  }

  canCurrentUserCreateReservationOnDoor(door: Door) {
    if (door == null) {
      return false;
    }

    let canCreateReservation = false;
    if (door.warehouse.permissions && door.warehouse.permissions.length > 0 && door.availability.timeWindows.length > 0) {
      const perm = door.warehouse.permissions[0];
      if (perm.carrierId == this.auth.loggedInUser.id && perm.status == PermissionStatus.Accepted && hasPermissionsForDoor(perm, door.id)) {
        canCreateReservation = true;
      }
    }

    return canCreateReservation;
  }

  navigateToDoorEdit(id: number) {
    this.router.navigate(["edit-door", id]);
  }

  async copyDoor(id: number) {
    try {
      const door = await this.http.post<Door>(`api/door/copy/${id}`, {}).toPromise();
      this.toast.success(this.msgT.copyDoorSuccess());
      return door;
    } catch (e) {
      this.toast.error(this.msgT.copyDoorError());
      console.log(e);
    }

    return null;
  }

  async deleteDoor(id: number) {
    try {
      await this.http.post(`api/door/delete/${id}`, {}).toPromise();
      this.toast.success(this.msgT.deleteDoorSuccess());
      return true;
    } catch (e) {
      this.toast.error(this.msgT.deleteDoorError());
      console.log(e);
    }

    return false;
  }

  async editDoor(door: Door) {
    if (door.availability) {
      const availability = door.availability;
      const keys = ["minimumNotice", "maxArrivalInacurracy", "workTimeFrom", "workTimeTo"];
      for (const key of keys) {
        if (availability[key]) {
          availability[key] = this.timespanConverter.convertToTimespan(availability[key]);
        }
      }
    }

    try {
      await this.http.post(`api/door/update`, door).toPromise();
      this.toast.success(this.msgT.updateDoorSuccess());
      return true;
    } catch (e) {
      this.toast.error(this.msgT.updateDoorError());
      console.log(e);
    }

    return false;
  }

  doReservationFieldsMatchDoor(fieldsFilters: DoorFieldsFilter[], fields: ReservationField[]): boolean {
    if (fieldsFilters.length === 0) {
      return true;
    }

    for (let i = 0; i < fieldsFilters.length; i++) {
      const filter = fieldsFilters[i];
      const field = fields.find((f) => f.id === filter.reservationFieldId);
      if (!field) {
        continue;
      }

      const valueMatches = filter.values.includes(field.value);
      if (!valueMatches) {
        return false;
      }
    }

    return true;
  }

  async getAllCompanyDoors(): Promise<Door[]> {
    try {
      return await this.http.post<Door[]>(`api/door/getAllCompanyDoors`, {}).toPromise();
    } catch (e) {
      console.log(e);
    }

    return [];
  }

  async copyDoorSettingsToAnotherDoor(settings: CopyToDoorSettings, fromDoor: Door, toDoors: Door[]) {
    try {
      await this.http
        .post(`api/door/copyToAnotherDoor/${fromDoor.id}`, {
          settings,
          toDoorIds: toDoors.map((d) => d.id),
        })
        .toPromise();
      this.toast.success(this.msgT.copySuccess());
      return true;
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
      console.log(e);
    }

    return false;
  }
}
