import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { RestService } from "../services/rest.service";

@Component({
  selector: "app-profile-owl-company-settings",
  templateUrl: "./profile-owl-company-settings.component.html",
  styleUrls: ["./profile-owl-company-settings.component.css"],
})
export class ProfileOwlCompanySettingsComponent implements OnInit {
  canEdit: boolean;

  disableTwoPhaseReservations: boolean;

  constructor(private http: RestService, private auth: AuthService, private toast: ToastrService, private msgT: MessageTranslationService) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.disableTwoPhaseReservations = this.auth.loggedInUser.company.disableTwoPhaseReservations;
      this.canEdit = this.auth.IsWarehouseAdmin();
    });
  }

  changeCompanyData() {
    this.http
      .post(`api/settings/setOWLCompanySettings`, {
        disableTwoPhaseReservations: this.disableTwoPhaseReservations,
      })
      .subscribe(
        (d) => {
          this.toast.success(this.msgT.updateSuccess());
        },
        (error) => {
          console.log("Change OWL company data error");
          console.log(error.error);
          this.toast.error(this.msgT.unknownError());
        }
      );
  }
}
