export const environment = {
    production: true,
    carriersRealmName: "carriers",
    dashboardUrl: "https://dashboard-stage.warehousing.omniopti.eu",
    yamasUrl: "https://yamas-stage.warehousing.omniopti.eu",
    authServerUrl: "https://login.warehousing.omniopti.eu/auth",
    languages: [
      {
        name: "SLO 🇸🇮",
        link: "https://sl.owl-stage.warehousing.omniopti.eu",
        code: "sl",
      },
      {
        name: "ENG 🇬🇧",
        link: "https://owl-stage.warehousing.omniopti.eu",
        code: "en-US",
      },
    ],
    apiUrl: "https://api.owl-stage.warehousing.omniopti.eu",
  };