import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-address-autocomplete",
  templateUrl: "./address-autocomplete.component.html",
  styleUrls: ["./address-autocomplete.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AddressAutocompleteComponent implements AfterViewInit {
  @Output() onPlaceSelected: EventEmitter<{ lat: number; lng: number }> = new EventEmitter<{ lat: number; lng: number }>();

  @Input() address: string = "";
  @Output() addressChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild("search")
  public searchElementRef: ElementRef;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    const google = (window as any).google;

    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        const place = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        this.onPlaceSelected.emit({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });

        this.addressChange.emit(place.formatted_address);
      });
    });
  }
}
