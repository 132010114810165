import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";

import { DataTableColumn } from "src/app/data-table/data-table.component";
import { ReservationDto, ReservationDtoType } from "src/app/models/MyReservations";
import { ReservationService } from "src/app/services/reservation.service";
import { WarehouseService } from "src/app/services/warehouse.service";
import {
  getDefaultReservationListArchiveSearchBarFilter,
  MultiSelectOption,
  ReservationListSearchBarFilter,
} from "../reservation-list-search-bar/reservation-list-search-bar.component";

@Component({
  selector: "app-reservation-archive",
  templateUrl: "./reservation-archive.component.html",
  styleUrls: ["./reservation-archive.component.css"],
})
export class ReservationArchiveComponent implements OnInit {
  ReservationDtoType = ReservationDtoType;

  isCarrier: boolean;

  warehousesOptions: MultiSelectOption[] = [];
  doorsOptions: MultiSelectOption[] = [];

  loading = true;
  loadingCsv = false;

  reservations: ReservationDto[] = [];
  filteredReservations: ReservationDto[] = [];
  reservationColumns: DataTableColumn[] = [];
  selectedReservationsSize: number = 0;

  filter: ReservationListSearchBarFilter = getDefaultReservationListArchiveSearchBarFilter();

  selectedPageNumber: number = 1;

  selectedTableLabels: number[] = [];

  constructor(private warehouseService: WarehouseService, private reservationService: ReservationService, private auth: AuthService) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.isCarrier = this.auth.IsCarrier();
      this.doFetch();
    });
  }

  async doFetch(): Promise<void> {
    await this.fetchWarehouseAndDoorOptions();
    await this.fetchReservations();
    this.loading = false;
    this.filterReservations();
  }

  async fetchReservations(): Promise<void> {
    this.loading = true;

    try {
      const reservations = await this.reservationService.fetchAllReservationsFromArchive();
      this.reservations = reservations.data;
      this.reservationColumns = reservations.columns;
    } catch (e) {
      console.log(e);
    }

    this.loading = false;
  }

  private async fetchWarehouseAndDoorOptions(): Promise<void> {
    const multiSelectOptions = await this.warehouseService.fetchWarehouseAndDoorMultiselectOptions(this.isCarrier);
    this.warehousesOptions = multiSelectOptions.warehousesOptions;
    this.doorsOptions = multiSelectOptions.doorsOptions;
  }

  async downloadCsv() {
    this.loadingCsv = true;

    this.reservationService.downloadArchiveCsv(this.filter, this.selectedPageNumber, this.selectedTableLabels);

    this.loadingCsv = false;
  }

  filterReservations() {
    this.filteredReservations = this.reservationService.filterReservationsByFilter(this.reservations, this.filter);
  }
}
