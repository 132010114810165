<div class="my-1" style="font-size: 19px" *ngIf="reservationLink">
  <span i18n>Reservation link: </span>
  <a target="_blank" style="color: black; text-decoration: underline" href="{{ reservationLink }}">{{ reservationLink }}</a>
  <span (click)="copyReservationLink()" style="text-decoration: underline; margin-left: 10px; cursor: pointer" i18n>Copy link</span>
</div>
<div class="my-1" style="font-size: 19px" *ngIf="twoPhaseReservationLink">
  <span i18n>Two-phase reservation link: </span>
  <a target="_blank" style="color: black; text-decoration: underline" href="{{ twoPhaseReservationLink }}">{{ twoPhaseReservationLink }}</a>
  <span (click)="copyTwoPhaseReservationLink()" style="text-decoration: underline; margin-left: 10px; cursor: pointer" i18n>Copy link</span>
</div>
