<div class="container app-container">
  <div class="d-flex align-items-center justify-content-center" *ngIf="loadingDoor">
    <app-spinner></app-spinner>
  </div>

  <ng-container *ngIf="!loadingDoor && door">
    <h3 class="app-heading">
      <ng-container i18n>Door: {{ door.name }}</ng-container>
    </h3>

    <div *ngxPermissionsOnly="[Role.COMPANY_ADMIN, Role.OWL_WAREHOUSE]" class="row d-flex justify-content-center mb-2">
      <div class="mr-2">
        <button (click)="navigateToWarehouseReservation()" i18n class="btn btn-info">Book warehouse {{ door.warehouse.name }}</button>
      </div>

      <ng-container *ngxPermissionsOnly="[Role.COMPANY_ADMIN]" class="row d-flex justify-content-center mb-2">
        <div class="mr-2">
          <button (click)="navigateToDoorEdit()" i18n class="btn btn-secondary">Edit</button>
        </div>
        <div class="mx-2">
          <button (click)="openModal(confirmCopyModal)" i18n class="btn btn-primary">Copy</button>
        </div>
        <div class="mx-2">
          <button (click)="openModal(confirmCopyToAnotherDoorModal); fetchAllCompanyDoors()" i18n class="btn btn-primary">Copy settings to another door</button>
        </div>
        <div class="ml-2">
          <button (click)="openModal(confirmDeleteModal)" i18n class="btn btn-danger">Delete</button>
        </div>
      </ng-container>
    </div>

    <app-warehouse-breadcrumbs [door]="door"></app-warehouse-breadcrumbs>

    <table class="table table-bordered mt-2">
      <tbody>
        <tr *ngIf="door.description">
          <th i18n>Description</th>
          <td style="white-space: pre-line">{{ door.description }}</td>
        </tr>
        <tr>
          <th i18n>Minimum notice before reservation</th>
          <td>{{ door.availability.minimumNotice }}</td>
        </tr>
        <tr>
          <th i18n>Work time</th>
          <td>{{ door.availability.workTimeFrom }} - {{ door.availability.workTimeTo }}</td>
        </tr>
        <tr>
          <th i18n>Maximum arrival inaccuracy</th>
          <td>{{ door.availability.maxArrivalInacurracy }}</td>
        </tr>
        <tr>
          <th i18n>Reservation duration</th>
          <td>
            <ng-container [ngSwitch]="door.properties.type" class="text-secondary small">
              <span *ngSwitchCase="reservationType.Fixed">
                <ng-container i18n="Every day">Every day</ng-container>
              </span>
              <span *ngSwitchCase="reservationType.Calculated">
                <span class="d-block" i18n="Calculated from pallets">Calculated from pallets</span>
                <span class="d-block" i18n>Base time: {{ door.properties.baseTime }}</span>
                <span class="d-block" i18n>Time per pallet: {{ door.properties.timePerPallet }}</span>
              </span>
              <span *ngSwitchCase="reservationType.Free">
                <ng-container i18n="Free"> Free</ng-container>
              </span>
            </ng-container>
          </td>
        </tr>
        <tr>
          <th i18n>Time windows</th>
          <td>
            <div *ngFor="let timeWindow of door.availability.timeWindows">{{ timeWindow.start }} - {{ timeWindow.end }}</div>
          </td>
        </tr>
      </tbody>
    </table>

    <app-door-availability-calendar
      *ngxPermissionsOnly="[Role.COMPANY_ADMIN, Role.OWL_WAREHOUSE]"
      [door]="door"
      [dragToSelect]="false"
      [forReading]="true"
    ></app-door-availability-calendar>
  </ng-container>
</div>

<ng-template #confirmCopyModal let-modal>
  <app-confirmation-modal
    [isLoading]="loadingCopy"
    header="Copy door?"
    i18n-header
    subHeader="Confirm copy door"
    i18n-subHeader
    cancelButtonText="Cancel"
    i18n-cancelButtonText
    confirmButtonText="Copy"
    i18n-confirmButtonText
    confirmButtonClass="btn-info"
    (onCancel)="modalService.dismissAll()"
    (onConfirm)="copyDoor()"
  >
  </app-confirmation-modal>
</ng-template>

<ng-template #confirmDeleteModal let-modal>
  <app-confirmation-modal
    [isLoading]="loadingDelete"
    header="Delete door?"
    i18n-header
    subHeader="Confirm deleting door"
    i18n-subHeader
    cancelButtonText="Cancel"
    i18n-cancelButtonText
    confirmButtonText="Delete"
    i18n-confirmButtonText
    confirmButtonClass="btn-danger"
    (onCancel)="modalService.dismissAll()"
    (onConfirm)="deleteDoor()"
  >
  </app-confirmation-modal>
</ng-template>

<ng-template #confirmCopyToAnotherDoorModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n>Copy settings to another door</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()" [disabled]="isCopyingToAnotherDoor">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <h5 i18n>Please select the settings to copy.</h5>
    <small i18n>Note: selected settings will be fully replaced.</small>

    <div>
      <div class="form-group">
        <label>
          <ng-container i18n>Copy to doors</ng-container>
        </label>
        <ng-multiselect-dropdown
          class="ng-multiselect-dropdown"
          placeholder="Select doors to copy to"
          i18n-placeholder
          [settings]="doorsSettings"
          [data]="allDoors"
          [(ngModel)]="copyToDoors"
        >
        </ng-multiselect-dropdown>
      </div>

      <app-app-checkbox [(value)]="copytoDoorSettings.name" name="copy-to-door-name">
        <ng-container i18n>Name</ng-container>
      </app-app-checkbox>

      <app-app-checkbox [(value)]="copytoDoorSettings.description" name="copy-to-door-description">
        <ng-container i18n>Description</ng-container>
      </app-app-checkbox>

      <app-app-checkbox [(value)]="copytoDoorSettings.emailAttachments" name="copy-to-door-emailAttachments">
        <ng-container i18n>Email attachments</ng-container>
      </app-app-checkbox>

      <app-app-checkbox [(value)]="copytoDoorSettings.minimumArrivalInaccuracy" name="copy-to-door-minimumArrivalInaccuracy">
        <ng-container i18n>Minimum arrival inaccuracy</ng-container>
      </app-app-checkbox>

      <app-app-checkbox [(value)]="copytoDoorSettings.palletsPerDay" name="copy-to-door-palletsPerDay">
        <ng-container i18n>Pallets per day</ng-container>
      </app-app-checkbox>

      <app-app-checkbox [(value)]="copytoDoorSettings.reservationDurationSettings" name="copy-to-door-reservationDurationSettings">
        <ng-container i18n>Reservation duration settings</ng-container>
      </app-app-checkbox>

      <app-app-checkbox [(value)]="copytoDoorSettings.reservationFields" name="copy-to-door-reservationFields">
        <ng-container i18n>Reservation fields</ng-container>
      </app-app-checkbox>

      <app-app-checkbox [(value)]="copytoDoorSettings.timeWindows" name="copy-to-door-timeWindows">
        <ng-container i18n>Time windows</ng-container>
      </app-app-checkbox>

      <app-app-checkbox [(value)]="copytoDoorSettings.workDay" name="copy-to-door-workDay">
        <ng-container i18n>Work day</ng-container>
      </app-app-checkbox>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn" (click)="modal.close()">
      <ng-container i18n>Cancel</ng-container>
    </button>
    <button type="button" class="btn btn-primary" (click)="confirmCopyToAnotherDoor()" [disabled]="copyToDoors.length === 0 || isCopyingToAnotherDoor">
      <ng-container i18n>Copy</ng-container>
    </button>
  </div>
</ng-template>
