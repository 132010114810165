import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { AuthService } from "src/app/core/auth.service";
import { CompanyReservationFlowV2Component } from "../company-reservation-flow-v2/company-reservation-flow-v2.component";

@Component({
  selector: "app-company-approve-two-phase",
  templateUrl: "./company-approve-two-phase.component.html",
  styleUrls: ["./company-approve-two-phase.component.css"],
})
export class CompanyApproveTwoPhaseComponent implements OnInit {
  editingReservationId: number | null = null;
  editingReservationCode: string | null = null;

  @ViewChild("reservationFlow") reservationFlow: CompanyReservationFlowV2Component;

  constructor(private auth: AuthService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.route.paramMap.subscribe(async (params) => {
        this.editingReservationId = Number(params.get("reservationId"));
        this.editingReservationCode = params.get("code");
      });
    });
  }

  @HostListener("window:beforeunload")
  public canDeactivate(): Observable<boolean> | boolean {
    return this.reservationFlow.canDeactivate();
  }
}
