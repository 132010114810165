<app-accordion-with-content i18n-label label="Contact emails">
  <small i18n class="mb-2 d-block">Configure email addresses that will receive every email.</small>

  <div class="mb-2">
    <ng-container *ngIf="contactMails.length === 0"
      ><small i18n><i>No contact mails yet.</i></small></ng-container
    >

    <ng-container *ngIf="contactMails.length > 0">
      <div class="d-flex justify-content-start align-items-center" *ngFor="let mail of contactMails">
        <p class="m-0">{{ mail.email }}</p>

        <fa-icon
          (click)="RemoveContactMail(mail.id)"
          style="color: red"
          class="ml-2 c-pointer"
          [icon]="faTimes"
          *ngxPermissionsOnly="[Role.COMPANY_ADMIN, Role.OWL_CARRIER]"
        ></fa-icon>
      </div>
    </ng-container>
  </div>

  <div class="form-group" *ngxPermissionsOnly="[Role.COMPANY_ADMIN, Role.OWL_CARRIER]">
    <label for="oldPassword"><ng-container i18n="Old password">Add new contact mail</ng-container></label>

    <div class="d-flex align-items-center">
      <input type="email" class="form-control mr-1 flex-grow-1" (keydown.enter)="AddContactMail()" [(ngModel)]="newContactMail" />

      <button class="ml-1 btn btn-primary" style="width: 250px" (click)="AddContactMail()" [disabled]="newContactMail.trim().length === 0">
        <ng-container i18n>Add contact mail</ng-container>
      </button>
    </div>
  </div>
</app-accordion-with-content>
