import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import * as HtmlDurationPicker from "html-duration-picker";
import { DateService } from "src/app/date.service";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-app-datetime-input",
  templateUrl: "./app-datetime-input.component.html",
  styleUrls: ["./app-datetime-input.component.css"],
})
export class AppDatetimeInputComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() label: string;

  date: Date;
  time: string;

  @Input() dateTime: Date;
  @Output() dateTimeChange = new EventEmitter<Date>();

  constructor(private dateService: DateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateTime) {
      const splitData = this.dateService.splitDateAndTime(this.dateTime);
      this.date = splitData.date;
      this.time = splitData.time;
    }
  }

  ngAfterViewInit() {
    HtmlDurationPicker.init();
  }

  onDateChange(date: Date) {
    this.date = date;
    this.onDateOrTimeChange();
  }

  onTimeChange(time: string) {
    this.time = time;
    this.onDateOrTimeChange();
  }

  onDateOrTimeChange() {
    const newDateTime = this.dateService.mergeDateAndTime(this.date, this.time);
    this.dateTimeChange.emit(newDateTime);
  }
}
