<div class="app-container arrival-table-container">
  <h1 class="app-heading" i18n>Reservations</h1>

  <app-reservation-list-search-bar
    [warehouses]="warehousesOptions"
    [doors]="doorsOptions"
    [filter]="filter"
    (refresh)="doRefresh()"
    (filterChange)="filter = $event; filterReservations()"
    [isArchive]="false"
  ></app-reservation-list-search-bar>

  <app-reservation-list-tabs [selectedTab]="selectedTab" (selectedTabChange)="onSelectedTabChange($event)"></app-reservation-list-tabs>

  <app-reservation-table
    [reservations]="paginatedReservations"
    [loading]="loading"
    [type]="selectedTab"
    [isCarrier]="isCarrier"
    (delete)="onReservationDelete($event)"
    [allReservationColumns]="selectedReservationsAllColumns"
    [tableId]="'normal'"
  ></app-reservation-table>
</div>
