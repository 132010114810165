import { Inject, Injectable, LOCALE_ID } from "@angular/core";

const TESTING_DOMAINS = ["owl-test.omniopti.eu", "owl-dev.omniopti.eu"];
@Injectable({
  providedIn: "root",
})
export class LocaleService {
  isTestingEnv = false;

  constructor(@Inject(LOCALE_ID) public locale: string) {
    this.checkIfTestingEnv();
  }

  checkIfTestingEnv() {
    const hostname = window.location.hostname.toLowerCase();
    this.isTestingEnv = TESTING_DOMAINS.some((domain) => hostname.includes(domain.toLowerCase()));
  }
}
