<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="!auth.notPermittedToAccessProduct">
  <a class="navbar-brand" [routerLink]="['/']">
    <img src="/assets/logo.png" alt="Home page" width="30" height="30" class="d-inline-block align-top" />
    OWL
  </a>
  <button class="navbar-toggler" type="button" (click)="toggleNavbar = !toggleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngbCollapse]="!toggleNavbar">
    <ul class="navbar-nav mr-auto">
      <ng-container *ngIf="auth.IsLoggedIn()">
        <ng-container *ngIf="auth.IsWarehouse()">
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/my-warehouses']">
              <ng-container i18n="My Warehouses">My Warehouses</ng-container>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/my-reservations']">
              <ng-container i18n="My Arrivals">My Arrivals</ng-container>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/manage/permissions']">
              <ng-container i18n="Manage Permissions">Manage Permissions</ng-container>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/archive']">
              <ng-container i18n="Archive">Archive</ng-container>
            </a>
          </li>
          <li class="nav-item" *ngIf="auth.IsWarehouseAdmin()">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/stats']">
              <ng-container i18n>Stats</ng-container>
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="auth.IsCarrier()">
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/my-warehouses']">
              <ng-container i18n="My Warehouses">My Warehouses</ng-container>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/warehouses']">
              <ng-container i18n="Warehouses">Warehouses</ng-container>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/my-reservations']">
              <ng-container i18n="My Reservations">My Reservations</ng-container>
            </a>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!auth.IsLoggedIn()">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/login']">
            <ng-container i18n="Log in">Log in</ng-container>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/register']">
            <ng-container i18n>Carrier registration</ng-container>
          </a>
        </li>
      </ng-container>
    </ul>

    <ul class="form-inline my-2 my-lg-0 navbar-nav">
      <ng-container *ngIf="auth.IsLoggedIn()">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/profile']">
            <ng-container>
              {{ auth.loggedInUser.email }}
            </ng-container>
          </a>
        </li>

        <div class="col-auto nav-item">
          <button class="btn btn-secondary" (click)="Logout()">
            <ng-container i18n="Log out">Log out</ng-container>
          </button>
        </div>

        <app-navbar-language-picker></app-navbar-language-picker>
      </ng-container>
    </ul>
  </div>
</nav>
