import { Injectable, Inject, LOCALE_ID } from "@angular/core";
import { EmailTemplatePlaceholder, EmailTemplateType } from "./models/EmailTemplate";
import { ReservationFieldSpecialMeaningField } from "./models/ReservationField";
import { UserMailSubjectTextType } from "./models/User";

@Injectable({
  providedIn: "root",
})
export class MessageTranslationService {
  translations;

  constructor(@Inject(LOCALE_ID) public locale: string) {
    if (locale === "sl") {
      this.translations = this.translationsSl;
    } else {
      this.translations = this.translationsEn;
    }
  }

  private userMailSubjectTextTypeEn: Record<UserMailSubjectTextType, string> = {
    [UserMailSubjectTextType.MAIL_ROOT]: "Mail root",
    [UserMailSubjectTextType.COMPANY_NAME]: "Company name",
    [UserMailSubjectTextType.RESERVATION_DATE]: "Reservation date",
    [UserMailSubjectTextType.RESERVATION_TYPE_SUBJECT]: "Reservation type subject",
  };

  private userMailSubjectTextTypeSl: Record<UserMailSubjectTextType, string> = {
    [UserMailSubjectTextType.MAIL_ROOT]: "Koren zadeve emaila",
    [UserMailSubjectTextType.COMPANY_NAME]: "Podjetje",
    [UserMailSubjectTextType.RESERVATION_DATE]: "Datum rezervacije",
    [UserMailSubjectTextType.RESERVATION_TYPE_SUBJECT]: "Zadeva za tip rezervacije",
  };

  private specialMeaningsTextsSl: Record<ReservationFieldSpecialMeaningField, string> = {
    [ReservationFieldSpecialMeaningField.YAMAS_DRIVER_CODE]: "Koda voznika",
    [ReservationFieldSpecialMeaningField.DRIVER_NAME]: "Ime voznika",
    [ReservationFieldSpecialMeaningField.DRIVER_SURNAME]: "Priimek voznika",
    [ReservationFieldSpecialMeaningField.TRANSPORT_COMPANY]: "Transportno podjetje",
    [ReservationFieldSpecialMeaningField.TRUCK_REGISTRATION_NUMBER]: "Registracijska številka vozila",
    [ReservationFieldSpecialMeaningField.TRANSPORT_COMPANY_COUNTRY]: "Država transportnega podjetja",
    [ReservationFieldSpecialMeaningField.LOADING_COUNTRY]: "Država nakladanja",
    [ReservationFieldSpecialMeaningField.EMAIL]: "Email",
    [ReservationFieldSpecialMeaningField.NUMBER_OF_PALLETS]: "Število palet",
    [ReservationFieldSpecialMeaningField.NUMBER_OF_HALF_PALLETS]: "Število polpalet",
  };

  private specialMeaningsTextsEn: Record<ReservationFieldSpecialMeaningField, string> = {
    [ReservationFieldSpecialMeaningField.YAMAS_DRIVER_CODE]: "Driver code",
    [ReservationFieldSpecialMeaningField.DRIVER_NAME]: "Driver name",
    [ReservationFieldSpecialMeaningField.DRIVER_SURNAME]: "Driver surname",
    [ReservationFieldSpecialMeaningField.TRANSPORT_COMPANY]: "Transport company",
    [ReservationFieldSpecialMeaningField.TRUCK_REGISTRATION_NUMBER]: "Registration number",
    [ReservationFieldSpecialMeaningField.TRANSPORT_COMPANY_COUNTRY]: "Transport company country",
    [ReservationFieldSpecialMeaningField.LOADING_COUNTRY]: "Loading country",
    [ReservationFieldSpecialMeaningField.EMAIL]: "Email",
    [ReservationFieldSpecialMeaningField.NUMBER_OF_PALLETS]: "Number of pallets",
    [ReservationFieldSpecialMeaningField.NUMBER_OF_HALF_PALLETS]: "Number of half-pallets",
  };

  private emailTemplateTypeTextsSl: Record<EmailTemplateType, string> = {
    [EmailTemplateType.RESERVATION_CREATED]: "Rezervacija ustvarjena",
    [EmailTemplateType.RECCURING_RESERVATION_CREATED]: "Ponavljajoča rezervacija ustvarjena",
    [EmailTemplateType.TWO_PHASE_RESERVATION_CREATED]: "Dvofazna rezervacija ustvarjena",
    [EmailTemplateType.RESERVATION_UPDATED]: "Rezervacija spremenjena",
    [EmailTemplateType.RECCURING_RESERVATION_UPDATED]: "Ponavljajoča rezervacija spremenjena",
    [EmailTemplateType.TWO_PHASE_RESERVATION_UPDATED]: "Dvofazna rezervacija spremenjena",
    [EmailTemplateType.RESERVATION_DELETED]: "Rezervacija izbrisana",
    [EmailTemplateType.RECCURING_RESERVATION_DELETED]: "Ponavljajoča rezervacija izbrisana",
    [EmailTemplateType.TWO_PHASE_RESERVATION_DELETED]: "Dvofazna rezervacija izbrisana",
    [EmailTemplateType.TWO_PHASE_RESERVATION_CONFIRMED]: "Dvofazna rezervacija potrjena",
  };

  private emailTemplateTypeTextsEn: Record<EmailTemplateType, string> = {
    [EmailTemplateType.RESERVATION_CREATED]: "Reservation created",
    [EmailTemplateType.RECCURING_RESERVATION_CREATED]: "Recurring reservation created",
    [EmailTemplateType.TWO_PHASE_RESERVATION_CREATED]: "Two-phase reservation created",
    [EmailTemplateType.RESERVATION_UPDATED]: "Reservation updated",
    [EmailTemplateType.RECCURING_RESERVATION_UPDATED]: "Recurring reservation updated",
    [EmailTemplateType.TWO_PHASE_RESERVATION_UPDATED]: "Two-phase reservation updated",
    [EmailTemplateType.RESERVATION_DELETED]: "Reservation deleted",
    [EmailTemplateType.RECCURING_RESERVATION_DELETED]: "Recurring reservation deleted",
    [EmailTemplateType.TWO_PHASE_RESERVATION_DELETED]: "Two-phase reservation deleted",
    [EmailTemplateType.TWO_PHASE_RESERVATION_CONFIRMED]: "Two-phase reservation confirmed",
  };

  private emailTemplatePlaceholdersEn: Record<EmailTemplatePlaceholder, string> = {
    recipient_name: "Recipient name (carrier)",
    warehouse_company_name: "Warehouse company name",
    warehouse_name: "Warehouse name",
    warehouse_address: "Warehouse address",
    warehouse_email: "Warehouse email",
    warehouse_phone: "Warehouse phone",
    warehouse_location: "Warehouse location on a map",
    warehouse_worktime_from: "Warehouse worktime start",
    warehouse_worktime_to: "Warehouse worktime end",
    warehouse_max_arrival_inaccuracy: "Warehouse maximum arrival inaccuracy",
    carrier_company_name: "Carrier company name",
    carrier_address: "Carrier address",
    carrier_contact_person_name: "Carrier contact person name",
    carrier_phone: "Carrier phone",
    reservation_date: "Date of reservation",
    reservation_time: "Time of reservation (if present)",
    reservation_data: "Reservation data",
    reservation_code: "Reservation code",
    reservation_link: "Reservation link",
    is_combination: "Country combination",
  };

  private emailTemplatePlaceholdersSl: Record<EmailTemplatePlaceholder, string> = {
    recipient_name: "Ime prejemnika (prevoznik)",
    warehouse_company_name: "Ime podjetja skladišča",
    warehouse_name: "Ime skladišča",
    warehouse_address: "Naslov skladišča",
    warehouse_email: "Email skladišča",
    warehouse_phone: "Telefon skladišča",
    warehouse_location: "Lokacija skladišča na zemljevidu",
    warehouse_worktime_from: "Delovni čas skladišča (začetek)",
    warehouse_worktime_to: "Delovni čas skladišča (konec)",
    warehouse_max_arrival_inaccuracy: "Dovoljena zamuda prihoda",
    carrier_company_name: "Naziv podjetja prevoznika",
    carrier_address: "Naslov prevoznika",
    carrier_contact_person_name: "Kontaktna oseba prevoznika",
    carrier_phone: "Telefon prevoznika",
    reservation_date: "Datum rezervacije",
    reservation_time: "Čas rezervacije",
    reservation_data: "Podatki v rezervaciji",
    reservation_code: "Koda rezervacije",
    reservation_link: "Povezava do rezervacije",
    is_combination: "Kombinacija držav",
  };

  private checkboxDropdownTextEn = {
    SELECT_ALL: "Select all",
    SELECT_NONE: "Unselect all",
    FILTERS: "Filters",
  };

  private checkboxDropdownTextSl = {
    SELECT_ALL: "Izberi vse",
    SELECT_NONE: "Odznači vse",
    FILTERS: "Filtri",
  };

  private staticDataFieldNamesEn = {
    CODE: "ID",
    WAREHOUSE: "Warehouse",
    DOOR: "Door",
    DATE: "Date",
    CARRIER: "Carrier",
    COMPANY: "Company",
    RECURRING_RESERVATION_RULE: "Recurrence rule",
    CREATED_AT: "Created at",
  };

  private staticDataFieldNamesSl = {
    CODE: "ID",
    WAREHOUSE: "Skladišče",
    DOOR: "Vrata",
    DATE: "Čas",
    CARRIER: "Ime prevoznika",
    COMPANY: "Prevoznik",
    RECURRING_RESERVATION_RULE: "Pravilo ponavljanja",
    CREATED_AT: "Ustvarjeno",
  };

  private recurrenceRulesEn = {
    EVERY_DAY: "Every day",
    EVERY_SUNDAY: "Every Sunday",
    EVERY_MONDAY: "Every Monday",
    EVERY_TUESDAY: "Every Tuesday",
    EVERY_WEDNESDAY: "Every Wednesday",
    EVERY_THURDAY: "Every Thursday",
    EVERY_FRIDAY: "Every Friday",
    EVERY_SATURDAY: "Every Saturday",
  };

  private recurrenceRulesSl = {
    EVERY_DAY: "Vsak dan",
    EVERY_SUNDAY: "Vsako nedeljo",
    EVERY_MONDAY: "Vsak ponedeljek",
    EVERY_TUESDAY: "Vsak torek",
    EVERY_WEDNESDAY: "Vsako sredo",
    EVERY_THURDAY: "Vsak četrtek",
    EVERY_FRIDAY: "Vsak petek",
    EVERY_SATURDAY: "Vsako soboto",
  };

  private reservationActionNamesEn = {
    DETAILS: "Details",
    EDIT: "Edit",
    DELETE: "Delete",
    APPROVE: "Approve",
  };

  private reservationActionNamesSl = {
    DETAILS: "Podrobnosti",
    EDIT: "Uredi",
    DELETE: "Izbriši",
    APPROVE: "Potrdi",
  };

  private reservationConfirmNamesEn = {
    CREATE: "Create",
    EDIT: "Edit",
    APPROVE: "Approve",
  };

  private reservationConfirmNamesSl = {
    CREATE: "Ustvari",
    EDIT: "Uredi",
    APPROVE: "Potrdi",
  };

  private bookButtonTextEn = {
    APPROVE: "Approve",
    TWO_PHASE: "Book two-phase",
    EDIT: "Edit",
    BOOK: "Book",
  };

  private bookButtonTextSl = {
    APPROVE: "Potrdi",
    TWO_PHASE: "Rezerviraj dvofazno",
    EDIT: "Uredi",
    BOOK: "Rezerviraj",
  }

  private reservationFieldErrorMessageEn = {
    FIELD_REQUIRED: "This field is required.",
    FIELD_ONE_OF_VALUES: "This field must be one of: ",
    FIELD_NUMBERS_MULTIPLE: "These fields must be numbers",
    FIELD_NUMBERS_SINGLE: "This field must be a number",
    FIELD_MORE_MULTIPLE: "These fields must be larger of egual than ",
    FIELD_MORE_SINGLE: "This field must be larger or equal than ",
    FIELD_LESS_MULTIPLE: "These fields must be smaller or equal than ",
    FIELD_LESS_SINGLE: "This field must be smaller or equal than",
  }

  private reservationFieldErrorMessageSl = {
    FIELD_REQUIRED: "To polje je obvezno.",
    FIELD_ONE_OF_VALUES: "To polje mora biti ena od: ",
    FIELD_NUMBERS_MULTIPLE: "Ta polja morajo vsebovati števila",
    FIELD_NUMBERS_SINGLE: "To polje mora vsebovati število.",
    FIELD_MORE_MULTIPLE: "Ta polja morajo biti večja ali enaka ",
    FIELD_MORE_SINGLE: "To polje mora biti večje ali enako ",
    FIELD_LESS_MULTIPLE: "Ta polja morajo biti manjša ali enaka ",
    FIELD_LESS_SINGLE: "To polje mora biti manjše ali enako ",
  }

  private translationsEn = {
    LINK_COPIED: "Link copied",
    DRIVER_CODE_DOESNT_EXIST: "Driver code or combination with name don't exist!",
    PLEASE_ENTER_DRIVER_NAME: "Please enter driver name!",
    DRIVER_AUTOFILL_SUCCESS: "Driver data autofilled!",
    PLEASE_ENTER_DRIVER_SURNAME: "Please enter driver surname!",
    PLEASE_ENTER_DRIVER_CODE: "Please enter driver code!",
    CHANGE_STATUS_SUCCESS: "Status updated!",
    ARRIVAL_MOVED_TO_ARCHIVE: "Marked as departed and moved to archive!",
    CHANGE_STATUS_ERROR: "Error while changing status!",
    FETCH_HISTORY_ERROR: "Error while fetching history!",
    ARRIVAL_ADDED_SUCCESS: "Arrival added!",
    ARRIVAL_ADDED_ERROR: "Error while adding arrival!",
    WELCOME: "Welcome",
    LOGIN_ERROR: "Login error! Check your credentials.",
    REGISTER_SUCCESS: "Account created!",
    REGISTER_ERROR: "Failed to create an account.",
    PASSWORDS_DONT_MATCH: "Passwords don't match!",
    LOGOUT_SUCCESS: "Logout success!",
    LOGOUT_ERROR: "Logout error!",
    ARRIVAL: "Arrival",
    DEPARTURE: "Departure",
    UNLOADING_STARTED: "Unloading start",
    UNLOADING_DONE: "Unloading end",
    FETCH_ARRIVAL_ERROR: "Error fetching the arrival!",
    MARK_CONTRACT_SUCCESS: "Contract marked as uploaded. Don't forget to upload long term contracts in drivers menu!",
    MARK_CONTRACT_ERROR: "Error marking contract as uploaded!",
    CHANGE_NAME_SUCCESS: "Successfuly updated the name!",
    CHANGE_BASIC_DATA_SUCCESS: "Successfuly updated the data!",
    CHANGE_NAME_ERROR: "Error while changing name!",
    CHANGE_ROLE_SUCCESS: "Successfuly updated the role!",
    CHANGE_ROLE_ERROR: "Error while changing role!",
    CHANGE_PASSWORD_SUCCESS: "Successfuly updated the password!",
    CHANGE_PASSWORD_ERROR: "Error while changing password!",
    UPDATE_ARRIVAL_SUCCESS: "Arrival successfuly updated!",
    UPDATE_ARRIVAL_ERROR: "Error while updating arrival!",
    DELETE_ARRIVAL_SUCCESS: "Arrival successfuly deleted!",
    DELETE_ARRIVAL_ERROR: "Error while deleting arrival!",
    FETCH_DRIVERS_ERROR: "Error while fetching drivers!",
    FETCH_DRIVER_ERROR: "Error while fetching driver!",
    FETCH_COMPANIES_ERROR: "Error while fetching companies!",
    FETCH_COMPANY_ERROR: "Error while fetching company!",
    FILE: "File",
    READY_FOR_UPLOAD_FOR_DRIVER: "is ready for upload for driver",
    UPLOAD_FILE_ERROR: "Error uploading selected file!",
    PLEASE_SELECT_CONTRACT_FILE: "Choose a contract file",
    INVALID_SIGN_DATE_FOR_DRIVER: "Invalid sign date for driver",
    INVALID_DRIVER_CODE: "Invalid driver code",
    UPLOAD_CONTRACT_SUCCESS_FOR_DRIVER: "Contract upload success for driver",
    UPLOAD_CONTRACT_ERROR_FOR_DRIVER: "Contract upload error for driver",
    ALL_CONTRACTS_UPLOADED: "All contracts uploaded!",
    ADD_DRIVER_SUCCESS: "Driver added!",
    ADD_DRIVER_ERROR: "Error while adding driver!",
    UPDATE_DRIVER_SUCCESS: "Driver updated!",
    UPDATE_DRIVER_ERROR: "Error while updating driver!",
    DELETE_DRIVER_SUCCESS: "Driver deleted!",
    DELETE_DRIVER_ERROR: "Error while deleting driver!",
    ADD_COMPANY_SUCCESS: "Company added!",
    ADD_CONTACT_MAIL_SUCCESS: "Contact mail added!",
    REMOVE_CONTACT_MAIL_SUCCESS: "Contact mail removed!",
    ADD_COMPANY_ERROR: "Error while adding company!",
    UPDATE_COMPANY_SUCCESS: "Company updated!",
    UPDATE_COMPANY_ERROR: "Error while updating company!",
    DELETE_COMPANY_SUCCESS: "Company deleted!",
    DELETE_COMPANY_ERROR: "Error while deleting company!",
    DATE_RANGE_INVALID: "Invalid date range!",
    DRIVER_WITH_ID: "Driver with id",
    NOT_FOUND: "not found",
    ALREADY_EXISTS: "already exists",
    ERROR_VALIDATING_FORM: "Error validating form! Please check input data.",
    ADD_ARRIVAL_SUCCESS: "Arrival added!",
    ADD_ARRIVAL_ERROR: "Error adding the arrival!",
    PASSWORD_TOO_WEAK:
      "Your password is too weak. It has to be at least 10 characters long and must contain 3 of the following sets: upper case, lower case, numbers and special characters.",
    FETCH_RAMPS_ERROR: "Error while fetching ramps!",
    FETCH_USERS_ERROR: "Error while fetching users!",
    CONFIRM_USER_SUCCESS: "User confirmed!",
    DISABLE_USER_SUCCESS: "User disabled!",
    UPDATE_USER_SUCCESS: "User updated!",
    UPDATE_USER_ERROR: "Error while updating user!",
    DELETE_USER_SUCCESS: "User deleted!",
    DELETE_USER_ERROR: "Error while deleting user!",
    REGISTER_ADMIN_SUCCESS: "New admin registered!",
    REGISTER_ADMIN_ERROR: "Error while registering new admin!",
    EMAIL_TAKEN_ERROR: "Registration error: username exists!",
    UNDO_STATUS_SUCCESS: "Status undone successfully!",
    UNDO_STATUS_ERROR: "Error while undoing status!",
    AT_RAMP: "At ramp",
    RAMP: "Ramp",
    CURRENTLY_TAKEN: "is currently taken!",
    FETCH_COMBINATIONS_ERROR: "Error while fetching combinations!",
    UPDATE_WAREHOUSE_ERROR: "Error while updating warehouse!",
    UPDATE_WAREHOUSE_SUCCESS: "Updated the warehouse!",
    PERMISSION_REQUESTED_ERROR: "Error while requesting permission!",
    PERMISSION_REQUESTED: "Requested permission!",
    PERMISSION_AUTO_ACCEPTED: "Permission was automatically accepted because the warehouse is public!",
    FETCH_WAREHOUSE_ERROR: "Error while fetching warehouse!",
    ADD_DOOR_SUCCESS: "Added the door!",
    ADD_DOOR_ERROR: "Error while adding door!",
    DELETE_WAREHOUSE_SUCCESS: "Deleted the warehouse!",
    ADD_WAREHOUSE_SUCCESS: "Warehouse added!",
    ADD_WAREHOUSE_ERROR: "Error while adding warehouse!",
    RESERVATION_APPROVE_SUCCESS: "Reservation approved!",
    RESERVATION_UPDATE_SUCCESS: "Reservation updated!",
    DELETE_RESERVATION_SUCCESS: "Reservation deleted!",
    DELETE_RESERVATION_ERROR: "Error while deleting reservation!",
    FETCHING_DOORS_ERROR: "Error while fetching doors!",
    UPLOADING_FILES_FINISHED_RESERVATION_COMPLETE: "Uploading files finished, reservation complete!",
    UPLOADING_FILES_FINISHED: "Uploading files finished!",
    CREATE_RESERVATION_ERROR: "Unknown error while creating reservation!",
    CREATE_RESERVATION_ERROR_MINIMUM_NOTICE: "Minimum notice error while creating reservation!",
    NO_PALLETS_FIELD: "No pallets field!",
    CREATE_RESERVATION_ERROR_START_AFTER_END: "Error: start time window is after end!",
    UPLOAD_FILES_ERROR: "Error while uploading files!",
    CREATE_RESERVATION_SUCCESS: "Reservation created!",
    UPLOADING_FILES_DONT_CLOSE_THIS_PAGE: "Uploading files, don't close this page!",
    DELETE_DOOR_ERROR: "Error while deleting door!",
    DELETE_DOOR_SUCCESS: "Door deleted!",
    COPY_DOOR_ERROR: "Error while copying door!",
    COPY_DOOR_SUCCESS: "Door copied!",
    UPDATE_DOOR_ERROR: "Error while updating door!",
    UPDATE_DOOR_SUCCESS: "Door updated!",
    FETCH_RESERVED_ERROR: "Error while fetching schedule!",
    FETCH_DOOR_ERROR: "Error while fetching door!",
    IMAGE_UPLOADED_ERROR: "Error while uploading image!",
    IMAGE_UPLOADED_SUCCESS: "Image uploaded!",
    DOOR_NOT_FOUND: "Door is not found!",
    FIXED_RESERVATION_TW_NOT_FOUND: "Selected time window is not found for fixed reservation",
    CALCULATED_RESERVATION_TW_NOT_MATCHING: "Calculated reservation time window end is not matching with given end ",
    CALCULATED: "calculated",
    YOURS: "given",
    CREATE_RESERVATION_NO_PERMISSION: "No permission for this door!",
    CREATE_RESERVATION_DOUBLE_BOOKING: "Booking with this driver id already exists for this day on this door!",
    CREATE_RESERVATION_DOUBLE_EMAIL_BOOKING: "Booking with this mail already exists! You may not create more than 1 booking per day!",
    INVALID_RECURRENCE_RULE: "Recurrence rule is not valid! Please contact the administrator.",
    DATE_IS_IN_PAST: "Date is in the past!",
    DOOR_EXISTING_RESERVATIONS: "Door has existing reservation on selected time!",
    DOOR_NO_TIME_WINDOWS: "Door has no time windows for selected start and end!",
    RESERVATION_DELETED_SUCCESS: "Reservation deleted!",
    UNKNOWN_ERROR: "Unknown error has occured!",
    ADD_RESERVATION_FIELD_SUCCESS: "Reservation field added!",
    EDIT_RESERVATION_FIELD_SUCCESS: "Reservation field edited!",
    DUPLICATE_RESERVATION_FIELD_NAME_ERROR: "Duplicate reservation field name error for language: ",
    REMOVE_RESERVATION_FIELD_SUCCESS: "Reservation field removed!",
    UPDATE_SUCCESS: "Update successful!",
    COPY_SUCCESS: "Copy successful!",
    MAIL_SUBJECT_TEXT_TYPE: this.userMailSubjectTextTypeEn,
    CHECKBOX_DROPDOWN_TEXT: this.checkboxDropdownTextEn,
    STATIC_DATA_FIELD_NAMES: this.staticDataFieldNamesEn,
    RESERVATION_ACTION_NAMES: this.reservationActionNamesEn,
    RESERVATION_CONFIRM_NAMES: this.reservationConfirmNamesEn,
    RECURRENCE_RULES: this.recurrenceRulesEn,
    SPECIAL_MEANINGS: this.specialMeaningsTextsEn,
    NO_DOORS_AVAILABLE: "No doors available",
    NO_WAREHOUSES_AVAILABLE: "No warehouses available",
    UNSELECT_ALL: "Unselect all",
    SELECT_ALL: "Select all",
    SEARCH_PLACEHOLDER: "Search ...",
    RESERVATION_NOT_FOUND_ERROR: "Reservation not found",
    COULD_NOT_SEND_MAIL_ERROR: "Could not send e-mail! Your reservation has been created regardless.",
    OUT_OF_WORKING_HOURS: "Out of working hours",
    TIME_WINDOW_EXPIRED: "Time window expired",
    TODAY: "Today",
    MY_RESERVATION: "My reservation",
    BOOKED: "Booked",
    EMAIL_TEMPLATE_TEXTS: this.emailTemplateTypeTextsEn,
    EMAIL_TEMPLATE_PLACEHOLDERS: this.emailTemplatePlaceholdersEn,
    ADD_HOLIDAY_SUCCESS: "Holiday added",
    REMOVE_HOLIDAY_SUCCESS: "Holiday removed",
    BOOK_BUTTON_TEXT: this.bookButtonTextEn,
    RESERVATION_FIELD_ERROR_MESSAGE: this.reservationFieldErrorMessageEn,
  };

  private translationsSl = {
    LINK_COPIED: "Link kopiran",
    DRIVER_CODE_DOESNT_EXIST: "Koda voznika ali kombinacija z imenom ne obstajata!",
    PLEASE_ENTER_DRIVER_NAME: "Prosimo vnesite ime voznika!",
    DRIVER_AUTOFILL_SUCCESS: "Podatki voznika so bili samodejno izpolnjeni!",
    PLEASE_ENTER_DRIVER_SURNAME: "Prosimo vnesite priimek voznika!",
    PLEASE_ENTER_DRIVER_CODE: "Prosimo vnesite kodo voznika!",
    CHANGE_STATUS_SUCCESS: "Status posodobljen!",
    ARRIVAL_MOVED_TO_ARCHIVE: "Odhod označen in prestavljen v arhiv!",
    CHANGE_STATUS_ERROR: "Napaka pri spreminjanju statusa!",
    FETCH_HISTORY_ERROR: "Napaka pri pridobivanju seznama prihodov!",
    ARRIVAL_ADDED_SUCCESS: "Prihod dodan!",
    ARRIVAL_ADDED_ERROR: "Napaka pri dodajanju prihoda!",
    WELCOME: "Dobrodošli",
    LOGIN_ERROR: "Napaka pri vpisu. Preverite mail in geslo.",
    REGISTER_SUCCESS: "Račun ustvarjen.",
    REGISTER_ERROR: "Napaka pri ustvarjanju računa.",
    PASSWORDS_DONT_MATCH: "Gesli se ne ujemata!",
    LOGOUT_SUCCESS: "Uspešno ste se odjavili!",
    LOGOUT_ERROR: "Napaka pri odjavi!",
    ARRIVAL: "Prihod",
    DEPARTURE: "Odhod",
    UNLOADING_STARTED: "Pričetek raztovarjanja",
    UNLOADING_DONE: "Konec raztovarjanja",
    FETCH_ARRIVAL_ERROR: "Napaka pri pridobivanju prihoda!",
    MARK_CONTRACT_SUCCESS: "Pogodba označena kot podpisana. Ne pozabite naložiti dologoročne pogodbe v seznamu voznikov!",
    MARK_CONTRACT_ERROR: "Napaka pri označevanju pogodbe!",
    CHANGE_NAME_SUCCESS: "Uspešno spremenjeno ime!",
    CHANGE_BASIC_DATA_SUCCESS: "Uspešno spremenjeni podatki!",
    CHANGE_NAME_ERROR: "Napaka pri spreminjanju imena!",
    CHANGE_ROLE_SUCCESS: "Uspešno spremenjena vloga!",
    CHANGE_ROLE_ERROR: "Napaka pri spreminjanju vloge!",
    CHANGE_PASSWORD_SUCCESS: "Uspešno spremenjeno geslo!",
    CHANGE_PASSWORD_ERROR: "Napaka pri spreminjanju gesla!",
    UPDATE_ARRIVAL_SUCCESS: "Prihod uspešno posodobljen!",
    UPDATE_ARRIVAL_ERROR: "Napaka pri posodabljanju prihoda!",
    DELETE_ARRIVAL_SUCCESS: "Prihod uspešno izbrisan!",
    DELETE_ARRIVAL_ERROR: "Napaka pri brisanju prihoda!",
    FETCH_DRIVERS_ERROR: "Napaka pri pridobivanju voznikov!",
    FETCH_DRIVER_ERROR: "Napaka pri pridobivanju voznika!",
    FETCH_COMPANIES_ERROR: "Napaka pri pridobivanju podjetij!",
    FETCH_COMPANY_ERROR: "Napaka pri pridobivanju podjetja!",
    FILE: "Datoteka",
    READY_FOR_UPLOAD_FOR_DRIVER: "je pripravljena za nalaganje za voznika",
    UPLOAD_FILE_ERROR: "Napaka pri nalaganju izbrane datoteke!",
    PLEASE_SELECT_CONTRACT_FILE: "Izberite datoteko",
    INVALID_SIGN_DATE_FOR_DRIVER: "Napačen datum podpisa za voznika",
    INVALID_DRIVER_CODE: "Napačna koda voznika!",
    UPLOAD_CONTRACT_SUCCESS_FOR_DRIVER: "Nalaganje pogodbe uspešno za voznika",
    UPLOAD_CONTRACT_ERROR_FOR_DRIVER: "Nalaganje pogodbe neuspešno za voznika",
    ALL_CONTRACTS_UPLOADED: "Vse pogodbe uspešno naložene!",
    ADD_DRIVER_SUCCESS: "Voznik uspešno dodan!",
    ADD_DRIVER_ERROR: "Napaka pri dodajanju voznika!",
    UPDATE_DRIVER_SUCCESS: "Voznik uspešno posodobljen!",
    UPDATE_DRIVER_ERROR: "Napaka pri posodabljanju voznika!",
    DELETE_DRIVER_SUCCESS: "Voznik uspešno izbrisan!",
    DELETE_DRIVER_ERROR: "Napaka pri brisanju voznika!",
    ADD_COMPANY_SUCCESS: "Podjetje uspešno dodano!",
    ADD_CONTACT_MAIL_SUCCESS: "Email dodan!",
    REMOVE_CONTACT_MAIL_SUCCESS: "Email odstranjen!",
    ADD_COMPANY_ERROR: "Napaka pri dodajanju podjetja!",
    UPDATE_COMPANY_SUCCESS: "Podjetje uspešno posodobljeno!",
    UPDATE_COMPANY_ERROR: "Napaka pri posodabljanju podjetja!",
    DELETE_COMPANY_SUCCESS: "Podjetje uspešno izbrisano!",
    DELETE_COMPANY_ERROR: "Napaka pri brisanju podjetja!",
    DATE_RANGE_INVALID: "Napačen razpon datumov!",
    DRIVER_WITH_ID: "Voznik z id-jem",
    NOT_FOUND: "ni bil najden",
    ALREADY_EXISTS: "že obstaja",
    ERROR_VALIDATING_FORM: "Napaka pri validiranju podatkov. Preverite vnosna polja!",
    ADD_ARRIVAL_SUCCESS: "Prihod dodan!",
    ADD_ARRIVAL_ERROR: "Napaka pri dodajanju prihoda!",
    PASSWORD_TOO_WEAK:
      "Geslo ni dovolj močno. Dolgo mora biti vsaj 10 znakov in vsebovati 3 izmed naslednjih skupin: velike črke, male črke, številke in posebni znaki.",
    FETCH_RAMPS_ERROR: "Napaka pri pridobivanju ramp!",
    FETCH_USERS_ERROR: "Napaka pri pridobivanju uporabnikov!",
    CONFIRM_USER_SUCCESS: "Uporabnik potrjen!",
    DISABLE_USER_SUCCESS: "Uporabnik onemogočen!",
    UPDATE_USER_SUCCESS: "Uporabnik posodobljen!",
    UPDATE_USER_ERROR: "Napaka pri posodabljanju uporabnika!",
    DELETE_USER_SUCCESS: "Uporabnik izbrisan!",
    DELETE_USER_ERROR: "Napaka pri brisanju uporabnika!",
    REGISTER_ADMIN_SUCCESS: "Nov administrator registriran!",
    REGISTER_ADMIN_ERROR: "Napaka pri registraciji novega administratorja!",
    EMAIL_TAKEN_ERROR: "Napaka pri registraciji: uporabniško ime že obstaja!",
    UNDO_STATUS_SUCCESS: "Status uspešno razveljavljen!",
    UNDO_STATUS_ERROR: "Napaka pri razveljavitvi statusa!",
    AT_RAMP: "Pri rampi",
    RAMP: "Rampa",
    CURRENTLY_TAKEN: "je trenutno zasedena!",
    FETCH_COMBINATIONS_ERROR: "Napaka pri pridobivanju kombinacij!",
    UPDATE_WAREHOUSE_ERROR: "Napaka pri posodabljanju skladišča!",
    UPDATE_WAREHOUSE_SUCCESS: "Skladišče posodobljeno!",
    PERMISSION_REQUESTED_ERROR: "Napaka pri zahtevanju dovoljenja!",
    PERMISSION_REQUESTED: "Poslan zahtevek!",
    PERMISSION_AUTO_ACCEPTED: "Dovoljenje je bilo avtomatsko sprejeto, saj je skladišče javno!",
    FETCH_WAREHOUSE_ERROR: "Napaka pri pridobivanju skladišča!",
    ADD_DOOR_SUCCESS: "Vrata dodana!",
    ADD_DOOR_ERROR: "Napaka pri dodajanju vrat!",
    DELETE_WAREHOUSE_SUCCESS: "Skladišče izbrisano!",
    ADD_WAREHOUSE_SUCCESS: "Skladišče dodano!",
    ADD_WAREHOUSE_ERROR: "Napaka pri dodajanju skladišča!",
    RESERVATION_APPROVE_SUCCESS: "Rezervacija potrjena!",
    RESERVATION_UPDATE_SUCCESS: "Rezervacija posodobljena!",
    DELETE_RESERVATION_SUCCESS: "Rezervacije izbrisana!",
    DELETE_RESERVATION_ERROR: "Napaka pri brisanju rezervacije!",
    FETCHING_DOORS_ERROR: "Napaka pri pridobivanju vrat!",
    UPLOADING_FILES_FINISHED_RESERVATION_COMPLETE: "Nalaganje datotek končano, rezervacija ustvarjen!",
    UPLOADING_FILES_FINISHED: "Nalaganje datotek končano!",
    CREATE_RESERVATION_ERROR: "Napaka pri ustvarjanju rezervacije!",
    CREATE_RESERVATION_ERROR_MINIMUM_NOTICE: "Napaka pri ustvarjanju rezervacije - najkasnejši opomnik!",
    NO_PALLETS_FIELD: "Manjka podatek o paletah!",
    CREATE_RESERVATION_ERROR_START_AFTER_END: "Napaka: začetni čas je kasnejši od končnega!",
    UPLOAD_FILES_ERROR: "Napaka pri nalaganju datotek!",
    CREATE_RESERVATION_SUCCESS: "Rezervacija ustvarjena!",
    UPLOADING_FILES_DONT_CLOSE_THIS_PAGE: "Nalaganje slik, ne zaprite te strani!",
    DELETE_DOOR_ERROR: "Napaka pri brisanju vrat!",
    DELETE_DOOR_SUCCESS: "Vrata izbrisana!",
    COPY_DOOR_ERROR: "Napaka pri kopiranju vrat!",
    COPY_DOOR_SUCCESS: "Vrata kopirana!",
    UPDATE_DOOR_ERROR: "Napaka pri posodabljanju vrat!",
    UPDATE_DOOR_SUCCESS: "Vrata posodobljena!",
    FETCH_RESERVED_ERROR: "Napaka pri pridobivanju urnika!",
    FETCH_DOOR_ERROR: "Napaka pri pridobivanju vrat!",
    IMAGE_UPLOADED_ERROR: "Napaka pri nalaganju slike!",
    IMAGE_UPLOADED_SUCCESS: "Slika naložena!",
    DOOR_NOT_FOUND: "Vrata niso bila najdena!",
    FIXED_RESERVATION_TW_NOT_FOUND: "Izbran fiksen termin ni bil najden!",
    CALCULATED_RESERVATION_TW_NOT_MATCHING: "Izračunan čas odhoda se ne ujema s podanim časom ",
    CALCULATED: "izračunan",
    YOURS: "podan",
    CREATE_RESERVATION_NO_PERMISSION: "Nimate pravic za ta vrata!",
    CREATE_RESERVATION_DOUBLE_BOOKING: "Rezervacija za to kodo voznika za ta vrata danes že obstaja!",
    CREATE_RESERVATION_DOUBLE_EMAIL_BOOKING: "Rezervacija za ta email že obstaja!",
    INVALID_RECURRENCE_RULE: "Rekurenčno pravilo je napačno! Kontaktirajte administratorja.",
    DATE_IS_IN_PAST: "Datum je v preteklosti!",
    DOOR_EXISTING_RESERVATIONS: "Vrata imajo obstoječe rezervacije na izbran termin!",
    DOOR_NO_TIME_WINDOWS: "Vrata nimajo časovnih oken za izbran termin!",
    RESERVATION_DELETED_SUCCESS: "Rezervacija izbrisana!",
    UNKNOWN_ERROR: "Prišlo je do neznane napake!",
    ADD_RESERVATION_FIELD_SUCCESS: "Rezervacijsko polje je bilo dodano!",
    EDIT_RESERVATION_FIELD_SUCCESS: "Rezervacijsko polje je bilo urejeno!",
    DUPLICATE_RESERVATION_FIELD_NAME_ERROR: "Duplikat imena rezervacijskega polja za jezik: ",
    REMOVE_RESERVATION_FIELD_SUCCESS: "Rezervacijsko polje je bilo izbrisano!",
    UPDATE_SUCCESS: "Posodobitev uspešna!",
    COPY_SUCCESS: "Kopiranje uspešno!",
    MAIL_SUBJECT_TEXT_TYPE: this.userMailSubjectTextTypeSl,
    CHECKBOX_DROPDOWN_TEXT: this.checkboxDropdownTextSl,
    STATIC_DATA_FIELD_NAMES: this.staticDataFieldNamesSl,
    RESERVATION_ACTION_NAMES: this.reservationActionNamesSl,
    RESERVATION_CONFIRM_NAMES: this.reservationConfirmNamesSl,
    RECURRENCE_RULES: this.recurrenceRulesSl,
    SPECIAL_MEANINGS: this.specialMeaningsTextsSl,
    NO_DOORS_AVAILABLE: "Ni vrat",
    NO_WAREHOUSES_AVAILABLE: "Ni skladišč",
    UNSELECT_ALL: "Odznači vse",
    SELECT_ALL: "Izberi vse",
    SEARCH_PLACEHOLDER: "Iskanje ...",
    RESERVATION_NOT_FOUND_ERROR: "Rezervacija ni bila najdena.",
    COULD_NOT_SEND_MAIL_ERROR: "E-maila ni bilo mogoče poslati! Vaša rezervacija je kljub temu shranjena.",
    OUT_OF_WORKING_HOURS: "Izven delovnega časa",
    TIME_WINDOW_EXPIRED: "Okno je poteklo",
    TODAY: "Danes",
    MY_RESERVATION: "Moja rezervacija",
    BOOKED: "Rezervirano",
    EMAIL_TEMPLATE_TEXTS: this.emailTemplateTypeTextsSl,
    EMAIL_TEMPLATE_PLACEHOLDERS: this.emailTemplatePlaceholdersSl,
    ADD_HOLIDAY_SUCCESS: "Praznik dodan",
    REMOVE_HOLIDAY_SUCCESS: "Praznik odstranjen",
    BOOK_BUTTON_TEXT: this.bookButtonTextSl,
    RESERVATION_FIELD_ERROR_MESSAGE: this.reservationFieldErrorMessageSl,
  };

  public changeStatusSuccess() {
    return this.translations.CHANGE_STATUS_SUCCESS;
  }

  public arrivalMovedToArchive() {
    return this.translations.ARRIVAL_MOVED_TO_ARCHIVE;
  }

  public changeStatusError() {
    return this.translations.CHANGE_STATUS_ERROR;
  }

  public fetchHistoryError() {
    return this.translations.FETCH_HISTORY_ERROR;
  }

  public fetchArrivalError() {
    return this.translations.FETCH_ARRIVAL_ERROR;
  }

  public arrivalAddedSuccess() {
    return this.translations.ARRIVAL_ADDED_SUCCESS;
  }

  public arrivalAddedError() {
    return this.translations.ARRIVAL_ADDED_ERROR;
  }

  public loginSuccess(userName) {
    return this.translations.WELCOME + ", " + userName;
  }

  public loginError() {
    return this.translations.LOGIN_ERROR;
  }

  public passwordDontMatch() {
    return this.translations.PASSWORDS_DONT_MATCH;
  }

  public registerSuccess() {
    return this.translations.REGISTER_SUCCESS;
  }

  public registerError() {
    return this.translations.REGISTER_ERROR;
  }

  public loggedOutSuccess() {
    return this.translations.LOGOUT_SUCCESS;
  }

  public loggedOutError() {
    return this.translations.LOGOUT_ERROR;
  }

  public markContractSuccess() {
    return this.translations.MARK_CONTRACT_SUCCESS;
  }

  public markContractError() {
    return this.translations.MARK_CONTRACT_ERROR;
  }

  public changeNameSuccess() {
    return this.translations.CHANGE_NAME_SUCCESS;
  }

  public changeBasicDataSuccess() {
    return this.translations.CHANGE_BASIC_DATA_SUCCESS;
  }

  public changeNameError() {
    return this.translations.CHANGE_NAME_ERROR;
  }

  public changeRoleSuccess() {
    return this.translations.CHANGE_ROLE_SUCCESS;
  }

  public changeRoleError() {
    return this.translations.CHANGE_ROLE_ERROR;
  }

  public updateArrivalSuccess() {
    return this.translations.UPDATE_ARRIVAL_SUCCESS;
  }

  public updateArrivalError() {
    return this.translations.UPDATE_ARRIVAL_ERROR;
  }

  public deleteArrivalSuccess() {
    return this.translations.DELETE_ARRIVAL_SUCCESS;
  }

  public deleteArrivalError() {
    return this.translations.DELETE_ARRIVAL_ERROR;
  }

  public fetchDriversError() {
    return this.translations.FETCH_DRIVERS_ERROR;
  }

  public fetchDriverError() {
    return this.translations.FETCH_DRIVER_ERROR;
  }

  public readyForUpload(fileName, driverName) {
    return this.translations.FILE + " " + fileName + " " + this.translations.READY_FOR_UPLOAD_FOR_DRIVER + " " + driverName + ".";
  }

  public uploadFileError() {
    return this.translations.UPLOAD_FILE_ERROR;
  }

  public pleaseSelectContactFile() {
    return this.translations.PLEASE_SELECT_CONTRACT_FILE;
  }

  public invalidSignDate(driverName) {
    return this.translations.INVALID_SIGN_DATE_FOR_DRIVER + " " + driverName + "!";
  }

  public invalidDriverCode() {
    return this.translations.INVALID_DRIVER_CODE;
  }

  public uploadContractSuccess(driverName) {
    return this.translations.UPLOAD_CONTRACT_SUCCESS_FOR_DRIVER + " " + driverName + "!";
  }

  public uploadContractError(driverName) {
    return this.translations.UPLOAD_CONTRACT_ERROR_FOR_DRIVER + " " + driverName + "!";
  }

  public allContactsUploaded() {
    return this.translations.ALL_CONTRACTS_UPLOADED;
  }

  public addDriverSuccess() {
    return this.translations.ADD_DRIVER_SUCCESS;
  }

  public addDriverError() {
    return this.translations.ADD_DRIVER_ERROR;
  }

  public updateDriverSuccess() {
    return this.translations.UPDATE_DRIVER_SUCCESS;
  }

  public updateDriverError() {
    return this.translations.UPDATE_DRIVER_ERROR;
  }

  public deleteDriverSuccess() {
    return this.translations.DELETE_DRIVER_SUCCESS;
  }

  public deleteDriverError() {
    return this.translations.DELETE_DRIVER_ERROR;
  }

  public fetchCompaniesError() {
    return this.translations.FETCH_COMPANIES_ERROR;
  }

  public fetchCompanyError() {
    return this.translations.FETCH_COMPANY_ERROR;
  }

  public addCompanySuccess() {
    return this.translations.ADD_COMPANY_SUCCESS;
  }

  public addContactMailSuccess() {
    return this.translations.ADD_CONTACT_MAIL_SUCCESS;
  }

  public removeContactMailSuccess() {
    return this.translations.REMOVE_CONTACT_MAIL_SUCCESS;
  }

  public addCompanyError() {
    return this.translations.ADD_COMPANY_ERROR;
  }

  public updateCompanySuccess() {
    return this.translations.UPDATE_COMPANY_SUCCESS;
  }

  public updateCompanyError() {
    return this.translations.UPDATE_COMPANY_ERROR;
  }

  public deleteCompanySuccess() {
    return this.translations.DELETE_COMPANY_SUCCESS;
  }

  public deleteCompanyError() {
    return this.translations.DELETE_COMPANY_ERROR;
  }

  public dateRangeInvalid() {
    return this.translations.DATE_RANGE_INVALID;
  }

  public driverWithIDNotFound(id) {
    return this.translations.DRIVER_WITH_ID + " " + id + " " + this.translations.NOT_FOUND + "!";
  }

  public driverWithIDAlreadyExists(id) {
    return this.translations.DRIVER_WITH_ID + " " + id + " " + this.translations.ALREADY_EXISTS + "!";
  }

  public errorValidatingForm() {
    return this.translations.ERROR_VALIDATING_FORM;
  }

  public addNewArrivalSuccess() {
    return this.translations.ADD_ARRIVAL_SUCCESS;
  }

  public addNewArrivalError() {
    return this.translations.ADD_ARRIVAL_ERROR;
  }

  public passwordTooWeak() {
    return this.translations.PASSWORD_TOO_WEAK;
  }

  public fetchRampsError() {
    return this.translations.FETCH_RAMPS_ERROR;
  }

  public fetchUsersError() {
    return this.translations.FETCH_USERS_ERROR;
  }

  public confirmUserSuccess(confirmed: boolean) {
    if (confirmed) {
      return this.translations.CONFIRM_USER_SUCCESS;
    } else {
      return this.translations.DISABLE_USER_SUCCESS;
    }
  }

  public updateUserSuccess() {
    return this.translations.UPDATE_USER_SUCCESS;
  }

  public updateUserError() {
    return this.translations.UPDATE_USER_ERROR;
  }

  public deleteUserSuccess() {
    return this.translations.DELETE_USER_SUCCESS;
  }

  public deleteUserError() {
    return this.translations.DELETE_USER_ERROR;
  }

  public registerAdminSuccess() {
    return this.translations.REGISTER_ADMIN_SUCCESS;
  }

  public registerAdminError() {
    return this.translations.REGISTER_ADMIN_ERROR;
  }

  public emailTakenError() {
    return this.translations.EMAIL_TAKEN_ERROR;
  }

  public undoStatusSuccess() {
    return this.translations.UNDO_STATUS_SUCCESS;
  }

  public undoStatusError() {
    return this.translations.UNDO_STATUS_ERROR;
  }

  public rampIsTakenError(rampId: string) {
    return this.translations.RAMP + " " + rampId + " " + this.translations.CURRENTLY_TAKEN;
  }

  public fetchCombinationsError() {
    return this.translations.FETCH_COMBINATIONS_ERROR;
  }

  updateWarehouseError(): string {
    return this.translations.UPDATE_WAREHOUSE_ERROR;
  }

  updateWarehouseSuccess(): string {
    return this.translations.UPDATE_WAREHOUSE_SUCCESS;
  }

  permissionError(): string {
    return this.translations.PERMISSION_REQUESTED_ERROR;
  }

  permissionRequested(): string {
    return this.translations.PERMISSION_REQUESTED;
  }

  permissionAutoAccepted(): string {
    return this.translations.PERMISSION_AUTO_ACCEPTED;
  }

  fetchWarehouseError(): string {
    return this.translations.FETCH_WAREHOUSE_ERROR;
  }

  addDoorSuccess(): string {
    return this.translations.ADD_DOOR_SUCCESS;
  }

  addDoorError(): string {
    return this.translations.ADD_DOOR_ERROR;
  }

  deleteWarehouseSuccess(): string {
    return this.translations.DELETE_WAREHOUSE_SUCCESS;
  }

  addWarehouseSuccess(): string {
    return this.translations.ADD_WAREHOUSE_SUCCESS;
  }

  addWarehouseError(): string {
    return this.translations.ADD_WAREHOUSE_ERROR;
  }

  reservationApprovedSuccess(): string {
    return this.translations.RESERVATION_APPROVE_SUCCESS;
  }

  reservationUpdatedSuccess(): string {
    return this.translations.RESERVATION_UPDATE_SUCCESS;
  }

  deleteReservationSuccess(): string {
    return this.translations.DELETE_RESERVATION_SUCCESS;
  }

  deleteReservationError(): string {
    return this.translations.DELETE_RESERVATION_ERROR;
  }

  fetchingDoorsError(): string {
    return this.translations.FETCHING_DOORS_ERROR;
  }

  uploadingFilesFinishedReservationComplete(): string {
    return this.translations.UPLOADING_FILES_FINISHED_RESERVATION_COMPLETE;
  }

  uploadingFilesFinished(): string {
    return this.translations.UPLOADING_FILES_FINISHED;
  }

  createReservationError(): string {
    return this.translations.CREATE_RESERVATION_ERROR;
  }

  noPalletsField(): string {
    return this.translations.NO_PALLETS_FIELD;
  }

  createReservationErrorMinimumNotice(): string {
    return this.translations.CREATE_RESERVATION_ERROR_MINIMUM_NOTICE;
  }

  createReservationErrorStartAfterEnd(): string {
    return this.translations.CREATE_RESERVATION_ERROR_START_AFTER_END;
  }

  uploadFilesError(): string {
    return this.translations.UPLOAD_FILES_ERROR;
  }

  createReservationSuccess(): string {
    return this.translations.CREATE_RESERVATION_SUCCESS;
  }

  uploadingFilesDontCloseThisPage(): string {
    return this.translations.UPLOADING_FILES_DONT_CLOSE_THIS_PAGE;
  }

  deleteDoorError(): string {
    return this.translations.DELETE_DOOR_ERROR;
  }

  deleteDoorSuccess(): string {
    return this.translations.DELETE_DOOR_SUCCESS;
  }

  copyDoorError(): string {
    return this.translations.COPY_DOOR_ERROR;
  }

  copyDoorSuccess(): string {
    return this.translations.COPY_DOOR_SUCCESS;
  }

  updateDoorError(): string {
    return this.translations.UPDATE_DOOR_ERROR;
  }

  updateDoorSuccess(): string {
    return this.translations.UPDATE_DOOR_SUCCESS;
  }

  fetchReservedError(): string {
    return this.translations.FETCH_RESERVED_ERROR;
  }

  fetchDoorError(): string {
    return this.translations.FETCH_DOOR_ERROR;
  }

  imageUploadedError(): string {
    return this.translations.IMAGE_UPLOADED_ERROR;
  }

  imageUploadedSuccess(): string {
    return this.translations.IMAGE_UPLOADED_SUCCESS;
  }

  createReservationErrorDoorNotFound(): string {
    return this.translations.DOOR_NOT_FOUND;
  }

  createReservationErrorFixedReservationTimeWindowNotFound(): string {
    return this.translations.FIXED_RESERVATION_TW_NOT_FOUND;
  }

  createReservationErrorCalculatedReservationTimeWindowNotMatching(actual: string, expected: string): string {
    return this.translations.CALCULATED_RESERVATION_TW_NOT_MATCHING + `(${this.translations.YOURS}: ${actual}, ${this.translations.CALCULATED}: ${expected})`;
  }

  createReservationErrorNoPermission(): string {
    return this.translations.CREATE_RESERVATION_NO_PERMISSION;
  }

  createReservationErrorDoubleEmailBooking(): string {
    return this.translations.CREATE_RESERVATION_DOUBLE_EMAIL_BOOKING;
  }

  createReservationErrorDoubleBooking(): string {
    return this.translations.CREATE_RESERVATION_DOUBLE_BOOKING;
  }

  createReservationErrorInvalidRecurrenceRule(): string {
    return this.translations.INVALID_RECURRENCE_RULE;
  }

  createReservationErrorDateIsInPast(): string {
    return this.translations.DATE_IS_IN_PAST;
  }

  createReservationErrorDoorExistingReservations(): string {
    return this.translations.DOOR_EXISTING_RESERVATIONS;
  }

  createReservationErrorDoorNoTimeWindows(): string {
    return this.translations.DOOR_NO_TIME_WINDOWS;
  }

  reservationDeletedSuccess(): string {
    return this.translations.RESERVATION_DELETED_SUCCESS;
  }

  unknownError(): string {
    return this.translations.UNKNOWN_ERROR;
  }

  addReservationFieldSuccess(): string {
    return this.translations.ADD_RESERVATION_FIELD_SUCCESS;
  }

  editReservationFieldSuccess(): string {
    return this.translations.EDIT_RESERVATION_FIELD_SUCCESS;
  }

  duplicateReservationFieldNameError(languageName: string): string {
    return this.translations.DUPLICATE_RESERVATION_FIELD_NAME_ERROR + languageName;
  }

  removeReservationFieldSuccess(): string {
    return this.translations.REMOVE_RESERVATION_FIELD_SUCCESS;
  }

  updateSuccess(): string {
    return this.translations.UPDATE_SUCCESS;
  }

  copySuccess(): string {
    return this.translations.COPY_SUCCESS;
  }

  translateUserMailSubjectTextType(type: UserMailSubjectTextType): string {
    return this.translations.MAIL_SUBJECT_TEXT_TYPE[type];
  }

  getCheckboxDropdownText() {
    return this.translations.CHECKBOX_DROPDOWN_TEXT;
  }

  getStaticDataFieldNames() {
    return this.translations.STATIC_DATA_FIELD_NAMES;
  }

  getReservationActionNames() {
    return this.translations.RESERVATION_ACTION_NAMES;
  }

  getReservationConfirmNames() {
    return this.translations.RESERVATION_CONFIRM_NAMES;
  }

  getSpecialMeaningsNames(): Record<ReservationFieldSpecialMeaningField, string> {
    return this.translations.SPECIAL_MEANINGS;
  }

  noDoorsAvailable(): string {
    return this.translations.NO_DOORS_AVAILABLE;
  }

  noWarehousesAvailable(): string {
    return this.translations.NO_WAREHOUSES_AVAILABLE;
  }

  unselectAll(): string {
    return this.translations.UNSELECT_ALL;
  }

  selectAll(): string {
    return this.translations.SELECT_ALL;
  }

  search(): string {
    return this.translations.SEARCH_PLACEHOLDER;
  }

  translateRecurrenceRule(ruleCode: string | null): string {
    if (ruleCode === "0") {
      return this.translations.RECURRENCE_RULES.EVERY_SUNDAY;
    } else if (ruleCode === "1") {
      return this.translations.RECURRENCE_RULES.EVERY_MONDAY;
    } else if (ruleCode === "2") {
      return this.translations.RECURRENCE_RULES.EVERY_TUESDAY;
    } else if (ruleCode === "3") {
      return this.translations.RECURRENCE_RULES.EVERY_WEDNESDAY;
    } else if (ruleCode === "4") {
      return this.translations.RECURRENCE_RULES.EVERY_THURSDAY;
    } else if (ruleCode === "5") {
      return this.translations.RECURRENCE_RULES.EVERY_FRIDAY;
    } else if (ruleCode === "6") {
      return this.translations.RECURRENCE_RULES.EVERY_SATURDAY;
    }

    return this.translations.RECURRENCE_RULES.EVERY_DAY;
  }

  reservationNotFoundError(): string {
    return this.translations.RESERVATION_NOT_FOUND_ERROR;
  }

  couldNotSendMailError(): string {
    return this.translations.COULD_NOT_SEND_MAIL_ERROR;
  }

  outOfWorkingHours() {
    return this.translations.OUT_OF_WORKING_HOURS;
  }

  timeWindowExpired() {
    return this.translations.TIME_WINDOW_EXPIRED;
  }

  today() {
    return this.translations.TODAY;
  }

  myReservation() {
    return this.translations.MY_RESERVATION;
  }

  booked() {
    return this.translations.BOOKED;
  }

  linkCopied() {
    return this.translations.LINK_COPIED;
  }

  driverCodeDoesntExist(): string {
    return this.translations.DRIVER_CODE_DOESNT_EXIST;
  }

  pleaseEnterDriverName(): string {
    return this.translations.PLEASE_ENTER_DRIVER_NAME;
  }

  pleaseEnterDriverSurname(): string {
    return this.translations.PLEASE_ENTER_DRIVER_SURNAME;
  }

  driverAutofillSuccess(): string {
    return this.translations.DRIVER_AUTOFILL_SUCCESS;
  }

  pleaseEnterDriverCode(): string {
    return this.translations.PLEASE_ENTER_DRIVER_CODE;
  }

  translateEmailTemplateType(type: EmailTemplateType): string {
    return this.translations.EMAIL_TEMPLATE_TEXTS[type];
  }

  getEmailTemplatePlaceholders() {
    return this.translations.EMAIL_TEMPLATE_PLACEHOLDERS;
  }

  public addHolidaySuccess() {
    return this.translations.ADD_HOLIDAY_SUCCESS;
  }

  public removeHolidaySuccess() {
    return this.translations.REMOVE_HOLIDAY_SUCCESS;
  }

  public bookButtonText() {
    return this.translations.BOOK_BUTTON_TEXT;
  }

  public reservationFieldErrorMessage() {
    return this.translations.RESERVATION_FIELD_ERROR_MESSAGE;
  }
}
