<div class="app-container arrival-table-container">
  <h1 class="app-heading" i18n>Stats</h1>

  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink i18n>Booked windows</a>
      <ng-template ngbNavContent>
        <app-stats-booked-windows></app-stats-booked-windows>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-4"></div>
</div>
