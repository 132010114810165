<app-accordion-with-content i18n-label label="Company OWL settings">
  
    <app-app-checkbox [(value)]="disableTwoPhaseReservations" name="disableTwoPhaseReservations">
      <ng-container i18n>Disable two phase reservations?</ng-container>
    </app-app-checkbox>


    <button class="btn btn-primary" (click)="changeCompanyData()">
      <ng-container i18n=>Edit data</ng-container>
    </button>

</app-accordion-with-content>
