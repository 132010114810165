import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-basic-reservation-view",
  templateUrl: "./basic-reservation-view.component.html",
  styleUrls: ["./basic-reservation-view.component.css"],
})
export class BasicReservationViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
