import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { CountriesService } from "src/app/services/countries.service";

@Component({
  selector: "app-country-picker",
  templateUrl: "./country-picker.component.html",
  styleUrls: ["./country-picker.component.css"],
})
export class CountryPickerComponent implements OnInit {
  @Input() fieldName: string = "Country";
  @Input() helpText: string = null;
  @Input() errorMessage: string | null = null;
  @Input() required: boolean = false;
  @Input() selectedCountryId: number | null = null;
  @Output() selectedCountryIdChange: EventEmitter<number> = new EventEmitter<number>();

  faQuestionCircle = faQuestionCircle;

  constructor(public countriesService: CountriesService) {}

  ngOnInit(): void {}
}
