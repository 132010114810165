<table class="table table-bordered warehouse-table mb-5" *ngIf="warehouse">
  <thead>
    <tr class="warehouse-header-row">
      <th colspan="2">
        <div class="d-flex align-items-center">
          <img
            *ngIf="warehouse.company.profilePictureUrl"
            [src]="warehouse.company.profilePictureUrl"
            style="height: auto; width: 120px; object-fit: cover; margin-right: 10px"
          />

          <h3 class="ml-3" style="margin-bottom: unset; margin-top: unset">
            {{ warehouse.company.name }}
          </h3>
        </div>
      </th>
    </tr>
  </thead>
  <tbody *ngIf="warehouse.warehouses.length > 0">
    <ng-container *ngFor="let warehouseListItem of warehouse.warehouses">
      <tr class="c-pointer warehouse-row" [routerLink]="'/warehouse/' + warehouseListItem.id">
        <td class="warehouse-name-td">
          <div class="d-flex align-items-center">
            <img
              *ngIf="warehouseListItem.image"
              [src]="FileUrl(warehouseListItem.image)"
              style="height: auto; width: 120px; object-fit: cover; margin-right: 10px"
            />
            <div>
              <span class="font-weight-bold d-block">{{ warehouseListItem.name }}</span>
              <span style="color: gray">{{ warehouseListItem.description }}</span>
              <app-render-warehouse-permission-status
                *ngIf="isCarrier"
                [status]="warehouseListItem.permission?.status"
                [canCarrierCreateAnonymousReservation]="warehouseListItem.canCarrierCreateAnonymousReservation"
              ></app-render-warehouse-permission-status>
            </div>
          </div>
        </td>

        <td width="45%" class="details-td">
          <span
            class="btn btn-primary mr-2"
            *ngIf="isCarrier && warehouseListItem.permission == null"
            (click)="onRequestPermission.emit(warehouseListItem.id); $event.stopPropagation()"
          >
            <ng-container i18n>Request permission</ng-container>
          </span>
          <span
            class="btn btn-info mr-2"
            (click)="navigateToWarehouseReservation(warehouseListItem); $event.stopPropagation()"
            *ngIf="warehouseService.hasPermissionToBook(warehouseListItem, warehouseListItem.permission)"
          >
            <ng-container i18n>Book</ng-container>
          </span>
          <span
            class="btn btn-info mr-2"
            [routerLink]="'/reserve-company-two-phase/' + warehouse.company.id"
            [queryParams]="{ warehouseId: warehouseListItem.id }"
            (click)="$event.stopPropagation()"
            *ngIf="warehouseService.hasPermissionToBookTwoPhase(warehouseListItem, warehouseListItem.permission, warehouse.company)"
          >
            <ng-container i18n>Book two phase</ng-container>
          </span>
          <span
            class="btn btn-primary mr-2"
            *ngxPermissionsOnly="[Role.COMPANY_ADMIN]"
            (click)="
              warehouseForAddingDoor = {
                id: warehouseListItem.id,
                name: warehouseListItem.name
              };
              modalService.openModal(addDoorContent);
              $event.stopPropagation()
            "
          >
            <ng-container i18n>Add door</ng-container>
          </span>
          <span class="btn btn-secondary">
            <ng-container i18n>Details</ng-container>
          </span>
        </td>
      </tr>

      <tr *ngIf="warehouseListItem.doors.length">
        <td colspan="2" class="door-table-td">
          <table class="table mb-0 table-borderless table-hover">
            <tbody>
              <tr class="door-table-tr c-pointer" *ngFor="let door of warehouseListItem.doors" [routerLink]="'/door/' + door.id">
                <td>
                  {{ door.name }}<br />
                  <span style="color: gray">{{ door.description }}</span>
                </td>
                <td width="25%" class="details-td">
                  <span class="btn btn-secondary ml-2" *ngIf="!isCarrier">
                    <ng-container i18n="Details">Details</ng-container>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="warehouse.warehouses.length === 0">
    <div class="d-flex align-items-center justify-content-center pt-3">
      <p i18n>No warehouses yet.</p>
    </div>
  </tbody>
</table>

<ng-template #addDoorContent let-modal>
  <app-add-door-modal
    [warehouse]="warehouseForAddingDoor"
    (onCancel)="modalService.closeModals(); warehouseForAddingDoor = null"
    (onAddSuccess)="modalService.closeModals(); warehouseForAddingDoor = null"
  ></app-add-door-modal>
</ng-template>
