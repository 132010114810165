import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { LocaleService } from "src/app/locale.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { MessageTranslationService } from "src/app/message-translation.service";
import { DoorFieldsFilter } from "src/app/models/Door";
import { ReservationField, ReservationFieldType } from "src/app/models/ReservationField";
import { RestService } from "src/app/services/rest.service";

@Component({
  selector: "app-important-fields-filter",
  templateUrl: "./important-fields-filter.component.html",
  styleUrls: ["./important-fields-filter.component.css"],
})
export class ImportantFieldsFilterComponent implements OnInit, OnChanges {
  fieldsFilter: DoorFieldsFilter[] = [];
  filtersSettings : IDropdownSettings = {};

  @Input() forDoor: boolean | null = null;
  @Input() doorId: number | null = null;
  @Input() warehouseId: number | null = null;
  @Input() timeWindowId: number | null = null;

  constructor(private locale: LocaleService, private toast: ToastrService, private msgT: MessageTranslationService, private http: RestService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.fetchFieldsFilter();
  }

  async fetchFieldsFilter() {
    if (this.forDoor) {
      await this.fetchDoorFieldsFilter();
    } else {
      await this.fetchTimeWindowFieldsFilter();
    }
  }

  async fetchDoorFieldsFilter() {
    if (this.warehouseId == null || this.doorId == null) {
      return;
    }

    try {
      const importantFields = await this.http.get<ReservationField[]>(`api/settings/getImportantWarehouseReservationFields/${this.warehouseId}`).toPromise();
      const existingFilters = await this.http.get<DoorFieldsFilter[]>(`api/door/getDoorFieldsFilter/${this.doorId}`).toPromise();
      this.setFieldsFilter(importantFields, existingFilters);
    } catch (e) {
      console.error(e);
    }
  }

  async fetchTimeWindowFieldsFilter() {
    if (this.doorId == null || this.timeWindowId == null) {
      return;
    }

    try {
      const importantFields = await this.http.get<ReservationField[]>(`api/settings/getDoorReservationFields/${this.doorId}`).toPromise();
      const existingFilters = await this.http.get<DoorFieldsFilter[]>(`api/door/getTimeWindowFieldsFilter/${this.timeWindowId}`).toPromise();
      this.setFieldsFilter(importantFields, existingFilters);
    } catch (e) {
      console.error(e);
    }
  }

  setFieldsFilter(importantFields: ReservationField[], existingFilters: DoorFieldsFilter[]) {
    this.fieldsFilter = [];

    for (let i = 0; i < importantFields.length; i++) {
      if (importantFields[i].type !== ReservationFieldType.Select) {
        continue;
      }

      const existingFilter = existingFilters.find((f) => f.reservationField.id === importantFields[i].id);
      const possibleValues = importantFields[i].selectValues.values;

      if (existingFilter) {
        this.fieldsFilter.push({ ...existingFilter, possibleValues });
      } else {
        this.fieldsFilter.push({
          reservationField: importantFields[i],
          value: "",
          values: [],
          possibleValues,
          reservationFieldId: importantFields[i].id,
        });
      }
    }
  }

  async onSaveChangesClick() {
    if (this.forDoor) {
      await this.setDoorFieldsFilter(this.fieldsFilter);
    } else {
      await this.setTimeWindowFieldsFilter(this.fieldsFilter);
    }
  }

  async setDoorFieldsFilter(fields: DoorFieldsFilter[]) {
    if (this.doorId == null) {
      return;
    }

    try {
      await this.http.post<void>(`api/door/setDoorFieldsFilter/${this.doorId}`, this.toRequestItems(fields)).toPromise();
      this.toast.success(this.msgT.updateSuccess());
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
      console.error(e);
    }
  }

  async setTimeWindowFieldsFilter(fields: DoorFieldsFilter[]) {
    if (this.timeWindowId == null) {
      return;
    }

    try {
      await this.http.post<void>(`api/door/setTimeWindowFieldsFilter/${this.timeWindowId}`, this.toRequestItems(fields)).toPromise();
      this.toast.success(this.msgT.updateSuccess());
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
      console.error(e);
    }
  }

  private toRequestItems(fields: DoorFieldsFilter[]) {
    return {
      items: fields.map((field) => ({
        reservationFieldId: field.reservationField.id,
        values: field.values,
      })),
    };
  }
}
