<ng-container *ngIf="door && reservation">
  <div class="form-group">
    <label><ng-container i18n>Date</ng-container></label>
    <app-pick-date [date]="reservationDateRange.date" (dateChange)="onReservationDateRangeDateChange($event)"></app-pick-date>
  </div>

  <ng-container *ngIf="door.properties.type == ReservationType.Free">
    <div class="d-flex">
      <app-app-time-input
        class="flex-1 w-100 mr-1"
        [time]="reservationDateRange?.start"
        (timeChange)="onReservationDateRangeTimeStartChange($event)"
        label="From"
        i18n-label
      ></app-app-time-input>
      <app-app-time-input
        class="flex-1 w-100 ml-1"
        [time]="reservationDateRange?.end"
        (timeChange)="onReservationDateRangeTimeEndChange($event)"
        label="To"
        i18n-label
      ></app-app-time-input>
    </div>
  </ng-container>

  <ng-container *ngIf="door.properties.type == ReservationType.Calculated">
    <div class="d-flex">
      <app-app-time-input
        class="flex-1 w-100 mr-1"
        [time]="reservationDateRange.start"
        (timeChange)="onReservationDateRangeTimeStartChange($event)"
        label="From"
        i18n-label
      ></app-app-time-input>
      <app-app-time-input class="flex-1 w-100 ml-1" [time]="reservationDateRange.end" label="To (calculated)" i18n-label [disabled]="true"></app-app-time-input>
    </div>
  </ng-container>

  <ng-container *ngIf="door.properties.type == ReservationType.Fixed">
    <td><ng-container i18n>Choose time window:</ng-container></td>
    <ng-container *ngIf="!reservationDateRange.date">
      <span class="d-block"><i i18n>Please select date.</i></span>
    </ng-container>
    <ng-container *ngIf="reservationDateRange.date">
      <span class="d-block" *ngIf="fixedTimeWindows.length === 0"><i i18n>No time windows available for this day.</i></span>
      <td class="row mb-1" *ngIf="fixedTimeWindows.length > 0">
        <div *ngFor="let tw of fixedTimeWindows" class="col-auto">
          <button
            (click)="selectFixedTimeWindow(tw)"
            class="btn"
            [ngClass]="{
              'btn-primary': selectedTimeWindowId == tw.id,
              'btn-secondary': selectedTimeWindowId != tw.id
            }"
          >
            {{ tw.start }}-{{ tw.end }}
          </button>
        </div>
      </td>
    </ng-container>
  </ng-container>

  <app-door-availability-calendar
    [door]="door"
    [selectedReservationId]="reservation.id"
    [dragToSelect]="door.properties.type === ReservationType.Free || door.properties.type === ReservationType.Calculated"
    [selection]="reservationDateRange"
    [selectedDate]="reservationDateRange.date"
    (selectionChange)="reservationDateRange = $event; onReservationDateRangeChange({}, true)"
  ></app-door-availability-calendar>

  <button class="btn btn-primary mt-2" (click)="onNextClick.emit()" [disabled]="!savedDateRange" i18n>Select time and continue</button>
</ng-container>
