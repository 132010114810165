import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../core/auth.service";
import { Availability } from "../models/Availability";
import { ReservationType } from "../models/Door";
import { TimeWindow } from "../models/TimeWindow";
import { faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";

export interface DateRange {
  date: Date;
  start: string;
  end: string;
}

export const isDateRangeValid = (r: DateRange) => {
  return r.date != null && r.start != null && r.end != null;
};

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-availability-display",
  templateUrl: "./availability-display.component.html",
  styleUrls: ["./availability-display.component.css"],
})
export class AvailabilityDisplayComponent implements OnInit {
  @Input() availability: Availability;
  @Input() type: ReservationType | null = null;
  @Input() doorId: number | null = null;

  faTrash = faTrash;
  faCopy = faCopy;

  ReservationType = ReservationType;
  Number = Number;

  twForSettingFilters: TimeWindow | null = null;

  constructor(public auth: AuthService, private modal: NgbModal) {}

  ngOnInit() {}

  AddWindow() {
    let tw = new TimeWindow();
    tw.start = "09:00:00";
    tw.end = "10:00:00";
    tw.availabilityId = this.availability.id;
    tw.bookableSlots = 1;
    tw.bookablePallets = 0;

    this.availability.timeWindows.push(tw);
  }

  Remove(tw: TimeWindow) {
    let key: string = null;
    for (let k in this.availability.timeWindows) {
      let t = this.availability.timeWindows[k];
      if (t.id == tw.id) {
        key = k;
        break;
      }
    }
    if (key != null) {
      this.availability.timeWindows.splice(parseInt(key), 1);
    }
  }

  Copy(tw: TimeWindow) {
    this.availability.timeWindows.push({
      id: null,
      bookableWeekdays: tw.bookableWeekdays ? [...tw.bookableWeekdays] : null,
      timeWindowFieldsFilter: tw.timeWindowFieldsFilter ? [...tw.timeWindowFieldsFilter] : null,
      ...tw,
    });
  }

  OnSetFiltersClick(tw: TimeWindow, content: any) {
    this.twForSettingFilters = tw;
    this.modal.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      () => {
        this.twForSettingFilters = null;
      },
      () => {
        this.twForSettingFilters = null;
      }
    );
  }
}
