<ng-container
  *ngIf="
    reservationData != null &&
    selectedWarehouse != null &&
    (mode == ReservationFlowMode.CREATING_TWO_PHASE || selectedDoor != null) &&
    (mode == ReservationFlowMode.CREATING_TWO_PHASE || reservationData.dateRange != null)
  "
>
  <div class="mb-2">
    <h3 i18n class="mb-0">Review</h3>
    <span class="d-flex" i18n>Please review reservation data before booking.</span>
  </div>

  <div>
    <h5 i18n>Location</h5>
    <span class="d-block"><b i18n>Warehouse:</b> {{ selectedWarehouse.name }}</span>
    <span class="d-block">
      <b i18n>Door: </b>
      <ng-container *ngIf="selectedDoor">{{ selectedDoor.name || "/" }}</ng-container>
      <ng-container *ngIf="!selectedDoor" i18n>The door will be determined when your two-phase reservation gets approved.</ng-container>
    </span>
  </div>

  <div class="mt-3">
    <h5 i18n>Time</h5>
    <div *ngIf="mode === ReservationFlowMode.CREATING_TWO_PHASE">
      <ng-container><i i18n>The time will be determined when your two-phase reservation gets approved.</i></ng-container>
    </div>

    <ng-container *ngIf="mode !== ReservationFlowMode.CREATING_TWO_PHASE">
      <div *ngIf="!reservationData.reservation.isRecurring">
        <span class="d-block"><b i18n>Date:</b> {{ dateService.toTime(reservationData.dateRange.date, { onlyDate: true }) }}</span>
        <span class="d-block" *ngIf="reservationData.dateRange.start && reservationData.dateRange.end">
          <b i18n>Time:</b> {{ reservationData.dateRange.start }} - {{ reservationData.dateRange.end }}
        </span>
      </div>

      <div *ngIf="reservationData.reservation.isRecurring">
        {{ support.recurringReservationToString(reservationData.reservation.recurrenceRule) }}
      </div>
    </ng-container>
  </div>

  <div class="mt-3">
    <h5 i18n>Data</h5>
    <app-reservation-data-table [reservationData]="reservationFields"></app-reservation-data-table>
  </div>

  <ng-container *ngIf="isGuest">
    <app-app-text-input
      name="guestEmail"
      [type]="'email'"
      [value]="guestEmail"
      (valueChange)="onGuestEmailChange($event)"
      [placeholder]="'Enter your contact email...'"
    >
      <ng-container>
        <span class="d-block" i18n>Your contact email</span>
        <small class="d-block" i18n>
          The confirmation of the reservation will be sent the this email address. You must enter this email to create a booking.
        </small>
      </ng-container>
    </app-app-text-input>

    <p *ngIf="!isGuestEmailValid()" class="text-danger m-0" i18n>Please enter a valid contact email to book.</p>

    <div class="mb-2"></div>
  </ng-container>

  <button class="btn btn-primary" [disabled]="!isGuestEmailValid() || loading" (click)="onReservationBooked.emit(guestEmail)">
    <ng-container *ngIf="!loading">{{ bookButtonText }}</ng-container>
    <ng-container *ngIf="loading" i18n>Please wait ...</ng-container>
  </button>
</ng-container>
