import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { Company } from "../models/Company";
import { AddDoorRequest, DoorExcerptDto } from "../models/Door";
import { Permission, PermissionStatus, PermissionType } from "../models/Permission";
import { ReservationField } from "../models/ReservationField";
import {
  AddWarehouseRequest,
  WarehouseDoorListItem,
  WarehouseExcerptDto,
  WarehouseListItem,
  WarehouseCompany,
  WarehouseCompanyListItem,
  Warehouse,
} from "../models/Warehouse";
import { RestService } from "./rest.service";
import { hasPermissionsForDoor } from "./support.service";

@Injectable({
  providedIn: "root",
})
export class WarehouseService {
  constructor(
    private http: RestService,
    private auth: AuthService,
    private router: Router,
    private toast: ToastrService,
    private msgT: MessageTranslationService
  ) {}

  async fetchAllWarehouses(): Promise<WarehouseCompanyListItem[]> {
    const warehouses = await this.http.get<WarehouseCompanyListItem[]>(`api/warehouse/list`).toPromise();

    warehouses.forEach((w) => {
      this.assignFilterStringToWarehouse(w);
    });

    return warehouses;
  }

  async fetchBookableCompanyWarehouses(companyId: number): Promise<WarehouseCompanyListItem> {
    const warehouses = await this.http.get<WarehouseCompanyListItem>(`api/warehouse/bookableWarehouses/${companyId}`).toPromise();
    return warehouses;
  }

  async fetchMyWarehouses(): Promise<WarehouseCompanyListItem[]> {
    const warehouses = await this.http.get<WarehouseCompanyListItem[]>(`api/warehouse/myList`).toPromise();

    warehouses.forEach((w) => {
      this.assignFilterStringToWarehouse(w);
    });

    return warehouses;
  }

  private assignFilterStringToWarehouse(warehouse: WarehouseCompanyListItem) {
    warehouse.company.filterString = this.filterStringForCompany(warehouse.company);
    warehouse.warehouses.forEach((w) => {
      w.filterString = this.filterStringForWarehouse(w);
      w.doors.forEach((d) => {
        d.filterString = this.filterStringForDoor(d);
      });
    });
  }

  private filterStringForCompany(company: WarehouseCompany) {
    return `${company.name} ${company.phone} ${company.contactPerson} ${company.address}`.toLowerCase();
  }

  private filterStringForWarehouse(warehouse: WarehouseListItem) {
    return `${warehouse.name} ${warehouse.description}`.toLowerCase();
  }

  private filterStringForDoor(door: WarehouseDoorListItem) {
    return `${door.name} ${door.description}`.toLowerCase();
  }

  filterWarehousesByFilterString(warehouses: WarehouseCompanyListItem[], filterString: string) {
    if (filterString == null || filterString.trim().length === 0) {
      return warehouses;
    }

    filterString = filterString.toLowerCase();

    return warehouses
      .map((warehouse) => {
        if (warehouse.company.filterString.includes(filterString)) {
          return warehouse;
        }

        const matchingWarehouses = warehouse.warehouses
          .map((w) => {
            if (w.filterString.includes(filterString)) {
              return w;
            }

            const matchingDoors = w.doors.filter((d) => d.filterString.includes(filterString));

            if (matchingDoors.length > 0) {
              return {
                ...w,
                doors: matchingDoors,
              };
            }

            return null;
          })
          .filter((w) => w != null);

        if (matchingWarehouses.length > 0) {
          return {
            ...warehouse,
            warehouses: matchingWarehouses,
          };
        }

        return null;
      })
      .filter((w) => w != null);
  }

  async requestPermission(warehouseId: number): Promise<Permission | null> {
    try {
      const permission = await this.http.post<Permission>(`api/carrier/createPermission/${warehouseId}`, {}).toPromise();
      if (permission.status === PermissionStatus.Accepted) {
        this.toast.success(this.msgT.permissionAutoAccepted());
      } else {
        this.toast.success(this.msgT.permissionRequested());
      }
      return permission;
    } catch (e) {
      console.log(e);
      this.toast.error(this.msgT.permissionError());
    }

    return null;
  }

  async addWarehouse(req: AddWarehouseRequest): Promise<WarehouseExcerptDto | null> {
    try {
      const warehouse = await this.http.post<WarehouseExcerptDto>(`api/warehouse/addWarehouse`, req).toPromise();
      this.toast.success(this.msgT.addWarehouseSuccess());
      return warehouse;
    } catch (e) {
      console.log(e);
      this.toast.error(this.msgT.addWarehouseError());
    }

    return null;
  }

  async addDoor(warehouseId: number, req: AddDoorRequest): Promise<DoorExcerptDto | null> {
    try {
      const warehouse = await this.http.post<WarehouseExcerptDto>(`api/warehouse/addDoor/${warehouseId}`, req).toPromise();
      this.toast.success(this.msgT.addDoorSuccess());
      return warehouse;
    } catch (e) {
      console.log(e);
      this.toast.error(this.msgT.addDoorError());
    }

    return null;
  }

  async fetchWarehouseAndDoorMultiselectOptions(isCarrier: boolean) {
    const warehousesOptions = [];
    const doorsOptions = [];

    try {
      const myWarehouses = await this.fetchMyWarehouses();

      for (let mw = 0; mw < myWarehouses.length; mw++) {
        const myWarehouse = myWarehouses[mw];

        for (let w = 0; w < myWarehouse.warehouses.length; w++) {
          const warehouse = myWarehouse.warehouses[w];
          if (isCarrier && warehouse.permission.status !== PermissionStatus.Accepted) {
            continue;
          }

          warehousesOptions.push({
            item_id: warehouse.id,
            item_text: warehouse.name,
          });

          for (let d = 0; d < warehouse.doors.length; d++) {
            const door = warehouse.doors[d];
            if (isCarrier && !hasPermissionsForDoor(warehouse.permission, door.id)) {
              continue;
            }

            doorsOptions.push({
              item_id: door.id,
              item_text: `${door.name} (${warehouse.name})`,
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
    }

    return { warehousesOptions, doorsOptions };
  }

  public hasPermissionToBook(warehouse: Warehouse, permission: Permission | null): boolean {
    if (this.auth.loggedInUser == null && warehouse.canCarrierCreateAnonymousReservation) {
      return true;
    }

    const isPermissionAccepted = permission?.status === PermissionStatus.Accepted;
    return this.auth.IsWarehouse() || (isPermissionAccepted && permission?.type !== PermissionType.ONLY_TWO_PHASE);
  }

  public hasPermissionToBookTwoPhase(warehouse: Warehouse, permission: Permission | null, company: Company): boolean {
    if (company.disableTwoPhaseReservations) {
      return false;
    }

    if (this.auth.loggedInUser == null && warehouse.canCarrierCreateAnonymousReservation) {
      return true;
    }

    const isPermissionAccepted = permission?.status === PermissionStatus.Accepted;
    return this.auth.IsWarehouse() || isPermissionAccepted;
  }

  navigateToWarehouseReservation(companyId: number, warehouseId: number) {
    this.router.navigate(["reserve-company", companyId], { queryParams: { warehouseId: warehouseId } });
  }

  async uploadWarehouseImage(imageFile: File, warehouse: WarehouseExcerptDto) {
    if (imageFile == null || warehouse == null) return;

    const formData: FormData = new FormData();
    formData.append("file", imageFile, imageFile.name);

    try {
      await this.http.postFormData(`api/file/uploadWarehouseImage/${warehouse.id}`, formData).toPromise();
      this.auth.Refresh();
    } catch (error) {
      console.log(error);
    }
  }

  async getWarehouseReservationFields(id: number): Promise<ReservationField[]> {
    try {
      return await this.http.get<ReservationField[]>(`api/settings/getWarehouseReservationFields/${id}`).toPromise();
    } catch (e) {
      console.error("getWarehouseReservationFields error", e);
      return [];
    }
  }
}
