<div class="container app-container arrival-table-container">
  <app-company-first-time-setup-notice></app-company-first-time-setup-notice>
  <h1 class="app-heading" i18n>My warehouses</h1>
  <app-company-reservation-link></app-company-reservation-link>
  <app-warehouse-list-search-bar [disabled]="loading" [(filterText)]="filterText" (onRefresh)="onRefresh()"></app-warehouse-list-search-bar>

  <div class="row mb-2" *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
    <div class="col-3">
      <button class="btn btn-primary w-100" (click)="modalService.openModal(addWarehouseContent)" i18n>Add warehouse</button>
    </div>
  </div>

  <ng-container *ngIf="loading">
    <app-spinner></app-spinner>
  </ng-container>

  <ng-container *ngIf="!loading">
    <app-warehouse-list [warehouses]="myCompaniesWithWarehouses" [filterText]="filterText" [isCarrier]="isCarrier"></app-warehouse-list>
  </ng-container>
</div>

<ng-template #addWarehouseContent let-modal>
  <app-add-warehouse-modal (onCancel)="modalService.closeModals()" (onAddSuccess)="modalService.closeModals()"></app-add-warehouse-modal>
</ng-template>
