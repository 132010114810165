import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import {
  getFieldByMeaning,
  getReservationFieldErrorMessage,
  isHalfPalletsField,
  isPalletsField,
  isStringNullOrEmpty,
  ReservationField,
  ReservationFieldSpecialMeaningField,
  ReservationFieldType,
} from "../models/ReservationField";
import { RestService } from "../services/rest.service";
import { Driver, parseFullName } from "../models/Driver";
import { AuthService } from "../core/auth.service";
import { DomSanitizer } from "@angular/platform-browser";
import { Inject, LOCALE_ID } from "@angular/core";

export interface OnReservationFieldsChangeData {
  isValid: boolean;
  triggeredByChange: boolean;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-reservation-fields-form",
  templateUrl: "./reservation-fields-form.component.html",
  styleUrls: ["./reservation-fields-form.component.css"],
})
export class ReservationFieldsFormComponent implements OnInit, OnChanges {
  @Input() reservationFields: ReservationField[] = [];
  @Input() confirmButtonText: string;
  @Input() loading: boolean = false;

  @Input() isCreatingNew: boolean = true;

  @Input() validateOnEachChange: boolean = false;

  codeAutofillLoading = false;

  JSON = JSON;

  @Output() onConfirmClick: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onPalletsFieldNgModelChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() hideConfirmButton: boolean = false;
  @Output() onReservationFieldsChange: EventEmitter<OnReservationFieldsChangeData> = new EventEmitter<OnReservationFieldsChangeData>();

  ReservationFieldType = ReservationFieldType;
  ReservationFieldSpecialMeaningField = ReservationFieldSpecialMeaningField;
  @Inject(LOCALE_ID) public locale: string

  constructor(
    public domSanitizer: DomSanitizer,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    private http: RestService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {}

  shouldHideField(reservationField: ReservationField) {
    if (reservationField.hideField) {
      return true;
    }

    if (reservationField.hideForCarriers && this.auth.IsCarrier()) {
      return true;
    }

    return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isCreatingNew || changes.reservationFields) {
      this.SetDefaults();
    }

    if (changes.reservationFields) {
      this.emitReservationFieldsChange(false);
    }
  }

  SetDefaults() {
    if (!this.isCreatingNew) {
      return;
    }

    for (let field of this.reservationFields) {
      if (field.value == null) {
        field.value = field.default;
      }
    }

    this.emitReservationFieldsChange(false);
  }

  onFieldNgModelChange(field: ReservationField, value: any) {
    field.value = value;
    if (isPalletsField(field) || isHalfPalletsField(field)) {
      let pallets = 0;

      const palletsField = this.reservationFields.find((f) => isPalletsField(f));
      if (palletsField) {
        pallets += Number(palletsField.value);
      }

      const halfPalletsField = this.reservationFields.find((f) => isHalfPalletsField(f));
      if (halfPalletsField) {
        pallets += Number(halfPalletsField.value) * 0.5;
      }

      this.onPalletsFieldNgModelChange.emit(pallets);
    }

    this.emitReservationFieldsChange(true);
  }

  emitReservationFieldsChange(triggeredByChange: boolean): void {
    const isValid = this.checkIfFormValid(!this.validateOnEachChange);
    this.onReservationFieldsChange.emit({
      isValid,
      triggeredByChange,
    });
  }

  onFormSubmit() {
    const isValid = this.checkIfFormValid();

    if (!isValid) {
      this.toast.warning(this.msgT.errorValidatingForm());
      return;
    }

    this.onConfirmClick.emit();
  }

  checkIfFormValid(justChecking = false) {
    let isValid = true;

    for (let field of this.reservationFields) {
      if (!justChecking) {
        field.errorMessage = null;
      }

      const errorMessage = getReservationFieldErrorMessage(field, this.shouldHideField(field), this.msgT.reservationFieldErrorMessage());
      if (!justChecking) {
        field.errorMessage = errorMessage;
      }

      if (errorMessage != null) {
        isValid = false;
        if (justChecking) {
          return false;
        }
      }
    }

    return isValid;
  }

  async autofillFromDriverCode(code: string) {
    const isCodeNullOrEmpty = isStringNullOrEmpty(code);
    if (isCodeNullOrEmpty) {
      this.toast.warning(this.msgT.pleaseEnterDriverCode());
      return;
    }

    const driverNameField = getFieldByMeaning(this.reservationFields, ReservationFieldSpecialMeaningField.DRIVER_NAME);
    let inputDriverName = null;
    if (driverNameField) {
      inputDriverName = driverNameField.value || "";
      const isNameNullOrEmpty = isStringNullOrEmpty(inputDriverName);
      if (isNameNullOrEmpty) {
        this.toast.warning(this.msgT.pleaseEnterDriverName());
        return;
      }
    }

    const driverSurnameField = getFieldByMeaning(this.reservationFields, ReservationFieldSpecialMeaningField.DRIVER_SURNAME);
    let inputDriverSurname = null;
    if (driverSurnameField) {
      inputDriverSurname = driverSurnameField.value || "";
      const isSurnameNullOrEmpty = isStringNullOrEmpty(inputDriverSurname);
      if (isSurnameNullOrEmpty) {
        this.toast.warning(this.msgT.pleaseEnterDriverSurname());
        return;
      }
    }

    try {
      this.codeAutofillLoading = true;

      const driver = await this.http
        .post<Driver>("api/company/getDriverByCode", { code: code.trim(), name: inputDriverName, surname: inputDriverSurname })
        .toPromise();

      this.toast.success(this.msgT.driverAutofillSuccess());

      const codeField = getFieldByMeaning(this.reservationFields, ReservationFieldSpecialMeaningField.YAMAS_DRIVER_CODE);
      if (codeField) {
        this.onFieldNgModelChange(codeField, driver.code);
      }

      const [firstName, lastName] = parseFullName(driver);

      const nameField = getFieldByMeaning(this.reservationFields, ReservationFieldSpecialMeaningField.DRIVER_NAME);
      if (nameField) {
        this.onFieldNgModelChange(nameField, firstName);
      }

      const surnameField = getFieldByMeaning(this.reservationFields, ReservationFieldSpecialMeaningField.DRIVER_SURNAME);
      if (surnameField) {
        this.onFieldNgModelChange(surnameField, lastName);
      }

      const transportCompanyField = getFieldByMeaning(this.reservationFields, ReservationFieldSpecialMeaningField.TRANSPORT_COMPANY);
      if (transportCompanyField) {
        this.onFieldNgModelChange(transportCompanyField, driver.transportCompany);
      }

      const transportCompanyCountryField = getFieldByMeaning(this.reservationFields, ReservationFieldSpecialMeaningField.TRANSPORT_COMPANY_COUNTRY);
      if (transportCompanyCountryField) {
        this.onFieldNgModelChange(transportCompanyCountryField, driver.transportCompanyCountry.id);
      }

      const emailField = getFieldByMeaning(this.reservationFields, ReservationFieldSpecialMeaningField.EMAIL);
      if (emailField) {
        this.onFieldNgModelChange(emailField, driver.email);
      }
    } catch (e) {
      console.error(e);
      this.toast.error(this.msgT.driverCodeDoesntExist());
    }

    this.codeAutofillLoading = false;
  }
}
