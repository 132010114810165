import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MessageTranslationService } from "../message-translation.service";
import { EmailTemplatePlaceholder, EmailTemplatePlaceholderLocation, EmailTemplatePlaceholderLocationMapping } from "../models/EmailTemplate";

@Component({
  selector: "app-email-template-placeholder-dropdown",
  templateUrl: "./email-template-placeholder-dropdown.component.html",
  styleUrls: ["./email-template-placeholder-dropdown.component.css"],
})
export class EmailTemplatePlaceholderDropdownComponent implements OnInit, OnChanges {
  @Input() location: EmailTemplatePlaceholderLocation;
  @Input() disabled: boolean;

  placeholders: { type: EmailTemplatePlaceholder; text: string }[] = [];

  @Output() onPlaceholderSelect = new EventEmitter<EmailTemplatePlaceholder>();

  selectValue = null;

  constructor(private msgT: MessageTranslationService) {}

  ngOnInit(): void {
    this.assignPlaceholders();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.location) {
      this.assignPlaceholders();
    }
  }

  assignPlaceholders() {
    this.placeholders = [];

    const allPlaceholders = this.msgT.getEmailTemplatePlaceholders();
    const allPlaceholderTypes = Object.keys(allPlaceholders) as EmailTemplatePlaceholder[];
    for (const type of allPlaceholderTypes) {
      if (!EmailTemplatePlaceholderLocationMapping[type].includes(this.location)) {
        continue;
      }

      this.placeholders.push({
        type,
        text: allPlaceholders[type],
      });
    }
  }

  onSelectPlaceholder(placeholder: EmailTemplatePlaceholder) {
    this.onPlaceholderSelect.emit(placeholder);
  }

  onValueSelected(type) {
    this.selectValue = type;
    setTimeout(() => {
      this.selectValue = null;
    }, 10);
  }
}
