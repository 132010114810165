<nav aria-label="breadcrumb" *ngIf="auth.loggedInUser && auth.loggedInUser.id && (bDoor || bWarehouse)">
  <ol class="breadcrumb">
    <ng-container *ngIf="bWarehouse">
      <li class="breadcrumb-item" *ngIf="bWarehouse.companyId == auth.loggedInUser.company?.id">
        <a [routerLink]="'/my-warehouses'"><ng-container i18n="My warehouses">My warehouses</ng-container></a>
      </li>
      <li class="breadcrumb-item" *ngIf="bWarehouse.companyId != auth.loggedInUser.company?.id">
        <a [routerLink]="'/warehouses'"><ng-container i18n="Warehouses">Warehouses</ng-container></a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="'/warehouse/' + bWarehouse.id">{{ bWarehouse.name }}</a>
      </li>
    </ng-container>
    <ng-container *ngIf="bDoor">
      <li class="breadcrumb-item active">
        <a [routerLink]="'/door/' + bDoor.id">{{ bDoor.name }}</a>
      </li>
    </ng-container>
  </ol>
</nav>
