import { Component, OnInit, Input } from "@angular/core";
import { ReservationStatus } from "../models/Reservation";

@Component({
  selector: "app-reservation-status",
  templateUrl: "./reservation-status.component.html",
  styleUrls: ["./reservation-status.component.css"],
})
export class ReservationStatusComponent implements OnInit {
  @Input() reservationStatus: ReservationStatus;
  ReservationStatus = ReservationStatus;

  constructor() {}

  ngOnInit(): void {}
}
