<div *ngIf="isLoading" class="spinner-border ml-1" role="status">
  <span class="sr-only">Loading...</span>
</div>

<ng-container *ngIf="!isLoading">
  <p i18n style="text-align: center" *ngIf="!rows.length">{{ noDataText }}</p>

  <div>
    <p-table
      #dt
      *ngIf="rowData.length"
      [loading]="isLoading"
      [columns]="selectedColumns"
      [value]="rowData"
      [paginator]="true"
      [rows]="10"
      [totalRecords]="totalRecords"
      [resizableColumns]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      i18n-currentPageReportTemplate
      [rowsPerPageOptions]="[10, 25, 50]"
      responsiveLayout="scroll"
      styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
      columnResizeMode="expand"
      stateStorage="local"
      stateKey="{{ tableId }}dataTable-filterState"
      (sortFunction)="customSort($event)"
      [customSort]="true"
    >
      <ng-template pTemplate="caption">
        <div class="d-flex justify-content-between">
          <p-multiSelect
            [options]="columnData"
            [(ngModel)]="selectedColumns"
            optionLabel="text"
            i18n-selectedItemsLabel
            selectedItemsLabel="{0} columns selected"
            [style]="{ minWidth: '300px' }"
            i18n-placeholder
            placeholder="Choose Columns"
          ></p-multiSelect>

          <button (click)="exportToCsv()" class="btn btn-info export-button">
            <span class="d-inline-block mr-2" i18n>Export CSV</span>
            <fa-icon [icon]="faFile"></fa-icon>
          </button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.id">{{ col.text }} <p-sortIcon [field]="col.id"> </p-sortIcon></th>
          <th pResizableColumn i18n>Details / edit / delete</th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" class="search-input-th">
            <input
              pInputText
              class="form-control"
              type="text"
              (input)="dt.filter($event.target.value, col.id, 'contains')"
              [value]="dt.filters[col.id]?.value"
            />
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <ng-container *ngIf="rData[col.id] != null">
              <ng-container *ngIf="col.id | dataTableEntryAccess: rows[rData.index].entries as rowItem; else noValue">
                <a class="d-block" style="color: black" *ngIf="rowItem.additionalData?.link; else noLink" [routerLink]="rowItem.additionalData?.link">
                  <u>{{ rData[col.id] }}</u>
                </a>
                <ng-template #noLink>{{ rData[col.id] }}</ng-template>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!rData[col.id]">-</ng-container>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <button
                *ngFor="let action of rows[rData.index].actions"
                (click)="onRowAction.emit({ action: action.actionName, id: rows[rData.index].id })"
                class="btn {{ action.buttonClass }} mx-1 icon-button"
                [pTooltip]="action.actionTranslation"
                tooltipPosition="top"
              >
                <fa-icon *ngIf="action.icon" [icon]="action.icon"></fa-icon>
                <ng-container *ngIf="action.translation">{{ action.translation }}</ng-container>
              </button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>
