<div class="form-check mb-2">
  <input
    class="form-check-input"
    type="checkbox"
    [attr.id]="name"
    [ngModel]="value"
    (ngModelChange)="valueChange.emit($event)"
    [ngModelOptions]="{ standalone: true }"
    [disabled]="disabled"
  />
  <label class="form-check-label c-pointer" [attr.for]="name" i18n>
    <ng-content></ng-content>
  </label>
</div>
