<div>
  <app-app-checkbox name="Mon" [value]="isSelected(1)" (valueChange)="onValueChange(1)">
    <span i18n>Mon</span>
  </app-app-checkbox>
  <app-app-checkbox name="Tue" [value]="isSelected(2)" (valueChange)="onValueChange(2)">
    <span i18n>Tue</span>
  </app-app-checkbox>
  <app-app-checkbox name="Wed" [value]="isSelected(3)" (valueChange)="onValueChange(3)">
    <span i18n>Wed</span>
  </app-app-checkbox>
  <app-app-checkbox name="Thu" [value]="isSelected(4)" (valueChange)="onValueChange(4)">
    <span i18n>Thu</span>
  </app-app-checkbox>
  <app-app-checkbox name="Fri" [value]="isSelected(5)" (valueChange)="onValueChange(5)">
    <span i18n>Fri</span>
  </app-app-checkbox>
  <app-app-checkbox name="Sat" [value]="isSelected(6)" (valueChange)="onValueChange(6)">
    <span i18n>Sat</span>
  </app-app-checkbox>
  <app-app-checkbox name="Sun" [value]="isSelected(0)" (valueChange)="onValueChange(0)">
    <span i18n>Sun</span>
  </app-app-checkbox>
</div>
