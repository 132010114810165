import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { WarehouseCompanyListItem } from "src/app/models/Warehouse";
import { WarehouseService } from "src/app/services/warehouse.service";

@Component({
  selector: "app-warehouse-list",
  templateUrl: "./warehouse-list.component.html",
  styleUrls: ["./warehouse-list.component.css"],
})
export class WarehouseListComponent implements OnInit, OnChanges {
  @Input() warehouses: WarehouseCompanyListItem[] = [];
  @Input() filterText: string = "";
  @Input() isCarrier: boolean;

  @Output() onRequestPermission: EventEmitter<number> = new EventEmitter<number>();

  filteredWarehouses: WarehouseCompanyListItem[] = [];

  constructor(private warehouseService: WarehouseService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!(changes?.filterText || changes?.warehouses)) {
      return;
    }

    this.filteredWarehouses = this.warehouseService.filterWarehousesByFilterString(this.warehouses, this.filterText);
  }
}
