import { Warehouse } from "./Warehouse";
import { Availability } from "./Availability";
import { Reservation } from "./Reservation";
import { ReservationField } from "./ReservationField";

export enum ReservationType {
  Fixed,
  Calculated,
  Free,
}
export class DoorProperties {
  type: ReservationType;
  timePerPallet: string;
  baseTime: string;
}
export class Door {
  id: number;
  name: string;
  warehouseId: number;
  availabilityId: number;
  dailyPalletsLimit: number;
  properties: DoorProperties;
  description: string;

  warehouse: Warehouse;
  availability: Availability;
  reservations: Array<Reservation>;

  filterString: string;

  reserved: any = null;
  reservedDatePick: Date = new Date();

  doorFieldsFilters: DoorFieldsFilter[];
}

export interface DoorExcerptDto {
  id: number;
  name: string;
}

export interface AddDoorRequest {
  name: string;
  description: string;
}

export interface DoorFieldsFilter {
  reservationField: ReservationField;
  value: string;
  values: string[];
  possibleValues: string[];
  reservationFieldId: number;
}
