import { Injectable } from "@angular/core";
import { Country } from "../models/Country";
import { RestService } from "./rest.service";

@Injectable({
  providedIn: "root",
})
export class CountriesService {
  countries: Country[] = [];

  constructor(private http: RestService) {
    this.http.get(`api/country/list`).subscribe(
      (countries: Country[]) => {
        this.countries = countries;
      },
      (error) => {
        console.log("Error getting countries!");
        console.log(error);
      }
    );
  }

  public getCountries() {
    return this.countries;
  }

  public getCountryById(countryID: number): Country | null {
    return this.countries.find((c) => c.id === Number(countryID));
  }
}
