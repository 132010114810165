import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { ReservationDtoType } from "src/app/models/MyReservations";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-reservation-list-tabs",
  templateUrl: "./reservation-list-tabs.component.html",
  styleUrls: ["./reservation-list-tabs.component.css"],
})
export class ReservationListTabsComponent implements OnInit {
  ReservationDtoType = ReservationDtoType;
  @Input() selectedTab: ReservationDtoType = ReservationDtoType.STANDARD;
  @Output() selectedTabChange: EventEmitter<ReservationDtoType> = new EventEmitter<ReservationDtoType>();

  constructor() {}

  ngOnInit(): void {}
}
