<ng-container *ngIf="fieldsFilter.length === 0">
  <p class="mb-0 mt-2">
    <ng-container i18n *ngIf="forDoor">No fields yet - you can only filter by warehouse important fields of type select.</ng-container>
    <ng-container i18n *ngIf="!forDoor">No fields yet - you can only filter by door reservation fields of type select.</ng-container>
  </p>
</ng-container>
<div class="form-group" *ngFor="let filter of fieldsFilter">
  <div class="form-group">
    <label>
      <ng-container i18n>{{ filter.reservationField.name }}</ng-container>
    </label>
    <ng-multiselect-dropdown
      class="ng-multiselect-dropdown"
      placeholder="Select filters"
      i18n-placeholder
      [settings]="filtersSettings"
      [data]="filter.reservationField.selectValues.values"
      [(ngModel)]="filter.values"
    >
    </ng-multiselect-dropdown>
  </div>
</div>
<div class="d-flex" *ngIf="fieldsFilter.length > 0">
  <button class="btn btn-primary" (click)="onSaveChangesClick()">
    <ng-container i18n>Save Changes</ng-container>
  </button>
</div>
