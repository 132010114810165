<span *ngIf="sortedWeekdays.length === 0">
  <ng-container i18n>None</ng-container>
</span>

<span class="text-center" *ngIf="sortedWeekdays.length > 0">
  <ng-container *ngFor="let weekDay of sortedWeekdays; let last = last">
    <ng-container [ngSwitch]="weekDay">
      <ng-container *ngSwitchCase="1" i18n>Mon</ng-container>
      <ng-container *ngSwitchCase="2" i18n>Tue</ng-container>
      <ng-container *ngSwitchCase="3" i18n>Wed</ng-container>
      <ng-container *ngSwitchCase="4" i18n>Thu</ng-container>
      <ng-container *ngSwitchCase="5" i18n>Fri</ng-container>
      <ng-container *ngSwitchCase="6" i18n>Sat</ng-container>
      <ng-container *ngSwitchCase="0" i18n>Sun</ng-container>
    </ng-container>
    <ng-container *ngIf="!last">, </ng-container>
  </ng-container>
</span>
