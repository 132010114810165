import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-day-display",
  templateUrl: "./day-display.component.html",
  styleUrls: ["./day-display.component.css"],
})
export class DayDisplayComponent implements OnInit {
  @Input() recurrenceRule: string;

  constructor() {}

  ngOnInit(): void {}
}
