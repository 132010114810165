import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { platform } from "os";
import { Observable } from "rxjs";
import { DateRange } from "src/app/availability-display/availability-display.component";
import { DoorService } from "src/app/door.service";
import { MessageTranslationService } from "src/app/message-translation.service";
import { Door, ReservationType } from "src/app/models/Door";
import { ReservationDto } from "src/app/models/MyReservations";
import { Reservation } from "src/app/models/Reservation";
import { isHalfPalletsField, isPalletsField } from "src/app/models/ReservationField";
import { TimeWindow } from "src/app/models/TimeWindow";
import { RouteHelperService } from "src/app/router.service";
import { ReservationService } from "src/app/services/reservation.service";
import { TimespanConverterService } from "src/app/timespan-converter.service";
import { SelectedCalendarDate } from "../reservation-calendar/reservation-calendar.component";

@Component({
  selector: "app-create-or-edit-reservation-on-door",
  templateUrl: "./create-or-edit-reservation-on-door.component.html",
  styleUrls: ["./create-or-edit-reservation-on-door.component.css"],
})
export class CreateOrEditReservationOnDoorComponent implements OnInit, OnChanges {
  @Input() door: Door | null = null;
  @Input() reservationToEditId: number | null = null;
  @Input() reservationToEditCode: string | null = null;

  @Input() isApproving: boolean = false;

  ReservationType = ReservationType;

  reservation: Reservation;
  selectedTimeWindowId: number = null;

  isEditingReservation: boolean = false;

  isRecurring: boolean = false;
  loadingDelete: boolean = false;

  files: File[] = [];

  loading = false;

  reservationDate: Date;
  inputFromTimeWindow: string;
  inputToTimeWindow: string;

  reservationDateRange: DateRange;

  calendarSelectedDate: SelectedCalendarDate | null = null;
  reservationsForWeek: ReservationDto[] = [];

  confirmButtonText = "";

  constructor(
    private msgT: MessageTranslationService,
    private reservationService: ReservationService,
    private doorService: DoorService,
    private routeHelper: RouteHelperService,
    private timespanConverter: TimespanConverterService,
    private router: Router,
    public modalService: NgbModal
  ) {
    this.init();
  }

  private init() {
    this.reservation = new Reservation();
    this.reservationDateRange = {
      date: new Date(),
      start: "09:00:00",
      end: "10:00:00",
    };
  }

  ngOnInit(): void {
    this.initReservationCreateOrEdit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reservationToEditId || changes.isApproving) {
      this.initReservationCreateOrEdit();
    }

    if (changes.door) {
      this.onDoorChange();
    }
  }

  // the goal of this method is that door and reservation variables are set
  private async initReservationCreateOrEdit() {
    if (this.reservationToEditId != null) {
      await this.initReservationEdit(this.reservationToEditId, this.reservationToEditCode);
    } else if (this.door != null) {
      this.initReservationCreate();
    } else {
      return;
    }

    this.onDoorChange();
  }

  private initReservationCreate() {
    this.isEditingReservation = false;
    this.confirmButtonText = this.msgT.getReservationConfirmNames().CREATE;
  }

  private async initReservationEdit(reservationId: number, reservationCode: string) {
    const reservationToEdit = await this.reservationService.getReservationToEdit(reservationId, reservationCode, this.isApproving);
    if (reservationToEdit == null) {
      return;
    }

    if (reservationToEdit.doorId != null) {
      const door = await this.doorService.getDoor(reservationToEdit.doorId);
      if (!door) {
        return;
      }

      this.door = door;
    }

    this.isEditingReservation = true;
    this.confirmButtonText = this.isApproving ? this.msgT.getReservationConfirmNames().APPROVE : this.msgT.getReservationConfirmNames().EDIT;
    this.reservation = new Reservation();
    this.reservation.copyDataFrom(reservationToEdit);
  }

  async onDoorSelected(doorId: number | null) {
    if (doorId == null) {
      this.door = null;
      return;
    }

    const door = await this.doorService.getDoor(doorId);
    if (door) {
      this.door = door;
    }
  }

  private async onDoorChange() {
    if (!this.door || !this.reservation) {
      return;
    }

    if (!this.isEditingReservation) {
      await this.setDoorReservationFields(this.door.id);
    }

    this.selectedTimeWindowId = null;

    this.applyChangesOnDoorOrReservation();
  }

  private async setDoorReservationFields(doorId: number) {
    this.reservation.data = await this.doorService.getDoorReservationFields(doorId);
  }

  private applyChangesOnDoorOrReservation() {
    this.calculateEndTimeFromPallets();
    this.updateReservationDateRange();
  }

  private updateReservationDateRange() {
    this.onReservationDateRangeChange(
      {
        date: this.reservation.date,
        start: this.reservation.start,
        end: this.reservation.end,
      },
      true
    );
  }

  selectFixedTimeWindow(tw: TimeWindow) {
    this.selectedTimeWindowId = tw.id;
    this.onReservationDateRangeChange(
      {
        start: tw.start,
        end: tw.end,
      },
      true
    );
  }

  onReservationDateRangeDateChange(date: Date) {
    this.onReservationDateRangeChange({ date }, true);
  }

  onReservationDateRangeTimeStartChange(start: string) {
    this.onReservationDateRangeChange({ start }, true);
  }

  onReservationDateRangeTimeEndChange(end: string) {
    this.onReservationDateRangeChange({ end }, true);
  }

  onReservationDateRangeChange(newDateRange: Partial<DateRange>, triggerCalculations: boolean = false) {
    this.reservationDateRange = {
      date: newDateRange.date || this.reservationDateRange.date,
      start: newDateRange.start || this.reservationDateRange.start,
      end: newDateRange.end || this.reservationDateRange.end,
    };

    if (!triggerCalculations) {
      return;
    }

    if (this.door.properties.type === ReservationType.Calculated) {
      this.calculateEndTimeFromPallets();
    } else if (this.door.properties.type === ReservationType.Free) {
      const hasEndChanged = newDateRange.end != null;
      this.makeSureStartIsBeforeEnd(hasEndChanged);
    }
  }

  calculateEndTimeFromPallets() {
    if (this.door.properties.type !== ReservationType.Calculated) {
      return;
    }

    const palletsField = this.reservation.data.find((f) => isPalletsField(f));
    const halfPalletsField = this.reservation.data.find((f) => isHalfPalletsField(f));

    const start = moment.duration(this.reservationDateRange.start);
    const perPallet = moment.duration(this.door.properties.timePerPallet);
    const baseTime = moment.duration(this.door.properties.baseTime);

    let pallets = 0;
    if (palletsField) {
      pallets += Number(palletsField.value);
    }

    if (halfPalletsField) {
      pallets += Number(halfPalletsField.value) * 0.5;
    }

    const end = start.add(baseTime.asHours() + perPallet.asHours() * pallets, "hours");

    this.reservationDateRange = {
      ...this.reservationDateRange,
      end: this.timespanConverter.durationToString(end),
    };
  }

  private makeSureStartIsBeforeEnd(hasEndChanged = false) {
    if (this.door.properties.type !== ReservationType.Free) {
      return;
    }

    const start = moment.duration(this.reservationDateRange.start);
    const end = moment.duration(this.reservationDateRange.end);

    if (start.asSeconds() >= end.asSeconds()) {
      if (hasEndChanged) {
        const adjustedEnd = start.add(15, "minutes");
        this.onReservationDateRangeChange({ end: this.timespanConverter.durationToString(adjustedEnd) }, false);
      } else {
        const adjustedStart = end.subtract(15, "minutes");
        this.onReservationDateRangeChange({ start: this.timespanConverter.durationToString(adjustedStart) }, false);
      }
    }
  }

  async createReservation() {
    if (this.isEditingReservation) {
      return;
    }

    this.loading = true;

    const successCreatingReservation = null; /* await this.reservationService.createReservation(
      this.reservation,
      this.door.id,
      this.reservationDateRange,
      this.isRecurring,
      this.files
    );*/

    if (successCreatingReservation) {
      this.routeHelper.Refresh();
    }

    this.loading = false;
  }

  async editReservation() {
    if (!this.isEditingReservation) {
      return;
    }

    this.loading = true;

    const editedReservation = null; /*await this.reservationService.editReservation(
      this.reservation,
      this.door.id,
      this.reservationDateRange,
      this.files,
      this.isApproving
    );*/

    if (editedReservation) {
      if (this.isApproving) {
        this.reservationService.navigateToEditReservation(editedReservation.id, editedReservation.code);
      } else {
        this.routeHelper.Refresh();
      }
    }

    this.loading = false;
  }

  openDeleteReservationModal(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  async deleteReservation() {
    if (!this.editReservation) {
      return;
    }

    this.loadingDelete = true;

    const deleteSuccess = await this.reservationService.removeReservation(this.reservation.id, this.reservation.code, this.isRecurring);
    if (deleteSuccess) {
      this.router.navigate(["warehouse", this.door.warehouseId]);
    }

    this.loadingDelete = false;
  }

  async handleReservationConfirmClick() {
    if (!this.isEditingReservation) {
      await this.createReservation();
    } else {
      await this.editReservation();
    }
  }

  // can exit screen?
  canDeactivate(): Observable<boolean> | boolean {
    return !this.loading && !this.loadingDelete;
  }
}
