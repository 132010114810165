import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "../core/auth.service";
import { Door } from "../models/Door";
import { Warehouse } from "../models/Warehouse";

@Component({
  selector: "app-warehouse-breadcrumbs",
  templateUrl: "./warehouse-breadcrumbs.component.html",
  styleUrls: ["./warehouse-breadcrumbs.component.css"],
})
export class WarehouseBreadcrumbsComponent implements OnInit {
  @Input() warehouse: Warehouse | null = null;
  @Input() door: Door | null = null;

  bWarehouse: Warehouse | null = null;
  bDoor: Door | null = null;

  constructor(public auth: AuthService) {}

  ngOnInit(): void {
    if (this.door) {
      this.bDoor = this.door;
      this.bWarehouse = this.door.warehouse;
    } else if (this.warehouse) {
      this.bWarehouse = this.warehouse;
    }
  }
}
