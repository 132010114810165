<div class="mt-4"></div>

<app-data-table
  [isLoading]="loading"
  [totalRecords]="reservations.length"
  [rows]="reservationsForDataTable"
  [allColumns]="allReservationColumns"
  (onRowAction)="onActionOccurred(deleteContent, $event)"
  i18n-noDataText
  [noDataText]="'No reservations.'"
  [showDetailsButton]="true"
  [tableId]="tableId"
></app-data-table>

<ng-template #deleteContent let-modal>
  <app-confirm-delete-modal
    [isLoading]="loadingDelete"
    [entity]="'reservation'"
    (onClose)="closeDeleteModal()"
    (onConfirm)="onDelete()"
  ></app-confirm-delete-modal>
</ng-template>
