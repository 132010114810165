<div>
  <ng-container i18n="Drag and drop your files"> Drag and drop your files</ng-container>
</div>

<div>
  <ngx-dropzone (change)="onSelectFiles($event)">
    <ngx-dropzone-label i18n>Click to upload or drag and drop your file(s) here.</ngx-dropzone-label>
    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemoveFile()">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>
</div>
