import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";
import { DateService } from "src/app/date.service";
import { Door } from "src/app/models/Door";
import { WarehouseListItem } from "src/app/models/Warehouse";
import { LocalStorageItems, LocalStorageService } from "src/app/services/local-storage.service";
import { ReservationService } from "src/app/services/reservation.service";
import { SupportService } from "src/app/services/support.service";
import { ReservationFlowMode } from "../company-reservation-flow-v2/company-reservation-flow-v2.component";
import { ReservationData } from "../company-reservation-reserve-door/company-reservation-reserve-door.component";

@Component({
  selector: "app-company-reservation-review",
  templateUrl: "./company-reservation-review.component.html",
  styleUrls: ["./company-reservation-review.component.css"],
})
export class CompanyReservationReviewComponent implements OnInit {
  @Input() reservationData: ReservationData;
  @Input() mode: ReservationFlowMode;
  @Input() loading: boolean = false;
  @Input() bookButtonText: string = "";
  @Input() selectedWarehouse: WarehouseListItem | null = null;
  @Input() selectedDoor: Door | null = null;

  @Output() onReservationBooked: EventEmitter<string> = new EventEmitter<string>();

  isGuest = false;

  guestEmail: string = "";

  ReservationFlowMode = ReservationFlowMode;

  constructor(public dateService: DateService, private auth: AuthService, public reservationService: ReservationService, public support: SupportService) {}

  onGuestEmailChange(email: string) {
    this.guestEmail = email;
    LocalStorageService.setItem(LocalStorageItems.GUEST_BOOKING_EMAIL, email);
  }

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      this.isGuest = !this.auth.IsLoggedIn();
      this.onGuestEmailChange(LocalStorageService.getItem(LocalStorageItems.GUEST_BOOKING_EMAIL) || "");
    });
  }

  get reservationFields() {
    return this.reservationService.getReservationFields(this.reservationData);
  }

  isGuestEmailValid() {
    if (!this.isGuest) {
      return true;
    }
    return this.support.isEmailValid(this.guestEmail);
  }
}
