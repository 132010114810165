import { Availability } from "./Availability";
import { DoorFieldsFilter } from "./Door";

export class TimeWindow {
  id: number;
  availabilityId: number;
  start: string;
  end: string;
  bookableSlots: number;
  bookablePallets: number;

  bookableWeekdays: number[];

  availability: Availability;
  timeWindowFieldsFilter: DoorFieldsFilter[];
}
