<div *ngIf="availability != null">
  <div class="d-flex mb-2">
    <app-app-time-input
      class="flex-1 w-100 mr-1"
      [(time)]="availability.minimumNotice"
      label="Minimum notice before reservation"
      i18n-label
    ></app-app-time-input>
  </div>

  <div class="d-flex">
    <app-app-time-input class="flex-1 w-100 mr-1" [(time)]="availability.workTimeFrom" label="Work day - from" i18n-label></app-app-time-input>
    <app-app-time-input class="flex-1 w-100 mr-1" [(time)]="availability.workTimeTo" label="Work day - to" i18n-label></app-app-time-input>
  </div>

  <div class="d-flex mb-2">
    <app-app-time-input
      class="flex-1 w-100 mr-1"
      [(time)]="availability.maxArrivalInacurracy"
      label="Minimum arrival inaccuracy"
      i18n-label
    ></app-app-time-input>
  </div>

  <div class="form-group mr-1 flex-grow-1" *ngIf="type == ReservationType.Free">
    <label><ng-container i18n>Reservation granularity (in minutes)</ng-container></label>
    <input class="form-control" type="number" i18n-placeholder [(ngModel)]="availability.granularityMinutes" />
  </div>

  <div class="mt-3">
    <div class="mt-3">
      <span class="h5"><ng-container i18n>Windows</ng-container></span>
    </div>
    <div *ngFor="let tw of availability.timeWindows" class="d-flex align-items-end mb-3 availability-windows">
      <app-app-time-input class="flex-1 mr-1" [(time)]="tw.start" label="From" i18n-label></app-app-time-input>
      <app-app-time-input class="flex-1 mr-1" [(time)]="tw.end" label="To" i18n-label></app-app-time-input>
      <ng-container *ngIf="type == ReservationType.Fixed">
        <app-app-text-input
          name="bookableSlots"
          class="mr-1"
          [type]="'number'"
          [noMargin]="true"
          [value]="tw.bookableSlots"
          (valueChange)="tw.bookableSlots = Number($event)"
        >
          <ng-container i18n>Slots</ng-container>
        </app-app-text-input>
        <app-app-text-input
          name="bookablePallets"
          [type]="'number'"
          [noMargin]="true"
          [value]="tw.bookablePallets"
          (valueChange)="tw.bookablePallets = Number($event)"
        >
          <ng-container i18n>Pallets</ng-container>
        </app-app-text-input>
      </ng-container>

      <div class="form-group ml-2" style="min-width: 20%; flex-basis: 20%; width: 20%">
        <label for="" i18n>Week day</label>
        <div style="height: 36.5px" class="m-0 d-flex align-items-center">
          <app-weekday-display [weekdays]="tw.bookableWeekdays"></app-weekday-display>
        </div>
      </div>

      <button class="ml-2 btn btn-info" style="flex-shrink: 0" (click)="OnSetFiltersClick(tw, setWorkdaysContent)">
        <ng-container i18n>Set work days</ng-container>
      </button>

      <button
        class="ml-2 btn btn-info"
        [ngbTooltip]="tw.id == null ? disabledTooltip : undefined"
        triggers="hover:blur"
        style="flex-shrink: 0"
        [disabled]="tw.id == null"
        *ngIf="type == ReservationType.Fixed"
        (click)="OnSetFiltersClick(tw, setFiltersContent)"
      >
        <ng-container i18n>Set filters</ng-container>
      </button>

      <ng-template #disabledTooltip><div [innerHtml]="'Please save the time window to set filters.'"></div></ng-template>

      <button class="ml-2 btn btn-info" (click)="Copy(tw)">
        <fa-icon style="color: white" [icon]="faCopy"></fa-icon>
      </button>

      <button class="ml-2 btn btn-danger" *ngIf="availability.timeWindows.length > 1" (click)="Remove(tw)">
        <fa-icon style="color: white" [icon]="faTrash"></fa-icon>
      </button>
    </div>
    <button class="btn btn-secondary" (click)="AddWindow()">
      <ng-container i18n>Add</ng-container>
    </button>
  </div>
</div>

<ng-template #setFiltersContent let-modal>
  <ng-container *ngIf="twForSettingFilters">
    <div class="modal-header">
      <h4 class="modal-title" i18n>Set window filters</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <small i18n>Filters determine which values should the fields (only of type select) in the reservation have for the time window to be bookable.</small>
      <app-important-fields-filter [forDoor]="false" [doorId]="doorId" [timeWindowId]="twForSettingFilters.id"></app-important-fields-filter>
    </div>
  </ng-container>
</ng-template>

<ng-template #setWorkdaysContent let-modal>
  <ng-container *ngIf="twForSettingFilters">
    <div class="modal-header">
      <h4 class="modal-title" i18n>Set window weekdays</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <small i18n>Set work days that this time window is active on.</small>
      <app-weekday-picker [(value)]="twForSettingFilters.bookableWeekdays"></app-weekday-picker>
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <button type="button" class="btn btn-info" (click)="modal.close()">
        <ng-container i18n>Close</ng-container>
      </button>
    </div>
  </ng-container>
</ng-template>
