<form (ngSubmit)="onFormSubmit()">
  <div *ngFor="let field of reservationFields" style="{{ shouldHideField(field) ? 'display: none' : '' }}">
    <div class="form-group" *ngIf="field.type == ReservationFieldType.String || field.type == ReservationFieldType.Number">
      <app-reservation-field-label [field]="field"></app-reservation-field-label>

      <div class="d-flex" *ngIf="field.isMultiLine === false">
        <input
          [ngClass]="{ 'is-invalid': field.errorMessage != null }"
          [attr.required]="field.required"
          class="form-control"
          type="text"
          [ngModelOptions]="{ standalone: true }"
          [ngModel]="field.value"
          (ngModelChange)="onFieldNgModelChange(field, $event)"
        />
        <button
          [disabled]="codeAutofillLoading"
          type="button"
          *ngIf="field.specialMeaning === ReservationFieldSpecialMeaningField.YAMAS_DRIVER_CODE"
          i18n
          class="ml-2 btn btn-primary"
          (click)="autofillFromDriverCode(field.value)"
        >
          Autofill
        </button>
      </div>

      <textarea
        *ngIf="field.isMultiLine === true"
        [ngClass]="{ 'is-invalid': field.errorMessage != null }"
        [attr.required]="field.required"
        class="form-control"
        [ngModelOptions]="{ standalone: true }"
        [ngModel]="field.value"
        (ngModelChange)="onFieldNgModelChange(field, $event)"
      ></textarea>

      <div *ngIf="field.errorMessage" class="invalid-feedback">
        {{ field.errorMessage }}
      </div>
    </div>

    <div class="form-check form-check-inline" *ngIf="field.type == ReservationFieldType.Checkbox">
      <input
        class="form-check-input"
        type="checkbox"
        [attr.id]="field.name"
        [attr.required]="field.required"
        [ngModelOptions]="{ standalone: true }"
        [ngModel]="field.value"
        (ngModelChange)="onFieldNgModelChange(field, $event)"
      />
      <app-reservation-field-label [field]="field"></app-reservation-field-label>
    </div>

    <div class="form-group" *ngIf="field.type == ReservationFieldType.Date">
      <app-reservation-field-label [field]="field"></app-reservation-field-label>
      <app-pick-date [(date)]="field.value"></app-pick-date>

      <div *ngIf="field.errorMessage" class="invalid-feedback">
        {{ field.errorMessage }}
      </div>
    </div>

    <div class="form-group" *ngIf="field.type == ReservationFieldType.Select">
      <app-reservation-field-label [field]="field"></app-reservation-field-label>

      <div class="d-flex mt-2" style="gap: 24px; flex-wrap: wrap">
        <div *ngFor="let value of field.selectValues.values; let i = index" style="width: 140px">
          <label for="{{ value }}" class="d-block">
            <div>
              <input
                id="{{ value }}"
                type="radio"
                [value]="value"
                [name]="field.name"
                [ngModel]="field.value"
                (ngModelChange)="onFieldNgModelChange(field, $event)"
              />
              {{ value }}
            </div>
            <div *ngIf="field.selectValues.base64Images?.[i]" style="width: 100%; height: auto">
              <img [src]="domSanitizer.bypassSecurityTrustUrl(field.selectValues.base64Images[i])" style="object-fit: cover; width: 100%; height: auto" />
            </div>
          </label>
        </div>
      </div>

      <div *ngIf="field.errorMessage" class="invalid-feedback">
        {{ field.errorMessage }}
      </div>
    </div>

    <app-country-picker
      *ngIf="field.type == ReservationFieldType.Country"
      [helpText]="field.helpText"
      [fieldName]="field.name"
      [errorMessage]="field.errorMessage"
      [selectedCountryId]="field.value"
      [required]="field.required"
      (selectedCountryIdChange)="onFieldNgModelChange(field, $event)"
    ></app-country-picker>
  </div>

  <ng-content></ng-content>

  <div class="mt-2">
    <button class="btn btn-primary" type="submit" *ngIf="!loading && !hideConfirmButton">
      <ng-container>{{ confirmButtonText }}</ng-container>
    </button>
    <div class="spinner-border" role="status" *ngIf="loading">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</form>
