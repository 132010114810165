<div class="form-group mb-0" style="width: 200px">
  <label class="d-flex justify-content-between">
    <ng-container i18n>Placeholder text</ng-container>
    <app-help-text text="Placeholders will be substituted with real reservation data when sending the email." i18n-text></app-help-text>
  </label>
  <select class="form-control" (ngModelChange)="onValueSelected($event)" [ngModel]="selectValue" [disabled]="disabled">
    <option disabled selected i18n [value]="null">Select placeholder</option>
    <option *ngFor="let placeholder of placeholders" [value]="placeholder.type" (click)="onSelectPlaceholder(placeholder.type); $event.preventDefault()">
      {{ placeholder.text }}
    </option>
  </select>
</div>
