<div class="container app-container arrival-table-container" *ngIf="reservation">
  <h1 class="app-heading">
    <ng-container i18n>Reservation</ng-container>
    <ng-container i18n *ngIf="reservation.type === ReservationDtoType.TWO_PHASE"> (two-phase)</ng-container>
    <ng-container i18n *ngIf="reservation.type === ReservationDtoType.RECURRING"> (recurring)</ng-container>
  </h1>

  <div class="mb-3" *ngIf="reservation.yamasArrivalId != null">
    <h5 i18n>Arrival</h5>
    <span class="d-block">
      <b i18n>Link to YAMAS arrival: &nbsp;</b>
      <a target="_blank" href="{{ getLinkToYamasArrival() }}">{{ getLinkToYamasArrival() }}</a>
    </span>
  </div>

  <div class="mb-3" *ngIf="reservation.yamasArrivalId == null && reservation.code != null">
    <h5 i18n>Arrival</h5>
    <span class="d-block">
      <a href="{{ getLinkToYamasArrivalCreation() }}">Create an arrival in YAMAS from this reservation</a>
    </span>
  </div>

  <span class="d-block mb-2"> <b i18n>Code: &nbsp;</b> {{ reservation.code }} </span>

  <div>
    <h5 i18n>Location</h5>
    <span class="d-block">
      <b i18n>Warehouse: &nbsp;</b> <u class="c-pointer" [routerLink]="['/warehouse', reservation.warehouse.id]">{{ reservation.warehouse.name }}</u>
    </span>
    <span class="d-block">
      <b i18n>Door: &nbsp;</b> <ng-container *ngIf="!reservation.door">/</ng-container>
      <ng-container *ngIf="reservation.door">
        <u class="c-pointer" [routerLink]="['/door', reservation.door.id]">{{ reservation.door.name }}</u>
      </ng-container>
    </span>
    <ng-container *ngIf="reservation.additionalContactEmail">
      <span class="d-block"><b i18n>Contact email upon reservation:</b> {{ reservation.additionalContactEmail }}</span>
    </ng-container>
  </div>

  <div class="mt-3">
    <h5 i18n>Time</h5>
    <div><b i18n>Created at:</b> {{ dateService.toTime(reservation.createdAt) }}</div>

    <div *ngIf="!reservation.standardReservationData && !reservation.recurringReservationData">
      <ng-container><i i18n>No time determined yet.</i></ng-container>
    </div>

    <div *ngIf="reservation.standardReservationData">
      <span class="d-block"><b i18n>Date:</b> {{ dateService.toTime(reservation.standardReservationData.date, { onlyDate: true }) }}</span>
      <span class="d-block" *ngIf="reservation.standardReservationData.start && reservation.standardReservationData.end">
        <b i18n>Time:</b> {{ reservation.standardReservationData.start }} - {{ reservation.standardReservationData.end }}
      </span>
    </div>

    <div *ngIf="reservation.recurringReservationData">
      {{ support.recurringReservationToString(reservation.recurringReservationData) }}
    </div>
  </div>

  <div class="mt-3">
    <h5 i18n>Data</h5>
    <app-reservation-data-table [reservationData]="reservation.data"></app-reservation-data-table>
  </div>

  <div class="mt-3">
    <h5 i18n>Files</h5>
    <div *ngIf="reservation.files.length > 0">
      <ul>
        <li *ngFor="let file of reservation.files">
          <a style="color: black; text-decoration: underline; cursor: pointer" (click)="downloadReservationFile(file)">
            {{ file.name }}
          </a>
        </li>
      </ul>
    </div>
    <div *ngIf="reservation.files.length === 0">
      <i i18n>No files for this reservation.</i>
    </div>
  </div>

  <div class="mt-3" *ngIf="reservation.yamasArrivalId == null">
    <h5 i18n>Actions</h5>
    <div class="d-flex" style="gap: 15px">
      <button *ngIf="reservation.allowEdit" class="btn btn-info" i18n (click)="onEditClick()">Edit</button>
      <button *ngIf="reservation.allowApprove" (click)="onApproveClick()" class="ml-1 btn btn-secondary">
        <ng-container i18n>Approve</ng-container>
      </button>
      <button *ngIf="reservation.allowDelete" (click)="onDeleteClick(deleteContent)" class="ml-1 btn btn-danger">
        <ng-container i18n>Delete</ng-container>
      </button>
    </div>
  </div>

  <ng-template #deleteContent let-modal>
    <app-confirm-delete-modal
      [isLoading]="loadingDelete"
      [entity]="'reservation'"
      (onClose)="closeDeleteModal()"
      (onConfirm)="onDelete()"
    ></app-confirm-delete-modal>
  </ng-template>
</div>
