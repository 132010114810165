import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppLanguage } from "../models/ReservationField";
import { SupportService } from "../services/support.service";

@Component({
  selector: "app-language-select",
  templateUrl: "./language-select.component.html",
  styleUrls: ["./language-select.component.css"],
})
export class LanguageSelectComponent implements OnInit {
  @Input() selectedLanguage: AppLanguage;
  @Output() onSelect: EventEmitter<AppLanguage> = new EventEmitter<AppLanguage>();

  constructor(public support: SupportService) {}

  ngOnInit(): void {}

  onSelectLanguage(id: string) {
    let lang: AppLanguage | null = null;
    if (id != null) {
      lang = this.support.languages.find((l) => l.id === Number(id));
    }

    this.onSelect.emit(lang);
  }
}
