import { Injectable } from "@angular/core";
import { AuthService } from "./core/auth.service";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class DateService {
  constructor(private auth: AuthService) {}

  get dateFormatForBackend() {
    return "DD. MM. yyyy";
  }

  get dateFormat() {
    return this.auth.loggedInUser?.displayDateFormat || this.dateFormatForBackend;
  }

  get timeFormat() {
    return "HH:mm:ss";
  }

  get dateTimeFormat() {
    return `${this.dateFormat} ${this.timeFormat}`;
  }

  public toTime(date, { onlyDate }: { onlyDate?: boolean } = {}) {
    let format = this.dateTimeFormat;
    if (onlyDate) {
      format = this.dateFormat;
    }

    return moment(date).format(format);
  }

  public timeDiff(date1: Date, date2: Date, unit: moment.unitOfTime.Diff): number {
    return Math.abs(moment(date1).diff(date2, unit));
  }

  public areSameDates(date1: Date, date2: Date): boolean {
    return moment(date1).isSame(date2, "date");
  }

  public formatDate(d: Date, { forBackend }: { forBackend?: boolean } = {}) {
    let dateFormat = this.dateFormat;
    if (forBackend) {
      dateFormat = this.dateFormatForBackend;
    }

    return moment(d).format(dateFormat);
  }

  public formatTime(d: Date): string {
    return moment(d).format(this.timeFormat);
  }

  public mergeDateAndTime(date: Date, time: string) {
    const dateString = this.formatDate(date);
    return moment(`${dateString} ${time}`, this.dateTimeFormat).toDate();
  }

  public splitDateAndTime(date: Date) {
    const dateTimeSplit = moment(date).format(this.dateTimeFormat).split(" ");
    return {
      date: new Date(dateTimeSplit[0]),
      time: dateTimeSplit[1],
    };
  }

  static dateIsAfter(date: Date, refDate: Date): boolean {
    return moment(date).isSameOrAfter(refDate, "date");
  }

  static dateIsBefore(date: Date, refDate: Date): boolean {
    return moment(date).isSameOrBefore(refDate, "date");
  }

  static removeTime(date: Date | null): Date | null {
    if (!date) {
      return null;
    }

    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public swapDatesIfFromLaterThanTo(dateFrom: Date | null | undefined, dateTo: Date | null | undefined) {
    if (dateFrom && dateTo && dateFrom > dateTo) {
      const tmp = dateFrom;
      dateFrom = dateTo;
      dateTo = tmp;
    }

    return { dateFrom, dateTo };
  }
}
