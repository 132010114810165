<div class="d-flex" class="table-filter-container">
  <input
    class="form-control table-filter-input"
    i18n-placeholder
    placeholder="Filter by company, warehouse or door..."
    type="text"
    [ngModel]="filterText"
    (ngModelChange)="filterTextChange.emit($event.trim().toLowerCase())"
    [disabled]="disabled"
  />
  <button class="btn btn-primary table-refresh-btn" i18n [disabled]="disabled" (click)="onRefresh.emit()">Refresh</button>
</div>
