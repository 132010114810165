import { Component, OnInit } from "@angular/core";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { DateService } from "src/app/date.service";
import { BookedWindowsStats, BookedWindowsStatsDatePrecision, StatsService } from "src/app/services/stats.service";

export interface BookedWindowsDateFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
  precision: BookedWindowsStatsDatePrecision;
}

@Component({
  selector: "app-stats-booked-windows",
  templateUrl: "./stats-booked-windows.component.html",
  styleUrls: ["./stats-booked-windows.component.css"],
})
export class StatsBookedWindowsComponent implements OnInit {
  BookedWindowsStatsDatePrecision = BookedWindowsStatsDatePrecision;
  faQuestionCircle = faQuestionCircle;

  isLoading = false;
  stats: BookedWindowsStats | null = null;

  filter: BookedWindowsDateFilter = {
    dateFrom: moment().subtract(1, "week").toDate(),
    dateTo: new Date(),
    precision: BookedWindowsStatsDatePrecision.WEEKLY,
  };

  constructor(private dateService: DateService, private statsService: StatsService) {}

  ngOnInit(): void {}

  async calculate() {
    this.isLoading = true;
    this.stats = await this.statsService.getBookedWindowsStats(this.filter);
    this.isLoading = false;
  }

  onDateChange(dateFrom: Date | null | undefined, dateTo: Date | null | undefined) {
    dateFrom = dateFrom || this.filter.dateFrom;
    dateTo = dateTo || this.filter.dateTo;

    ({ dateFrom, dateTo } = this.dateService.swapDatesIfFromLaterThanTo(dateFrom, dateTo));

    this.filter.dateFrom = dateFrom;
    this.filter.dateTo = dateTo;
  }

  formatDate(date: Date, precision: BookedWindowsStatsDatePrecision): string {
    switch (precision) {
      case BookedWindowsStatsDatePrecision.YEARLY:
        return moment(date).format("YYYY");
      case BookedWindowsStatsDatePrecision.MONTHLY:
        return moment(date).format("MMM YYYY");
      case BookedWindowsStatsDatePrecision.WEEKLY:
        return moment(date).format("W[W] YYYY");
      case BookedWindowsStatsDatePrecision.DAILY:
      default:
        return moment(date).format("D MMM YYYY (dddd)");
    }
  }
}
