<app-spinner *ngIf="loading"></app-spinner>
<div *ngIf="warehouses != null">
  <div class="mb-2">
    <h3 i18n class="mb-0">Choose warehouse</h3>
    <span class="d-flex" i18n>Please choose warehouse of your delivery by clicking on the warehouse and confirming your selection.</span>
    <small i18n class="d-block text-warning" *ngIf="selectedWarehouse">Warning: changing the warehouse will erase existing data in next steps!</small>
  </div>

  <ng-container *ngIf="warehouses.length > 0">
    <div class="warehouse-card-container">
      <div
        *ngFor="let warehouse of warehouses"
        class="warehouse-card"
        [ngClass]="{ 'pending-selected': warehouse.id === pendingSelectedWarehouse?.id, selected: warehouse.id === selectedWarehouse?.id }"
        (click)="pendingSelectedWarehouse = warehouse"
      >
        <img *ngIf="warehouse.image" [src]="File.Url(warehouse.image)" style="height: 150px; width: 100%; object-fit: cover" />
        <h5 style="margin-top: 5px">{{ warehouse.name }}</h5>
        <p class="m-0">{{ warehouse.description }}</p>
        <p class="m-0">{{ warehouse.address }}</p>
        <p class="m-0">{{ warehouse.contactEmail }}</p>
        <p class="m-0">{{ warehouse.contactPhone }}</p>
      </div>
    </div>

    <div *ngIf="pendingSelectedWarehouse != null && pendingSelectedWarehouse?.id != selectedWarehouse?.id" style="gap: 15px" class="mt-3 d-flex">
      <button class="btn btn-info" (click)="selectedWarehouseChange.emit(pendingSelectedWarehouse); pendingSelectedWarehouse = null" i18n>
        Select warehouse {{ pendingSelectedWarehouse.name }}
      </button>
      <button class="btn" (click)="pendingSelectedWarehouse = null" i18n>Cancel selection</button>
    </div>
  </ng-container>

  <div *ngIf="warehouses.length === 0">
    <p i18n>No bookable warehouses found.</p>
  </div>
</div>
