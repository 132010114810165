<div class="form-group mb-2" [class.mb-2]="!noMargin">
  <label [attr.for]="name">
    <ng-content></ng-content>
  </label>
  <input
    [type]="type"
    class="form-control"
    [disabled]="disabled"
    [id]="name"
    [ngModel]="value"
    (ngModelChange)="valueChange.emit($event)"
    [placeholder]="placeholder"
  />
</div>
