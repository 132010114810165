import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from "@angular/core";
import * as HtmlDurationPicker from "html-duration-picker";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-app-time-input",
  templateUrl: "./app-time-input.component.html",
  styleUrls: ["./app-time-input.component.css"],
})
export class AppTimeInputComponent implements OnInit, AfterViewInit {
  @Input() label: string;

  @Input() disabled: boolean = false;
  @Input() time: string | null;
  @Output() timeChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    HtmlDurationPicker.init();
  }

  onTimeChange(e: any) {
    this.timeChange.emit(e.target.value);
  }
}
