import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "../core/auth.service";
import { Role } from "../models/Role";

export interface ContactMail {
  email: string;
  id: number;
}

@Component({
  selector: "app-warehouse-profile",
  templateUrl: "./warehouse-profile.component.html",
  styleUrls: ["./warehouse-profile.component.css"],
})
export class WarehouseProfileComponent implements OnInit {
  email: string;
  name: string;

  dashboardUrl: string;

  Role = Role;

  encodeURIComponent = encodeURIComponent;

  constructor(public auth: AuthService) {
    this.dashboardUrl = environment.dashboardUrl;
  }

  ngOnInit() {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (isDone) {
        this.name = this.auth.loggedInUser.name;
        this.email = this.auth.loggedInUser.email;
      }
    });
  }
}
