import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { BookedWindowsDateFilter } from "../stats/stats-booked-windows/stats-booked-windows.component";
import { RestService } from "./rest.service";

export enum BookedWindowsStatsDatePrecision {
  DAILY,
  WEEKLY,
  MONTHLY,
  YEARLY,
}

export interface BookedWindowsStats {
  precision: BookedWindowsStatsDatePrecision;
  data: BookedWindowsStatsData[];
  totalNumberOfBookings: number;
}

interface BookedWindowsStatsData {
  forDate: Date;
  totalNumberOfBookings: number;
  bookingsPerWarehouse: BookedWindowsStatsPerWarehouse[];
}

interface BookedWindowsStatsPerWarehouse {
  warehouseId: number;
  warehouseName: string;
  totalNumberOfBookings: number;
  bookingsPerDoor: BookedWindowsStatsPerDoor[];
}

interface BookedWindowsStatsPerDoor {
  doorId: number;
  doorName: string;
  totalNumberOfBookings: number;
  bookingsPerTimeWindow: BookedWindowsStatsPerTimeWindow[];
}

interface BookedWindowsStatsPerTimeWindow {
  timeWindow: string;
  totalNumberOfBookings: number;
}

@Injectable({
  providedIn: "root",
})
export class StatsService {
  constructor(private msgT: MessageTranslationService, private toast: ToastrService, private rest: RestService) {}

  public async getBookedWindowsStats(filter: BookedWindowsDateFilter): Promise<BookedWindowsStats> {
    try {
      return await this.rest
        .post<BookedWindowsStats>(`api/stats/getBookedWindowsStats`, {
          precision: filter.precision,
          dateFrom: filter.dateFrom,
          dateTo: filter.dateTo,
        })
        .toPromise();
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    }

    return {
      data: [],
      precision: filter.precision,
      totalNumberOfBookings: 0,
    };
  }
}
