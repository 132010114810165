import { Component, Input, OnInit } from "@angular/core";
import { PermissionStatus } from "src/app/models/Permission";

@Component({
  selector: "app-render-warehouse-permission-status",
  templateUrl: "./render-warehouse-permission-status.component.html",
  styleUrls: ["./render-warehouse-permission-status.component.css"],
})
export class RenderWarehousePermissionStatusComponent implements OnInit {
  PermissionStatus = PermissionStatus;
  @Input() status: PermissionStatus;
  @Input() canCarrierCreateAnonymousReservation: boolean;

  constructor() {}

  ngOnInit(): void {}
}
