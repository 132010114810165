import { Permission } from "./Permission";
import { Warehouse } from "./Warehouse";
import { File } from "./File";
import { OptimapiServer } from "./OmptimapiServer";
import { Role } from "./Role";
import { Company } from "./Company";

export class UserMailSendingData {
  sendAttachmentForReservationCreated: boolean;
  sendAttachmentForReservationEdited: boolean;
  sendAttachmentForReservationDeleted: boolean;

  sendMailsForReservationCreated: boolean;
  sendMailsForReservationEdited: boolean;
  sendMailsForReservationDeleted: boolean;

  sendMailsForRecurringReservationCreated: boolean;
  sendMailsForRecurringReservationEdited: boolean;
  sendMailsForRecurringReservationDeleted: boolean;

  sendMailsForTwoPhaseReservationCreated: boolean;
  sendMailsForTwoPhaseReservationConfirmed: boolean;
  sendMailsForTwoPhaseReservationEdited: boolean;
  sendMailsForTwoPhaseReservationDeleted: boolean;
}

export const defaultUserMailSendingData: UserMailSendingData = {
  sendAttachmentForReservationCreated: true,
  sendAttachmentForReservationEdited: true,
  sendAttachmentForReservationDeleted: true,

  sendMailsForReservationCreated: true,
  sendMailsForReservationEdited: true,
  sendMailsForReservationDeleted: true,

  sendMailsForRecurringReservationCreated: true,
  sendMailsForRecurringReservationEdited: true,
  sendMailsForRecurringReservationDeleted: true,

  sendMailsForTwoPhaseReservationCreated: true,
  sendMailsForTwoPhaseReservationConfirmed: true,
  sendMailsForTwoPhaseReservationEdited: true,
  sendMailsForTwoPhaseReservationDeleted: true,
};

export enum UserMailSubjectTextType {
  MAIL_ROOT,
  COMPANY_NAME,
  RESERVATION_DATE,
  RESERVATION_TYPE_SUBJECT,
}

export enum DataTableFieldNamesDisplayMode {
  RESERVATION_TIME,
  PRESENT_TIME,
}

export class User {
  id: number;
  email: string;
  name: string;
  title: string;
  address: string;
  passwordHasn: any;
  emailConfirmed: Date;
  confirmed: Date;
  confirmedBy: User;
  passwordReset: string;
  passwordResetTimeout: Date;
  registeredAt: Date;
  phoneNumber: string;
  dataTableFieldNamesDisplayMode: DataTableFieldNamesDisplayMode;
  displayDateFormat: string;

  permissions: Array<Permission>;
  warehouses: Array<Warehouse>;
  image: File;
  server: OptimapiServer;

  originalData: {
    name: string;
  };

  isModified: boolean = false;
  filterString: string;

  roles: Role[];

  company: Company;
}

export interface UserExcerptDto {
  id: number;
  name: string;
  email: string;
  title: string;
  profilePictureUrl: string;
}
