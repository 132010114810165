import { Component, Input, OnInit } from "@angular/core";
import { ReservationField } from "../models/ReservationField";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-reservation-field-label",
  templateUrl: "./reservation-field-label.component.html",
  styleUrls: ["./reservation-field-label.component.css"],
})
export class ReservationFieldLabelComponent implements OnInit {
  faQuestionCircle = faQuestionCircle;
  @Input() field: ReservationField | null = null;

  constructor() {}

  ngOnInit(): void {}
}
