<app-spinner *ngIf="doors == null"></app-spinner>
<ng-container *ngIf="doors != null">
  <ng-container *ngIf="doors.length === 0">
    <p><i i18n>This warehouse has no doors.</i></p>
    <button class="btn btn-info" (click)="onSelectAnotherWarehouse.emit()" i18n>Select another warehouse</button>
  </ng-container>

  <ng-container *ngIf="doors.length > 0 && !isLoading">
    <ng-container *ngIf="importantFields.length > 0">
      <div class="mb-2">
        <h3 i18n class="mb-0">Additional data</h3>
        <span class="d-flex" i18n>Please enter additional data so we can direct you to the correct door.</span>
        <small i18n class="d-block text-warning" *ngIf="selectedDoor">Warning: changes will not be applied until you select the door.</small>
      </div>

      <app-reservation-fields-form
        [isCreatingNew]="true"
        [hideConfirmButton]="true"
        (onReservationFieldsChange)="onImportantFieldsChange($event)"
        [validateOnEachChange]="true"
        [reservationFields]="importantFields"
        [loading]="loading"
      >
      </app-reservation-fields-form>
    </ng-container>

    <ng-container *ngIf="canSelectDoor">
      <ng-container *ngIf="bookableDoors.length === 0">
        <p><i i18n>No doors in this warehouse match your specified additional data. Please choose another warehouse.</i></p>
        <button class="btn btn-info" (click)="onSelectAnotherWarehouse.emit()" i18n>Select another warehouse</button>
      </ng-container>
      <div class="door-card-container">
        <div
          *ngFor="let door of bookableDoors"
          class="door-card"
          [ngClass]="{ 'pending-selected': door.id === pendingSelectedDoor?.id, selected: door.id === selectedDoor?.id }"
          (click)="pendingSelectedDoor = door"
        >
          <h5 style="margin-top: 5px">{{ door.name }}</h5>
          <span style="color: gray">{{ door.description }}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!canSelectDoor">
      <p i18n class="text-danger">Please correctly fill out the additional data to select the door!</p>
    </ng-container>

    <div class="mt-3">
      <div *ngIf="pendingSelectedDoor != null && pendingSelectedDoor.id != selectedDoor?.id" style="gap: 15px" class="d-flex">
        <button class="btn btn-info" (click)="onDoorSelected(pendingSelectedDoor)" i18n>Select door {{ pendingSelectedDoor.name }}</button>
        <button class="btn" (click)="pendingSelectedDoor = null" i18n>Cancel selection</button>
      </div>

      <div *ngIf="pendingSelectedDoor == null && haveReservationsFieldsChanged && selectedDoor" style="gap: 15px" class="d-flex">
        <button class="btn btn-info" (click)="onDoorSelected(selectedDoor)" *ngIf="canSelectDoor && isSelectedDoorBookable" i18n>
          Update reservation with new fields
        </button>
        <button class="btn" (click)="resetImportantFieldValues(true)" i18n>Reset reservation data</button>
      </div>
    </div>
  </ng-container>
</ng-container>
