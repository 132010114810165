<div
  *ngIf="active"
  class="popUp position-fixed p-0 m-0 d-flex flex-column justify-content-center align-items-center"
  style="top: 50%; transform: translateY(-50%); max-width: 30vw"
>
  <div class="container alert alert-secondary">
    <div>
      <ng-content></ng-content>
    </div>
    <div class="my-2 w-100 row justify-content-around">
      <button (click)="Yes()" class="btn btn-danger" i18n>Yes</button>
      <button (click)="No()" class="btn btn-primary" i18n>No</button>
    </div>
  </div>
</div>
