<ng-container *ngIf="reservationData">
  <div class="mb-2">
    <h3 i18n class="mb-0">Reservation data</h3>
    <span class="d-flex" i18n>Enter data about your reservation.</span>
  </div>

  <app-reservation-fields-form
    [isCreatingNew]="mode == ReservationFlowMode.CREATING || mode == ReservationFlowMode.CREATING_TWO_PHASE"
    *ngIf="reservationData.reservation.data"
    i18n-confirmButtonText
    confirmButtonText="Continue to next step"
    [reservationFields]="reservationData.reservation.data"
    (onConfirmClick)="onNextClick.emit()"
    (onReservationFieldsChange)="reservationData.isValid = $event.isValid; onReservationFieldsChange.emit()"
    [validateOnEachChange]="true"
  >
    <div class="form-group mb-2">
      <label class="mb-0" i18n>Language for email</label>
      <app-language-picker
        [selectedLanguageCode]="reservationData.languageForEmail"
        (onSelect)="reservationData.languageForEmail = $event.code"
      ></app-language-picker>
    </div>

    <div class="mb-2">
      <app-multi-file-picker [(files)]="reservationData.files"></app-multi-file-picker>
    </div>

    <div class="mt-3 mb-3" *ngIf="mode == ReservationFlowMode.CREATING && auth.IsLoggedIn()">
      <app-recurring-reservation-form
        [(isRecurring)]="reservationData.isRecurring"
        [(recurrenceRule)]="reservationData.reservation.recurrenceRule"
        [(fromDate)]="reservationData.reservation.fromDate"
        [(toDate)]="reservationData.reservation.toDate"
      ></app-recurring-reservation-form>
    </div>
  </app-reservation-fields-form>
</ng-container>
