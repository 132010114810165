import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DateRange } from "src/app/availability-display/availability-display.component";
import { AuthService } from "src/app/core/auth.service";
import { Reservation } from "src/app/models/Reservation";
import { ReservationField } from "src/app/models/ReservationField";
import { ReservationFlowMode } from "../company-reservation-flow-v2/company-reservation-flow-v2.component";

export interface ReservationData {
  reservation: Reservation;
  files: File[];
  isRecurring: boolean;
  isValid: boolean;
  dateRange: DateRange | null;
  importantFields: ReservationField[];
  guestEmail: string | null;
  languageForEmail: string;
}

@Component({
  selector: "app-company-reservation-reserve-door",
  templateUrl: "./company-reservation-reserve-door.component.html",
  styleUrls: ["./company-reservation-reserve-door.component.css"],
})
export class CompanyReservationReserveDoorComponent implements OnInit {
  @Input() mode: ReservationFlowMode = null;
  @Input() reservationData: ReservationData;
  @Output() onNextClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onReservationFieldsChange: EventEmitter<void> = new EventEmitter<void>();

  ReservationFlowMode = ReservationFlowMode;

  constructor(public modalService: NgbModal, public auth: AuthService) {}

  ngOnInit(): void {}
}
