<div class="container app-container" *ngIf="auth.loggedInUser">
  <app-company-first-time-setup-notice [isInProfile]="true"></app-company-first-time-setup-notice>

  <h1 class="app-heading">
    <ng-container i18n>Profile and company settings</ng-container>
  </h1>

  <app-accordion-with-content i18n-label label="Basic profile information">
    <div class="form-group">
      <label for="email"><ng-container i18n>Username</ng-container></label>
      <input type="text" class="form-control" id="email" disabled [(ngModel)]="email" />
    </div>
    <div class="form-group">
      <label for="name"><ng-container i18n>Name</ng-container></label>
      <input type="text" class="form-control" id="name" disabled [(ngModel)]="name" />
    </div>

    <div>
      <a href="{{ dashboardUrl }}/profile?company={{ encodeURIComponent(auth.loggedInUser.company.realmName) }}" target="_blank" class="btn btn-primary" i18n
        >Edit your profile in Omniopti dashboard</a
      >
    </div>
  </app-accordion-with-content>

  <br />

  <app-profile-owl-settings></app-profile-owl-settings>

  <br />

  <app-upload-user-image></app-upload-user-image>

  <br />

  <app-profile-owl-company-settings></app-profile-owl-company-settings>

  <br />

  <app-accordion-with-content i18n-label label="Company information">
    <app-company-reservation-link></app-company-reservation-link>

    <div class="form-group">
      <label for="cName"><ng-container i18n>Name</ng-container></label>
      <input type="text" class="form-control" id="cName" disabled [(ngModel)]="auth.loggedInUser.company.realmName" />
    </div>

    <div class="form-group">
      <label for="cPhoneNumber"><ng-container i18n>Phone number</ng-container></label>
      <input type="text" class="form-control" id="cPhoneNumber" disabled [(ngModel)]="auth.loggedInUser.company.phone" />
    </div>

    <div class="form-group">
      <label for="cAddress"><ng-container i18n>Address</ng-container></label>
      <input type="text" class="form-control" id="cAddress" disabled [(ngModel)]="auth.loggedInUser.company.address" />
    </div>

    <div class="form-group">
      <label for="cContactPerson"><ng-container i18n>Contact person</ng-container></label>
      <input type="text" class="form-control" id="cContactPerson" disabled [(ngModel)]="auth.loggedInUser.company.contactPerson" />
    </div>

    <div *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
      <a
        href="{{ dashboardUrl }}/manage-my-company?company={{ encodeURIComponent(auth.loggedInUser.company.realmName) }}"
        target="_blank"
        class="btn btn-primary"
        i18n
        >Edit company in Omniopti dashboard</a
      >
    </div>
  </app-accordion-with-content>

  <br />

  <app-accordion-with-content i18n-label label="Email settings">
    <a [routerLink]="['/email-settings']" class="btn btn-primary" i18n
      >View <ng-container *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">and edit </ng-container>email settings</a
    >
  </app-accordion-with-content>

  <br />

  <app-company-holiday-list></app-company-holiday-list>

  <br />

  <app-accordion-with-content i18n-label label="Default warehouse reservation fields settings">
    <app-reservation-fields></app-reservation-fields>
  </app-accordion-with-content>
</div>
