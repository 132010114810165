<app-accordion-with-content i18n-label label="OWL preferences">
  
    <div class="form-group">
      <label for="type"><ng-container i18n>Reservation fields names table display mode</ng-container></label>
      <select [(ngModel)]="dataTableFieldNamesDisplayMode" id="type" name="type" class="form-control">
        <option [value]="DataTableFieldNamesDisplayMode.RESERVATION_TIME">
          <ng-container i18n="Carrier"> Original value</ng-container>
        </option>
        <option [value]="DataTableFieldNamesDisplayMode.PRESENT_TIME">
          <ng-container i18n="Warehouse"> Current value</ng-container>
        </option>
      </select>
    </div>

    <app-app-text-input
      name="dateFormat"
      [type]="'text'"
      [(value)]="displayDateFormat"
    >
      <ng-container i18n>Display date format</ng-container>
    </app-app-text-input>

    <div class="mb-2">
    <small i18n>Click <a href="https://www.tutorialspoint.com/momentjs/momentjs_format.htm" target="_blank">here</a> for help with date formats</small>
  </div>

    <button class="btn btn-primary" (click)="changeOWLData()">
      <ng-container i18n=>Edit data</ng-container>
    </button>

</app-accordion-with-content>