import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/core/auth.service";
import { MessageTranslationService } from "src/app/message-translation.service";

@Component({
  selector: "app-company-reservation-link",
  templateUrl: "./company-reservation-link.component.html",
  styleUrls: ["./company-reservation-link.component.css"],
})
export class CompanyReservationLinkComponent implements OnInit {
  reservationLink: string | null = null;
  twoPhaseReservationLink: string | null = null;

  constructor(private auth: AuthService, private toast: ToastrService, private msg: MessageTranslationService) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      if (this.auth.IsCarrier()) {
        return;
      }

      this.reservationLink = `${location.origin}/reserve-company/${this.auth.loggedInUser.company.id}`;
      this.twoPhaseReservationLink = `${location.origin}/reserve-company-two-phase/${this.auth.loggedInUser.company.id}`;
    });
  }

  copyReservationLink() {
    if (this.reservationLink == null) {
      return;
    }

    navigator.clipboard.writeText(this.reservationLink);
    this.toast.success(this.msg.linkCopied());
  }

  copyTwoPhaseReservationLink() {
    if (this.twoPhaseReservationLink == null) {
      return;
    }

    navigator.clipboard.writeText(this.twoPhaseReservationLink);
    this.toast.success(this.msg.linkCopied());
  }
}
