<div *ngIf="warehouse != null" class="container app-container arrival-table-container">
  <app-warehouse-breadcrumbs [warehouse]="warehouse"></app-warehouse-breadcrumbs>

  <div *ngIf="disabled">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center mb-2">
        <h3 style="text-align: start" class="m-0">
          {{ warehouse.name }}
        </h3>
      </div>

      <div class="d-flex align-items-center">
        <div class="mr-2" *ngIf="hasPermissionToBook">
          <a class="btn btn-primary" [routerLink]="'/reserve-company/' + warehouse.company.id" [queryParams]="{ warehouseId: warehouse.id }">
            <ng-container i18n> Book </ng-container>
          </a>
        </div>
        <div class="mr-2" *ngIf="hasPermissionToBookTwoPhase">
          <a class="btn btn-primary" [routerLink]="'/reserve-company-two-phase/' + warehouse.company.id" [queryParams]="{ warehouseId: warehouse.id }">
            <ng-container i18n> Two phase reservation </ng-container>
          </a>
        </div>
      </div>

      <div *ngIf="!hasPermissionToBook" class="d-flex align-items-center">
        <div>
          <div *ngIf="permission != null" style="text-align: center">
            <div *ngIf="permission.status == permissionStatus.Pending">
              <span class="text-info"><ng-container i18n="Permission pending"> Permission pending</ng-container> </span>
            </div>
            <div *ngIf="permission.status == permissionStatus.Declined">
              <span class="text-danger"><ng-container i18n="Permission denied"> Permission denied</ng-container>! </span>
            </div>
          </div>

          <div *ngIf="permission == null">
            <button class="btn btn-primary" (click)="RequestPermission()">
              <ng-container i18n="Request permission">Request permission</ng-container>
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-2">
        <img *ngIf="warehouse.image" [src]="FileUrl(warehouse.image)" style="height: auto; width: 150px; object-fit: cover" />
      </div>

      <table class="table table-bordered mt-2">
        <tbody>
          <tr>
            <th i18n>Created by</th>
            <td>{{ warehouse.createdBy.name }}</td>
          </tr>
          <tr *ngIf="warehouse.description && warehouse.description.length > 0">
            <th i18n>Description</th>
            <td style="white-space: pre-line">{{ warehouse.description }}</td>
          </tr>
          <tr *ngIf="warehouse.address && warehouse.address.length > 0">
            <th i18n>Address</th>
            <td style="white-space: pre-line">{{ warehouse.address }}</td>
          </tr>
          <tr *ngIf="warehouse.contactEmail && warehouse.contactEmail.length > 0">
            <th i18n>Contact email</th>
            <td style="white-space: pre-line">{{ warehouse.contactEmail }}</td>
          </tr>
          <tr *ngIf="warehouse.contactPhone && warehouse.contactPhone.length > 0">
            <th i18n>Contact phone</th>
            <td style="white-space: pre-line">{{ warehouse.contactPhone }}</td>
          </tr>
          <tr>
            <th i18n>Permissons</th>
            <td>
              <span class="d-block">
                <ng-container i18n>Carrier can edit reservation </ng-container>
                <input type="checkbox" disabled [ngModel]="warehouse.canCarrierEditReservation" />
              </span>
              <span class="d-block">
                <ng-container i18n>Carrier can delete reservation </ng-container>
                <input type="checkbox" disabled [ngModel]="warehouse.canCarrierDeleteReservation" />
              </span>
              <span class="d-block">
                <ng-container i18n>Allow public reservations </ng-container>
                <input type="checkbox" disabled [ngModel]="warehouse.canCarrierCreateAnonymousReservation" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex" *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
        <button *ngIf="editable" class="mx-1 btn btn-secondary" (click)="Edit()">
          <ng-container i18n="Edit"> Edit</ng-container>
        </button>
        <button *ngIf="editable" class="mx-1 btn btn-danger" (click)="openDeleteModal(deleteContent)">
          <ng-container i18n="Delete"> Delete</ng-container>
        </button>
      </div>
    </div>

    <div class="mt-4" *ngIf="editable || (permission && permission.status == permissionStatus.Accepted)">
      <h3 class="app-heading" i18n="Doors">Doors</h3>

      <ng-container *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
        <div class="d-flex justify-content-center mt-2 mb-4" *ngIf="editable">
          <button class="btn btn-primary" (click)="appModalService.openModal(addDoorContent)">
            <ng-container i18n="Add a Door"> Add a Door</ng-container>
          </button>
        </div>
      </ng-container>

      <div class="d-flex justify-content-center mb-4 checkboxes-container">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="tableViewRadio"
            [(ngModel)]="viewType"
            (ngModelChange)="toggleView()"
            [value]="ViewType.TABLE"
            [name]="'viewType'"
          />
          <label class="form-check-label" for="tableViewRadio"><ng-container i18n="Table view">Table view</ng-container></label>
        </div>

        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="graphicalViewRadio"
            [(ngModel)]="viewType"
            (ngModelChange)="toggleView()"
            [value]="ViewType.GRAPHICAL"
            [name]="'viewType'"
          />
          <label class="form-check-label" for="graphicalViewRadio"><ng-container i18n="Graphical view">Graphical view</ng-container></label>
        </div>
      </div>

      <ng-container *ngIf="filteredDoors">
        <div class="d-flex" class="table-filter-container">
          <input
            class="form-control table-filter-input"
            i18n-placeholder="Filter..."
            placeholder="Filter..."
            type="text"
            (input)="doorFilterTextChange($event.target.value)"
          />
        </div>

        <ng-container *ngIf="filteredDoors.length > 0">
          <ng-container *ngIf="viewType === ViewType.GRAPHICAL">
            <div class="d-flex flex-column" id="warehouse-graphical-view-wrapper">
              <div class="d-flex mb-4">
                <app-pick-date class="w-100 flex-1" [(date)]="sharedDoorDate"></app-pick-date>

                <button class="btn btn-primary ml-2 flex-1" style="width: 240px" (click)="onApplyDateToAllCalendars()">
                  <ng-container i18n>Apply date to all doors</ng-container>
                </button>
              </div>

              <app-app-checkbox
                [value]="showDoorCalendarsSideBySide"
                (valueChange)="onShowDoorCalendarsSideBySideChange($event)"
                name="showDoorCalendarsSideBySide"
              >
                <ng-container i18n>Show door calendars side by side</ng-container>
              </app-app-checkbox>

              <app-app-checkbox [value]="synchronizeCalendarsTime" (valueChange)="onSynchronizeCalendarsTimeChange($event)" name="synchronizeCalendarsTime">
                <ng-container i18n>Synchronize calendars time</ng-container>
              </app-app-checkbox>

              <div class="d-flex mt-2 overflow-auto" *ngIf="showDoorCalendarsSideBySide">
                <div *ngFor="let door of filteredDoors" class="mr-4 flex-shrink-0" style="width: 250px">
                  <a class="d-block h5" style="color: black; text-decoration: underline" [routerLink]="'/door/' + door.id">
                    <ng-container>{{ door.name }}</ng-container>
                  </a>

                  <app-door-availability-calendar
                    [door]="door"
                    [forReading]="true"
                    [dragToSelect]="false"
                    [selectedDate]="appliedSharedDoorDate"
                    [mode]="'day'"
                  ></app-door-availability-calendar>
                </div>
              </div>

              <div class="mt-2" *ngIf="!showDoorCalendarsSideBySide">
                <div *ngFor="let door of filteredDoors" class="mb-4">
                  <app-accordion-with-content [label]="door.name">
                    <a class="btn btn-secondary" [routerLink]="'/door/' + door.id">
                      <ng-container i18n="Details">Details</ng-container>
                    </a>

                    <div class="mt-2">
                      <app-door-availability-calendar
                        [door]="door"
                        [forReading]="true"
                        [dragToSelect]="false"
                        [selectedDate]="appliedSharedDoorDate"
                      ></app-door-availability-calendar>
                    </div>
                  </app-accordion-with-content>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="viewType === ViewType.TABLE">
            <table class="table table-bordered mb-5">
              <thead>
                <tr>
                  <th i18n="Name">Name</th>
                  <th i18n="Details">Details</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let door of filteredDoors">
                  <td>
                    {{ door.name }}<br />
                    <span style="color: gray">{{ door.description }}</span>
                  </td>

                  <td width="25%">
                    <a class="btn btn-secondary" [routerLink]="'/door/' + door.id">
                      <ng-container i18n="Details">Details</ng-container>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </ng-container>
      </ng-container>

      <p *ngIf="filteredDoors && filteredDoors.length === 0" i18n="No doors.">No doors.</p>
    </div>
  </div>
  <div *ngIf="!disabled" class="d-flex flex-column">
    <app-accordion-with-content i18n-label label="Basic warehouse data">
      <div class="form-group">
        <label><ng-container i18n="Name">Name</ng-container></label>
        <input class="form-control input-h3" placeholder="Name" [(ngModel)]="warehouse.name" />
      </div>

      <div class="form-group">
        <label><ng-container i18n="Description">Description</ng-container></label>
        <input class="form-control" placeholder="Description" [(ngModel)]="warehouse.description" />
      </div>

      <div class="form-group">
        <label><ng-container i18n="Contact email">Contact email</ng-container></label>
        <input class="form-control" placeholder="Contact email" [(ngModel)]="warehouse.contactEmail" />
      </div>

      <div class="form-group">
        <label><ng-container i18n="Contact phone">Contact phone</ng-container></label>
        <input class="form-control" placeholder="Contact phone" [(ngModel)]="warehouse.contactPhone" />
      </div>

      <div class="form-group">
        <label><ng-container i18n>Image</ng-container></label>
        <input type="file" (change)="warehouseImageFile = $event.target.files[0]" class="form-control-file" />
      </div>

      <br />

      <div class="form-check">
        <input
          id="canCarrierEditReservation"
          type="checkbox"
          class="form-check-input"
          [(ngModel)]="warehouse.canCarrierEditReservation"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="form-check-label" for="canCarrierEditReservation" i18n="Carrier can edit reservation"> Carrier can edit reservation </label>
      </div>

      <div class="form-check">
        <input
          type="checkbox"
          id="canCarrierDeleteReservation"
          class="form-check-input"
          [(ngModel)]="warehouse.canCarrierDeleteReservation"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="form-check-label" i18n="Carrier can delete reservation" for="canCarrierDeleteReservation"> Carrier can delete reservation </label>
      </div>

      <div class="form-check">
        <input
          type="checkbox"
          id="canCarrierCreateAnonymousReservation"
          class="form-check-input"
          [(ngModel)]="warehouse.canCarrierCreateAnonymousReservation"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="form-check-label" i18n for="canCarrierCreateAnonymousReservation"> Carrier can create public reservation </label>
      </div>

      <div class="mt-2">
        <h4>
          <ng-container i18n> Location</ng-container>
        </h4>

        <app-address-autocomplete
          [(address)]="warehouse.address"
          (onPlaceSelected)="warehouse.latitude = $event.lat; warehouse.longitude = $event.lng"
        ></app-address-autocomplete>

        <app-map-location-picker
          (onLonLatSelected)="warehouse.latitude = $event[1]; warehouse.longitude = $event[0]"
          [selectedLon]="warehouse.longitude"
          [selectedLat]="warehouse.latitude"
        ></app-map-location-picker>
      </div>

      <div class="mt-2">
        <h4>
          <ng-container i18n="Default door availability settings"> Default door availability settings</ng-container>
        </h4>
        <app-availability-display [(availability)]="warehouse.availability"> </app-availability-display>
      </div>

      <div class="d-flex mt-4">
        <button class="btn btn-primary mr-2" (click)="Update()">
          <ng-container i18n="Save Changes"> Save Changes</ng-container>
        </button>
        <button class="btn ml-2" (click)="CancelUpdate()">
          <ng-container i18n="Cancel"> Cancel</ng-container>
        </button>
      </div>
      <br />
    </app-accordion-with-content>

    <br />

    <app-accordion-with-content i18n-label label="Email attachments">
      <small i18n class="mb-2 d-block">Configure email attachments that will be sent with every mail for this warehouse.</small>
      <app-mail-attachments-settings [warehouseId]="warehouse.id"></app-mail-attachments-settings>
      <br />
    </app-accordion-with-content>

    <app-accordion-with-content i18n-label label="Important reservation fields">
      <small i18n class="mb-2 d-block"
        >Reservation fields that the user needs to enter in order to pick the door on the warehouse. In doors settings you can pick which doors will appear
        based on values in these fields.</small
      >
      <app-reservation-fields [importantFieldWarehouseId]="warehouse.id"></app-reservation-fields>
      <br />
    </app-accordion-with-content>

    <app-accordion-with-content i18n-label label="Default door reservation fields settings">
      <app-reservation-fields [warehouseId]="warehouse.id"></app-reservation-fields>
    </app-accordion-with-content>
  </div>
</div>

<ng-template #deleteContent let-modal>
  <ng-container>
    <div class="modal-header">
      <h4 class="modal-title" i18n>Delete warehouse?</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <p *ngIf="!deleteLoading" i18n>
        Confirm deleting the warehouse
        {{ warehouse.name }}.
      </p>
      <div *ngIf="deleteLoading" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <button type="submit" class="btn" (click)="modal.close()" [disabled]="deleteLoading">
        <ng-container i18n="Cancel">Cancel</ng-container>
      </button>
      <button *ngIf="!deleteLoading" class="btn btn-danger" i18n="Change" (click)="Delete()">Delete</button>
      <div *ngIf="deleteLoading" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div></ng-container
  >
</ng-template>

<ng-template #addDoorContent let-modal>
  <app-add-door-modal
    [warehouse]="{ id: warehouse.id, name: warehouse.name }"
    (onCancel)="appModalService.closeModals()"
    (onAddSuccess)="appModalService.closeModals()"
  ></app-add-door-modal>
</ng-template>
