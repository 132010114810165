import { User } from "./User";
import { Door } from "./Door";
import { File } from "./File";
import { TimeWindow } from "./TimeWindow";
import { Warehouse } from "./Warehouse";
import { ReservationField, ReservationFieldType, reservationFieldTypeToDataTableEntryType } from "./ReservationField";
import { DataTableColumn, DataTableEntry, DataTableRowAction } from "../data-table/data-table.component";
import { RecurringReservationData, ReservationDto } from "./MyReservations";
import { faEdit, faEye, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
import { DateService } from "../date.service";
import { FileDetector } from "protractor";
import { SupportService } from "../services/support.service";

export class Reservation {
  id: number;
  code: string;
  carrierId: number;
  doorId: number;
  start: string;
  pdfFileName: string;
  end: string;
  date: Date;
  createdAt: Date;
  data: ReservationField[];
  carrier: User | null;
  door: Door;
  warehouseId: number;
  warehouse: Warehouse;
  timeWindow: TimeWindow;
  recurrenceRule: string | null;
  fromDate?: Date;
  toDate?: Date;
  files: Array<File> = [];
  reservationStatusUpdates: ReservationStatusUpdate[] = [];
  fixedTimeWindowId?: number;

  allowView: boolean;
  allowEdit: boolean;
  allowDelete: boolean;
  allowApprove: boolean;

  copyDataFrom(from: Reservation) {
    this.carrierId = from.carrierId;
    this.warehouseId = from.warehouseId;
    this.data = from.data;
    this.date = from.date;
    this.doorId = from.doorId;
    this.end = from.end;
    this.files = from.files;
    this.start = from.start;
    this.id = from.id;
  }
}

interface StaticReservationData {
  id: number;
  text: string;
  value: string;
  additionalData?: any;
  type: ReservationFieldType;
}

export const DataTableDateColumn = -3;

const applyStaticReservationData = (
  reservation: ReservationDto | null,
  staticDataFieldNames: any,
  dateService: DateService,
  supportService: SupportService
): StaticReservationData[] => {
  const staticReservationData: StaticReservationData[] = [];

  const warehouse = reservation?.warehouse;

  staticReservationData.push({
    id: -6,
    text: staticDataFieldNames.CODE,
    value: reservation?.code || "",
    type: ReservationFieldType.String,
  });

  staticReservationData.push({
    id: -1,
    text: staticDataFieldNames.WAREHOUSE,
    value: warehouse ? warehouse.name : "-",
    additionalData: warehouse
      ? {
          link: `/warehouse/${warehouse.id}`,
        }
      : null,
    type: ReservationFieldType.String,
  });

  staticReservationData.push({
    id: -2,
    text: staticDataFieldNames.DOOR,
    value: reservation?.door ? reservation.door.name : "-",
    additionalData: reservation?.door
      ? {
          link: `/door/${reservation.door.id}`,
        }
      : null,
    type: ReservationFieldType.String,
  });

  let date = "-";
  if (reservation?.standardReservationData) {
    date = `${dateService.toTime(reservation.standardReservationData.date, { onlyDate: true })}`;
    if (reservation.standardReservationData.start && reservation.standardReservationData.end) {
      date = `${date}; ${reservation.standardReservationData.start} - ${reservation.standardReservationData.end}`;
    }
  }

  staticReservationData.push({
    id: DataTableDateColumn,
    text: staticDataFieldNames.DATE,
    value: date,
    type: ReservationFieldType.String,
  });

  let recurringReservationRule = supportService.recurringReservationToString(reservation?.recurringReservationData);

  staticReservationData.push({
    id: -10,
    text: staticDataFieldNames.RECURRING_RESERVATION_RULE,
    value: recurringReservationRule,
    type: ReservationFieldType.String,
  });

  staticReservationData.push({
    id: -11,
    text: staticDataFieldNames.CREATED_AT,
    value: reservation ? dateService.toTime(reservation.createdAt) : "",
    type: ReservationFieldType.String,
  });

  staticReservationData.push({
    id: -4,
    text: staticDataFieldNames.CARRIER,
    value: reservation?.carrier?.name || "",
    type: ReservationFieldType.String,
  });

  staticReservationData.push({
    id: -5,
    text: staticDataFieldNames.COMPANY,
    value: reservation?.carrier?.title || "",
    type: ReservationFieldType.String,
  });

  return staticReservationData;
};

export const getStaticReservationColumns = (staticDataFieldNames: any, dateService: DateService, supportService: SupportService): DataTableColumn[] => {
  const staticReservationFields = applyStaticReservationData(null, staticDataFieldNames, dateService, supportService);
  return staticReservationFields.map((field) => ({
    ids: [field.id],
    text: field.text,
  }));
};

export const reservationToDataTableEntries = (
  reservation: ReservationDto,
  staticDataFieldNames: any,
  translateRecurrenceRule: (rule: string) => void,
  columns: DataTableColumn[],
  dateService: DateService,
  supportService: SupportService
): DataTableEntry[] => {
  const entries: DataTableEntry[] = [];
  const staticReservationData = applyStaticReservationData(reservation, staticDataFieldNames, dateService, supportService);
  for (let i = 0; i < staticReservationData.length; i++) {
    entries.push({
      columnIds: findColumnForColumnId(staticReservationData[i].id, columns)?.ids,
      value: staticReservationData[i].value,
      type: reservationFieldTypeToDataTableEntryType(staticReservationData[i].type),
      additionalData: staticReservationData[i].additionalData,
      selectOptions: [],
    });
  }

  entries.push(
    ...reservation.data.map((field): DataTableEntry => {
      let value = field.value;
      if (field.type === ReservationFieldType.Date && field.value) {
        value = dateService.formatDate(new Date(field.value));
      }

      return {
        columnIds: findColumnForColumnId(field.id, columns)?.ids,
        value,
        type: reservationFieldTypeToDataTableEntryType(field.type),
        selectOptions: field.selectValues?.values || [],
      };
    })
  );

  return entries;
};

const findColumnForColumnId = (columnId: number, columns: DataTableColumn[]) => {
  return columns.find((c) => c.ids.includes(columnId));
};

export const ReservationActions = {
  DETAILS: "details",
  APPROVE: "approve",
  EDIT: "edit",
  DELETE: "delete",
};

export const getReservationActions = (reservation: ReservationDto, actionTranslations: Record<string, string>) => {
  const actions: DataTableRowAction[] = [
    {
      actionName: ReservationActions.DETAILS,
      actionTranslation: actionTranslations.DETAILS,
      buttonClass: "btn-primary",
      icon: faEye,
    },
  ];

  if (reservation.allowApprove) {
    actions.push({
      actionName: ReservationActions.APPROVE,
      actionTranslation: actionTranslations.APPROVE,
      buttonClass: "btn-info",
      icon: faCheck,
    });
  }

  if (reservation.allowEdit) {
    actions.push({
      actionName: ReservationActions.EDIT,
      actionTranslation: actionTranslations.EDIT,
      buttonClass: "btn-info",
      icon: faEdit,
    });
  }

  if (reservation.allowDelete) {
    actions.push({
      actionName: ReservationActions.DELETE,
      actionTranslation: actionTranslations.DELETE,
      buttonClass: "btn-danger",
      icon: faTrash,
    });
  }

  return actions;
};

export enum ReservationStatus {
  AwaitingArrival,
  Arrived,
  UnloadingStarted,
  UnloadingDone,
  Departed,
}

class ReservationStatusUpdate {
  status: ReservationStatus;
  createdAt: Date;
}

export enum ReservationOperation {
  CREATE,
  UPDATE,
  DELETE,
}

export enum ReservationEmailType {
  STANDARD,
  TWO_PHASE,
  CONFIRM_TWO_PHASE,
  RECURRING,
}
